<template>
  <div class="home">
    <div class="m-bvi-banner">
      <p class="banner-p1">一站式塞浦路斯公司注册服务</p>
      <p class="banner-p2">【快企务专注于涉内外企业服务十余年】</p>
      <p class="banner-btn banner-btn1" @click="toAsk">立即咨询</p>
    </div>

    <div class="box" style="height: 313px;">
      <p class="box-p1">注册塞浦路斯公司优势</p>
      <div class="cy-adv">
        <div class="cy-adv-inner">
          <img class="cy-adv-icon" src="../../assets/img/register/Cyprus/pic_1_1.png" alt="">
          <p class="cy-adv-p">欧盟互免关税分红没有税收</p>
        </div>
        <div class="cy-adv-inner">
          <img class="cy-adv-icon" src="../../assets/img/register/Cyprus/pic_1_2.png" alt="">
          <p class="cy-adv-p">法律氛围良好国内税务宽松</p>
        </div>
        <div class="cy-adv-inner">
          <img class="cy-adv-icon" src="../../assets/img/register/Cyprus/pic_1_3.png" alt="">
          <p class="cy-adv-p">亚欧非交通要塞</p>
        </div>
        <div class="cy-adv-inner">
          <img class="cy-adv-icon" src="../../assets/img/register/Cyprus/pic_1_4.png" alt="">
          <p class="cy-adv-p">自由经济体系无外汇管制</p>
        </div>
        <div class="cy-adv-inner">
          <img class="cy-adv-icon" src="../../assets/img/register/Cyprus/pic_1_5.png" alt="">
          <p class="cy-adv-p">专业化设施世界最低犯罪率</p>
        </div>
        <div class="cy-adv-inner">
          <p class="cy-adv-p1" @click="toAsk">立即咨询 ></p>
        </div>
      </div>
    </div>

    <div class="box" style="height: auto;padding-bottom: 30px;">
      <p class="box-p1">注册塞浦路斯公司类型</p>
      <div class="type-content flex center-width">
        <img class="type-img" style="height: 110px;" src="../../assets/img/register/Cyprus/pic_2_1.jpg" alt="">
        <img class="type-img" style="height: 110px;" src="../../assets/img/register/Cyprus/pic_2_2.jpg" alt="">
        <img class="type-img" style="height: 110px;" src="../../assets/img/register/Cyprus/pic_2_3.jpg" alt="">
        <img class="type-img" style="height: 110px;" src="../../assets/img/register/Cyprus/pic_2_4.jpg" alt="">
        <img class="type-img" style="height: 110px;" src="../../assets/img/register/Cyprus/pic_2_5.jpg" alt="">
        <img class="type-img" @click="toAsk" style="height: 110px;" src="../../assets/img/register/Cyprus/pic_2_6.jpg" alt="">
      </div>
    </div>

    <div class="box" style="height: 649px;">
      <p class="box-p1">注册塞浦路斯公司条件</p>
      <div class="mall-box">
        <p class="mall-box-p1">名称</p>
        <p class="mall-box-p2">公司名称必须以“limited”结尾</p>
        <p class="mall-box-p1">秘书</p>
        <p class="mall-box-p2">必须有秘书，可为自然人或法人，无当地秘书要求</p>
        <p class="mall-box-p1">注册资本</p>
        <p class="mall-box-p2">不需验资，没有最低注册资本限制</p>
        <p class="mall-box-p1">注册地址</p>
        <p class="mall-box-p2">必须有当地的注册地址</p>
        <p class="mall-box-p1">股东/董事</p>
        <p class="mall-box-p2">股东/董事至少1人，可以为自然人或法人，无当地居民要求</p>
        <p class="mall-box-p1">审计报告</p>
        <p class="mall-box-p2">每年需交审计报告</p>
        <p class="mall-box-p1">国际信托</p>
        <p class="mall-box-p2">塞浦路斯国际信托公司，家庭理财管理或财富保护</p>
      </div>
      <img class="mall-box-img" style="height: 180px;width: 220px;margin-top: 30px;" src="../../assets/img/register/Cyprus/pic_3.jpg" alt="">
    </div>

    <div class="box" style="height: 461px;">
      <p class="box-p1">注册塞浦路斯公司流程</p>
      <div class="mall-box">
        <p class="cyp-flow-p"><span class="number">1</span><span class="step">第一步：</span>3个备选公司名称</p>
        <p class="cyp-flow-p"><span class="number">2</span><span class="step">第二步：</span>安排文书</p>
        <p class="cyp-flow-p"><span class="number">3</span><span class="step">第三步：</span>交付费用</p>
        <p class="cyp-flow-p"><span class="number">4</span><span class="step">第四步：</span>签署制作法定稳定，提供文档、护照原件</p>
        <p class="cyp-flow-p"><span class="number">5</span><span class="step">第五步：</span>政府部门办理相关手续</p>
        <p class="cyp-flow-p"><span class="number">6</span><span class="step">第六步：</span>完成注册，开具发票，领取资料</p>
      </div>
      <img class="mall-box-img" style="height: 150px;width: 283px;margin-top: 30px;" src="../../assets/img/register/Cyprus/pic_4_7.jpg" alt="">
    </div>
    <div class="box" style="height: 506px;">
      <p class="box-p1">注册完成后所得资料</p>
      <div class="mall-box">
        <p class="mall-box-p1" style="margin-bottom: 12px;">以希腊语为官方语言的公司执照和备忘录</p>
        <p class="mall-box-p1" style="margin-bottom: 12px;">公司注册证、董事证明、股东证明</p>
        <p class="mall-box-p1" style="margin-bottom: 12px;">记名股东和提名董事签发的无期限股票转让文件和无期限辞职信（是否记名可选）</p>
        <p class="mall-box-p1" style="margin-bottom: 12px;">记名股东的委托书</p>
        <p class="mall-box-p1" style="margin-bottom: 12px;">受益人保障提名者行为的赔偿契约</p>
      </div>
      <img class="mall-box-img" style="height: 210px;width: 250px;margin-top: 30px;" src="../../assets/img/register/Cyprus/pic_5.jpg" alt="">
    </div>
    
    <div class="box" style="height: 725px;background: rgba(242, 245, 250, 1);">
      <p class="box-p1" style="width: 100vw;">注册塞浦路斯公司后为您提供的后续服务</p>
      <div class="re-serve">
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Cyprus/pic_6_1.png" alt="">
          <p class="re-serve-p1">年审/续牌</p>
          <p class="re-serve-p2">公司年审：必须每年交审计报告，提交周年申报表，进行塞浦路斯公司年审</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Cyprus/pic_6_2.png" alt="">
          <p class="re-serve-p1">银行开户</p>
          <p class="re-serve-p2">塞浦路斯银行，银行设有中国业务部门，由说中文的银行员工为中国客户提供个性化服务 </p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Cyprus/pic_6_3.png" alt="">
          <p class="re-serve-p1">做账报税</p>
          <p class="re-serve-p2">塞浦路斯企业税为净利润的10%。塞浦路斯本地公司应对其全球所有收入缴纳企业所得税，为属人税制。每3个月需要提交一次VAT报告</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Cyprus/pic_6_4.png" alt="">
          <p class="re-serve-p1">税号申请</p>
          <p class="re-serve-p2">根据税收征收与评估法（1978-2016）第5、5A条的规定，税务管理部门出于身份识别目的而发放给实体的纳税人识别号</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Cyprus/pic_6_5.png" alt="">
          <p class="re-serve-p1">秘书服务</p>
          <p class="re-serve-p2">根据税收征收与评估法（1978-2016）第5、5A条的规定，税务管理部门出于身份识别目的而发放给实体的纳税人识别号</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Cyprus/pic_6_6.png" alt="">
          <p class="re-serve-p1">公司注销</p>
          <p class="re-serve-p2">因破产、股东决议解散、存续期届满等原因需要注销的，应及时主动注销公司</p>
        </div>
      </div>
    </div>

    <div class="box" style="height: auto;padding-bottom: 30px;">
      <p class="box-p1">塞浦路斯公司注册常见问题</p>
      <p class="box-p2">解决您的一切疑问</p>
      <div class="question">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item title="塞浦路斯公司对公司名有什么要求？" name="1">
            <p class="question-p">所有公司名，要以Limited或者Ltd.结尾。</p>
          </el-collapse-item>
          <el-collapse-item title="塞浦路斯需要公司秘书吗？" name="2">
            <p class="question-p">成立公司要委任秘书，秘书可以是公司法人或任何国籍的自然人。</p>
          </el-collapse-item>
          <el-collapse-item title="塞浦路斯是欧盟成员国，那注册塞浦路斯公司是否出口欧盟国家产品，就可以免26个成员国的关税吗？" name="3">
            <p class="question-p">是的，但要看货物如何流通，欧盟内是免税的，但仍然要申请税号，申报免税。</p>
          </el-collapse-item>
        </el-collapse>
      </div>
      <p class="to-ask" @click="toAsk"> 
        <img class="to-ask-icon" src="../../assets/img/register/bvi/icon_6_customer_service.png" alt=""> 
        更多问题？立即咨询人工客服</p>
    </div>
    <div class="box" style="height: 480px;">
      <p class="box-p1">快企务的优势</p>
      <p class="box-p2">十余年注册塞浦路斯公司经验</p>
      <div class="re-adv">
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_1.png" alt="">
          <p class="re-adv-p1">保密完善</p>
          <p class="re-adv-p2">保密系统完善，客户隐私不外泄</p>
        </div>
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_2.png" alt="">
          <p class="re-adv-p1">全程服务</p>
          <p class="re-adv-p2">一站式专业服务从注册公司到年审、开户，审计报税等</p>
        </div>
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_3.png" alt="">
          <p class="re-adv-p1">专业高效</p>
          <p class="re-adv-p2">根据需求，为客户制定专业快捷的解决方案</p>
        </div>
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_4.png" alt="">
          <p class="re-adv-p1">合同保障</p>
          <p class="re-adv-p2">正规机构，合同保障，费用透明，无隐形消费</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  data(){
    return{
      tab: 1,
      activeName: '1'
    }
  },
  mounted(){
    window.scrollTo(0,0);
  },
  methods:{
    toAsk(){
      window.open('https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn','_blank')
    },
  }
}
</script>
<style scoped>
  .m-bvi-banner{
    width: 100%;
    height: 460px;
    background: url('../../assets/img/register/Cyprus/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
</style>
