import { render, staticRenderFns } from "./Singapore.vue?vue&type=template&id=323e4098&scoped=true&"
import script from "./Singapore.vue?vue&type=script&lang=js&"
export * from "./Singapore.vue?vue&type=script&lang=js&"
import style0 from "./Singapore.vue?vue&type=style&index=0&id=323e4098&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "323e4098",
  null
  
)

export default component.exports