<template>
  <div class="home">
    <div class="m-bvi-banner">
      <p class="banner-p1">一站式法国公司注册服务</p>
      <p class="banner-p2">【快企务专注于涉内外企业服务十余年】</p>
      <p class="banner-btn banner-btn1" @click="toAsk">立即咨询</p>
    </div>

    <div class="box" style="height:612px;background: rgba(242, 245, 250, 1);">
      <p class="box-p1">注册法国公司优势</p>
      <div class="adv-content">
        <div class="adv-line" style="height: 97px;align-items: flex-start;">
          <img class="adv-icon" style="width: 42px;margin-top: 20px;" src="../../assets/img/register/French/pic_1_1.png" alt="">
          <div>
            <p class="adv-p" style="font-weight: bold;padding-top: 20px;">法国公司运营成本更低</p>
            <p class="adv-p5">仅包括会计及税务师费用、公司地址费用，银行账户费用等</p>
          </div>
        </div>
        <div class="adv-line" style="height: 97px;">
          <img class="adv-icon" style="width: 42px;" src="../../assets/img/register/French/pic_1_2.png" alt="">
          <div>
            <p class="adv-p">入驻亚马逊或其他电商平台</p>
            <p class="adv-p5">有些电商平台硬性要求有法国本土公司</p>
          </div>
        </div>
        <div class="adv-line" style="height: 97px;"> 
          <img class="adv-icon" style="width: 42px;" src="../../assets/img/register/French/pic_1_3.png" alt="">
          <div>
            <p class="adv-p">打造国际品牌</p>
            <p class="adv-p5">法国品牌国际知名度高，有利于提升企业及产品的品牌形象</p>
          </div>
        </div>
        <div class="adv-line" style="height: 97px;">
          <img class="adv-icon" style="width: 42px;" src="../../assets/img/register/French/pic_1_4.png" alt="">
          <div>
            <p class="adv-p">法国增值税VAT获得更快</p>
            <p class="adv-p5">注册提交后，同时在税务部门登记申请，即可同步获得VAT增值税号</p>
          </div>
        </div>
        <div class="adv-line" style="height: 97px;">
          <img class="adv-icon" style="width: 42px;" src="../../assets/img/register/French/pic_1_5.png" alt="">
          <div>
            <p class="adv-p">法国公司税收政策更优惠</p>
            <p class="adv-p5">国税率处于欧洲中等偏下水平，最高25%，最低15%</p>
          </div>
        </div>
      </div>
    </div>

    <div class="box" style="height: auto;padding-bottom: 30px;">
      <p class="box-p1">注册法国公司类型</p>
      <div class="type-content flex center-width">
        <img class="type-img" style="height: 110px;" src="../../assets/img/register/French/pic_2_1.jpg" alt="">
        <img class="type-img" style="height: 110px;" src="../../assets/img/register/French/pic_2_2.jpg" alt="">
        <img class="type-img" style="height: 110px;" src="../../assets/img/register/French/pic_2_3.jpg" alt="">
        <img class="type-img" style="height: 110px;" src="../../assets/img/register/French/pic_2_4.jpg" alt="">
      </div>
    </div>
    <div class="box bg-box" style="height: auto;padding-bottom: 15px;">
      <p class="box-p1">注册法国公司条件/流程/资料</p>
      <div class="tab-box">
        <div class="tab-inner" :class="{'active': tab == 1}" @click="tab = 1">注册条件</div>
        <div class="tab-inner" :class="{'active': tab == 2}" @click="tab = 2">注册流程</div>
        <div class="tab-inner" :class="{'active': tab == 3}" @click="tab = 3">所得资料</div>
      </div>
      <div class="file-content">
        <div v-if="tab == 1">
          <div class="french-flow">
            <div class="french-flow-inner">
              <img class="french-flow-icon" src="../../assets/img/register/French/pic_3_1.png" alt="">
              <p class="french-flow-p1">名称</p>
              <p class="french-flow-p2">公司名称的后尾必须加上Soci-été à respon-sabilité limitée（有限责任公司）字样或其开头字母“S.A.R.L”</p>
            </div>
            <div class="french-flow-inner">
              <img class="french-flow-icon" src="../../assets/img/register/French/pic_3_2.png" alt="">
              <p class="french-flow-p1">注册资本</p>
              <p class="french-flow-p2">没有实际要求，不需要实际到位；（如需开户，资金需要实际到位）</p>
            </div>
            <div class="french-flow-inner">
              <img class="french-flow-icon" src="../../assets/img/register/French/pic_3_3.png" alt="">
              <p class="french-flow-p1">董事股东要求</p>
              <p class="french-flow-p2">1人或以上年满18周岁的人士担任，无国籍限制</p>
            </div>
            <div class="french-flow-inner">
              <img class="french-flow-icon" src="../../assets/img/register/French/pic_3_4.png" alt="">
              <p class="french-flow-p1">注册地址</p>
              <p class="french-flow-p2">公司注册地址必须是法国，我司可提供</p>
            </div>
            <div class="french-flow-inner">
              <img class="french-flow-icon" src="../../assets/img/register/French/pic_3_5.png" alt="">
              <p class="french-flow-p1">持股率</p>
              <p class="french-flow-p2">如果是多个股东，每个股东的持股率</p>
            </div>
            <div class="french-flow-inner">
              <img class="french-flow-icon" src="../../assets/img/register/French/pic_3_6.png" alt="">
              <p class="french-flow-p1">经营范围</p>
              <p class="french-flow-p2">公司的营业范围（请详细介绍）</p>
            </div>
          </div>
        </div>
        <div v-if="tab == 2">
          <div class="ger-tab2">
            <div class="ger-tab2-inner">
              <img class="ger-tab2-icon" src="../../assets/img/register/French/pic_4_1.png" alt="">
              <p class="ger-tab2-p1">步骤一</p>
              <p class="ger-tab2-p2">客户与快企务签订委托书 </p>
            </div>
            <div class="ger-tab2-inner">
              <img class="ger-tab2-icon" src="../../assets/img/register/French/pic_4_2.png" alt="">
              <p class="ger-tab2-p1">步骤二</p>
              <p class="ger-tab2-p2">双方签订协议书</p>
            </div>
            <div class="ger-tab2-inner">
              <img class="ger-tab2-icon" src="../../assets/img/register/French/pic_4_3.png" alt="">
              <p class="ger-tab2-p1">步骤三</p>
              <p class="ger-tab2-p2">准备公司注册文件提交审核</p>
            </div>
            <div class="ger-tab2-inner">
              <img class="ger-tab2-icon" src="../../assets/img/register/French/pic_4_4.png" alt="">
              <p class="ger-tab2-p1">步骤四</p>
              <p class="ger-tab2-p2">审核通过，获取营业执照</p>
            </div>
            <div class="ger-tab2-inner">
              <img class="ger-tab2-icon" src="../../assets/img/register/French/pic_4_5.png" alt="">
              <p class="ger-tab2-p1">步骤五</p>
              <p class="ger-tab2-p2">申请银行开设公司账户</p>
            </div>
            <div class="ger-tab2-inner">
              <img class="ger-tab2-icon" src="../../assets/img/register/French/pic_4_6.png" alt="">
              <p class="ger-tab2-p1">步骤六</p>
              <p class="ger-tab2-p2">完成注册，邮寄公司文件给客户</p>
            </div>
          </div>
        </div>
        <div v-if="tab == 3">
          <div class="ger-tab3">
          <div class="ger-tab3-inner fre-tab3">
            <img class="ger-tab3-icon" src="../../assets/img/register/French/pic_5_1.png" alt="">
            <p class="ger-tab3-p">营业执照</p>
          </div>
          <div class="ger-tab3-inner fre-tab3">
            <img class="ger-tab3-icon" src="../../assets/img/register/French/pic_5_2.png" alt="">
            <p class="ger-tab3-p">印章</p>
          </div>
          <div class="ger-tab3-inner fre-tab3">
            <img class="ger-tab3-icon" src="../../assets/img/register/French/pic_5_3.png" alt="">
            <p class="ger-tab3-p">印章卡</p>
          </div>
          <div class="ger-tab3-inner fre-tab3">
            <img class="ger-tab3-icon" src="../../assets/img/register/French/pic_5_4.png" alt="">
            <p class="ger-tab3-p">章程</p>
          </div>
          <div class="ger-tab3-inner fre-tab3">
            <img class="ger-tab3-icon" src="../../assets/img/register/French/pic_5_5.png" alt="">
            <p class="ger-tab3-p">印章证明书</p>
          </div>
         </div>
        </div>
      </div>
    </div>
    
    <div class="box" style="height: 725px;">
      <p class="box-p1">注册法国公司后为您提供的后续服务</p>
      <div class="re-serve">
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/French/pic_6_1.png" alt="">
          <p class="re-serve-p1">税号申请</p>
          <p class="re-serve-p2">法国VAT注册需要1-2个月左右，一年12次月申报，不需要做年度申报，法国申报税率是20%，适用于大部分商品和服务</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/French/pic_6_2.png" alt="">
          <p class="re-serve-p1">商标注册</p>
          <p class="re-serve-p2">到国家工业产权局登记或到公司所在地区的商业法院登记。法国商标保护期为10年。期满后半年内可以续展。</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/French/pic_6_3.png" alt="">
          <p class="re-serve-p1">银行开户</p>
          <p class="re-serve-p2">法国巴黎银行、法国兴业银行、里昂信贷银行、法国工商信贷银行等</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/French/pic_6_4.png" alt="">
          <p class="re-serve-p1">做账报税</p>
          <p class="re-serve-p2">首年年底前向税务局提交1003P号表格，之后每年5月1日前提交1003号表格申明公司财产等</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/French/pic_6_5.png" alt="">
          <p class="re-serve-p1">秘书服务</p>
          <p class="re-serve-p2">注册代理人或本地人代理人服务，办公地址，公司周年报表年检，公司董事股东成员名册更新，在职证明与良好证明，代缴政府规费，修改公司章程等</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/French/pic_6_6.png" alt="">
          <p class="re-serve-p1">公司注销</p>
          <p class="re-serve-p2">公司不再运营，或宣告破产、营业期限届满，公司内部分离解散等可以申请注销，注销3个月内无人提出异议即可陈宫注销</p>
        </div>
      </div>
    </div>

    <div class="box" style="height: auto;padding-bottom: 30px;background: rgba(242, 245, 250, 1)">
      <p class="box-p1">法国公司注册常见问题</p>
      <p class="box-p1">解决您的一切疑问</p>
      <div class="question">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item title="注册法国公司需要多久？是否需要亲自过去？" name="1">
            <p class="question-p">法国公司从初期准备材料到后面拿到公司文件，一共需要大约3周半，法人代表及股东不需要实际到场。</p>
          </el-collapse-item>
          <el-collapse-item title="法国公司名称有什么限制吗？" name="2">
            <p class="question-p">(1) 公司名称避免带有完全外来名词，也不能在公司名称加上“法国”字眼。
(2) 公司应使用法语一般用词。
(3) 公司名的后尾须加上“Société à responsabilité limitée”字样或其开头字母“S、A、R、L</p>
          </el-collapse-item>
          <el-collapse-item title="法国公司董事有什么要求？" name="3">
            <p class="question-p">至少有一个董事，该董事须是自然人。董事可以是任何国籍的人，并且不必居住在法国。董事会只涉及S.A.(股
份有限公司)，S.A.R.L(责任有限公司)只设定一个经营或持牌人(GERANT)管理公司。章程中应规定董事会的构
成形式及其权利，在法律上，董事会享有代表公司进行活动的充分权利。董事会应设董事长一人，董事若干人</p>
          </el-collapse-item>
        </el-collapse>
      </div>
      <p class="to-ask" @click="toAsk"> 
        <img class="to-ask-icon" src="../../assets/img/register/bvi/icon_6_customer_service.png" alt=""> 
        更多问题？立即咨询人工客服</p>
    </div>
    <div class="box" style="height: 480px;">
      <p class="box-p1">快企务的优势</p>
      <p class="box-p2">十余年注册法国公司经验</p>
      <div class="re-adv">
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_1.png" alt="">
          <p class="re-adv-p1">保密完善</p>
          <p class="re-adv-p2">保密系统完善，客户隐私不外泄</p>
        </div>
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_2.png" alt="">
          <p class="re-adv-p1">全程服务</p>
          <p class="re-adv-p2">一站式专业服务从注册公司到年审、开户，审计报税等</p>
        </div>
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_3.png" alt="">
          <p class="re-adv-p1">专业高效</p>
          <p class="re-adv-p2">根据需求，为客户制定专业快捷的解决方案</p>
        </div>
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_4.png" alt="">
          <p class="re-adv-p1">合同保障</p>
          <p class="re-adv-p2">正规机构，合同保障，费用透明，无隐形消费</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  data(){
    return{
      tab: 1,
      activeName: '1'
    }
  },
 
  mounted(){
    window.scrollTo(0,0);
  },
  methods:{
    toAsk(){
      window.open('https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn','_blank')
    },
  }
}
</script>
<style scoped>
  .m-bvi-banner{
    width: 100%;
    height: 460px;
    background: url('../../assets/img/register/French/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
</style>
