import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vueI18n from 'vue-i18n'
import './assets/font/font.css'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);



import axios from 'axios'

Vue.prototype.$axios = axios

axios.defaults.baseURL = '/api'

Vue.use(ElementUI);

Vue.use(vueI18n)
const locale=localStorage.getItem('lang')
const i18n=new vueI18n({
	locale:locale||'zh',
	messages:{
		'zh':require('@/assets/lang/zh'),
		'en':require('@/assets/lang/en'),
	}
})

import scroll from 'vue-seamless-scroll'
Vue.use(scroll)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
