<template>
  <div class="home">
    <div class="m-bvi-banner">
      <p class="banner-p1">一站式毛里求斯公司注册服务</p>
      <p class="banner-p2">【快企务专注于涉内外企业服务十余年】</p>
      <p class="banner-btn banner-btn1" @click="toAsk">立即咨询</p>
    </div>

    <div class="box" style="height: auto;padding-bottom: 20px; background: rgba(242, 245, 250, 1);">
      <p class="box-p1">注册毛里求斯公司优势</p>
      <div class="adv-content">
        <div class="adv-line">
          <img class="adv-icon" src="../../assets/img/register/Mauritius/pic_1_1.png" alt="">
          <p class="adv-p" style="width: 250px;">可免交公司税、所得税、利润税、资产税等税项</p>
        </div>
        <div class="adv-line">
          <img class="adv-icon" src="../../assets/img/register/Mauritius/pic_1_2.png" alt="">
          <p class="adv-p">可用公司名称买卖物业</p>
        </div>
        <div class="adv-line">
          <img class="adv-icon" src="../../assets/img/register/Mauritius/pic_1_3.png" alt="">
          <p class="adv-p" style="width: 250px;">无外汇管制，对资本投资非常开放，可以双向自由流进流出，允许外国人百分百控股</p>
        </div>
        <div class="adv-line">
          <img class="adv-icon" src="../../assets/img/register/Mauritius/pic_1_4.png" alt="">
          <p class="adv-p">不必每年提交公司账目</p>
        </div>
        <div class="adv-line">
          <img class="adv-icon" src="../../assets/img/register/Mauritius/pic_1_5.png" alt="">
          <p class="adv-p" style="width: 250px;">政府为企业提供隐私保护，不会公开公司受益人身份</p>
        </div>
        <div class="adv-line">
          <img class="adv-icon" src="../../assets/img/register/Mauritius/pic_1_6.png" alt="">
          <p class="adv-p">每年不需办理税务申报及财务报表申请</p>
        </div>
      </div>
    </div>

    <div class="box" style="height: auto;padding-bottom: 30px;">
      <p class="box-p1">注册毛里求斯公司类型</p>
      <div class="type-content flex center-width">
        <img class="type-img" style="height: 110px;" src="../../assets/img/register/Mauritius/pic_2_1.jpg" alt="">
        <img class="type-img" style="height: 110px;" src="../../assets/img/register/Mauritius/pic_2_2.jpg" alt="">
      </div>
    </div>

    <div class="box" style="height: auto;padding-bottom: 15px;">
      <p class="box-p1">注册毛里求斯公司的条件/流程/资料</p>
      <div class="tab-box">
        <div class="tab-inner" :class="{'active': tab == 1}" @click="tab = 1">注册条件</div>
        <div class="tab-inner" :class="{'active': tab == 2}" @click="tab = 2">注册流程</div>
        <div class="tab-inner" :class="{'active': tab == 3}" @click="tab = 3">所得资料</div>
      </div>
      <div class="file-content">
        <div v-if="tab == 1">
          <div class="bri-flow" style="justify-content: flex-start;">
            <div class="bri-flow-inner" style="width: 50%;">
              <img class="bri-flow-icon" src="../../assets/img/register/Mauritius/pic_3_1.png" alt="">
              <p class="bri-flow-p1" style="width: 137px;margin: 0 auto;margin-top: 15px">2或3个拟定公司名称</p>
            </div>
            <div class="bri-flow-inner" style="width: 50%;">
              <img class="bri-flow-icon" src="../../assets/img/register/Mauritius/pic_3_2.png" alt="">
              <p class="bri-flow-p1" style="width: 137px;margin: 0 auto;margin-top: 15px">银行推荐信</p>
            </div>
            <div class="bri-flow-inner" style="width: 50%;">
              <img class="bri-flow-icon" src="../../assets/img/register/Mauritius/pic_3_3.png" alt="">
              <p class="bri-flow-p1" style="width: 137px;margin: 0 auto;margin-top: 15px">股东护照复印件（可先发送扫描的复印件，后期需要提供公证的复印件原件）</p>
            </div>
            <div class="bri-flow-inner" style="width: 50%;">
              <img class="bri-flow-icon" src="../../assets/img/register/Mauritius/pic_3_4.png" alt="">
              <p class="bri-flow-p1" style="width: 137px;margin: 0 auto;margin-top: 15px">水电费账单复印件（近3个月内的），可先发送扫描的复印件，然后提供公证的账单复印件或账单原件</p>
            </div>
            <div class="bri-flow-inner" style="width: 50%;">
              <img class="bri-flow-icon" src="../../assets/img/register/Mauritius/pic_3_5.png" alt="">
              <p class="bri-flow-p1" style="width: 137px;margin: 0 auto;margin-top: 15px">股东简历（需股东签字）</p>
            </div>
          </div>
        </div>
        <div v-if="tab == 2">
          <img class="tab2-img" src="../../assets/img/register/Mauritius/pic_4.png" alt="">
        </div>
        <div v-if="tab == 3">
          <div class="ger-tab3">
            <div class="ger-tab3-inner fre-tab3">
              <img class="ger-tab3-icon" src="../../assets/img/register/Mauritius/pic_5_1.png" alt="">
              <p class="ger-tab3-p">注册证书（简称IC）</p>
            </div>
            <div class="ger-tab3-inner fre-tab3">
              <img class="ger-tab3-icon" src="../../assets/img/register/Mauritius/pic_5_2.png" alt="">
              <p class="ger-tab3-p">公司章程3本</p>
            </div>
            <div class="ger-tab3-inner fre-tab3">
              <img class="ger-tab3-icon" src="../../assets/img/register/Mauritius/pic_5_3.png" alt="">
              <p class="ger-tab3-p">股票本</p>
            </div>
            <div class="ger-tab3-inner fre-tab3">
              <img class="ger-tab3-icon" src="../../assets/img/register/Mauritius/pic_5_4.png" alt="">
              <p class="ger-tab3-p"> 钢印、签字原子印各一枚</p>
            </div>
            <div class="ger-tab3-inner fre-tab3">
              <img class="ger-tab3-icon" src="../../assets/img/register/Mauritius/pic_5_5.png" alt="">
              <p class="ger-tab3-p"> 申请注册毛里求斯公司文件复印件一套</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <div class="box" style="height: 725px;background: rgba(242, 245, 250, 1);">
      <p class="box-p1">注册毛里求斯公司后为您提供的后续服务</p>
      <div class="re-serve">
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Vietnam/pic_6_1.png" alt="">
          <p class="re-serve-p1">年审/续牌</p>
          <p class="re-serve-p2">所有公司须按年度定时递交包含股东、董事、公司秘书和其他任何当年可能影响公司行政管理结构详情信息的公司年度报表</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Vietnam/pic_6_2.png" alt="">
          <p class="re-serve-p1">秘书服务</p>
          <p class="re-serve-p2">办公地址，公司周年报表年检，公司董事股东成员名册更新，在职证明与良好证明，代缴政府规费，修改公司章程等 </p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Vietnam/pic_6_3.png" alt="">
          <p class="re-serve-p1">做账报税</p>
          <p class="re-serve-p2">公司注册之后需按照当地政府单位要求进行做账报税</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Vietnam/pic_6_4.png" alt="">
          <p class="re-serve-p1">银行开户</p>
          <p class="re-serve-p2">开设公司注册所在地的银行账户，有利于公司随时接受贸易汇款，享受当地政策或规避外汇风险</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Vietnam/pic_6_5.png" alt="">
          <p class="re-serve-p1">税号申请</p>
          <p class="re-serve-p2">公司经营过程中的公司信息（如地址、股东、经营范围等）变更</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Vietnam/pic_6_6.png" alt="">
          <p class="re-serve-p1">公司注销</p>
          <p class="re-serve-p2">因破产、股东决议解散、存续期届满等原因需要注销的，应及时主动注销公司</p>
        </div>
      </div>
    </div>
    <div class="box" style="height: auto;padding-bottom: 30px;background: rgba(242, 245, 250, 1)">
      <p class="box-p1">毛里求斯公司注册常见问题</p>
      <p class="box-p1">解决您的一切疑问</p>
      <div class="question">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item title="毛里求斯查名要多久？" name="1">
            <p class="question-p">不含中文查名最快要3个工作日左右，一般1周。</p>
          </el-collapse-item>
          <el-collapse-item title="毛里求斯公司名称是不是能直接用中文，英文可以不加是吗？" name="2">
            <p class="question-p">不可以单独用中文名称，毛里求斯公司12月份要年审的。</p>
          </el-collapse-item>
          <el-collapse-item title="毛里求斯公司类型有哪些？" name="3">
            <p class="question-p">毛里求斯国外公司可分二类：国际公司International Company（IC）及海外公司Ordinary Status
Company（OS）。二者皆是境外所得免税，若是要享受毛里求斯与其它国家所签定的租税协议的优惠，则
需选择毛里求斯海外公司（OS），且每年需将公司经会计师签证之税务报表呈交给当地政府备查。</p>
          </el-collapse-item>
        </el-collapse>
      </div>
      <p class="to-ask" @click="toAsk"> 
        <img class="to-ask-icon" src="../../assets/img/register/bvi/icon_6_customer_service.png" alt=""> 
        更多问题？立即咨询人工客服</p>
    </div>

    <div class="box" style="height: 480px;">
      <p class="box-p1">快企务的优势</p>
      <p class="box-p2">十余年注册毛里求斯公司经验</p>
      <div class="re-adv">
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_1.png" alt="">
          <p class="re-adv-p1">保密完善</p>
          <p class="re-adv-p2">保密系统完善，客户隐私不外泄</p>
        </div>
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_2.png" alt="">
          <p class="re-adv-p1">全程服务</p>
          <p class="re-adv-p2">一站式专业服务从注册公司到年审、开户，审计报税等</p>
        </div>
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_3.png" alt="">
          <p class="re-adv-p1">专业高效</p>
          <p class="re-adv-p2">根据需求，为客户制定专业快捷的解决方案</p>
        </div>
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_4.png" alt="">
          <p class="re-adv-p1">合同保障</p>
          <p class="re-adv-p2">正规机构，合同保障，费用透明，无隐形消费</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  data(){
    return{
      tab: 1,
      activeName: '1'
    }
  },
  mounted(){
    window.scrollTo(0,0);
  },
  methods:{
    toAsk(){
      window.open('https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn','_blank')
    },
  }
}
</script>
<style scoped>
  .m-bvi-banner{
    width: 100%;
    height: 460px;
    background: url('../../assets/img/register/Mauritius/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
</style>
