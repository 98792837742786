<template>
  <div class="certification m-bvi">
    <div class="m-bvi-banner">
      <div class="banner-tag">
        <p class="banner-tag-inner">无需回澳大利亚</p>
        <p class="banner-tag-inner">平台式申请</p>
        <p class="banner-tag-inner">合同保障</p>
      </div>
      <p class="banner-p1" style="padding-top: 20px">澳大利亚无犯罪记录申请</p>
      <p class="banner-p2">移民 | 公证签证 | 永久居留权 | 婚姻 | 其他证明</p>
      <p class="banner-btn banner-btn1" @click="toAsk">立即咨询</p>
    </div>
    <div class="box" style="height: 373px;background: #fff;">
      <p class="box-p1">申请澳大利亚无犯罪证明所需材料</p>
      <p class="box-p2">请填写以下表单留下您的信息，我们将第一时间联系您，谢谢！</p>
      <div class="form-box">
        <div class="form-line">
          <p class="form-label">姓名</p>
          <input class="form-input" placeholder="请输入您的姓名" type="text" v-model="form.name">
        </div>
        <div class="form-line">
          <p class="form-label">电话</p>
          <input class="form-input" type="text" placeholder="请输入您的联系方式" v-model="form.mobile">
        </div>
        <div class="form-line">
          <p class="form-label">您的需求</p>
          <input class="form-input" type="text" placeholder="请输入您的需求" v-model="form.remark">
        </div>
      </div>
      <p class="form-submit" @click="toSubmit">提交</p>
    </div>
    <div class="box" style="background: rgba(242, 245, 250, 1);height: 784px;">
      <p class="box-p1">申请澳大利亚无犯罪证明所需材料</p>
      <div class="fingle-box" style="margin-top: 30px">
        <p class="fingle-p1">基于指纹无犯罪</p>
        <p class="fingle-p2">电子版可申请加急</p>
        <p class="fingle-p3">具体收费请咨询客户经理</p>
        <p class="fingle-p4">可办理移民、绿卡</p>
        <p class="fingle-p4">需15 ~ 25个工作日</p>
        <p class="fingle-p4">需护照扫描件、澳公民证/驾照扫描件、过去十年的地址、各类联系方式及行用卡</p>
      </div>
      <div class="fingle-box fingle-box1">
        <p class="fingle-p1">基于名字无犯罪</p>
        <p class="fingle-p2">电子版可申请加急</p>
        <p class="fingle-p3">具体收费请咨询客户经理</p>
        <p class="fingle-p4">可办理工作、入职、留学、续签证</p>
        <p class="fingle-p4">需15个工作日</p>
        <p class="fingle-p4">需护照扫描件、澳公民证/驾照扫描件、过去十年的地址、各类联系方式及行用卡</p>
      </div>
      <p class="fingle-tips">注：全国各城市公证处均可采集公证指纹</p>
    </div>
    
    <div class="box" style="height: 504px;background: #fff;">
      <p class="box-p1" style="width: 96%;">哪个部门可出具澳大利亚无犯罪记录证明</p>
      <!-- <p class="box-p2" style="margin-bottom: 10px">加拿大驻中国大使馆或领事馆并不能出具加拿大无犯罪记录证明，申请人需通过加拿大皇家警察和地方警察署来申请加拿大无犯罪记录证明</p> -->
      <div class="lunbo-box cer-lunbo-box" >
          <van-swipe class="my-swipe" :autoplay="3000" indicator-color="rgba(47, 114, 235, 1)" >
            <van-swipe-item>
              <div class="noc-swiper-box">
                <img class="noc-swiper-img" src="../../assets/img/noCriminal/Australia/pic_2.jpg" alt="">
                <div class="noc-swiper-bottom">
                  <p class="noc-swiper-p1">澳大利亚联邦警局AFP</p>
                  <p class="noc-swiper-p2">作用范围：澳大利亚全境</p>
                  <p class="noc-swiper-p3">指纹采集（Fingerprint acquisition）鉴定方式名字（Name Based）检索的方式</p>
                  <p class="form-submit" style="width: 88px;margin: 20px 0 0 20px;">立即咨询</p>
                  <p class="noc-swiper-p5" style="top: 5px">基于指纹无犯罪</p>
                  <p class="noc-swiper-p5" style="top: 40px">基于名字无犯罪</p>
                </div>
              </div>
            </van-swipe-item>
          </van-swipe>
        </div>
    </div>

    <div class="box" style="background: rgba(242, 245, 250, 1);height: 516px;">
      <p class="box-p1">哪些人可以申请澳大利亚无犯罪证明</p>
      <p class="box-p1" style="padding-top: 0;font-size: 14px;font-weight: bold;margin-top: 20px;">在澳连续居住12个月以上的外国人</p>
      <p class="box-p2" style="margin-bottom: 20px">澳大利亚没有户口管理制度，整个国家的警署记录都是全国联网的，澳大利亚公民或者在澳大利亚居住的时间连续超过12个月以上的外国人均可以申请。</p>
      <p class="form-submit" style="width: 88px;">立即咨询</p>
      <img class="box-img-center" src="../../assets/img/noCriminal/Australia/pic_3.png" alt="">
    </div>

    <div class="box" style="height: 489px;">
      <p class="box-p1">服务优势</p>
      <div class="noc-adv">
        <div class="noc-adv-inner">
          <img class="noc-adv-img" src="../../assets/img/noCriminal/Canada/icon_1_1.png" alt="">
          <p class="noc-adv-p">专业人员全程跟进</p>
        </div>
        <div class="noc-adv-inner">
          <img class="noc-adv-img" src="../../assets/img/noCriminal/Canada/icon_1_2.png" alt="">
          <p class="noc-adv-p">丰富经验积累</p>
        </div>
        <div class="noc-adv-inner">
          <img class="noc-adv-img" src="../../assets/img/noCriminal/Canada/icon_1_3.png" alt="">
          <p class="noc-adv-p">保护客户隐私</p>
        </div>
        <div class="noc-adv-inner">
          <img class="noc-adv-img" src="../../assets/img/noCriminal/Canada/icon_1_4.png" alt="">
          <p class="noc-adv-p">量身定制办理方案</p>
        </div>
        <div class="noc-adv-inner">
          <img class="noc-adv-img" src="../../assets/img/noCriminal/Canada/icon_1_5.png" alt="">
          <p class="noc-adv-p">合同保障客户权益</p>
        </div>
        <div class="noc-adv-inner">
          <img class="noc-adv-img" src="../../assets/img/noCriminal/Canada/icon_1_6.png" alt="">
          <p class="noc-adv-p">实时反馈办理进度</p>
        </div>
      </div>
    </div>

    <div class="box" style="height: auto;padding-bottom: 30px;background: rgba(242, 245, 250, 1)">
      <p class="box-p1">澳大利亚无犯罪热门问答</p>
      <div class="question">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item title="申请签证不知道怎么做澳洲无犯罪记录证明？" name="1">
            <p class="question-p">申请所需材料：
1）在有效期内的护照信息页扫描件
2）澳洲驾照扫描件
3）出生证、结婚证扫描件
4）银行、水电账单
澳大利亚各州警局出具的澳洲无犯罪记录证明
申请周期：由于各州情况不同，以新南威尔士州为例。基于名字的无犯罪证明10个工作日，基于指纹的无犯罪证明15个工作日。
申请所需材料：由于各州情况不同，以新南威尔士州为例。
1）在有效期内的护照原件
2）澳洲驾照原件
3）出生证、结婚证原件
4）学生卡原件等</p>
          </el-collapse-item>
          <el-collapse-item title="澳洲无犯罪记录证明怎么办?" name="2">
            <p class="question-p">在澳洲留学的人士，在国内就业入职，或者申请移民，都需要提供在澳洲留学期间的无犯罪记录证明。澳洲无犯
罪证明提供申请人有效的护照首页扫描件，填写一份申请表，信息准确完整，澳洲驾照扫描件。申请时间需要
15个工作日</p>
          </el-collapse-item>
          <el-collapse-item title="移民澳洲,需要开的是无犯罪记录还是无刑事犯罪记录?" name="3">
            <p class="question-p">无刑事犯罪记录，如果是刑事犯罪记录是有刑事判决书的。一般的劳教，纠纷都不算刑事犯罪记录。要去户口所
在地开具证明，到有信誉的公证处公证。
另外，如果在国外居住超过12个月，还要办国外的无犯罪证明，这个要到该国的领事馆办了</p>
          </el-collapse-item>
          <el-collapse-item title="澳洲使馆认证的认证材料是什么?" name="4">
            <p class="question-p">澳洲使馆认证资料包含个人文件和公司文件 比较常用的文件名单如下：澳洲结婚证，出生证，无犯罪证明，离
婚证，学历证明，成绩单，委托书，声明书，居留证明，公司注册证书，股东董事名册，章程等文书。</p>
          </el-collapse-item>
        </el-collapse>
      </div>
      <p class="to-ask" @click="toAsk"> 
        <img class="to-ask-icon" src="../../assets/img/register/bvi/icon_6_customer_service.png" alt=""> 
        更多问题？立即咨询人工客服</p>
    </div>

    <div class="box" style="height: 339px;">
      <p class="box-p1">澳大利亚无犯罪记录证明案例</p>
      <div class="lunbo-box cer-lunbo-box" style="margin-top: 20px">
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="rgba(47, 114, 235, 1)" >
          <van-swipe-item>
            <div class="cer-case-box">
              <img class="cer-case-img" src="../../assets/img/noCriminal/Australia/pic_4_1.jpg" alt="">
              <img class="cer-case-img" src="../../assets/img/noCriminal/Australia/pic_4_2.jpg" alt="">
            </div>
          </van-swipe-item>
          <van-swipe-item>
            <div class="cer-case-box">
              <img class="cer-case-img" src="../../assets/img/noCriminal/Australia/pic_4_3.jpg" alt="">
            </div>
          </van-swipe-item>
        </van-swipe>
      </div>
    </div>
    <div class="box noc-bottom">
      <p class="box-p1" style="color: #fff;">办理中国驻澳大利亚大使馆领事认证</p>
      <p class="box-p2" style="width: 300px;color: rgba(214, 231, 255, 1);">澳大利亚无犯罪记录证明用于国内工作签证或永久居留权申请，需要办理中国驻澳大利亚大使馆的领事认证</p>
      <p class="noc-bottom-p1">将申请下来的澳大利亚无犯罪记录证明及当事人的护照扫描件提交至国际公证人处办理国际公证</p>
      <p class="noc-bottom-p1">提交至澳大利亚外交部或者省政府指定部门进行办理认证</p>
      <p class="noc-bottom-p1">交至中国驻澳大利亚大使馆办理认证</p>
      <p class="noc-bottom-p2">正常办理：15-18个工作日</p>
      <p class="noc-bottom-p2">加急服务：7-10个工作日（不包含国际邮寄时间）</p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'bvi-page',
  props: {
    msg: String
  },
  data(){
    return{
      form: {},
      activeName: '1'
    }
  },
  mounted(){
    window.scrollTo(0,0);
  },
  methods:{
    toAsk(){
      window.open('https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn','_blank')
    },
    toSubmit(){
      this.$message({
          message: '提交成功！',
          type: 'success',
          offset: 100
        });
    }
  }
}
</script>

<style scoped lang="scss">
  .m-bvi-banner{
    width: 100%;
    height: 460px;
    background: url('../../assets/img/noCriminal/Australia/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
  .fingle-box{
    background: url('../../assets/img/noCriminal/Canada/pic_1_2.png') no-repeat center center;
    background-size: 100% 100%;
  }
  .fingle-box1{
    background: url('../../assets/img/noCriminal/Canada/pic_1_1.png') no-repeat center center;
    background-size: 100% 100%;
  }
  .noc-bottom{
    background: url('../../assets/img/noCriminal/Canada/pic_5.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
</style>
