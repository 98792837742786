<template>
  <div class="home">
    <div class="m-bvi-banner">
      <p class="banner-p1">一站式加拿大公司注册服务</p>
      <p class="banner-p2">【快企务专注于涉内外企业服务十余年】</p>
      <p class="banner-btn banner-btn1" @click="toAsk">立即咨询</p>
    </div>

    <div class="box" style="height: auto;margin-bottom: 30px;">
      <p class="box-p1">注册加拿大公司的优势</p>
      <div class="mal-adv">
        <div class="mal-adv-inner">
          <img class="mal-adv-icon" src="../../assets/img/register/Canada/pic_1_1.png" alt="">
          <p class="mal-adv-p" style="font-weight: normal;">18岁以后在加拿大居住10年（拿到绿卡），可以享受加拿大退休养老保障，养老金根据国家经济发展情况逐年递增。无论您那是在全球的任何地方度假或居住都能得到政府的支票</p>
        </div>
        <div class="mal-adv-inner">
          <img class="mal-adv-icon" src="../../assets/img/register/Canada/pic_1_2.png" alt="">
          <p class="mal-adv-p" style="font-weight: normal;">投资移民仅限于做出投资，有权选择在加拿大做生意或不做生意</p>
        </div>
        <div class="mal-adv-inner">
          <img class="mal-adv-icon" src="../../assets/img/register/Canada/pic_1_3.png" alt="">
          <p class="mal-adv-p" style="font-weight: normal;">投资移民在所有移民申请中享受VIP服务，最优先审理</p>
        </div>
        <div class="mal-adv-inner">
          <img class="mal-adv-icon" src="../../assets/img/register/Canada/pic_1_4.png" alt="">
          <p class="mal-adv-p" style="font-weight: normal;">无风险，面试通过后将资金注入</p>
        </div>
        <div class="mal-adv-inner">
          <img class="mal-adv-icon" src="../../assets/img/register/Canada/pic_1_5.png" alt="">
          <p class="mal-adv-p" style="font-weight: normal;">注册温哥华公司移民温哥华无年龄、语言、学历要求</p>
        </div>
        <div class="mal-adv-inner">
          <img class="mal-adv-icon" src="../../assets/img/register/Canada/pic_1_6.png" alt="">
          <p class="mal-adv-p" style="font-weight: normal;">享受加拿大全部公费医疗保障及社会福利</p>
        </div>
      </div>
    </div>

    <div class="box" style="height: auto;padding-bottom: 30px;">
      <p class="box-p1">注册加拿大公司类型</p>
      <div class="type-content flex center-width">
        <img class="type-img" style="height: 110px;" src="../../assets/img/register/Canada/pic_2_1.jpg" alt="">
        <img class="type-img" style="height: 110px;" src="../../assets/img/register/Canada/pic_2_2.jpg" alt="">
        <img class="type-img" style="height: 110px;" src="../../assets/img/register/Canada/pic_2_3.jpg" alt="">
        <img class="type-img" @click="toAsk" style="height: 110px;" src="../../assets/img/register/Canada/pic_2_4.jpg" alt="">
      </div>
    </div>
    
    <div class="box" style="height: auto;padding-bottom: 15px;">
      <p class="box-p1">注册加拿大公司的条件/流程/资料</p>
      <div class="tab-box">
        <div class="tab-inner" :class="{'active': tab == 1}" @click="tab = 1">注册条件</div>
        <div class="tab-inner" :class="{'active': tab == 2}" @click="tab = 2">注册流程</div>
        <div class="tab-inner" :class="{'active': tab == 3}" @click="tab = 3">所得资料</div>
      </div>
      <div class="file-content">
        <div v-if="tab == 1">
          <div class="Malta-tab1">
            <div class="Malta-tab1-box" style="height: 250px;padding-top: 30px;">
              <p class="Malta-tab1-p">没有资金资本要求，不需要验资</p>
              <p class="Malta-tab1-p">公司名称可以用数字字母，但不可以与其他公司名称相同或相似，以“有限公司”、“责任有限公司”字样或缩写结尾，外国公司在加拿大的分公司可在名称加上“加拿大”以与其母公司相区别</p>
              <p class="Malta-tab1-p">董事最少一名，年满18周岁，无犯罪记录，信誉良好，无国籍限制。可以是自然人但不能是法人，有些省份要求有当地或永久居留权的董事占比 25% 或 30% 以上，温哥华注册公司无需提供当地董事</p>
              <p class="Malta-tab1-p">股东最少一名，年满18周岁，可以是自然人或法人，无犯罪记录，信誉良好</p>
             </div>
          </div>
        </div>
        <div v-if="tab == 2">
          <img class="tab2-img" src="../../assets/img/register/Canada/pic_4.jpg" alt="">
        </div>
        <div v-if="tab == 3">
          <div class="ger-tab3">
            <div class="ger-tab3-inner fre-tab3">
              <img class="ger-tab3-icon" src="../../assets/img/register/Canada/pic_5_1.png" alt="">
              <p class="ger-tab3-p">经营证</p>
            </div>
            <div class="ger-tab3-inner fre-tab3">
              <img class="ger-tab3-icon" src="../../assets/img/register/Canada/pic_5_2.png" alt="">
              <p class="ger-tab3-p">证明文件（NUANS）</p>
            </div>
            <div class="ger-tab3-inner fre-tab3">
              <img class="ger-tab3-icon" src="../../assets/img/register/Canada/pic_5_3.png" alt="">
              <p class="ger-tab3-p">股份证明书2本</p>
            </div>
            <div class="ger-tab3-inner fre-tab3">
              <img class="ger-tab3-icon" src="../../assets/img/register/Canada/pic_5_4.png" alt="">
              <p class="ger-tab3-p"> 会议记录1本</p>
            </div>
            <div class="ger-tab3-inner fre-tab3">
              <img class="ger-tab3-icon" src="../../assets/img/register/Canada/pic_5_5.png" alt="">
              <p class="ger-tab3-p"> 公司原子签名章\及金属钢印</p>
            </div>
            <div class="ger-tab3-inner fre-tab3">
              <img class="ger-tab3-icon" src="../../assets/img/register/Canada/pic_5_6.png" alt="">
              <p class="ger-tab3-p"> 公司股东、董事及秘书资料文件1份</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <div class="box" style="height: 725px;background: rgba(242, 245, 250, 1);">
      <p class="box-p1">注册加拿大公司后为您提供的后续服务</p>
      <div class="re-serve">
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Vietnam/pic_6_1.png" alt="">
          <p class="re-serve-p1">年审/续牌</p>
          <p class="re-serve-p2">所有公司须按年度定时递交包含股东、董事、公司秘书和其他任何当年可能影响公司行政管理结构详情信息的公司年度报表</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Vietnam/pic_6_2.png" alt="">
          <p class="re-serve-p1">秘书服务</p>
          <p class="re-serve-p2">办公地址，公司周年报表年检，公司董事股东成员名册更新，在职证明与良好证明，代缴政府规费，修改公司章程等 </p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Vietnam/pic_6_3.png" alt="">
          <p class="re-serve-p1">做账报税</p>
          <p class="re-serve-p2">公司注册之后需按照当地政府单位要求进行做账报税</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Vietnam/pic_6_4.png" alt="">
          <p class="re-serve-p1">银行开户</p>
          <p class="re-serve-p2">开设公司注册所在地的银行账户，有利于公司随时接受贸易汇款，享受当地政策或规避外汇风险</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Vietnam/pic_6_5.png" alt="">
          <p class="re-serve-p1">税号申请</p>
          <p class="re-serve-p2">公司经营过程中的公司信息（如地址、股东、经营范围等）变更</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Vietnam/pic_6_6.png" alt="">
          <p class="re-serve-p1">公司注销</p>
          <p class="re-serve-p2">因破产、股东决议解散、存续期届满等原因需要注销的，应及时主动注销公司</p>
        </div>
      </div>
    </div>
    <div class="box" style="height: auto;padding-bottom: 30px;background: rgba(242, 245, 250, 1)">
      <p class="box-p1">加拿大公司注册常见问题</p>
      <p class="box-p1">解决您的一切疑问</p>
      <div class="question">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item title="加拿大注册公司的注册类型?" name="1">
            <p class="question-p">联邦公司注册和注册省级公司，加拿大省级企业主要有三种形式，即独资/合资企业/有限公司。</p>
          </el-collapse-item>
          <el-collapse-item title="加拿大公司每年是否必需要做年检?" name="2">
            <p class="question-p">是的。加拿大公司如果连续两年不年检，BC Registry部门会关闭相应的公司。如果想要维持公司的存续，必
须做年检，而且需要另外支付地址服务费用。</p>
          </el-collapse-item>
          <el-collapse-item title="加拿大公司注册时间需要多久？" name="3">
            <p class="question-p">注册加拿大公司一般在递交完资料后两-三个月完成。由于文件的寄送和具体问题的往返可能会有所延误，但
一般都不会发生这种问题，具体时间可以点击咨询在线顾问。</p>
          </el-collapse-item>
        </el-collapse>
      </div>
      <p class="to-ask" @click="toAsk"> 
        <img class="to-ask-icon" src="../../assets/img/register/bvi/icon_6_customer_service.png" alt=""> 
        更多问题？立即咨询人工客服</p>
    </div>

    <div class="box" style="height: 480px;">
      <p class="box-p1">快企务的优势</p>
      <p class="box-p2">十余年注册加拿大公司经验</p>
      <div class="re-adv">
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_1.png" alt="">
          <p class="re-adv-p1">保密完善</p>
          <p class="re-adv-p2">保密系统完善，客户隐私不外泄</p>
        </div>
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_2.png" alt="">
          <p class="re-adv-p1">全程服务</p>
          <p class="re-adv-p2">一站式专业服务从注册公司到年审、开户，审计报税等</p>
        </div>
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_3.png" alt="">
          <p class="re-adv-p1">专业高效</p>
          <p class="re-adv-p2">根据需求，为客户制定专业快捷的解决方案</p>
        </div>
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_4.png" alt="">
          <p class="re-adv-p1">合同保障</p>
          <p class="re-adv-p2">正规机构，合同保障，费用透明，无隐形消费</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  data(){
    return{
      tab: 1,
      activeName: '1'
    }
  },
  mounted(){
    window.scrollTo(0,0);
  },
  methods:{
    toAsk(){
      window.open('https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn','_blank')
    },
  }
}
</script>
<style scoped>
  .m-bvi-banner{
    width: 100%;
    height: 460px;
    background: url('../../assets/img/register/Canada/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
</style>
