<template>
  <div class="home">
    <div class="m-bvi-banner">
      <p class="banner-p1">一站式马耳他公司注册服务</p>
      <p class="banner-p2">【快企务专注于涉内外企业服务十余年】</p>
      <p class="banner-btn banner-btn1" @click="toAsk">立即咨询</p>
    </div>

    <div class="box" style="height: 750px;">
      <p class="box-p1">注册马耳他公司的优势</p>
      <div class="mal-adv">
        <div class="mal-adv-inner">
          <img class="mal-adv-icon" src="../../assets/img/register/Malta/pic_1_1.png" alt="">
          <p class="mal-adv-p">全球唯一的“四位一体”国</p>
          <p class="mal-adv-p1">申根国、欧盟国、欧元国、英联邦成员国。持有马耳他护照可以自由出入欧洲和英联邦多个国家，无需签证</p>
        </div>
        <div class="mal-adv-inner">
          <img class="mal-adv-icon" src="../../assets/img/register/Malta/pic_1_2.png" alt="">
          <p class="mal-adv-p">政治、经济局势稳定</p>
        </div>
        <div class="mal-adv-inner">
          <img class="mal-adv-icon" src="../../assets/img/register/Malta/pic_1_3.png" alt="">
          <p class="mal-adv-p">欧洲著名避税天堂</p>
          <p class="mal-adv-p1">无遗产税，无全球征税。马耳他的收税制度对永久居民非常优惠。国外收入则不征收任何税费，移民批准后的半年内带入马耳他的实物资产不征税</p>
        </div>
        <div class="mal-adv-inner">
          <img class="mal-adv-icon" src="../../assets/img/register/Malta/pic_1_4.png" alt="">
          <p class="mal-adv-p">社会保障体系较完备</p>
          <p class="mal-adv-p1">实行免费教育、免费医疗及退休保险制</p>
        </div>
      </div>
    </div>

    <div class="box" style="height: auto;padding-bottom: 30px;">
      <p class="box-p1">注册马耳他公司类型</p>
      <div class="type-content flex center-width">
        <img class="type-img" style="height: 110px;" src="../../assets/img/register/Malta/pic_2_1.jpg" alt="">
        <img class="type-img" style="height: 110px;" src="../../assets/img/register/Malta/pic_2_2.jpg" alt="">
      </div>
    </div>
    
    <div class="box" style="height: auto;padding-bottom: 15px;">
      <p class="box-p1">注册马耳他公司的条件/流程/资料</p>
      <div class="tab-box">
        <div class="tab-inner" :class="{'active': tab == 1}" @click="tab = 1">注册条件</div>
        <div class="tab-inner" :class="{'active': tab == 2}" @click="tab = 2">注册流程</div>
        <div class="tab-inner" :class="{'active': tab == 3}" @click="tab = 3">所得资料</div>
      </div>
      <div class="file-content">
        <div v-if="tab == 1">
          <div class="Malta-tab1">
            <div class="Malta-tab1-box">
              <p class="Malta-tab1-title" style="text-align: left;padding-left: 20px;">有限责任公司<span style="font-size: 12px;font-weight: normal;">（必须在马耳他注册）</span></p>
              <p class="Malta-tab1-p">私人有限责任公司至少有1个创始人，股东不能超过50个</p>
              <p class="Malta-tab1-p">最少股本金要1,200欧元，20%需要在注册前缴纳</p>
              <p class="Malta-tab1-p">至少1名董事和1名公司秘书。股东和董事可以在当地，也可以在国外</p>
            </div>
            <div class="Malta-tab1-box">
              <p class="Malta-tab1-title" style="text-align: left;padding-left: 20px;">股份有限公司<span style="font-size: 12px;font-weight: normal;">（必须在马耳他注册）</span></p>
              <p class="Malta-tab1-p">股份有限公司必须至少2个创始人，可以多于50个股东</p>
              <p class="Malta-tab1-p">最少需要股本金46,588（46,000）欧元，25%需要在注册前缴纳</p>
            </div>
          </div>
        </div>
        <div v-if="tab == 2">
          <img class="tab2-img" style="width: 70%;" src="../../assets/img/register/Malta/pic_4.jpg" alt="">
        </div>
        <div v-if="tab == 3">
          <div class="ger-tab3">
            <div class="ger-tab3-inner fre-tab3">
              <img class="ger-tab3-icon" src="../../assets/img/register/Malta/pic_5_1.png" alt="">
              <p class="ger-tab3-p">代理人架构证书</p>
            </div>
            <div class="ger-tab3-inner fre-tab3">
              <img class="ger-tab3-icon" src="../../assets/img/register/Malta/pic_5_2.png" alt="">
              <p class="ger-tab3-p">首任董事委任书</p>
            </div>
            <div class="ger-tab3-inner fre-tab3">
              <img class="ger-tab3-icon" src="../../assets/img/register/Malta/pic_5_3.png" alt="">
              <p class="ger-tab3-p">章程两本一正一副</p>
            </div>
            <div class="ger-tab3-inner fre-tab3">
              <img class="ger-tab3-icon" src="../../assets/img/register/Malta/pic_5_4.png" alt="">
              <p class="ger-tab3-p"> 股票</p>
            </div>
            <div class="ger-tab3-inner fre-tab3">
              <img class="ger-tab3-icon" src="../../assets/img/register/Malta/pic_5_5.png" alt="">
              <p class="ger-tab3-p">印章</p>
            </div>
            <div class="ger-tab3-inner fre-tab3">
              <img class="ger-tab3-icon" src="../../assets/img/register/Malta/pic_5_6.png" alt="">
              <p class="ger-tab3-p">注册证书</p>
            </div>
            <div class="ger-tab3-inner fre-tab3">
              <img class="ger-tab3-icon" src="../../assets/img/register/Malta/pic_5_7.png" alt="">
              <p class="ger-tab3-p">公司会议记录表格（董事名册、股东名册、秘书名册）</p>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="box" style="height: 725px;background: rgba(242, 245, 250, 1);">
      <p class="box-p1">注册马耳他公司后为您提供的后续服务</p>
      <div class="re-serve">
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Malta/pic_6_1.png" alt="">
          <p class="re-serve-p1">年审/续牌</p>
          <p class="re-serve-p2">所有公司须按年度定时递交包含股东、董事、公司秘书和其他任何当年可能影响公司行政管理结构详情信息的公司年度报表</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Malta/pic_6_2.png" alt="">
          <p class="re-serve-p1">秘书服务</p>
          <p class="re-serve-p2">办公地址，公司周年报表年检，公司董事股东成员名册更新，在职证明与良好证明，代缴政府规费，修改公司章程等</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Malta/pic_6_3.png" alt="">
          <p class="re-serve-p1">做账报税</p>
          <p class="re-serve-p2">马耳他有限责任制公司须让拥有马耳他公司审计经验的公共注册会计师对其年度金融财务报表做审计。审计后的金融财务报表须按年度定时递交马耳他公司注册局</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Malta/pic_6_4.png" alt="">
          <p class="re-serve-p1">银行开户</p>
          <p class="re-serve-p2">马耳他银行有100多家分行网络，提供所有合法的银行业务服务。各国主要银行在马耳他都设有直接分行或在地方商业银行和马耳他中央银行设代表处。</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Malta/pic_6_5.png" alt="">
          <p class="re-serve-p1">税号申请</p>
          <p class="re-serve-p2">可申请税号，一般不需要申请</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Malta/pic_6_6.png" alt="">
          <p class="re-serve-p1">公司注销</p>
          <p class="re-serve-p2">因破产、股东决议解散、存续期届满等原因需要注销的，应及时主动注销公司</p>
        </div>
      </div>
    </div>

    <div class="box" style="height: auto;padding-bottom: 30px;">
      <p class="box-p1">马耳他公司注册常见问题</p>
      <p class="box-p1">解决您的一切疑问</p>
      <div class="question">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item title="马耳他公司的董事必须是本地的吗？" name="1">
            <p class="question-p">私人有限责任公司至少有1个创始人，股东不能超过50个。至少1名董事和1名公司秘书，股东和董事可以在
当地也可以在国外。</p>
          </el-collapse-item>
          <el-collapse-item title="马耳他可以设立合伙企业吗？" name="2">
            <p class="question-p">可以设立，合伙公司至少需要有2名合伙人，无低股本要求</p>
          </el-collapse-item>
        </el-collapse>
      </div>
      <p class="to-ask" @click="toAsk"> 
        <img class="to-ask-icon" src="../../assets/img/register/bvi/icon_6_customer_service.png" alt=""> 
        更多问题？立即咨询人工客服</p>
    </div>
    <div class="box" style="height: 480px;">
      <p class="box-p1">快企务的优势</p>
      <p class="box-p2">十余年注册马耳他公司经验</p>
      <div class="re-adv">
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_1.png" alt="">
          <p class="re-adv-p1">保密完善</p>
          <p class="re-adv-p2">保密系统完善，客户隐私不外泄</p>
        </div>
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_2.png" alt="">
          <p class="re-adv-p1">全程服务</p>
          <p class="re-adv-p2">一站式专业服务从注册公司到年审、开户，审计报税等</p>
        </div>
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_3.png" alt="">
          <p class="re-adv-p1">专业高效</p>
          <p class="re-adv-p2">根据需求，为客户制定专业快捷的解决方案</p>
        </div>
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_4.png" alt="">
          <p class="re-adv-p1">合同保障</p>
          <p class="re-adv-p2">正规机构，合同保障，费用透明，无隐形消费</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  data(){
    return{
      tab: 1,
      activeName: '1'
    }
  },
  mounted(){
    window.scrollTo(0,0);
  },
  methods:{
    toAsk(){
      window.open('https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn','_blank')
    },
  }
}
</script>
<style scoped>
  .m-bvi-banner{
    width: 100%;
    height: 460px;
    background: url('../../assets/img/register/Malta/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
</style>
