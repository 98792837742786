<template>
  <div class="home">
    <div class="m-bvi-banner">
      <p class="banner-p1">注册香港公司</p>
      <p class="banner-p2">【企业全球拓展第一步】</p>
      <p class="banner-btn banner-btn1" @click="toAsk">立即咨询</p>
    </div>

    <div class="box" >
      <p class="box-p1">香港公司注册套餐</p>
      <p class="box-p2">选择您需要的服务</p>
      <div class="hk-content">
        <div class="hk-content-inner">
          <img class="hk-content-img" src="../../assets/img/register/HongKong/content.png" alt="">
        </div>
      </div>
    </div>
    
    <div class="box bg-box" style="height: auto;padding-bottom: 15px;">
      <p class="box-p1">注册香港公司的条件、流程、时间</p>
      <div class="tab-box"  style="justify-content: center;">
        <div class="tab-inner" style="margin-right: 12px;" :class="{'active': tab == 1}" @click="tab = 1">注册流程</div>
        <div class="tab-inner" style="width: 120px;" :class="{'active': tab == 2}" @click="tab = 2">注册条件/时间</div>
      </div>
      <div class="file-content flex center-width">
        <div v-if="tab == 1">
          <div class="hk-flow">
            <p class="hk-flow-p1">香港公司注册条件</p>
            <div class="hk-flow-box">
              <p class="hk-flow-p2">公司股东</p>
              <p class="hk-flow-p3">一位或一位股东以上，股东必须超过18岁</p>
              <p class="hk-flow-p2">股东身份证明</p>
              <p class="hk-flow-p3">所以股东必须出示有效身份或护照影印件</p>
              <p class="hk-flow-p2">企业身份证明</p>
              <p class="hk-flow-p3">以企业法人作为股东的，企业法人必须提交营业执照和法人代表的身份证复印件</p>
              <p class="hk-flow-p2">公司地址</p>
              <p class="hk-flow-p3">提供香港注册地址（我司提供）</p>
              <p class="hk-flow-p2">法定秘书</p>
              <p class="hk-flow-p3">委任香港人或香港公司担任公司法定秘书（一般由我们提供）</p>
              <p class="hk-flow-p2">注册资本</p>
              <p class="hk-flow-p3">香港公司法规定：标准注册资本为10000港币</p>
              <p class="hk-flow-p2">公司注册名称</p>
              <p class="hk-flow-p3">需提供三个以供查册</p>
            </div>
            <p class="hk-flow-p1">香港公司注册时间</p>
            <div class="hk-flow-box">
              <p class="hk-flow-p2" style="color: rgba(47, 114, 235, 1);">常规</p>
              <p class="hk-flow-p3" style="color: rgba(47, 114, 235, 1);">8-10个工作日</p>
              <p class="hk-flow-p2" style="color: rgba(237, 41, 65, 1);">加急</p>
              <p class="hk-flow-p3" style="color: rgba(237, 41, 65, 1);">电子注册，最快3个工作日内完成</p>
            </div>
          </div>
        </div>
        <div v-if="tab == 2">
          <img class="tab2-img" src="../../assets/img/register/HongKong/pic_2.png" alt="">
        </div>
        
      </div>
    </div>

    <div class="box">
      <p class="box-p1">香港公司银行开户</p>
      <p class="box-p2">我们只做更好的</p>
      <div class="hk-bank-top">
        <div class="top-inner">
          <img class="top-inner-icon" src="../../assets/img/register/HongKong/pic_3_1.png" alt="">
          <p class="top-inner-p">多元化渠道</p>
        </div>
        <div class="top-inner">
          <img class="top-inner-icon" src="../../assets/img/register/HongKong/pic_3_2.png" alt="">
          <p class="top-inner-p">VIP绿色通道</p>
        </div>
        <div class="top-inner">
          <img class="top-inner-icon" src="../../assets/img/register/HongKong/pic_3_3.png" alt="">
          <p class="top-inner-p">个性化匹配</p>
        </div>
        <div class="top-inner">
          <img class="top-inner-icon" src="../../assets/img/register/HongKong/pic_3_4.png" alt="">
          <p class="top-inner-p">保证成功率</p>
        </div>
      </div>
      <img class="top-inner-img" src="../../assets/img/register/HongKong/pic_3_5.jpg" alt="">
      <div class="more-bank">
        <img class="bank-img" src="../../assets/img/register/HongKong/pic_3_6.jpg" alt="">
        <img class="bank-img" src="../../assets/img/register/HongKong/pic_3_7.jpg" alt="">
        <img class="bank-img" src="../../assets/img/register/HongKong/pic_3_8.jpg" alt="">
        <img class="bank-img" src="../../assets/img/register/HongKong/pic_3_9.jpg" alt="">
        <img class="bank-img" src="../../assets/img/register/HongKong/pic_3_10.jpg" alt="">
        <img class="bank-img" src="../../assets/img/register/HongKong/pic_3_11.jpg" alt="">
        <img class="bank-img" src="../../assets/img/register/HongKong/pic_3_12.jpg" alt="">
        <img class="bank-img" src="../../assets/img/register/HongKong/pic_3_13.jpg" alt="">
        <p class="more-btn">更多银行开户 > </p>
      </div>
    </div>

    <div class="box">
      <p class="box-p1">一站式香港企业服务</p>
      <div class="re-serve">
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/HongKong/pic_4_1.png" alt="">
          <p class="re-serve-p1">香港公司年审</p>
          <p class="re-serve-p2">香港公司每年均需要针对其商业登记证进行一次全新的审核</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/HongKong/pic_4_2.png" alt="">
          <p class="re-serve-p1">香港公司税务筹划</p>
          <p class="re-serve-p2">项目投资、运用香港税款双边协定及相应税收优惠</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/HongKong/pic_4_3.png" alt="">
          <p class="re-serve-p1">香港公司公证</p>
          <p class="re-serve-p2">公司文件应用于内地，即中国委托公证人公证</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/HongKong/pic_4_4.png" alt="">
          <p class="re-serve-p1">香港公司变更</p>
          <p class="re-serve-p2">股东、董事、公司名称、地址等发生变化</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/HongKong/pic_4_5.png" alt="">
          <p class="re-serve-p1">香港公司SCR备案</p>
          <p class="re-serve-p2">所有在香港注册的公司注册为重要控制人，该备案政策专门针对香港公司，旨在打击税务违规行为等。</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/HongKong/pic_4_6.png" alt="">
          <p class="re-serve-p1">香港商标注册</p>
          <p class="re-serve-p2">可以是各种各样的图案，甚至可以是声音，按照货品和服务分为45个类，一般6-10个月有结果</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/HongKong/pic_4_7.png" alt="">
          <p class="re-serve-p1">会计师咨询服务</p>
          <p class="re-serve-p2">做账报税审计等</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/HongKong/pic_4_8.png" alt="">
          <p class="re-serve-p1">协助香港银行开户</p>
          <p class="re-serve-p2">渣打银行、恒生银行、汇丰银行等</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/HongKong/pic_4_9.png" alt="">
          <p class="re-serve-p1">香港公司注销</p>
          <p class="re-serve-p2">分为主动注销或自动注销</p>
        </div>
      </div>
    </div>

    <div class="box" >
      <p class="box-p1">快企务 <span>VS </span> 其他公司</p>
      <p class="box-p2">更直观了解我们的优势</p>
      <div class="lunbo-box">
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
          <van-swipe-item>
            <img class="swiper-img" style="height: 388px;" src="../../assets/img/register/HongKong/pic_5_1.jpg" alt="">
          </van-swipe-item>
          <van-swipe-item>
            <img class="swiper-img" style="height: 388px;" src="../../assets/img/register/HongKong/pic_5_2.jpg" alt="">
          </van-swipe-item>
        </van-swipe>
      </div>
    </div>

    <div class="box" style="height: auto;padding-bottom: 30px;background: rgba(242, 245, 250, 1)">
      <p class="box-p1">注册香港公司常见问题</p>
      <p class="box-p2">解决您的一切疑问</p>
      <div class="question">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item title="注册香港公司取名有什么限制？" name="1">
            <p class="question-p">香港公司取名相对自在，公司名称可以含有国际、集团、控股、实业、出版社、物流、修建、旅行、财政、等字眼，为了避免重名，公司名称有必要查册。需要注意的是香港公司的名称必须有英文组成，可搭配中文起名；</p>
          </el-collapse-item>
          <el-collapse-item title="注册香港公司注册资本是多少?" name="2">
            <p class="question-p">有限公司注册资本惯例为1万港币，公司注册资本的巨细决议着股东承担职责的巨细。需要提前准备好公司的股份及股份分
配，并准备好股东出资方的证明文件</p>
          </el-collapse-item>
          <el-collapse-item title="商业登记证是否必须填写业务性质?" name="3">
            <p class="question-p">不一定，如公司不想特别注明，可不填写。</p>
          </el-collapse-item>
          <el-collapse-item title="股东和董事有什么要求？" name="4">
            <p class="question-p">香港公司需一位以上的股东和董事，股东能够由法人和自然人担任，自然人为年满18周岁。</p>
          </el-collapse-item>
          <el-collapse-item title="大陆居民是否可以注册香港公司？" name="5">
            <p class="question-p">可以。香港的公司法对股东/董事的国籍没有任何限制。</p>
          </el-collapse-item>
          <el-collapse-item title="注册香港公司需要多久？" name="6">
            <p class="question-p">一般来说，注册一家香港公司需要3-6个工作日，客户选择加急注册，并且提交资料完整时，快企务注册最快6小时出执照。</p>
          </el-collapse-item>
        </el-collapse>
      </div>
      <p class="to-ask" @click="toAsk"> 
        <img class="to-ask-icon" src="../../assets/img/register/bvi/icon_6_customer_service.png" alt=""> 
        更多问题？立即咨询人工客服</p>
    </div>
    <div class="box">
      <p class="box-p1">快企务的优势</p>
      <p class="box-p2">十余年注册香港公司经验</p>
      <div class="re-adv">
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_1.png" alt="">
          <p class="re-adv-p1">保密完善</p>
          <p class="re-adv-p2">保密系统完善，客户隐私不外泄</p>
        </div>
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_2.png" alt="">
          <p class="re-adv-p1">全程服务</p>
          <p class="re-adv-p2">一站式专业服务从注册公司到年审、开户，审计报税等</p>
        </div>
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_3.png" alt="">
          <p class="re-adv-p1">专业高效</p>
          <p class="re-adv-p2">根据需求，为客户制定专业快捷的解决方案</p>
        </div>
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_4.png" alt="">
          <p class="re-adv-p1">合同保障</p>
          <p class="re-adv-p2">正规机构，合同保障，费用透明，无隐形消费</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  data(){
    return{
      tab: 1,
      activeName: '1'
    }
  },
  mounted(){
    window.scrollTo(0,0);
  },
  methods:{
    toAsk(){
      window.open('https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn','_blank')
    },
  }
}
</script>
<style scoped>
  .m-bvi-banner{
    width: 100%;
    height: 460px;
    background: url('../../assets/img/register/HongKong/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
</style>
