<template>
  <div class="certification m-bvi">
    <div class="m-bvi-banner">
      <div class="banner-tag">
        <p class="banner-tag-inner">无需回加拿大</p>
        <p class="banner-tag-inner">平台式申请</p>
        <p class="banner-tag-inner">合同保障</p>
      </div>
      <p class="banner-p1" style="padding-top: 20px">加拿大无犯罪记录申请</p>
      <p class="banner-p2">留学 | 移民 | 工作 | 旅游 | 签证</p>
      <p class="banner-btn banner-btn1" @click="toAsk">立即咨询</p>
    </div>
    <div class="box" style="height: 373px;background: #fff;">
      <p class="box-p1">加拿大无犯罪记录办理</p>
      <p class="box-p2">请填写以下表单留下您的信息，我们将第一时间联系您，谢谢！</p>
      <div class="form-box">
        <div class="form-line">
          <p class="form-label">姓名</p>
          <input class="form-input" placeholder="请输入您的姓名" type="text" v-model="form.name">
        </div>
        <div class="form-line">
          <p class="form-label">电话</p>
          <input class="form-input" type="text" placeholder="请输入您的联系方式" v-model="form.mobile">
        </div>
        <div class="form-line">
          <p class="form-label">您的需求</p>
          <input class="form-input" type="text" placeholder="请输入您的需求" v-model="form.remark">
        </div>
      </div>
      <p class="form-submit" @click="toSubmit">提交</p>
    </div>
    <div class="box" style="background: rgba(242, 245, 250, 1);height: 784px;">
      <p class="box-p1">加拿大无犯罪办理类型</p>
      <p class="box-p2">有指纹无犯罪/无指纹无犯罪</p>
      <div class="fingle-box">
        <p class="fingle-p1">有指纹无犯罪</p>
        <p class="fingle-p2">电子版可申请加急</p>
        <p class="fingle-p3">具体收费请咨询客户经理</p>
        <p class="fingle-p4">可办理移民、绿卡</p>
        <p class="fingle-p4">需20 ~ 25个工作日</p>
        <p class="fingle-p4">需奔赴采集指纹，无需回加拿大</p>
        <p class="fingle-p4">需两个ID扫描件、护照扫描件、指纹信息</p>
        <!-- <p class="form-submit">立即咨询</p> -->
      </div>
      <div class="fingle-box fingle-box1">
        <p class="fingle-p1">无指纹无犯罪</p>
        <p class="fingle-p2">电子版可申请加急</p>
        <p class="fingle-p3">具体收费请咨询客户经理</p>
        <p class="fingle-p4">可办理工作、入职、留学、续签证</p>
        <p class="fingle-p4">需3 ~ 5个工作日</p>
        <p class="fingle-p4">无需奔赴，就是这么任性</p>
        <p class="fingle-p4">需两个ID扫描件、护照扫描件</p>
        <!-- <p class="form-submit">立即咨询</p> -->
      </div>
      <p class="fingle-tips">注：全国各城市公证处均可采集公证指纹</p>
    </div>
    
    <div class="box" style="height: 554px;background: #fff;">
      <p class="box-p1" style="width: 96%;">哪个部门可出具加拿大无犯罪记录证明</p>
      <p class="box-p2" style="margin-bottom: 10px">加拿大驻中国大使馆或领事馆并不能出具加拿大无犯罪记录证明，申请人需通过加拿大皇家警察和地方警察署来申请加拿大无犯罪记录证明</p>
      <div class="lunbo-box cer-lunbo-box" >
          <van-swipe class="my-swipe" :autoplay="3000" indicator-color="rgba(47, 114, 235, 1)" >
            <van-swipe-item>
              <div class="noc-swiper-box">
                <img class="noc-swiper-img" src="../../assets/img/noCriminal/Canada/pic_2_1.jpg" alt="">
                <div class="noc-swiper-bottom">
                  <p class="noc-swiper-p1">加拿大皇家警察RCMP</p>
                  <p class="noc-swiper-p2">Royal Canadian Mounted Police</p>
                  <p class="noc-swiper-p3">指纹采集（Fingerprint acquisition）鉴定方式</p>
                  <p class="form-submit" style="width: 88px;margin: 20px 0 0 20px;">立即咨询</p>
                  <p class="noc-swiper-p5">有指纹无犯罪</p>
                </div>
              </div>
            </van-swipe-item>
            <van-swipe-item>
              <div class="noc-swiper-box">
                <img class="noc-swiper-img" src="../../assets/img/noCriminal/Canada/pic_2_2.jpg" alt="">
                <div class="noc-swiper-bottom">
                  <p class="noc-swiper-p1">加拿大皇家警察RCMP</p>
                  <p class="noc-swiper-p2">Royal Canadian Mounted Police</p>
                  <p class="noc-swiper-p3">指纹采集（Fingerprint acquisition）鉴定方式</p>
                  <p class="form-submit" style="width: 88px;margin: 20px 0 0 20px;">立即咨询</p>
                  <p class="noc-swiper-p5">有指纹无犯罪</p>
                </div>
              </div>
            </van-swipe-item>
          </van-swipe>
        </div>
    </div>

    <div class="box" style="background: rgba(242, 245, 250, 1);height: 516px;">
      <p class="box-p1">加拿大无犯罪记录证明有效期</p>
      <p class="box-p2" style="margin-bottom: 20px">一般为6个月内的无犯罪证明，超出需重新办理</p>
      <p class="box-p1" style="padding-top: 0;font-size: 14px;font-weight: bold;">6个月以内无犯罪证明</p>
      <p class="box-p2" style="margin-bottom: 20px">加拿大无犯罪记录证明报告上并不会体现该报告的有效期，一般来讲，用于在国内申请工作签证或永久居留权时，政府部门要求该报告为六个月以内的无犯罪记录证明，超出六个月则需要重新办理</p>
      <p class="form-submit" style="width: 88px;">立即咨询</p>
      <img class="box-img-center" src="../../assets/img/noCriminal/Canada/pic_3.png" alt="">
    </div>

    <div class="box" style="height: 489px;">
      <p class="box-p1">服务优势</p>
      <div class="noc-adv">
        <div class="noc-adv-inner">
          <img class="noc-adv-img" src="../../assets/img/noCriminal/Canada/icon_1_1.png" alt="">
          <p class="noc-adv-p">专业人员全程跟进</p>
        </div>
        <div class="noc-adv-inner">
          <img class="noc-adv-img" src="../../assets/img/noCriminal/Canada/icon_1_2.png" alt="">
          <p class="noc-adv-p">丰富经验积累</p>
        </div>
        <div class="noc-adv-inner">
          <img class="noc-adv-img" src="../../assets/img/noCriminal/Canada/icon_1_3.png" alt="">
          <p class="noc-adv-p">保护客户隐私</p>
        </div>
        <div class="noc-adv-inner">
          <img class="noc-adv-img" src="../../assets/img/noCriminal/Canada/icon_1_4.png" alt="">
          <p class="noc-adv-p">量身定制办理方案</p>
        </div>
        <div class="noc-adv-inner">
          <img class="noc-adv-img" src="../../assets/img/noCriminal/Canada/icon_1_5.png" alt="">
          <p class="noc-adv-p">合同保障客户权益</p>
        </div>
        <div class="noc-adv-inner">
          <img class="noc-adv-img" src="../../assets/img/noCriminal/Canada/icon_1_6.png" alt="">
          <p class="noc-adv-p">实时反馈办理进度</p>
        </div>
      </div>
    </div>

    <div class="box" style="height: auto;padding-bottom: 30px;background: rgba(242, 245, 250, 1)">
      <p class="box-p1">加拿大无犯罪热门问答</p>
      <div class="question">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item title="加拿大无犯罪记录证明有哪些？" name="1">
            <p class="question-p">加拿大无犯罪记录证明的类型主要包括两大类：一类是Criminal record check，另一类是Police informationcheck</p>
          </el-collapse-item>
          <el-collapse-item title="加拿大公民申请在北京申请中国永久居留（也叫中国绿卡）必须要提供皇家骑警出具的无犯罪记录证明吗？" name="2">
            <p class="question-p"></p>
          </el-collapse-item>
          <el-collapse-item title="办理哪种类型的无犯罪记录证明？" name="3">
            <p class="question-p">在中国境内用于工作或办理永居时，一般国内政府机构没有特别限定需要哪种类型的无犯罪，只要求加拿大
无犯罪记录证明需要经过中国驻加拿大大使馆或领事馆的认证即可。
而加拿大无犯罪记录证明用于移民用途时，移民国家一般都会要求出具加拿大皇家骑警出具的无犯罪记录
证明，这种情况下需要申请人采集指纹才可以。</p>
          </el-collapse-item>
          <el-collapse-item title="基于名字检索的加拿大无犯罪记录证明 （加拿大当地警局出具）" name="4">
            <p class="question-p">一种是以名字（Name Based）检索的方式，用申请人姓名和出生日期在加拿大皇家骑警信息服务中心CPIC（
Canadian Police Information Centre）的数据库中检索是否存在犯罪记录。
基于名字检索的无犯罪记录证明是由加拿大当地的警局出具的。CPIC是一个全国性的警方数据库。</p>
          </el-collapse-item>
          <el-collapse-item title="基于指纹见搜的加拿大无犯罪记录证明（加拿大皇家骑警出具）" name="5">
            <p class="question-p">另外一种是指纹采集（Fingerprinted Based）鉴定方式，用采集到的指纹信息以及申请人的姓名及出生日期
在CPIC数据库中匹配是否存在犯罪记录。
基于指纹检索的无犯罪记录证明由加拿大皇家骑警出具的。
</p>
          </el-collapse-item>
        </el-collapse>
      </div>
      <p class="to-ask" @click="toAsk"> 
        <img class="to-ask-icon" src="../../assets/img/register/bvi/icon_6_customer_service.png" alt=""> 
        更多问题？立即咨询人工客服</p>
    </div>

    <div class="box" style="height: 339px;">
      <p class="box-p1">加拿大无犯罪记录证明案例</p>
      <div class="lunbo-box cer-lunbo-box" style="margin-top: 20px">
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="rgba(47, 114, 235, 1)" >
          <van-swipe-item>
            <div class="cer-case-box">
              <img class="cer-case-img" src="../../assets/img/noCriminal/Canada/pic_4_1.jpg" alt="">
              <img class="cer-case-img" src="../../assets/img/noCriminal/Canada/pic_4_2.jpg" alt="">
            </div>
          </van-swipe-item>
          <van-swipe-item>
            <div class="cer-case-box">
              <img class="cer-case-img" src="../../assets/img/noCriminal/Canada/pic_4_3.jpg" alt="">
            </div>
          </van-swipe-item>
        </van-swipe>
      </div>
    </div>
    <div class="box noc-bottom">
      <p class="box-p1" style="color: #fff;">办理中国驻加拿大大使馆领事认证</p>
      <p class="box-p2" style="width: 300px;color: rgba(214, 231, 255, 1);">加拿大无犯罪记录证明用于国内工作签证或永久居留权申请，需要办理中国驻加拿大大使馆的领事认证</p>
      <p class="noc-bottom-p1">将申请下来的加拿大无犯罪记录证明及当事人的护照扫描件提交至国际公证人处办理国际公证</p>
      <p class="noc-bottom-p1">提交至加拿大外交部或者省政府指定部门进行办理认证</p>
      <p class="noc-bottom-p1">交至中国驻加拿大大使馆办理认证</p>
      <p class="noc-bottom-p2">正常办理：15-18个工作日</p>
      <p class="noc-bottom-p2">加急服务：7-10个工作日（不包含国际邮寄时间）</p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'bvi-page',
  props: {
    msg: String
  },
  data(){
    return{
      form: {},
      activeName: '1'
    }
  },
  mounted(){
    window.scrollTo(0,0);
  },
  methods:{
    toAsk(){
      window.open('https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn','_blank')
    },
    toSubmit(){
      this.$message({
          message: '提交成功！',
          type: 'success',
          offset: 100
        });
    }
  }
}
</script>

<style scoped lang="scss">
  .m-bvi-banner{
    width: 100%;
    height: 460px;
    background: url('../../assets/img/noCriminal/Canada/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
  .fingle-box{
    background: url('../../assets/img/noCriminal/Canada/pic_1_2.png') no-repeat center center;
    background-size: 100% 100%;
  }
  .fingle-box1{
    background: url('../../assets/img/noCriminal/Canada/pic_1_1.png') no-repeat center center;
    background-size: 100% 100%;
  }
  .noc-bottom{
    background: url('../../assets/img/noCriminal/Canada/pic_5.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
</style>
