<template>
  <div class="home">
    <div class="m-bvi-banner">
      <p class="banner-p1">一站式澳门公司注册服务</p>
      <p class="banner-p2">【快企务专注于涉内外企业服务十余年】</p>
      <p class="banner-btn banner-btn1" @click="toAsk">立即咨询</p>
    </div>

    <div class="box" style="height:612px;background: rgba(242, 245, 250, 1);">
      <p class="box-p1">注册中国澳门公司优势</p>
      <div class="adv-content">
        <div class="adv-line" style="height: 97px;align-items: flex-start;">
          <img class="adv-icon" style="width: 42px;margin-top: 20px;" src="../../assets/img/register/Macau/pic_1_1.png" alt="">
          <div>
            <p class="adv-p" style="font-weight: bold;padding-top: 20px;">低税率</p>
            <p class="adv-p5">地税制度，经营陈本低</p>
          </div>
        </div>
        <div class="adv-line" style="height: 97px;">
          <img class="adv-icon" style="width: 42px;" src="../../assets/img/register/Macau/pic_1_2.png" alt="">
          <div>
            <p class="adv-p">无需会计师</p>
            <p class="adv-p5">无需会计师申报税表，澳门是拓展葡语地区</p>
          </div>
        </div>
        <div class="adv-line" style="height: 97px;"> 
          <img class="adv-icon" style="width: 42px;" src="../../assets/img/register/Macau/pic_1_3.png" alt="">
          <div>
            <p class="adv-p">位置优越</p>
            <p class="adv-p5">澳门公司方便在澳门银行开户的纽带，商机无限</p>
          </div>
        </div>
        <div class="adv-line" style="height: 97px;">
          <img class="adv-icon" style="width: 42px;" src="../../assets/img/register/Macau/pic_1_4.png" alt="">
          <div>
            <p class="adv-p">银行开户</p>
            <p class="adv-p5">澳门公司方便在澳门银行开户</p>
          </div>
        </div>
        <div class="adv-line" style="height: 97px;">
          <img class="adv-icon" style="width: 42px;" src="../../assets/img/register/Macau/pic_1_5.png" alt="">
          <div>
            <p class="adv-p">投资待遇</p>
            <p class="adv-p5">回程投资享受“超国民待遇”</p>
          </div>
        </div>
      </div>
    </div>

    <div class="box" style="height: auto;padding-bottom: 30px;">
      <p class="box-p1">注册中国澳门公司类型</p>
      <div class="type-content flex center-width">
        <img class="type-img" style="height: 110px;" src="../../assets/img/register/Macau/pic_2_1.jpg" alt="">
        <img class="type-img" style="height: 110px;" src="../../assets/img/register/Macau/pic_2_2.jpg" alt="">
        <img class="type-img" style="height: 110px;" src="../../assets/img/register/Macau/pic_2_3.jpg" alt="">
        <img class="type-img" style="height: 110px;" src="../../assets/img/register/Macau/pic_2_4.jpg" alt="">
      </div>
    </div>

    <div class="box" style="height: 600px;">
      <p class="box-p1">注册中国澳门公司条件</p>
      <div class="mall-box">
        <p class="mall-box-p1">公司名称</p>
        <p class="mall-box-p2">澳门公司可用中文或葡文书写，若加英文，需中英葡三语，需对应翻译</p>
        <p class="mall-box-p1">本厘印税及登记费</p>
        <p class="mall-box-p2">政府按实际的注册资本额收取千分之一至千分之四的厘印税</p>
        <p class="mall-box-p1">法人信息</p>
        <p class="mall-box-p2">法人信息护照扫描件</p>
        <p class="mall-box-p1">注册资本</p>
        <p class="mall-box-p2">最少25000澳门币，没有上限（高于25000将会产生厘印税）</p>
        <p class="mall-box-p1">注册地址</p>
        <p class="mall-box-p2">由我司提供注册地址（若自身已有办公地址，可用自有地址）</p>
        <p class="mall-box-p1">经营范围</p>
        <p class="mall-box-p2">经营范围没有限制，除了少数几个行业需要特批，比如博彩，金融</p>
      </div>
      <img class="mall-box-img" style="height: 150px;width: 248px;" src="../../assets/img/register/Macau/pic_3.jpg" alt="">
    </div>

    <div class="box" style="height: 447px;">
      <p class="box-p1">注册中国澳门公司流程</p>
      <div class="mall-box">
        <p class="mall-box-p1" style="margin-bottom: 12px;">第一步：确认公司名称及经营范围</p>
        <p class="mall-box-p1" style="margin-bottom: 12px;">第二步：填写委托书及递交有效证件签署协议书</p>
        <p class="mall-box-p1" style="margin-bottom: 12px;">第三步：交付费用</p>
        <p class="mall-box-p1" style="margin-bottom: 12px;">第四步：签署制作法定文档，提供文档、护照原件</p>
        <p class="mall-box-p1" style="margin-bottom: 12px;">第五步：政府部门办理相关手续</p>
        <p class="mall-box-p1" style="margin-bottom: 12px;">第六步：完成注册，领取资料</p>
      </div>
      <img class="mall-box-img" style="height: 156px;width: 250px;" src="../../assets/img/register/Macau/pic_4.jpg" alt="">
    </div>

    <div class="box" style="height: 320px;">
      <p class="box-p1">注册完成后所得资料</p>
      <div class="macau-file">
        <div class="macau-file-inner">
          <img class="macau-file-icon" src="../../assets/img/register/Macau/pic_5_1.png" alt="">
          <p class="macau-file-p">商业登记证</p>
        </div>
        <div class="macau-file-inner">
          <img class="macau-file-icon" src="../../assets/img/register/Macau/pic_5_1.png" alt="">
          <p class="macau-file-p">公司章程</p>
        </div>
        <div class="macau-file-inner">
          <img class="macau-file-icon" src="../../assets/img/register/Macau/pic_5_1.png" alt="">
          <p class="macau-file-p">税务登记</p>
        </div>
        <div class="macau-file-inner">
          <img class="macau-file-icon" src="../../assets/img/register/Macau/pic_5_1.png" alt="">
          <p class="macau-file-p">原子印章</p>
        </div>
        <div class="macau-file-inner" style="width: 66%;">
          <img class="macau-file-icon" src="../../assets/img/register/Macau/pic_5_1.png" alt="">
          <p class="macau-file-p">行政管理机关成员（董事）清单</p>
        </div>
      </div>
      <img class="mall-box-img" style="height: 156px;width: 250px;" src="../../assets/img/register/Macau/pic_5_2.jpg" alt="">
    </div>
    
    <div class="box" style="height: 676px;background: rgba(242, 245, 250, 1);">
      <p class="box-p1">注册澳门公司后为您提供的后续服务</p>
      <div class="re-serve">
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Macau/pic_6_1.png" alt="">
          <p class="re-serve-p1">银行开户</p>
          <p class="re-serve-p2">中国银行澳门分行、澳门立桥银行、澳门发展银行、澳门广发银行等</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Macau/pic_6_2.png" alt="">
          <p class="re-serve-p1">秘书服务</p>
          <p class="re-serve-p2">办公地址，公司周年报表年检，名称变更，公司董事股东成员名册更新</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Macau/pic_6_3.png" alt="">
          <p class="re-serve-p1">做账报税</p>
          <p class="re-serve-p2">申报职业税、申报收益、所得税等</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Macau/pic_6_4.png" alt="">
          <p class="re-serve-p1">税号申请</p>
          <p class="re-serve-p2">澳门公司所需文件的公证手续办理或澳门公司投资内地的主体相关公证文件</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Macau/pic_6_5.png" alt="">
          <p class="re-serve-p1">商标注册</p>
          <p class="re-serve-p2">澳门商标申请：从注册日起算7年，注册有效期满需继续使用的，应在注册有效期满前 12个月申请续展注册，每次续展注册有效期为7年。</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Macau/pic_6_6.png" alt="">
          <p class="re-serve-p1">公司注销</p>
          <p class="re-serve-p2">因破产、股东决议解散、存续期届满等原因需要注销的，向登记局和财政局办理相关注销手续</p>
        </div>
      </div>
    </div>

    <div class="box" style="height: auto;padding-bottom: 30px;">
      <p class="box-p1">澳门公司注册常见问题</p>
      <p class="box-p1">解决您的一切疑问</p>
      <div class="question">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item title="全部国内人可不可以注册澳门公司？" name="1">
            <p class="question-p">可以，持中国身份证也可办理注册。在澳门注册公司，并没有对股东/董事之国籍有所限制。</p>
          </el-collapse-item>
          <el-collapse-item title="澳门公司需要本地居民做股东或董事吗?" name="2">
            <p class="question-p">没有这方面的要求，任何地方的居民皆可成为澳门公司的股东或董事。</p>
          </el-collapse-item>
          <el-collapse-item title="澳门公司对于商业名称有没有限制?" name="3">
            <p class="question-p">基本上没有限制，只要满足以下几个条件：
1、没有冲突在先登记；
2、不涉及金融等行业；
3、公司名称可以仅为中文或仅为葡文，根据商法典的规定，若要加英文，则需要中英
葡三语，客户只需提供中英文即可，葡文由我们翻译。</p>
          </el-collapse-item>
          <el-collapse-item title="注册澳门公司需时多长？" name="4">
            <p class="question-p">新注册一间澳门公司，在签署完所有法定文件起7个工作日左右。</p>
          </el-collapse-item>
        </el-collapse>
      </div>
      <p class="to-ask" @click="toAsk"> 
        <img class="to-ask-icon" src="../../assets/img/register/bvi/icon_6_customer_service.png" alt=""> 
        更多问题？立即咨询人工客服</p>
    </div>
    <div class="box" style="height: 480px;">
      <p class="box-p1">快企务的优势</p>
      <p class="box-p2">十余年注册澳门公司经验</p>
      <div class="re-adv">
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_1.png" alt="">
          <p class="re-adv-p1">保密完善</p>
          <p class="re-adv-p2">保密系统完善，客户隐私不外泄</p>
        </div>
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_2.png" alt="">
          <p class="re-adv-p1">全程服务</p>
          <p class="re-adv-p2">一站式专业服务从注册公司到年审、开户，审计报税等</p>
        </div>
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_3.png" alt="">
          <p class="re-adv-p1">专业高效</p>
          <p class="re-adv-p2">根据需求，为客户制定专业快捷的解决方案</p>
        </div>
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_4.png" alt="">
          <p class="re-adv-p1">合同保障</p>
          <p class="re-adv-p2">正规机构，合同保障，费用透明，无隐形消费</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  data(){
    return{
      tab: 1,
      activeName: '1'
    }
  },
  mounted(){
    window.scrollTo(0,0);
  },
  methods:{
    toAsk(){
      window.open('https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn','_blank')
    },
  }
}
</script>
<style scoped>
  .m-bvi-banner{
    width: 100%;
    height: 460px;
    background: url('../../assets/img/register/Macau/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
</style>
