<template>
  <div class="certification m-bvi">
    <div class="m-bvi-banner">
      <p class="finance-banner-p">国内商标注册</p>
    </div>
    <div class="box" style="height: 635px;background: rgba(242, 245, 250, 1);">
      <p class="box-p1">商标裸奔的危害</p>
      <div class="danger-box">
        <div class="danger-inner">
          <p class="danger-p1">不享有商标专用权</p>
          <p class="danger-p2">《商标法》只保护注册商标的专有权，未注册不保护，谁都可以用</p>
        </div>
        <div class="danger-inner">
          <p class="danger-p1">存在被抢注的风险</p>
          <p class="danger-p2">商标注册采样申请在先原则，一但他人抢注，商标专属权就规他</p>
        </div>
        <div class="danger-inner">
          <p class="danger-p1">容易造成侵权行为</p>
          <p class="danger-p2">一旦该商标被抢注，原企业在不知情的状况下如果继续使用该商标进行推广，便会构成侵权行为</p>
        </div>
        <div class="danger-inner">
          <p class="danger-p1">品牌形象受损</p>
          <p class="danger-p2">其他公司使用同样的商标，如果出现质量问题，您的品牌也会遭到影响</p>
        </div>
        <div class="danger-inner">
          <p class="danger-p1">造成经济损失</p>
          <p class="danger-p2">无论是商标被抢注还是大量低品质产品的涌入，企业能做的只有两点：要么将商标“赎”回来，要么放弃此商标的使用权。而无论选择哪一种，势必都将造成巨额经济损失</p>
        </div>
      </div>
    </div>
    <div class="box" style="height: 608px;">
      <p class="box-p1">商标注册所需资料</p>
      <div class="trademark-file">
        <p class="trademark-file-p1">商标注册所需资料</p>
        <p class="trademark-file-p2">以公司名义申请，附企业营业执照复印件；</p>
        <p class="trademark-file-p2">以个人名义申请，附身份证或者护照复印件。 （需有个体工商户营业执照且执照经营范围要与所注册的商标类别相符）</p>
        <p class="trademark-file-p1">列出寻求注册的商品或者服务，指出商标类别 5cm×5cm，最大不超过 10cm×20cm；</p>
        <p class="trademark-file-p1">《商标注册委托书》，我司提供，用于委托商标事务所代理商标注册业务</p>
        <img class="trademark-file-img" src="../../assets/img/finance/innerTrademark/pic_1.jpg" alt="">
      </div>
    </div>
    <div class="box" style="height: 407px;background: rgba(242, 245, 250, 1);">
      <p class="box-p1">商标注册流程</p>
      <div class="trademark-flow">
        <p class="trademark-flow-p1">
          <img class="trademark-flow-icon" src="../../assets/img/finance/innerTrademark/pic_2_1.png" alt="">
          <span class="trademark-flow-span1">第一步：</span>
          <span class="trademark-flow-span2">查询商标能否注册</span> 
        </p>
        <p class="trademark-flow-p1">
          <img class="trademark-flow-icon" src="../../assets/img/finance/innerTrademark/pic_2_2.png" alt="">
          <span class="trademark-flow-span1">第二步：</span>
          <span class="trademark-flow-span2">提交注册申请</span> 
        </p>
        <p class="trademark-flow-p1">
          <img class="trademark-flow-icon" src="../../assets/img/finance/innerTrademark/pic_2_3.png" alt="">
          <span class="trademark-flow-span1">第三步：</span>
          <span class="trademark-flow-span2">核实并递交商标局</span> 
        </p>
        <p class="trademark-flow-p1">
          <img class="trademark-flow-icon" src="../../assets/img/finance/innerTrademark/pic_2_4.png" alt="">
          <span class="trademark-flow-span1">第四步：</span>
          <span class="trademark-flow-span2">商标局形式复查</span> 
        </p>
        <p class="trademark-flow-p1">
          <img class="trademark-flow-icon" src="../../assets/img/finance/innerTrademark/pic_2_5.png" alt="">
          <span class="trademark-flow-span1">第五步：</span>
          <span class="trademark-flow-span2">下发商标申请受理书</span> 
        </p>
        <p class="trademark-flow-p1">
          <img class="trademark-flow-icon" src="../../assets/img/finance/innerTrademark/pic_2_6.png" alt="">
          <span class="trademark-flow-span1">第六步：</span>
          <span class="trademark-flow-span2">实质审查</span> 
        </p>
        <p class="trademark-flow-p1">
          <img class="trademark-flow-icon" src="../../assets/img/finance/innerTrademark/pic_2_7.png" alt="">
          <span class="trademark-flow-span1">第七步：</span>
          <span class="trademark-flow-span2">公示期</span> 
        </p>
        <p class="trademark-flow-p1">
          <img class="trademark-flow-icon" src="../../assets/img/finance/innerTrademark/pic_2_8.png" alt="">
          <span class="trademark-flow-span1">第八步：</span>
          <span class="trademark-flow-span2">下发商标注册证书</span> 
        </p>
      </div>
    </div>
    <div class="box" style="height: 704px;">
      <p class="box-p1">商标价值</p>
      <div class="trademark-value">
        <div class="t-value-box">
          <div class="t-value-inner">
            <img class="t-value-icon" src="../../assets/img/finance/innerTrademark/icon_1_1.png" alt="">
            <p class="t-value-p">申请微信、微博官方认证</p>
          </div>
          <div class="t-value-inner">
            <img class="t-value-icon" src="../../assets/img/finance/innerTrademark/icon_1_2.png" alt="">
            <p class="t-value-p">入驻天猫、京东等电商网站</p>
          </div>
          <div class="t-value-inner">
            <img class="t-value-icon" src="../../assets/img/finance/innerTrademark/icon_1_3.png" alt="">
            <p class="t-value-p">提高公司或个人信誉</p>
          </div>
          <div class="t-value-inner">
            <img class="t-value-icon" src="../../assets/img/finance/innerTrademark/icon_1_4.png" alt="">
            <p class="t-value-p">无形资产(转让,许可,参股,抵押)</p>
          </div>
          <div class="t-value-inner">
            <img class="t-value-icon" src="../../assets/img/finance/innerTrademark/icon_1_5.png" alt="">
            <p class="t-value-p">办理质检、卫检、条码等</p>
          </div>
          <div class="t-value-inner">
            <img class="t-value-icon" src="../../assets/img/finance/innerTrademark/icon_1_6.png" alt="">
            <p class="t-value-p">主动防御(防止被人抢注)</p>
          </div>
          <div class="t-value-inner">
            <img class="t-value-icon" src="../../assets/img/finance/innerTrademark/icon_1_7.png" alt="">
            <p class="t-value-p">享有商标专用权，受法律保护</p>
          </div>
          <div class="t-value-inner">
            <img class="t-value-icon" src="../../assets/img/finance/innerTrademark/icon_1_8.png" alt="">
            <p class="t-value-p">增加购买信心，有利于消费者认牌购物</p>
          </div>
        </div>
      </div>
    </div>
    <div class="box" style="height: auto;padding-bottom: 30px;background: rgba(242, 245, 250, 1)">
      <p class="box-p1">常见问题</p>
      <div class="question">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item title="申请注册商标都需要提交哪些材料？" name="1">
            <p class="question-p">一、商标注册委托书、申请书（用于代理机构商标注册业务，商标注册委托书，申请书可在网站自动生成）。
二、申请人资格证明资料：
① 以公司名义申请，附企业营业执照复印件;
② 以个体名义申请，附身份证或者护照复印件及个体营业执照。
三、商标图样：商标图样（JPG格式，400*400-1000*1000大小）。</p>
          </el-collapse-item>
          <el-collapse-item title="商标分哪些类型？" name="2">
            <p class="question-p">（1）根据商标的构图形式来分类，可分为文字商标、图形商标、图形与文字组合商标。
（2）根据商标的用途和作用来分类，可划分为商品商标和服务商标。
（3）根据商标拥有者、使用者的不同来分类，可划分为制造商标、销售商标、集体商标。
（4）根据商标使用人对商标的使用动机来分类，可划分为联合商标、防御商标、证明商标。
（5）根据商标的载体分类，可分为平面商标、立体商标、音啊商标、气味商标等。</p>
          </el-collapse-item>
          <el-collapse-item title="哪些东西可注册商标？" name="3">
            <p class="question-p">文字、图形、字母、数字、三维标志和颜色组合，以及上述要素的组合，均可以作为商标申请注册。</p>
          </el-collapse-item>
          <el-collapse-item title="商标保护期限是多长？" name="4">
            <p class="question-p">注册商标的有效期为10年。注册商标有效期满后需要继续使用的，应当在期满前的一年内申请续展注册。在此
期间未能提出申请的，还有6个月的宽展期。宽展期内仍未提出申请的，期满后商标局将予以注销。</p>
          </el-collapse-item>
        </el-collapse>
      </div>
      <p class="to-ask" @click="toAsk"> 
        <img class="to-ask-icon" src="../../assets/img/register/bvi/icon_6_customer_service.png" alt=""> 
        更多问题？立即咨询人工客服</p>
    </div>
    <div class="box" style="height: 418px;background: #fff;">
      <p class="box-p1">为什么选择我们</p>
      <div class="why-us">
        <div class="why-us-inner">
          <img class="why-us-icon" src="../../assets/img/finance/innerTrademark/icon_3_1.png" alt="">
          <p class="why-us-p1">快速</p>
          <p class="why-us-p2">一对一顾问服务，快速响应客户需求</p>
        </div>
        <div class="why-us-inner">
          <img class="why-us-icon" src="../../assets/img/finance/innerTrademark/icon_3_2.png" alt="">
          <p class="why-us-p1">专业</p>
          <p class="why-us-p2">十余年专业代理国内外商标注册相关业务</p>
        </div>
        <div class="why-us-inner">
          <img class="why-us-icon" src="../../assets/img/finance/innerTrademark/icon_3_3.png" alt="">
          <p class="why-us-p1">严谨</p>
          <p class="why-us-p2">商标顾问、质检部经理、客户三重质检、多重把关</p>
        </div>
        <div class="why-us-inner">
          <img class="why-us-icon" src="../../assets/img/finance/innerTrademark/icon_3_4.png" alt="">
          <p class="why-us-p1">保障</p>
          <p class="why-us-p2">知识产权的商标注册成功率高达98.3%</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'bvi-page',
  props: {
    msg: String
  },
  data(){
    return{
      form: {},
      activeName: '1'
    }
  },
  mounted(){
    window.scrollTo(0,0);
  },
  methods:{
    toAsk(){
      window.open('https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn','_blank')
    },
  }
}
</script>

<style scoped lang="scss">
  .m-bvi-banner{
    width: 100%;
    height: 230px;
    background: url('../../assets/img/finance/innerTrademark/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
</style>
