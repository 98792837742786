<template>
  <div class="certification m-bvi" style="background: rgba(242, 245, 250, 1);">
    <div class="m-bvi-banner">
      <p class="banner-certification">【快企务专注于涉内外企业服务十余年】</p>
      <p class="banner-p1" style="padding-top: 20px">中国委托公证人公证</p>
      <!-- <p class="banner-p2">七天取件丨一对一服务丨合同保障</p> -->
      <p class="banner-btn banner-btn1" @click="toAsk">立即咨询</p>
    </div>
    <div class="box" style="height: auto;background: #fff;">
      <p class="box-p1">中国委托公证人制度</p>
      <p class="box-p2" style="text-align: left;width: 320px;margin-bottom: 0;">中国委托公证人制度，即香港居民回内地处理法律事务所需公证书须由司法部任命的委托公证人出具，
        并经中国法律服务（香港）有限公司加章转递，才能发往内地使用。实行这一制度，是基于香港和内地之间法律制度不同，
        办理公证证明所依据的法律、办证程式和效力不同而设置的一项特殊法律制度，核心是为了确
        保香港发往内地使用的公证文书的真实性、合法性。</p>
      <p class="box-p2" style="text-align: left;width: 320px;margin-bottom: 0;margin-top: 0;">『最高人民法院』与『司法部』 《关于涉港公证文书效力问题的通知》：未经审核加章转递程式的证明文书，不具有公证文书的证明效力和执行效力。</p>
      <p class="box-p-title">选择您的公证类别</p>
      <div class="tab-content-box">
          <div class="tab-box1">
            <p class="tab-inner1" style="width: 50%;" :class="{'active': tab == 1}" @click="tab = 1">商业文件</p>
            <p class="tab-inner1" style="width: 50%;" :class="{'active': tab == 2}" @click="tab = 2">民事文件</p>
          </div>
          <div class="tab-content-box1" v-if="tab == 1">
            <img class="tab-content-img" style="margin-top: 30px" src="../../assets/img/notarization/china/pic_16.jpg" alt="">
          </div>
          <div class="tab-content-box1" v-if="tab == 2">
            <img class="tab-content-img" style="margin-top: 30px"  src="../../assets/img/notarization/china/pic_17.jpg" alt="">
          </div>
      </div>
      <p class="form-submit" @click="toAsk" style="width: 125px;border-radius: 4px;">更多 ></p>
    </div>
    <div class="box" style="height: 452px;background: #fff;margin-top: 10px;">
      <p class="box-p1">中国委托公证使用场景</p>
      <div class="screen-box">
        <p class="screen-p">很多香港同胞到内地探亲、定居、领养子女、结婚</p>
        <p class="screen-p">香港企业到内地人事投资、经商，亦或者在香港注册香港公司后，在国内用香港公司投资经营等相关事宜，由于内地有关部门及人士无法了解香港当事人的真实情况</p>
        <p class="screen-p">香港居民到内地登记结婚，相关机构有必要了解其婚姻状况</p>
        <p class="screen-p">香港企业在内地进行商业活动或投资，内地的合作伙伴或银行、工商登记机关亦有需要对香港公司有若干的了解，如公司的商业登记记录、董事或股东资料、财务状况等等</p>
        <p class="screen-p">香港企业需要开设代表处或港资公司时的法定提交文件，也可用于香港公司在内地购置不动产</p>
        <p class="screen-p">海外公司或外国人士如要在中国大陆境内注册公司、购买物业、或需要在中国注册登记的商品时，则需要出具其所在国家或地区的法律公证文件</p>
      </div>
    </div>
    <div class="box" style="height: 280px;background: #fff;margin-top: 10px;">
      <p class="box-p1">中国委托公证需要提供的信息</p>
      <p class="box-p2" style="margin-bottom: 0;">用途-国家-省份(州)-城市</p>
      <div class="em-file-box">
        <div class="hague-banner hague-banner4">
          <p class="hague-banner-p8">公证用途</p>
          <p class="hague-banner-p9">注册外商独资公司、中外合资公司外商驻代表处或其他用</p>
          <p class="hague-banner-p8">公证使用地</p>
          <p class="hague-banner-p9">需注明国家、省份、城市</p>
        </div> 
      </div>
    </div>
    <div class="box" style="height: 400px;background: #fff;">
      <p class="box-p1">中国委托公证人公证程序</p>
      <p class="box-p2" style="margin-bottom: 12px;">四步公证</p>
      <div class="program-box">
        <div class="program-inner inner1">
          <p class="program-p1">步骤一：</p>
          <p class="program-p2">申请人递交公证资料或文件</p>
        </div>
        <div class="program-inner inner2">
          <p class="program-p1">步骤二：</p>
          <p class="program-p2">司法部任命香港中国委托公证人出具委托公证文书证明文件并制作公证文件</p>
        </div>
        <div class="program-inner inner3">
          <p class="program-p1">步骤三：</p>
          <p class="program-p2">中国法律服务（香港）有限公司电脑登记公证文件加章转递</p>
        </div>
        <div class="program-inner inner4">
          <p class="program-p1">步骤四：</p>
          <p class="program-p2">确定文件之后4-5个工作日，如加急可2-3个工作日</p>
        </div>
      </div>
    </div>
    <div class="box" style="height: 756px;background: #fff;margin-top: 10px;">
      <p class="box-p1">香港公司公证案例</p>
      <p class="box-p4">中国香港公司用于福建诉讼使用</p>
      <div class="scroll-box">
        <div class="scroll-inner">
          <img class="scroll-img" src="../../assets/img/notarization/china/pic_3_1.jpg" alt="">
          <img class="scroll-img" src="../../assets/img/notarization/china/pic_3_2.jpg" alt="">
          <img class="scroll-img" src="../../assets/img/notarization/china/pic_3_3.jpg" alt="">
        </div>
      </div>
      <p class="box-p4">中国香港公司用于上海设立外资</p>
      <div class="scroll-box">
        <div class="scroll-inner" style="width: 100%;">
          <img class="scroll-img" src="../../assets/img/notarization/china/pic_4_1.jpg" alt="">
          <img class="scroll-img" src="../../assets/img/notarization/china/pic_4_2.jpg" alt="">
        </div>
      </div>
      <p class="box-p4">中国香港公司用于南通设立公司</p>
      <div class="scroll-box">
        <div class="scroll-inner" style="width: 450px">
          <img class="scroll-img" src="../../assets/img/notarization/china/pic_5_1.jpg" alt="">
          <img class="scroll-img" src="../../assets/img/notarization/china/pic_5_2.jpg" alt="">
          <img class="scroll-img" src="../../assets/img/notarization/china/pic_5_3.jpg" alt="">
          <img class="scroll-img" src="../../assets/img/notarization/china/pic_5_4.jpg" alt="">
        </div>
      </div>
    </div>
    
    <div class="box" style="height: 520px;background: #fff;margin-top: 10px;">
      <p class="box-p1">我司办理的优势</p>
      <p class="box-p2">十余年行业代办经验</p>
      <div class="cer-adv-box">
        <div class="cer-adv-inner">
          <img class="cer-adv-icon" src="../../assets/img/certification/Singapore/icon_1_1.png" alt="">
          <p class="cer-adv-p1">严格保密</p>
          <p class="cer-adv-p2">严密保存材料，公证书办理完成即刻销毁所有材料文件</p>
        </div>
        <div class="cer-adv-inner">
          <img class="cer-adv-icon" src="../../assets/img/certification/Singapore/icon_1_2.png" alt="">
          <p class="cer-adv-p1">透明公开</p>
          <p class="cer-adv-p2">价格公开透明，公证认证办理费用公开透明可查</p>
        </div>
        <div class="cer-adv-inner">
          <img class="cer-adv-icon" src="../../assets/img/certification/Singapore/icon_1_3.png" alt="">
          <p class="cer-adv-p1">全天跟进</p>
          <p class="cer-adv-p2">24小时跟进，每份公证材料专员全天候跟进反馈</p>
        </div>
        <div class="cer-adv-inner">
          <img class="cer-adv-icon" src="../../assets/img/certification/Singapore/icon_1_4.png" alt="">
          <p class="cer-adv-p1">退费承诺</p>
          <p class="cer-adv-p2">公证拒办退款，客户公证材料被拒全额退还费用</p>
        </div>
      </div>
    </div>
    <div class="box" style="height: 643px;background: #fff;margin-top: 10px;">
      <p class="box-p1">咨询服务</p>
      <p class="box-p2">我们还能为您做</p>
      <div class="ask-box">
        <p class="ask-p1">海牙认证</p>
        <p class="ask-p2">海牙公约组织成员国，目前成员国有102个。如德国、法国、韩国、美国、日本、澳大利亚、中国香港 及中国澳门都是公约成员国</p>
        <p class="ask-p3" @click="toAsk">咨询办理</p>
      </div>
      <div class="ask-box">
        <p class="ask-p1">国际公证认证</p>
        <p class="ask-p2">特聘具有国际公证员资格国际公证人，为香港文件、事实及法律文书等用于海外提供公证认证服务</p>
        <p class="ask-p3" @click="toAsk">咨询办理</p>
      </div>
      <div class="ask-box">
        <p class="ask-p1">使馆认证</p>
        <p class="ask-p2">使馆认证是指一国外交机构及其授权机构在涉外文书上确认所在国公证机关或某些特殊机关的印章或该机关主管人员的签字属实</p>
        <p class="ask-p3" @click="toAsk">咨询办理</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'bvi-page',
  props: {
    msg: String
  },
  data(){
    return{
      tab: 1
    }
  },
  mounted(){
    window.scrollTo(0,0);
  },
  methods: {
    toAsk(){
      window.open('https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn','_blank')
    }
  }
}
</script>

<style scoped lang="scss">
  .m-bvi-banner{
    width: 100%;
    height: 460px;
    background: url('../../assets/img/notarization/china/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
  .hague-banner4{
    width: 94%;
    margin: 0 auto;
    height: 170px;
    background: url('../../assets/img/notarization/china/pic_1.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
  .inner1{
    background: url('../../assets/img/notarization/china/pic_2_1.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
  .inner2{
    background: url('../../assets/img/notarization/china/pic_2_2.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
  .inner3{
    background: url('../../assets/img/notarization/china/pic_2_3.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
  .inner4{
    background: url('../../assets/img/notarization/china/pic_2_4.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
</style>
