<template>
  <div class="certification m-bvi">
    <div class="odi-banner">
      <p class="law-banner-p1">香港法律意见书</p>
      <p class="banner-btn banner-btn1">立即办理</p>
    </div>
    <div class="box" style="height: 477px;">
      <p class="box-p1">香港公司法律意见书</p>
      <img class="odi-img1" style="margin-top: 30px" src="../../assets/img/finance/HongKongLaw/pic_1.jpg" alt="">
      <p class="odi-p1">香港律师意见书，即由香港律师对香港公司相关文件进行审核，然后对其出具意见书，是由香港执业律师对香港公司的真实性，目前状态，财务状况等各种数据收集整理之后，从法律专业角度给出的综合评价。法律意见书是律师执业中的重要文书。一份形式严谨、结构缜密、分析准确、论证精当的法律意见书不仅是律师为客户提供专业法律服务的工作指南，同时也是律师对纷繁复杂的各种法律关系宏观驾驭能力和微观操作技巧的充分展示。</p>
    <p class="form-submit" style="width: 96px;margin-left: 5%;">立即咨询</p>
    </div>

    <div class="box" style="height: 390px;background: rgba(242, 245, 250, 1);">
      <p class="box-p1">什么情况下需要出具“香港公司法律意见书”</p>
      <p class="box-p2">越来越多的企业适用香港公司在国内进行投资经营，与此同时，香港公司法律意见书在各个场合的使用需求也越来越多，例如：</p>
      <div class="law-swipe">
        <van-swipe class="law-swipe-box" :width="286" :height="174" :autoplay="3000" indicator-color="white" loop="true">
          <van-swipe-item>
            <div class="law-swipe-inner">
              <img class="law-swiper-img" src="../../assets/img/finance/HongKongLaw/pic_2_1.jpg" alt="">
              <p class="law-swiper-p">新三板上市</p>
            </div>
          </van-swipe-item>
          <van-swipe-item>
            <div class="law-swipe-inner">
              <img class="law-swiper-img" src="../../assets/img/finance/HongKongLaw/pic_2_2.jpg" alt="">
              <p class="law-swiper-p">企业并购</p>
            </div>
          </van-swipe-item>
          <van-swipe-item>
            <div class="law-swipe-inner">
              <img class="law-swiper-img" src="../../assets/img/finance/HongKongLaw/pic_2_3.jpg" alt="">
              <p class="law-swiper-p">银行办理信贷业务</p>
            </div>
          </van-swipe-item>
          <van-swipe-item>
            <div class="law-swipe-inner">
              <img class="law-swiper-img" src="../../assets/img/finance/HongKongLaw/pic_2_4.jpg" alt="">
              <p class="law-swiper-p">股权质押</p>
            </div>
          </van-swipe-item>
        </van-swipe>
        <p class="law-swiper-tips">
          （香港律师意见书办理时间： 
          <span>3-5 </span>
          个工作日左右！）
        </p>
      </div>
    </div>
    <div class="box" style="height: 662px;">
      <p class="box-p1">法律意见书内容通常涉及以下范围</p>
      <p class="box-p2">一般而言，国内须要就香港公司以下各个范畴出具法律意见: 一般母公司上市, 其子公司需做以下7点调查</p>
      <div class="law-range">
        <div class="law-range-inner">
          <img class="law-range-p1" src="../../assets/img/finance/HongKongLaw/icon_1_1.png" alt="">
          <p class="law-range-p2">该公司设立的合法性</p>
        </div>
        <div class="law-range-inner">
          <img class="law-range-p1" src="../../assets/img/finance/HongKongLaw/icon_1_2.png" alt="">
          <p class="law-range-p2">该公司续存的合法性</p>
        </div>
        <div class="law-range-inner">
          <img class="law-range-p1" src="../../assets/img/finance/HongKongLaw/icon_1_3.png" alt="">
          <p class="law-range-p2">该公司的股权情况及历次股份变动的合法性</p>
        </div>
        <div class="law-range-inner">
          <img class="law-range-p1" src="../../assets/img/finance/HongKongLaw/icon_1_4.png" alt="">
          <p class="law-range-p2">该公司现行的章程及其历次修订的合法性</p>
        </div>
        <div class="law-range-inner">
          <img class="law-range-p1" src="../../assets/img/finance/HongKongLaw/icon_1_5.png" alt="">
          <p class="law-range-p2">该公司有权机构已经做出的决议的合法性</p>
        </div>
        <div class="law-range-inner">
          <img class="law-range-p1" src="../../assets/img/finance/HongKongLaw/icon_1_6.png" alt="">
          <p class="law-range-p2">该公司及董事的诉讼、仲裁和行政处罚</p>
        </div>
        <div class="law-range-inner">
          <img class="law-range-p1" src="../../assets/img/finance/HongKongLaw/icon_1_7.png" alt="">
          <p class="law-range-p2">该公司境外投资的情况</p>
        </div>
      </div>
    </div>
   
    <div class="box" style="height: 497px;background: rgba(242, 245, 250, 1);">
      <p class="box-p1">“香港公司法律意见书”办理程序</p>
      <div class="odi-flo" style="height: 400px;margin-top: 20px;">
        <p class="odi-flo-p4" style="padding-top: 5px;">首先，需要客户确定委聘，然后敝所将会出具一委聘函。</p>
        <p class="odi-flo-p4" style="padding-top: 50px;">客户需在敝所委聘函上签署确定指示，并缴付前期律师费用金。</p>
        <p class="odi-flo-p4" style="padding-top: 35px;">当事人提供所需文件，包括: 香港公司的法定记录，公司2年内的核数报告及其他所需资产证明(假若法律意见书需要就有关资产作出确定)等。</p>
        <p class="odi-flo-p4" style="padding-top: 30px;">在客户提供所有文件，并且做出所需审查后，敝所将草拟法律意见书。一般而言，敝所在得到客户书面确定及缴付前期律师费用金后5个工作日内出具一份意见书草稿。</p>
        <p class="odi-flo-p4" style="padding-top: 50px;">最后，待客户确定意见书草稿内容后，敝所签发法律意见书。</p>
      </div>
    </div>
    <div class="box" style="height: 675px;">
      <p class="box-p1">增值服务</p>
      <p class="box-p2">我们还为您提供</p>
      <div class="odi-add">
        <div class="odi-add-inner">
          <img class="odi-add-icon" src="../../assets/img/finance/ODI/icon_3_1.png" alt="">
          <p class="odi-add-p">境外公司注册</p>
        </div>
        <div class="odi-add-inner">
          <img class="odi-add-icon" src="../../assets/img/finance/ODI/icon_3_2.png" alt="">
          <p class="odi-add-p">境外银行开户</p>
        </div>
        <div class="odi-add-inner">
          <img class="odi-add-icon" src="../../assets/img/finance/ODI/icon_3_3.png" alt="">
          <p class="odi-add-p">IPO业务咨询</p>
        </div>
        <div class="odi-add-inner">
          <img class="odi-add-icon" src="../../assets/img/finance/ODI/icon_3_4.png" alt="">
          <p class="odi-add-p">税务筹划</p>
        </div>
        <div class="odi-add-inner">
          <img class="odi-add-icon" src="../../assets/img/finance/ODI/icon_3_5.png" alt="">
          <p class="odi-add-p">VAT税号的申请和申报</p>
        </div>
        <div class="odi-add-inner">
          <img class="odi-add-icon" src="../../assets/img/finance/ODI/icon_3_6.png" alt="">
          <p class="odi-add-p">全球商标申请</p>
        </div>
        <div class="odi-add-inner">
          <img class="odi-add-icon" src="../../assets/img/finance/ODI/icon_3_7.png" alt="">
          <p class="odi-add-p">香港及境外公证</p>
        </div>
        <div class="odi-add-inner">
          <img class="odi-add-icon" src="../../assets/img/finance/ODI/icon_3_8.png" alt="">
          <p class="odi-add-p">代持和法律意见书等服务</p>
        </div>
      </div>
    </div>
  
  </div>
</template>
<script>
export default {
  name: 'bvi-page',
  props: {
    msg: String
  },
  data(){
    return{
      form: {},
      activeName: '1'
    }
  },
  mounted(){
    
  }
}
</script>

<style scoped lang="scss">
  .odi-banner{
    width: 100%;
    height: 460px;
    background: url('../../assets/img/finance/HongKongLaw/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
  .odi-flo{
    background: url('../../assets/img/finance/HongKongLaw/pic_3.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
  


</style>
