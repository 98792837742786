<template>
  <div class="home">
    <div class="m-bvi-banner">
      <p class="banner-p1">一站式瑞士公司注册服务</p>
      <p class="banner-p2">【快企务专注于涉内外企业服务十余年】</p>
      <p class="banner-btn banner-btn1" @click="toAsk">立即咨询</p>
    </div>

    <div class="box" style="height: 600px;">
      <p class="box-p1">注册瑞士公司优势</p>
      <div class="mall-box">
        <p class="mall-box-p1">高度国际融合</p>
        <p class="mall-box-p2">高度国际融合，瑞士与欧洲关系稳固，强有力的出口导向型商业，欧盟提供保障，除了四种国家语言外，用英语交流</p>
        <p class="mall-box-p1">高效率的资本市场</p>
        <p class="mall-box-p2">高效率的资本市场及有力条件，范围广泛的银行和保险产品有利的利率期限，长期来看，物价稳定性高且通胀率低</p>
        <p class="mall-box-p1">金融中心</p>
        <p class="mall-box-p2">全球领先的产业集群，全球制药和生命科学类公司的独特聚集地，重要的金融中心，高端钟表业的市场领军者，欧盟的首选中立性商业驻地</p>
        <p class="mall-box-p1">适度的税收负担</p>
        <p class="mall-box-p2">适度的税收负担，相对其他欧洲其他各国具有竞争力，关税、税费和其他费用很低，税务规划方案颇具吸引力</p>
        <p class="mall-box-p1">无外汇管制</p>
        <p class="mall-box-p2">无外汇管制，募集资金容易</p>
      </div>
      <img class="mall-box-img" style="height: 172px;width: 277px;" src="../../assets/img/register/Swiss/pic_1.jpg" alt="">
    </div>

    <div class="box" style="height: auto;padding-bottom: 30px;">
      <p class="box-p1">注册瑞士公司类型</p>
      <div class="type-content flex center-width">
        <img class="type-img" style="height: 110px;" src="../../assets/img/register/Swiss/pic_2_1.jpg" alt="">
        <img class="type-img" style="height: 110px;" src="../../assets/img/register/Swiss/pic_2_2.jpg" alt="">
      </div>
    </div>

    <div class="box" style="height: 689px;">
      <p class="box-p1">注册瑞士公司条件</p>
      <div class="mall-box">
        <p class="mall-box-p1">公司名称</p>
        <p class="mall-box-p2">拟注册瑞士公司的名称（英文），必须以SA；公司注册证书不可以载述中文公司名称</p>
        <p class="mall-box-p1">瑞士公民</p>
        <p class="mall-box-p2">最少一名董事必须是居住在瑞士的瑞公民，职有多于一名董事，其中占大多数应为瑞士公民</p>
        <p class="mall-box-p1">注册资本</p>
        <p class="mall-box-p2">注册资本最低10万瑞士法郎，至少缴付5万瑞士法郎</p>
        <p class="mall-box-p1">法定资本</p>
        <p class="mall-box-p2">法定资本必须有二万瑞士法郎，并全部缴足</p>
        <p class="mall-box-p1">经营范围</p>
        <p class="mall-box-p2">确认瑞士公司的经营范围</p>
        <p class="mall-box-p1">注册地址</p>
        <p class="mall-box-p2">必须位于当地的公司商业大厦的地址。快企务可协助</p>
      </div>
      <img class="mall-box-img" style="height: 200px;width: 220px;margin-top: 30px;" src="../../assets/img/register/Swiss/pic_3.jpg" alt="">
    </div>

    <div class="box" style="height: 319px;">
      <p class="box-p1">注册瑞士公司流程</p>
      <div class="swiss-flow">
        <img class="swiss-flow-inner" src="../../assets/img/register/Swiss/pic_4_1.jpg" alt="">
        <img class="swiss-flow-inner" src="../../assets/img/register/Swiss/pic_4_2.jpg" alt="">
        <img class="swiss-flow-inner" src="../../assets/img/register/Swiss/pic_4_3.jpg" alt="">
        <img class="swiss-flow-inner" src="../../assets/img/register/Swiss/pic_4_4.jpg" alt="">
        <img class="swiss-flow-inner" src="../../assets/img/register/Swiss/pic_4_5.jpg" alt="">
        <img class="swiss-flow-inner" src="../../assets/img/register/Swiss/pic_4_6.jpg" alt="">
      </div>
    </div>

    <div class="box" style="height: 446px;">
      <p class="box-p1">注册完成后所得资料</p>
      <div class="swiss-file">
        <p class="swiss-file-p">公司营业藤本一份（营业执照）</p>
        <p class="swiss-file-p">株式会社的定款（章程）</p>
        <p class="swiss-file-p">公司印章卡一枚</p>
        <p class="swiss-file-p">公司印章一套（公章、银行用章）</p>
        <p class="swiss-file-p">公司印章证明书一份</p>
      </div>
      <img class="mall-box-img" style="height: 200px;width: 200px;margin-top: 30px;" src="../../assets/img/register/Swiss/pic_5.jpg" alt="">
    </div>
    
    <div class="box" style="height: 725px;background: rgba(242, 245, 250, 1);">
      <p class="box-p1">注册瑞士公司后为您提供的后续服务</p>
      <div class="re-serve">
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Swiss/pic_6_1.png" alt="">
          <p class="re-serve-p1">年审/续牌</p>
          <p class="re-serve-p2">公司年审：瑞士公司年审一年一次</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Swiss/pic_6_2.png" alt="">
          <p class="re-serve-p1">银行开户</p>
          <p class="re-serve-p2">瑞士银行瑞士银行管理着世界国际投资私人财富的四分之一到三分之一；另外可开在星展银行开户 </p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Swiss/pic_6_3.png" alt="">
          <p class="re-serve-p1">做账报税</p>
          <p class="re-serve-p2">瑞士的增值税仅为8%，瑞士税收系统采用联邦制形式，因此每个州对公司征收的实际税率各不相同，在12%和18%之间。</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Swiss/pic_6_4.png" alt="">
          <p class="re-serve-p1">税号申请</p>
          <p class="re-serve-p2">在商业登记机关注册登记的实体将会获得 UID 号码；一般情况下，UID 编号统一分配给作为法人实体的公司总部，其下设的分支机构不会获得 UID 编号</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Swiss/pic_6_5.png" alt="">
          <p class="re-serve-p1">秘书服务</p>
          <p class="re-serve-p2">办公地址，公司周年报表年检，公司董事股东成员名册更新，在职证明与良好证明，代缴政府规费，修改公司章程等</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Swiss/pic_6_6.png" alt="">
          <p class="re-serve-p1">公司注销</p>
          <p class="re-serve-p2">因破产、股东决议解散、存续期届满等原因需要注销的，应及时主动注销公司</p>
        </div>
      </div>
    </div>

    <div class="box" style="height: auto;padding-bottom: 30px;">
      <p class="box-p1">瑞士公司注册常见问题</p>
      <p class="box-p1">解决您的一切疑问</p>
      <div class="question">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item title="瑞士公司信息是保密的吗?" name="1">
            <p class="question-p">瑞士公司信息是保密的，注册的瑞士公司不需要披露实益拥有的数据，或披露银行账户信息，除非涉及正在
进行的刑事诉讼。</p>
          </el-collapse-item>
          <el-collapse-item title="瑞士公司名称要求?" name="2">
            <p class="question-p">公司名称必须以SA 或 SARL ( 即"有限公司 Limited ");公司名称不能以"信托公司"或"银行"或其它被认为意思
相似的词结尾，除非这些公司在 Switzerland 瑞士公司获得相应的执照，公司注册证书不可以载述中文公司
名称</p>
          </el-collapse-item>
          <el-collapse-item title="瑞士公司需要缴纳增值税吗?" name="3">
            <p class="question-p">瑞士公司是否缴纳增值税以及缴纳多少，与很多因素有关。原则上，如果在瑞士的年营业额高于10万瑞士法
郎，就应该缴纳增值税。</p>
          </el-collapse-item>
        </el-collapse>
      </div>
      <p class="to-ask" @click="toAsk"> 
        <img class="to-ask-icon" src="../../assets/img/register/bvi/icon_6_customer_service.png" alt=""> 
        更多问题？立即咨询人工客服</p>
    </div>
    <div class="box" style="height: 480px;">
      <p class="box-p1">快企务的优势</p>
      <p class="box-p2">十余年注册瑞士公司经验</p>
      <div class="re-adv">
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_1.png" alt="">
          <p class="re-adv-p1">保密完善</p>
          <p class="re-adv-p2">保密系统完善，客户隐私不外泄</p>
        </div>
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_2.png" alt="">
          <p class="re-adv-p1">全程服务</p>
          <p class="re-adv-p2">一站式专业服务从注册公司到年审、开户，审计报税等</p>
        </div>
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_3.png" alt="">
          <p class="re-adv-p1">专业高效</p>
          <p class="re-adv-p2">根据需求，为客户制定专业快捷的解决方案</p>
        </div>
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_4.png" alt="">
          <p class="re-adv-p1">合同保障</p>
          <p class="re-adv-p2">正规机构，合同保障，费用透明，无隐形消费</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  data(){
    return{
      tab: 1,
      activeName: '1'
    }
  },
  mounted(){
    window.scrollTo(0,0);
  },
  methods:{
    toAsk(){
      window.open('https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn','_blank')
    },
  }
}
</script>
<style scoped>
  .m-bvi-banner{
    width: 100%;
    height: 460px;
    background: url('../../assets/img/register/Swiss/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
</style>
