<template>
  <div class="certification m-bvi" style="background: rgba(242, 245, 250, 1);">
    <div class="m-bvi-banner">
      <p class="banner-certification">【快企务专注于涉内外企业服务十余年】</p>
      <p class="banner-p1" style="padding-top: 20px">使馆认证快速办理</p>
      <p class="banner-p2">留学 | 移民 | 探亲 | 旅游 | 工作 | 商务</p>
      <p class="banner-btn banner-btn1" @click="toAsk">立即咨询</p>
    </div>
    <div class="box" style="height: 317px;background: #fff;">
      <p class="box-p1">请输入您的使馆认证办理信息</p>
      <div class="form-box">
        <div class="form-line">
          <p class="form-label">姓名</p>
          <input class="form-input" placeholder="请输入您的姓名" type="text" v-model="form.name">
        </div>
        <div class="form-line">
          <p class="form-label">电话</p>
          <input class="form-input" type="text" placeholder="请输入您的联系方式" v-model="form.mobile">
        </div>
        <div class="form-line">
          <p class="form-label">您的需求</p>
          <input class="form-input" type="text" placeholder="请输入您的需求" v-model="form.remark">
        </div>
      </div>
      <p class="form-submit" @click="toSubmit">提交</p>
    </div>
    <div class="em-file-box">
      <div class="hague-banner hague-banner2">
        <p class="hague-banner-p4">商业文件 & 民事文件</p>
        <p class="hague-banner-p5">以下为常规办理文件</p>
      </div> 
    </div>
    <div style="background: #fff;padding-bottom: 20px;">
      <div class="embassy-file">
        <img class="embassy-file-img" src="../../assets/img/notarization/embassy/pic_1_16.jpg" alt="">
        <p class="embassy-file-btn" @click="toAsk">立即咨询</p>
      </div>
      <div class="embassy-file">
        <img class="embassy-file-img" src="../../assets/img/notarization/embassy/pic_1_26.jpg" alt="">
        <p class="embassy-file-btn" @click="toAsk">立即咨询</p>
      </div>
    </div>
    <div class="em-file-box">
      <div class="hague-banner hague-banner3">
        <p class="hague-banner-p6">中国驻外国使馆认证 & 外国驻中国使馆认证</p>
        <p class="hague-banner-p7">中国驻外使馆业务涵盖、外国驻中国使馆业务涵盖</p>
      </div> 
    </div>
    <div style="background: #fff;padding-bottom: 20px;">
      <div class="embassy-file">
        <img class="embassy-file-img" src="../../assets/img/notarization/embassy/pic_2_16.jpg" alt="">
        <p class="embassy-file-btn" @click="toAsk" style="background: rgba(237, 41, 65, 1);">立即咨询</p>
      </div>
      <div class="embassy-file">
        <img class="embassy-file-img" src="../../assets/img/notarization/embassy/pic_2_26.jpg" alt="">
        <p class="embassy-file-btn" @click="toAsk" style="background: rgba(237, 41, 65, 1);">立即咨询</p>
      </div>
    </div>
    <div class="box" style="height: 350px;background: #fff;margin-bottom: 20px;margin-top: 10px;">
      <p class="box-p1">使馆认证办理所需资料</p>
      <p class="box-p2">三类必要资料</p>
      <van-swipe class="my-swipe" style="width: 345px;margin: 0 auto;" :autoplay="3000" indicator-color="white" :loop="tr
        ">
        <van-swipe-item>
          <img class="cer-swiper-img" style="width: 345px;height: 210px;" src="../../assets/img/notarization/embassy/pic_3_1.jpg" alt="">
        </van-swipe-item>
        <van-swipe-item>
          <img class="cer-swiper-img" style="width: 345px;height: 210px;" src="../../assets/img/notarization/embassy/pic_3_2.jpg" alt="">
        </van-swipe-item>
        <van-swipe-item>
          <img class="cer-swiper-img" style="width: 345px;height: 210px;" src="../../assets/img/notarization/embassy/pic_3_3.jpg" alt="">
        </van-swipe-item>
      </van-swipe>
    </div>
    <div class="box" style="height: 368px;background: #fff;">
      <p class="box-p1">使馆认证流程/时间</p>
      <p class="box-p2">全程7~12个工作日</p>
      <div class="step-box">
        <p class="step-p"><span class="step-num">1</span>国际公证人公证</p>
        <p class="step-p"><span class="step-num">2</span>外交部认证</p>
        <p class="step-p" style="width: 38%;"><span class="step-num">3</span>驻内/驻外使馆认证</p>
      </div>
      <img class="cer-img4" style="width: 258px;height: 184px;" src="../../assets/img/notarization/embassy/pic_4.jpg" alt="">
    </div>
    <div class="box" style="height: 910px;background: #fff;margin-top: 10px;">
      <p class="box-p1">我们的优势</p>
      <p class="box-p2">公证认证价格透明、材料翻译认证盖章，提供一站式服务邮寄到家办理</p>
      <div class="cer-adv-box">
        <div class="cer-adv-inner">
          <img class="cer-adv-icon" src="../../assets/img/notarization/embassy/icon_1_1.png" alt="">
          <p class="cer-adv-p1">1对1代办</p>
          <p class="cer-adv-p2">公证代办，全程指定专员负责办理</p>
        </div>
        <div class="cer-adv-inner">
          <img class="cer-adv-icon" src="../../assets/img/notarization/embassy/icon_1_2.png" alt="">
          <p class="cer-adv-p1">透明公开</p>
          <p class="cer-adv-p2">价格公开透明，公证认证办理费用公开透明可查</p>
        </div>
        <div class="cer-adv-inner">
          <img class="cer-adv-icon" src="../../assets/img/notarization/embassy/icon_1_3.png" alt="">
          <p class="cer-adv-p1">专业翻译</p>
          <p class="cer-adv-p2">翻译内容制作，提供专业的涉外材料公证翻译工作</p>
        </div>
        <div class="cer-adv-inner">
          <img class="cer-adv-icon" src="../../assets/img/notarization/embassy/icon_1_4.png" alt="">
          <p class="cer-adv-p1">免费盖章</p>
          <p class="cer-adv-p2">免费排版盖章，免费提供公证材料排版认证盖章</p>
        </div>
        <div class="cer-adv-inner">
          <img class="cer-adv-icon" src="../../assets/img/notarization/embassy/icon_1_5.png" alt="">
          <p class="cer-adv-p1">全天跟进</p>
          <p class="cer-adv-p2">24小时跟进，每份公证材料专员全天候跟进反馈</p>
        </div>
        <div class="cer-adv-inner">
          <img class="cer-adv-icon" src="../../assets/img/notarization/embassy/icon_1_6.png" alt="">
          <p class="cer-adv-p1">退费承诺</p>
          <p class="cer-adv-p2">文件无法使用，全额退还费用</p>
        </div>
        <div class="cer-adv-inner">
          <img class="cer-adv-icon" src="../../assets/img/notarization/embassy/icon_1_7.png" alt="">
          <p class="cer-adv-p1">严格保密</p>
          <p class="cer-adv-p2">严密保存材料，公证书办理完成即刻销毁所有材料文件</p>
        </div>
        <div class="cer-adv-inner">
          <img class="cer-adv-icon" src="../../assets/img/notarization/embassy/icon_1_.png" alt="">
          <p class="cer-adv-p1">免费邮寄</p>
          <p class="cer-adv-p2">免排队邮寄到家，办公证不用愁，代排队代办理送到家</p>
        </div>
      </div>
    </div>
    <div class="box" style="height: 390px;background: #fff;margin-top: 10px;">
      <p class="box-p1">行业案例</p>
      <p class="box-p2" style="margin-bottom: 10px">公证认证价格透明、材料翻译认证盖章，提供一站式服务邮寄到家办理</p>
      <div class="lunbo-box cer-lunbo-box" style="margin-top: 20px">
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="rgba(47, 114, 235, 1)" >
          <van-swipe-item>
            <div class="cer-case-box">
              <img class="cer-case-img" src="../../assets/img/notarization/embassy/pic_5_1.jpg" alt="">
              <img class="cer-case-img" src="../../assets/img/notarization/embassy/pic_5_2.jpg" alt="">
            </div>
          </van-swipe-item>
          <van-swipe-item>
            <div class="cer-case-box">
              <img class="cer-case-img" src="../../assets/img/notarization/embassy/pic_5_3.jpg" alt="">
              <img class="cer-case-img" src="../../assets/img/notarization/embassy/pic_5_4.jpg" alt="">
            </div>
          </van-swipe-item>
        </van-swipe>
      </div>
    </div>
    <div class="box" style="height: 643px;background: #fff;margin-top: 10px;">
      <p class="box-p1">咨询服务</p>
      <p class="box-p2">我们还能为您做</p>
      <div class="ask-box">
        <p class="ask-p1">海牙认证</p>
        <p class="ask-p2">海牙公约组织成员国，目前成员国有102个。如德国、法国、韩国、美国、日本、澳大利亚、中国香港 及中国澳门都是公约成员国</p>
        <p class="ask-p3" @click="toAsk">咨询办理</p>
      </div>
      <div class="ask-box">
        <p class="ask-p1">国际公证认证</p>
        <p class="ask-p2">特聘具有国际公证员资格国际公证人，为香港文件、事实及法律文书等用于海外提供公证认证服务</p>
        <p class="ask-p3" @click="toAsk">咨询办理</p>
      </div>
      <div class="ask-box">
        <p class="ask-p1">使馆认证</p>
        <p class="ask-p2">使馆认证是指一国外交机构及其授权机构在涉外文书上确认所在国公证机关或某些特殊机关的印章或该机关主管人员的签字属实</p>
        <p class="ask-p3" @click="toAsk">咨询办理</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'bvi-page',
  props: {
    msg: String
  },
  data(){
    return{
      form: {

      }
    }
  },
  mounted(){
    window.scrollTo(0,0);
  },
  methods: {
    toAsk(){
      window.open('https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn','_blank')
    },
    toSubmit(){
      this.$message({
          message: '提交成功！',
          type: 'success',
          offset: 100
        });
    }
  }
}
</script>

<style scoped lang="scss">
  .m-bvi-banner{
    width: 100%;
    height: 460px;
    background: url('../../assets/img/notarization/embassy/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
  .hague-banner2{
    width: 94%;
    margin: 0 auto;
    height: 132px;
    background: url('../../assets/img/notarization/embassy/pic_1.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
  .hague-banner3{
    width: 94%;
    margin: 0 auto;
    height: 140px;
    background: url('../../assets/img/notarization/embassy/pic_2.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
</style>
