<template>
  <div class="certification m-bvi">
    <div class="m-bvi-banner">
      <p class="f1-banner-p1">新加坡公司年审</p>
      <p class="f1-banner-p2">一站式全程代办<br />最快两周完成</p>
      <p class="banner-btn banner-btn1" @click="toAsk">立即咨询</p>
    </div>

    <div class="box" style="height: auto;padding-bottom: 20px;">
      <p class="box-p1">为什么要按时年审</p>
      <p class="box-p2">逾期年审影响</p>
      <div class="why-exma1">
        <div class="why-exma1-inner">
          <img class="why-exma-inner-icon" src="../../assets/img/examination/Singapore/icon_1_1.png" alt="">
          <div class="why-exma1-right">
            <p class="why-exma-inner-p">公司及董事被起诉及罚款风险</p>
            <p class="why-exma-inner-p1">若公司没能在公司法所要求的期限内召开股东大会以及完成年检的申报，公司本身及公司董事将会被法院起诉以及罚款。</p>
          </div> 
        </div>
        <div class="why-exma1-inner">
          <img class="why-exma-inner-icon" src="../../assets/img/examination/Singapore/icon_1_2.png" alt="">
          <div class="why-exma1-right">
            <p class="why-exma-inner-p">被法院定罪后影响董事资格</p>
            <p class="why-exma-inner-p1">若发出法庭传票被法院定罪后，每项指控最高可被罚款5000新币。5年内被裁定范有3项或以上罪行的董事将被取消董事资格，且5年内不允许其担任公司董事或参与公司的管理(新方董事会有影响，因此不年审的会主动辞退)。</p>
          </div> 
        </div>
        <div class="why-exma1-inner">
          <img class="why-exma-inner-icon" src="../../assets/img/examination/Singapore/icon_1_3.png" alt="">
          <div class="why-exma1-right">
            <p class="why-exma-inner-p" style="margin-top: 20px;">个人商业信誉受损</p>
          </div> 
        </div>
      </div>
    </div>

    <div class="box" style="height: auto;padding-bottom: 20px;background: rgba(242, 245, 250, 1);">
      <p class="box-p1">新加坡公司年审办理时间与注意事项</p>
      <p class="box-p2">《新加坡公司法》175、197和201(1)条对公司年审期限有明确规定，公司每年需召开股东大会AGM、提交年报报告和财务报告。2018年，新加坡对年审规定的时限进行了全面更新。按财务年度为2018年8月31日或之后的新加坡公司，年审时限如下：</p>
      <div class="lunbo-box" style="height: 248px;">
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
          <van-swipe-item>
            <img class="swiper-img" style="height: 228px;" src="../../assets/img/examination/Singapore/pic_1_1.jpg" alt="">
          </van-swipe-item>
          <van-swipe-item>
            <img class="swiper-img" style="height: 228px;" src="../../assets/img/examination/Singapore/pic_1_2.jpg" alt="">
          </van-swipe-item>
        </van-swipe>
      </div>
      <p class="sin-exma-tips">如逾期不交年审，则会被注册局罚款，每次逾期罚款300新币，总罚款300至900新币(约4500人民币）</p>
    </div>


    <div class="box" style="height: auto;padding-bottom: 20px;">
      <p class="box-p1">新加坡公司年审事项</p>
      <div class="exma-things" style="padding-top: 20px;">
        <div class="exma-things-inner">
          <img class="exma-things-icon" src="../../assets/img/examination/Singapore/icon_2_1.png" alt="">
          <p class="exma-things-p1">年审周期</p>
          <p class="exma-things-p2">公司设立时间一年后的前一个月的月底最后一天</p>
        </div>
        <div class="exma-things-inner">
          <img class="exma-things-icon" src="../../assets/img/examination/Singapore/icon_2_2.png" alt="">
          <p class="exma-things-p1">所需资料</p>
          <p class="exma-things-p2">我司注册的不需要提供文件即可办理年审，其他公司注册的新加坡公司需先办理转秘书之后办理年审业务</p>
        </div>
        <div class="exma-things-inner">
          <img class="exma-things-icon" src="../../assets/img/examination/Singapore/icon_2_3.png" alt="">
          <p class="exma-things-p1">年审费用</p>
          <p class="exma-things-p2">普通公司零运营年审费用4280元，如果公司控股并且有业务的情况下，XBRL费用2500元</p>
        </div>
        <div class="exma-things-inner">
          <img class="exma-things-icon" src="../../assets/img/examination/Singapore/icon_2_4.png" alt="">
          <p class="exma-things-p1">办理时间</p>
          <p class="exma-things-p2">10个工作日</p>
        </div>
        <div class="exma-things-inner">
          <img class="exma-things-icon" src="../../assets/img/examination/Singapore/icon_2_5.png" alt="">
          <p class="exma-things-p1">年审完成后所得数据或文件</p>
          <p class="exma-things-p2">最新电子版注册纸</p>
        </div>
      </div>
    </div>

    <div class="box" style="height: auto;background: rgba(242, 245, 250, 1);">
      <p class="box-p1">快企务优质秘书服务</p>
      <p class="box-p2">快企务为您提供更多服务</p>
      <div class="exma-serve">
        <img class="exma-serve-img" src="../../assets/img/examination/HK/pic_3_1.jpg" alt="">
        <img class="exma-serve-img" src="../../assets/img/examination/HK/pic_3_2.jpg" alt="">
        <img class="exma-serve-img" src="../../assets/img/examination/HK/pic_3_3.jpg" alt="">
        <img class="exma-serve-img" src="../../assets/img/examination/HK/pic_3_4.jpg" alt="">
        <img class="exma-serve-img" src="../../assets/img/examination/HK/pic_3_5.jpg" alt="">
        <img class="exma-serve-img" src="../../assets/img/examination/HK/pic_3_6.jpg" alt="">
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'bvi-page',
  props: {
    msg: String
  },
  data(){
    return{
      form: {},
      activeName: '1'
    }
  },
  mounted(){
    window.scrollTo(0,0);
  },
  methods:{
    toAsk(){
      window.open('https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn','_blank')
    },
  }
}
</script>

<style scoped lang="scss">
  .m-bvi-banner{
    width: 100%;
    height: 460px;
    background: url('../../assets/img/examination/Singapore/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
</style>
