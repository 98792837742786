<template>
  <div class="certification m-bvi" style="background: rgba(242, 245, 250, 1);">
    <div class="m-bvi-banner" style="height: 460px;">
        <p class="no-banner-p2" style="padding-top: 132px">中国海牙认证办理中心</p>
        <p class="no-banner-p1" style="padding-top: 20px">专业顾问团队品质服务</p>
        <p class="banner-btn banner-btn1" @click="toAsk">立即咨询</p>
      </div>
      <div class="box" style="height: auto;padding-bottom: 20px;background: #fff;">
        <p class="box-p1">海牙认证类型</p>
        <p class="box-p2">选择您需要认证的类型 </p>
        <div class="tab-content-box">
          <div class="tab-box1">
            <p class="tab-inner1" style="width: 30%;" :class="{'active': tab == 1}" @click="tab = 1">公司文件类型</p>
            <p class="tab-inner1" style="width: 30%;" :class="{'active': tab == 2}" @click="tab = 2">个人文件类型</p>
            <p class="tab-inner1" style="width: 40%;" :class="{'active': tab == 3}" @click="tab = 3">第三方机构类文件</p>
          </div>
          <!-- <div class="tab-content-box1" v-if="tab == 1">
            <img class="tab-content-img" src="../../assets/img/notarization/notarization/pic_1_1.png" alt="">
           
          </div>
          <div class="tab-content-box1" v-if="tab == 2">
            <img class="tab-content-img" src="../../assets/img/notarization/notarization/pic_2_1.png" alt="">
            
          </div>
          <div class="tab-content-box1" v-if="tab == 3">
            <img class="tab-content-img" src="../../assets/img/notarization/notarization/pic_3_1.png" alt="">
           
          </div> -->
          <div class="hague-type-content" v-if="tab == 1">
                <p class="h-t-p">注册证书</p>
                <p class="h-t-p">更名证书</p>
                <p class="h-t-p">公司章程</p>
                <p class="h-t-p">会议记录</p>
                <p class="h-t-p">董事名册</p>
                <p class="h-t-p">股东名册</p>
                <p class="h-t-p">良好证明</p>
                <p class="h-t-p">协议书（合同）</p>
                <p class="h-t-p">授权书</p>
                <p class="h-t-p">声明书</p>
                <p class="h-t-p">资产证明</p>
                <p class="h-t-p">资信证明</p>
            </div>
            <div class="hague-type-content" v-if="tab == 2">
              <p class="h-t-p">结婚证</p>
              <p class="h-t-p">出生证</p>
              <p class="h-t-p">离婚书</p>
              <p class="h-t-p">亲属关系</p>
              <p class="h-t-p">死亡证明</p>
              <p class="h-t-p">单身证明</p>
              <p class="h-t-p">成绩单</p>
              <p class="h-t-p">学历证书</p>
              <p class="h-t-p">授权书</p>
              <p class="h-t-p">声明书</p>
              <p class="h-t-p">护照</p>
              <p class="h-t-p">身份证</p>
              <p class="h-t-p">判决书</p>
              <p class="h-t-p">无犯罪记录</p>
              <p class="h-t-p">房产证</p>
              <p class="h-t-p">职业资格证书</p>
              <p class="h-t-p">医学证明（出生证）</p>
            </div>
            <div class="hague-type-content" v-if="tab == 3">
              <p class="h-t-p">SO证书</p>
              <p class="h-t-p">CE证书</p>
              <p class="h-t-p">自由销售证书</p>
              <p class="h-t-p">完税证明</p>
              <p class="h-t-p">商标证书</p>
            </div>
            <p class="hague-type-more" @click="toAsk">更多 ></p>
        </div>
      </div>
      <div class="hague-banner-box">
        <div class="hague-banner">
          <p class="hague-banner-p1">快企务与国际公证人展开合作，最早进入海牙认证领域</p>
          <p class="hague-banner-p2">办理了<span style="color: #fff;font-weight: 700;">10000+</span>客户文件，承诺文件无法使用，100%全额退款</p>
        </div>  
      </div>
      
    <div class="box" style="height: 350px;background: #fff;margin-bottom: 20px;">
      <p class="box-p1">常见海牙认证的人群</p>
      <p class="box-p2">包含但不限于以下四种</p>
      <van-swipe class="my-swipe" style="width: 345px;margin: 0 auto;" :autoplay="3000" indicator-color="white" :loop="tr
        ">
          <van-swipe-item>
            <img class="cer-swiper-img" style="width: 345px;height: 210px;" src="../../assets/img/notarization/hague/pic_2_1.jpg" alt="">
          </van-swipe-item>
          <van-swipe-item>
            <img class="cer-swiper-img" style="width: 345px;height: 210px;" src="../../assets/img/notarization/hague/pic_2_2.jpg" alt="">
          </van-swipe-item>
          <van-swipe-item>
            <img class="cer-swiper-img" style="width: 345px;height: 210px;" src="../../assets/img/notarization/hague/pic_2_3.jpg" alt="">
          </van-swipe-item>
          <van-swipe-item>
            <img class="cer-swiper-img" style="width: 345px;height: 210px;" src="../../assets/img/notarization/hague/pic_2_4.jpg" alt="">
          </van-swipe-item>
        </van-swipe>
    </div>
    <div class="box" style="height: 637px;background: #fff;">
      <p class="box-p1">海牙认证流程</p>
      <p class="box-p2">三步拿下海牙认证</p>
      <div class="hague-banner-box">
        <div class="hague-banner hague-banner1">
          <p class="hague-banner-p3" style="padding-top: 25px;">认证用途：告知文件目的</p>
          <p class="hague-banner-p3">认证用途：告知文件目的</p>
          <p class="hague-banner-p3">认证要求：用证方对文件的要求</p>
        </div>  
        <div class="bg-text-box" style="margin-top: 20px">
          <p class="bg-text">准备与提交资料</p>
        </div>
        <div class="bg-text-box bg1">
          <p class="bg-text">审核文件</p>
        </div>
        <div class="bg-text-box bg2">
          <p class="bg-text">公证人起草文件客户确认</p>
        </div>
      </div>
    </div>
    <div class="box" style="height: 557px;background: #fff">
      <p class="box-p1">快企务—快速省心</p>
      <p class="box-p2">专业的机构会让你比别人快，比别人省心</p>
      <img class="cer-img4" src="../../assets/img/notarization/hague/pic_5_1.jpg" alt="">
      <img class="cer-img4" src="../../assets/img/notarization/hague/pic_5_2.jpg" alt="">
    </div>
    <div class="box" style="height: 375px;background: #fff;margin-top: 10px;">
        <p class="box-p1">认证案例</p>
        <p class="box-p2" style="margin-bottom: 10px;">多达 <span class="blue-style">10000+</span>证书，多家知名企业指定服务商 </p>
        <div class="lunbo-box cer-lunbo-box" >
          <van-swipe class="my-swipe" :autoplay="3000" indicator-color="rgba(47, 114, 235, 1)" >
            <van-swipe-item>
              <div class="cer-case-box">
                <img class="cer-case-img" src="../../assets/img/notarization/hague/pic_6_1.jpg" alt="">
                <img class="cer-case-img" src="../../assets/img/notarization/hague/pic_6_2.jpg" alt="">
              </div>
            </van-swipe-item>
            <van-swipe-item>
              <div class="cer-case-box">
                <img class="cer-case-img" src="../../assets/img/notarization/hague/pic_6_3.jpg" alt="">
                <img class="cer-case-img" src="../../assets/img/notarization/hague/pic_6_4.jpg" alt="">
              </div>
            </van-swipe-item>
          </van-swipe>
        </div>
      </div>
    <div class="box" style="height: 520px;background: #fff;margin-top: 10px;">
      <p class="box-p1">我司办理的优势</p>
      <p class="box-p2">十余年行业代办经验</p>
      <div class="cer-adv-box">
        <div class="cer-adv-inner">
          <img class="cer-adv-icon" src="../../assets/img/certification/Singapore/icon_1_1.png" alt="">
          <p class="cer-adv-p1">严格保密</p>
          <p class="cer-adv-p2">严密保存材料，公证书办理完成即刻销毁所有材料文件</p>
        </div>
        <div class="cer-adv-inner">
          <img class="cer-adv-icon" src="../../assets/img/certification/Singapore/icon_1_2.png" alt="">
          <p class="cer-adv-p1">透明公开</p>
          <p class="cer-adv-p2">价格公开透明，公证认证办理费用公开透明可查</p>
        </div>
        <div class="cer-adv-inner">
          <img class="cer-adv-icon" src="../../assets/img/certification/Singapore/icon_1_3.png" alt="">
          <p class="cer-adv-p1">全天跟进</p>
          <p class="cer-adv-p2">24小时跟进，每份公证材料专员全天候跟进反馈</p>
        </div>
        <div class="cer-adv-inner">
          <img class="cer-adv-icon" src="../../assets/img/certification/Singapore/icon_1_4.png" alt="">
          <p class="cer-adv-p1">退费承诺</p>
          <p class="cer-adv-p2">公证拒办退款，客户公证材料被拒全额退还费用</p>
        </div>
      </div>
    </div>

    <div class="box" style="height: 643px;background: #fff">
      <p class="box-p1">咨询服务</p>
      <p class="box-p2">我们还能为您做</p>
      <div class="ask-box">
        <p class="ask-p1">中国委托公证人公证</p>
        <p class="ask-p2">特聘具有中国委托公证人资格香港公证人，为香港文件、事实及法律文书等用于内地提供公证认证服务</p>
        <p class="ask-p3" @click="toAsk">咨询办理</p>
      </div>
      <div class="ask-box">
        <p class="ask-p1">国际公证认证</p>
        <p class="ask-p2">特聘具有国际公证员资格国际公证人，为香港文件、事实及法律文书等用于海外提供公证认证服务</p>
        <p class="ask-p3" @click="toAsk">咨询办理</p>
      </div>
      <div class="ask-box">
        <p class="ask-p1">使馆认证</p>
        <p class="ask-p2">使馆认证是指一国外交机构及其授权机构在涉外文书上确认所在国公证机关或某些特殊机关的印章或该机关主管人员的签字属实</p>
        <p class="ask-p3" @click="toAsk">咨询办理</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'bvi-page',
  props: {
    msg: String
  },
  data(){
    return{
      tab: 1
    }
  },
  mounted(){
    window.scrollTo(0,0);
  },
  methods: {
    toAsk(){
      window.open('https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn','_blank')
    }
  }
}
</script>

<style scoped lang="scss">
  .m-bvi-banner{
    width: 100%;
    height: 460px;
    background: url('../../assets/img/notarization/hague/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
  .hague-banner{
    width: 94%;
    margin: 0 auto;
    height: 132px;
    background: url('../../assets/img/notarization/hague/pic_1.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
  .hague-banner1{
    background: url('../../assets/img/notarization/hague/pic_3.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
  .bg-text-box{
    width: 94%;
    margin: 0 auto;
    height: 110px;
    margin-bottom: 10px;
    background: url('../../assets/img/notarization/hague/pic_4_1.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
  .bg1{
    background: url('../../assets/img/notarization/hague/pic_4_2.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
  .bg2{
    background: url('../../assets/img/notarization/hague/pic_4_3.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
</style>
