<template>
  <div class="home">
    <div class="m-bvi-banner">
      <p class="banner-p1">一站式日本公司注册服务</p>
      <p class="banner-p2">【快企务专注于涉内外企业服务十余年】</p>
      <p class="banner-btn banner-btn1" @click="toAsk">立即咨询</p>
    </div>

    <div class="box" style="height: 313px;">
      <p class="box-p1">注册日本公司优势</p>
      <div class="cy-adv">
        <div class="cy-adv-inner">
          <img class="cy-adv-icon" src="../../assets/img/register/Japan/pic_1_1.png" alt="">
          <p class="cy-adv-p">日本国际品牌</p>
        </div>
        <div class="cy-adv-inner">
          <img class="cy-adv-icon" src="../../assets/img/register/Japan/pic_1_2.png" alt="">
          <p class="cy-adv-p">日本出口退税</p>
        </div>
        <div class="cy-adv-inner">
          <img class="cy-adv-icon" src="../../assets/img/register/Japan/pic_1_3.png" alt="">
          <p class="cy-adv-p">公司取名自由</p>
        </div>
        <div class="cy-adv-inner">
          <img class="cy-adv-icon" src="../../assets/img/register/Japan/pic_1_4.png" alt="">
          <p class="cy-adv-p">日本永久签证，投资经营签证</p>
        </div>
        <div class="cy-adv-inner">
          <img class="cy-adv-icon" src="../../assets/img/register/Japan/pic_1_5.png" alt="">
          <p class="cy-adv-p">经营范围限制小</p>
        </div>
        <div class="cy-adv-inner">
          <p class="cy-adv-p1" @click="toAsk">立即咨询 ></p>
        </div>
      </div>
    </div>

    <div class="box" style="height: auto;padding-bottom: 30px;">
      <p class="box-p1">注册日本公司类型</p>
      <div class="type-content flex center-width">
        <img class="type-img" style="height: 110px;" src="../../assets/img/register/Japan/pic_2_1.jpg" alt="">
        <img class="type-img" style="height: 110px;" src="../../assets/img/register/Japan/pic_2_2.jpg" alt="">
        </div>
    </div>
    <div class="box bg-box" style="height: auto;padding-bottom: 15px;">
      <p class="box-p1">注册日本公司条件/流程/资料</p>
      <div class="tab-box">
        <div class="tab-inner" :class="{'active': tab == 1}" @click="tab = 1">注册条件</div>
        <div class="tab-inner" :class="{'active': tab == 2}" @click="tab = 2">注册流程</div>
        <div class="tab-inner" :class="{'active': tab == 3}" @click="tab = 3">所得资料</div>
      </div>
      <div class="file-content">
        <div v-if="tab == 1">
          <div class="french-flow">
            <div class="french-flow-inner">
              <img class="french-flow-icon" src="../../assets/img/register/Japan/pic_3_1.png" alt="">
              <p class="french-flow-p1">公司名称</p>
              <p class="french-flow-p2">名称选择自由，中文、日文、英文皆可</p>
            </div>
            <div class="french-flow-inner">
              <img class="french-flow-icon" src="../../assets/img/register/Japan/pic_3_2.png" alt="">
              <p class="french-flow-p1">日本代表</p>
              <p class="french-flow-p2">确定董事名单，法人可为本人</p>
            </div>
            <div class="french-flow-inner">
              <img class="french-flow-icon" src="../../assets/img/register/Japan/pic_3_3.png" alt="">
              <p class="french-flow-p1">注册资本</p>
              <p class="french-flow-p2">可以根据法人需求，没有限制，1日元也可以</p>
            </div>
            <div class="french-flow-inner">
              <img class="french-flow-icon" src="../../assets/img/register/Japan/pic_3_4.png" alt="">
              <p class="french-flow-p1">注册地址</p>
              <p class="french-flow-p2">可提供价格优惠的注册地址</p>
            </div>
            <div class="french-flow-inner">
              <img class="french-flow-icon" src="../../assets/img/register/Japan/pic_3_5.png" alt="">
              <p class="french-flow-p1">法人资料</p>
              <p class="french-flow-p2">身份证复印件、护照复印件、通信地址、联络电话、个人印章、印章公证书</p>
            </div>
            <div class="french-flow-inner">
              <img class="french-flow-icon" src="../../assets/img/register/Japan/pic_3_6.png" alt="">
              <p class="french-flow-p1">经营范围</p>
              <p class="french-flow-p2">在合法范围内，绝大部分行业都可以</p>
            </div>
          </div>
        </div>
        <div v-if="tab == 2">
          <img class="tab2-img" src="../../assets/img/register/Japan/pic_4.jpg" alt="">
        </div>
        <div v-if="tab == 3">
          <div class="ger-tab3">
            <div class="ger-tab3-inner fre-tab3">
              <img class="ger-tab3-icon" src="../../assets/img/register/Japan/pic_5_.png" alt="">
              <p class="ger-tab3-p">公司营业藤本一份（营业执照）</p>
            </div>
            <div class="ger-tab3-inner fre-tab3">
              <img class="ger-tab3-icon" src="../../assets/img/register/Japan/pic_5_1.png" alt="">
              <p class="ger-tab3-p">公司印章卡一枚</p>
            </div>
            <div class="ger-tab3-inner fre-tab3">
              <img class="ger-tab3-icon" src="../../assets/img/register/Japan/pic_5_2.png" alt="">
              <p class="ger-tab3-p">公司印章一套（公章、银行用章等）</p>
            </div>
            <div class="ger-tab3-inner fre-tab3">
              <img class="ger-tab3-icon" src="../../assets/img/register/Japan/pic_5_3.png" alt="">
              <p class="ger-tab3-p"> 公司印章证明书一份</p>
            </div>
            <div class="ger-tab3-inner fre-tab3">
              <img class="ger-tab3-icon" src="../../assets/img/register/Japan/pic_5_4.png" alt="">
              <p class="ger-tab3-p">株式会社的定款（章程）</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    
    <div class="box" style="height: 725px;background: rgba(242, 245, 250, 1);">
      <p class="box-p1">注册日本公司后为您提供的后续服务</p>
      <div class="re-serve">
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Japan/pic_6_1.png" alt="">
          <p class="re-serve-p1">年审/续牌</p>
          <p class="re-serve-p2">所有公司须按年度定时递交包含股东、董事、公司秘书和其他任何当年可能影响公司行政管理结构详情信息的公司年度报表</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Japan/pic_6_2.png" alt="">
          <p class="re-serve-p1">秘书服务</p>
          <p class="re-serve-p2">办公地址，公司周年报表年检，公司董事股东成员名册更新，在职证明与良好证明，代缴政府规费，修改公司章程等 </p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Japan/pic_6_3.png" alt="">
          <p class="re-serve-p1">做账报税</p>
          <p class="re-serve-p2">公司注册之后需按照当地政府单位要求进行做账报税。</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Japan/pic_6_4.png" alt="">
          <p class="re-serve-p1">银行开户</p>
          <p class="re-serve-p2">开设公司注册所在地的银行账户，有利于公司随时接受贸易汇款，享受当地政策或规避外汇风险</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Japan/pic_6_5.png" alt="">
          <p class="re-serve-p1">税号申请</p>
          <p class="re-serve-p2">公司经营过程中的公司信息（如地址、股东、经营范围等）变更</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Japan/pic_6_6.png" alt="">
          <p class="re-serve-p1">公司注销</p>
          <p class="re-serve-p2">因破产、股东决议解散、存续期届满等原因需要注销的，应及时主动注销公司</p>
        </div>
      </div>
    </div>

    <div class="box" style="height: auto;padding-bottom: 30px;">
      <p class="box-p1">日本公司注册常见问题</p>
      <p class="box-p1">解决您的一切疑问</p>
      <div class="question">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item title="日本公司股东和董事人数有什么限制要求吗？" name="1">
            <p class="question-p">公司董事设置1人即可，表示1个人就可以在日本注册公司，如果董事是中国籍自然人，则需要设置一个日籍董事。</p>
          </el-collapse-item>
          <el-collapse-item title="日本公司注册资本金需要多少？" name="2">
            <p class="question-p">注册资本金不受限制，1万日元也可以。</p>
          </el-collapse-item>
          <el-collapse-item title="注册日本公司有什么优势？" name="3">
            <p class="question-p">全球最富裕、经济最发达和生活水平最高的国家之一，注册资本不限，注册地址要求不高，经营范围无太大
限制，品牌知名度高。</p>
          </el-collapse-item>
          <el-collapse-item title="日本公司可以转年审吗？" name="4">
            <p class="question-p">可以转年审，需要把日本证书和章程发过来，先查下公司的情况。</p>
          </el-collapse-item>
        </el-collapse>
      </div>
      <p class="to-ask" @click="toAsk"> 
        <img class="to-ask-icon" src="../../assets/img/register/bvi/icon_6_customer_service.png" alt=""> 
        更多问题？立即咨询人工客服</p>
    </div>
    <div class="box" style="height: 480px;">
      <p class="box-p1">快企务的优势</p>
      <p class="box-p2">十余年注册日本公司经验</p>
      <div class="re-adv">
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_1.png" alt="">
          <p class="re-adv-p1">保密完善</p>
          <p class="re-adv-p2">保密系统完善，客户隐私不外泄</p>
        </div>
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_2.png" alt="">
          <p class="re-adv-p1">全程服务</p>
          <p class="re-adv-p2">一站式专业服务从注册公司到年审、开户，审计报税等</p>
        </div>
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_3.png" alt="">
          <p class="re-adv-p1">专业高效</p>
          <p class="re-adv-p2">根据需求，为客户制定专业快捷的解决方案</p>
        </div>
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_4.png" alt="">
          <p class="re-adv-p1">合同保障</p>
          <p class="re-adv-p2">正规机构，合同保障，费用透明，无隐形消费</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  data(){
    return{
      tab: 1,
      activeName: '1'
    }
  },
  mounted(){
    window.scrollTo(0,0);
  },
  methods:{
    toAsk(){
      window.open('https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn','_blank')
    },
  }
}
</script>
<style scoped>
  .m-bvi-banner{
    width: 100%;
    height: 460px;
    background: url('../../assets/img/register/Japan/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
</style>
