<template>
  <div class="certification m-bvi">
    <div class="m-bvi-banner">
      <p class="finance-banner-p">日本商标注册</p>
    </div>
    <div class="box" style="height: 834px;background: rgba(242, 245, 250, 1);">
      <p class="box-p1">日本商标注册概述</p>
      <p class="box-p2">以自然人或者法人直接向日本特许厅提出申请。允许多类申请，商品分类实行尼斯协定。<br />
日本《商标法》规定， 下述商标因设计没有特点，而不予注册</p>
      <div class="danger-box">
        <div class="danger-inner">
          <p class="danger-p1" style="width: 320px;">用商品本身名称表示的商标</p>
        </div>
        <div class="danger-inner">
          <p class="danger-p1" style="width: 320px;">凡用普通方式表示商品的产地、销售地、质量、原料、用途、数量、形状、价格或生产、加工和使用方法以及时期的商标</p>
        </div>
        <div class="danger-inner">
          <p class="danger-p1" style="width: 320px;">凡用普通方式表示常见姓名或名称的商标，如"田中"、"池田"等</p>
        </div>
        <div class="danger-inner">
          <p class="danger-p1" style="width: 320px;">极其简单而常见的标记。如一个日文字母或一个英文字母</p>
        </div>
        <div class="danger-inner">
          <p class="danger-p1" style="width: 320px;">其它使顾客不能辨认经销商的商标</p>
        </div>
        <div class="danger-inner">
          <p class="danger-p1" style="width: 320px;">与"惯用商标"相同或类似的商标。比如，日本清酒的"正宗"，最初是某个公司使用的商标，但久而久之制造同类商品的其它公司也用它来表示自己的商品，或作为商标的一部分使用，一般顾客总是把这样的文字或图形同这种商品结合在一起，使人不能辨别这是哪家公司的商品，这种“惯用商标”也不能注册</p>
        </div>
        <div class="danger-inner">
          <p class="danger-p1" style="width: 320px;">与菊花徽章（日本皇室标志）、国旗、勋章、联合国或其它国际组织的标志相同或相似的商标及有害风俗的商标。</p>
        </div>
      </div>
    </div>
    <div class="box" style="height: 608px;">
      <p class="box-p1">日本商标所需文件和资料</p>
      <div class="trademark-file">
        <p class="trademark-file-p1">申请人的姓名、地址或注册国家</p>
        <p class="trademark-file-p1">如拟注册商标是彩色图案，请提供6份商标图案； 如拟注册商标是黑白图案，则只需提供一份商标图案</p>
        <p class="trademark-file-p1">采用拟注册商标的产品或服务之详细清单，及欲注册的类别</p>
        <p class="trademark-file-p1">优先权文件，如申请优先注册</p>
        <img class="trademark-file-img" src="../../assets/img/finance/JapanTrademark/pic_1.jpg" alt="">
      </div>
    </div>
    <div class="box" style="height: 600px;background: rgba(242, 245, 250, 1);">
      <p class="box-p1">日本商标注册程序、时间及费用</p>
      <div class="trademark-flow" >
        <div class="trademark-flow-p1" style="align-items: flex-start;">
          <img class="trademark-flow-icon" src="../../assets/img/finance/innerTrademark/pic_2_1.png" alt="">
          <p>
            <span class="trademark-flow-span1">第一步：</span>
            <span class="trademark-flow-span2">商标注册申请递交到日本特许厅</span> 
          </p>
        </div>
        <div class="trademark-flow-p1" style="align-items: flex-start;">
          <img class="trademark-flow-icon" src="../../assets/img/finance/innerTrademark/pic_2_2.png" alt="">
          <p>
            <span class="trademark-flow-span1">第二步：</span>
          <span class="trademark-flow-span2">形式审查阶段。审查申请文件是否齐备，必填项目是否填写完整。如不符合要求，通知申请人更正</span> 
          </p>
        </div>
        <div class="trademark-flow-p1" style="align-items: flex-start;">
          <img class="trademark-flow-icon" src="../../assets/img/finance/innerTrademark/pic_2_3.png" alt="">
          <p>
            <span class="trademark-flow-span1">第三步：</span>
          <span class="trademark-flow-span2">实质审查阶段。审查商标是否满足实质审查要求；通过实质审查，做出允许商标注册的决定； 未能满足要求者发出驳回理由通知书，申请人对此出具抗辩书或理由书</span> 
          </p>
        </div>
        <div class="trademark-flow-p1" style="align-items: flex-start;">
          <img class="trademark-flow-icon" src="../../assets/img/finance/innerTrademark/pic_2_4.png" alt="">
          <p>
            <span class="trademark-flow-span1">第四步：</span>
            <span class="trademark-flow-span2">交付注册费用后，商标注册权确立，商标权开始存在，予以公告，异议期两个月；如果抗辩书或理由书不能消除驳回理由，驳回裁定成立；
  对裁定不服可以起诉，如果对起诉结果不满，可向东京高级法院提起上诉。如果仍不服，可继续向高级法院提起上诉</span> 
          </p>
        </div>
        <div class="trademark-flow-p1" style="align-items: flex-start;">
          <img class="trademark-flow-icon" src="../../assets/img/finance/innerTrademark/pic_2_5.png" alt="">
          <p>
            <span class="trademark-flow-span1">第五步：</span>
            <span class="trademark-flow-span2">整个顺利的申请注册过程，约需10到 15个月</span> 
          </p>
        </div>
      </div>
      <p class="tips2"><span style="font-weight: bold;">商标检索：</span> 每个注册的有效期为10年，如需延长，可以继续注册，这时需交纳6万日元的注册费</p>
      <p class="tips2"><span style="font-weight: bold;">费用：</span> USD255</p>
    </div>
    
    <div class="box" style="height: auto;padding-bottom: 30px;background: rgba(242, 245, 250, 1)">
      <p class="box-p1">常见问题</p>
      <div class="question">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item title="日本商标申请条件？" name="1">
            <p class="question-p">可以以自然人或法人申请，自然人必须附上护照或身份证复印件，法人必须附上营业执照复印件。</p>
          </el-collapse-item>
          <el-collapse-item title="日本商标注册申请所需材料？" name="2">
            <p class="question-p">提交申请人的姓名、地址、国籍；
提供商标的中文或外文名称；
提供20份清晰商标图样（2*2/8*8）；
如果商标为彩色，必须指定哪种颜色；如果提交的商标为黑白，以后使用什么颜色都可以；
所申请的商品或服务类别，假如知道的话；
填写申请表1份，申请表必须由申请人签署或由代理人签署。</p>
          </el-collapse-item>
          <el-collapse-item title="日本商标查询程序？" name="3">
            <p class="question-p">由于商标的申请是一个复杂而漫长的过程，为了避免在申请的过程中出现徒劳无功并浪费金钱的现象，查询是
一个必不可少的环节。查询通常可以得到两个结果，一是得知所申请的商标是否与他人的重复或类似；二是得
知我们的商标是否符合政府关于商标法律的标准。法律并没有规定一定要通过查询的环节，但申请注册之前，
最好先做好查册工作。
日本特许厅在受理申请时不提供免费查询，因此在注册之前建议进行独立的查询。</p>
          </el-collapse-item>
        </el-collapse>
      </div>
      <p class="to-ask" @click="toAsk"> 
        <img class="to-ask-icon" src="../../assets/img/register/bvi/icon_6_customer_service.png" alt=""> 
        更多问题？立即咨询人工客服</p>
    </div>
    <div class="box" style="height: 418px;background: #fff;">
      <p class="box-p1">为什么选择我们</p>
      <div class="why-us">
        <div class="why-us-inner">
          <img class="why-us-icon" src="../../assets/img/finance/innerTrademark/icon_3_1.png" alt="">
          <p class="why-us-p1">快速</p>
          <p class="why-us-p2">一对一顾问服务，快速响应客户需求</p>
        </div>
        <div class="why-us-inner">
          <img class="why-us-icon" src="../../assets/img/finance/innerTrademark/icon_3_2.png" alt="">
          <p class="why-us-p1">专业</p>
          <p class="why-us-p2">十余年专业代理国内外商标注册相关业务</p>
        </div>
        <div class="why-us-inner">
          <img class="why-us-icon" src="../../assets/img/finance/innerTrademark/icon_3_3.png" alt="">
          <p class="why-us-p1">严谨</p>
          <p class="why-us-p2">商标顾问、质检部经理、客户三重质检、多重把关</p>
        </div>
        <div class="why-us-inner">
          <img class="why-us-icon" src="../../assets/img/finance/innerTrademark/icon_3_4.png" alt="">
          <p class="why-us-p1">保障</p>
          <p class="why-us-p2">知识产权的商标注册成功率高达98.3%</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'bvi-page',
  props: {
    msg: String
  },
  data(){
    return{
      form: {},
      activeName: '1'
    }
  },
  mounted(){
    window.scrollTo(0,0);
  },
  methods:{
    toAsk(){
      window.open('https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn','_blank')
    },
  }
}
</script>

<style scoped lang="scss">
  .m-bvi-banner{
    width: 100%;
    height: 230px;
    background: url('../../assets/img/finance/JapanTrademark/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
</style>
