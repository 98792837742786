<template>
  <div id="app">
    <Header></Header>
    <router-view/>
    <Footer></Footer>
  </div>
</template>

<style lang="scss">
@import './assets/css/style.scss';
@import './assets/css/style1.scss';
@import './assets/css/style2.scss';
// #app {
//   font-family: '阿里巴巴惠普体标准';
// }
</style>
<script>
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'
export default {
  name: "App",
  components: {
    Header,
    Footer
  },
  methods: {
  }
}
</script>


