<template>
  <div class="certification m-bvi">
    <div class="m-bvi-banner">
      <p class="banner-p2" style="padding-top: 91px">无需亲赴 全球银行轻松成功开户</p>
      <p class="banner-p1" style="padding-top: 20px">全球银行任选<br />不成功全额退款</p>
      <p class="banner-p2" style="margin-top: 30px">费用透明/专人协助</p>
      <p class="banner-btn banner-btn1">立即咨询</p>
    </div>
    <div class="box" style="height: 418px;background: #fff;">
      <p class="box-p1">银行开户，您是否遇到以下情况</p>
      <img class="bank-img1" src="../../assets/img/finance/bank/pic_1.jpg" alt="">
    </div>
    <div class="box" style="height: 337px;background: rgba(242, 245, 250, 1);">
      <p class="box-p1">为什么会发生以上情况?</p>
      <p class="box-p2">因为银行也被罚了！细数近年银行被罚的案例</p>
      <div class="bank-swiper-box">
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white" :width="345" :height="190" :loop="tr
        ">
          <van-swipe-item>
            <div class="bank-swiper-inner1 inner1">
              <p class="bank-swiper1-p1">摩根大通罚款26亿美元</p>
              <p class="bank-swiper1-p2">2014年1月，摩根大通同意支付总计26亿美元的赔款，以和解对其涉嫌在麦道夫“旁氏骗局”案中不作为的刑事和民事诉讼</p>
            </div>
          </van-swipe-item>
          <van-swipe-item>
            <div class="bank-swiper-inner1 inner2">
              <p class="bank-swiper1-p1">瑞士信贷罚款25亿美元</p>
              <p class="bank-swiper1-p2">2014年5月瑞士信贷银行承认美国司法部对其帮助美国客户逃税的罪行指挥，并支付超过25亿美元的罚款，以此作为和解协议的一部分</p>
            </div>
          </van-swipe-item>
          <van-swipe-item>
            <div class="bank-swiper-inner1 inner3">
              <p class="bank-swiper1-p1">汇丰罚款19.2亿美元</p>
              <p class="bank-swiper1-p2">2012年12月，汇丰疑似洗钱，向美国联邦及地方主管机关缴交19.2亿美元和解金</p>
            </div>
          </van-swipe-item>
          <van-swipe-item>
            <div class="bank-swiper-inner1 inner4">
              <p class="bank-swiper1-p1">德意志罚款6.3亿美元</p>
              <p class="bank-swiper1-p2">2017年2月，因对涉嫌洗钱的交易监管不力导致大约100亿美元流出俄罗斯，德意志银行受到美国和英国金融监管机构罚款，金额共计约6.3亿美元</p>
            </div>
          </van-swipe-item>
          <van-swipe-item>
            <div class="bank-swiper-inner1 inner5">
              <p class="bank-swiper1-p1">渣打罚款6.27亿美元</p>
              <p class="bank-swiper1-p2">2012年底，渣打为伊朗与苏丹的客户，处理数千笔金融交易，缴交3.27亿美元和解金2014年中，渣打涉嫌反洗钱监控机制，罚款3亿美元</p>
            </div>
          </van-swipe-item>
          <van-swipe-item>
            <div class="bank-swiper-inner1 inner6">
              <p class="bank-swiper1-p1">花旗罚款1.4亿美元</p>
              <p class="bank-swiper1-p2">2015年7月，花旗集团子公司Banamax USA因在反洗钱作为上有缺失，被监管机构要求缴交1.4亿美元罚款</p>
            </div>
          </van-swipe-item>
        </van-swipe>
      </div>
    </div>
    <div class="box" style="height: 550px;background: #fff;">
      <p class="box-p1">快企务如何帮您解决开户问题</p>
      <p class="box-p2">银行开户六步曲</p>
      <img class="bank-img1" style="height: 405px;" src="../../assets/img/finance/bank/pic_3.jpg" alt="">
    </div>
    <div class="box" style="height: 410px;">
      <div class="bank-swiper-box2">
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white" :width="300" :height="384" :loop="true">
          <van-swipe-item>
            <div class="bank-swiper-inner2">
              <p class="bank-swiper2-p1">公司户</p>
              <p class="bank-swiper2-p2">提前审核材料，介绍适合的银行和方案VIP通道快速预约开户，解决排期久的问题提前审核资料是否齐全，确保面签一次通过开户前后有保障，不成功全额退款</p>
              <p class="form-submit" style="width: 250px;">预约开户</p>
            </div>
          </van-swipe-item>
          <van-swipe-item>
            <div class="bank-swiper-inner2 bank-swiper-inner3">
              <p class="bank-swiper2-p1">个人户</p>
              <p class="bank-swiper2-p2">无需购买理财，无需购买保险

仅需身份证及港澳通行证，无需地址证明

最快当天可安排开户，当天拿账户

开户前后有保障，不成功全额退款</p>
              <p class="form-submit" style="width: 250px;">预约开户</p>
            </div>
          </van-swipe-item>
        </van-swipe>
      </div>
    </div>
    <div class="box" style="height: 441px;background: rgba(242, 245, 250, 1);">
      <p class="box-p1">快企务开户优势</p>
      <p class="box-p2">四大优势助您开户无忧</p>
      <div class="bank-adv">
        <div class="bank-adv-inner">
          <img class="bank-adv-icon" src="../../assets/img/finance/bank/icon_1_1.png" alt="">
          <div class="bank-adv-right">
            <p class="bank-adv-p1">专家预审</p>
            <p class="bank-adv-p2">专人全程指导，银行面签前口述辅导，巧妙避开银行开户的痛点和难点</p>
          </div>
        </div>
        <div class="bank-adv-inner">
          <img class="bank-adv-icon" src="../../assets/img/finance/bank/icon_1_2.png" alt="">
          <div class="bank-adv-right">
            <p class="bank-adv-p1">快速开户</p>
            <p class="bank-adv-p2">与各大银行亲密合作，通过VIP通道快速预约开户，破解开户排期难题</p>
          </div>
        </div>
        <div class="bank-adv-inner">
          <img class="bank-adv-icon" src="../../assets/img/finance/bank/icon_1_3.png" alt="">
          <div class="bank-adv-right">
            <p class="bank-adv-p1">品牌保障</p>
            <p class="bank-adv-p2">拥有12年行业经验，在业界口碑好，品质和品牌均有保证</p>
          </div>
        </div>
        <div class="bank-adv-inner">
          <img class="bank-adv-icon" src="../../assets/img/finance/bank/icon_1_4.png" alt="">
          <div class="bank-adv-right">
            <p class="bank-adv-p1">不成功退款</p>
            <p class="bank-adv-p2">诚信保障，各大银行开户确保成功，不成功则退款</p>
          </div>
        </div>
      </div>
    </div>
    <div class="box" style="height: 255px;background: #fff;">
      <p class="box-p1">快企务合作银行内部通道</p>
      <p class="box-p2">VIP快速通道让您开户快人一步</p>
      <img class="bank-img1" style="width: 94%;height: 120px;" src="../../assets/img/finance/bank/pic_5.jpg" alt="">
    </div>
    <div class="box" style="height: 732px;background: #fff;">
      <p class="box-p1">境外账户维护指导</p>
      <p class="box-p2">我们的服务不止于开户</p>
      <div class="bank-info">
        <p class="bank-info-p1">保持账户活跃</p>
        <p class="bank-info-p2">及时激活，并进行存款；每月登陆1-2次网银，近三个月内进行3-5次交易，保持账户资金满足各银行日均余额要求，避免不使用账户或长时间不发生业务往来</p>
        <p class="bank-info-p1">及时回复银行信息</p>
        <p class="bank-info-p2">确保预留给银行的通讯信息真实有效，若收到银行发生的文件信息务必及时回复</p>
        <p class="bank-info-p1">确保交易安全性</p>
        <p class="bank-info-p2">避免与敏感、高危地区进行交易，尽量采取对公汇款，减少个人交易，避免不合理的大笔资金进出账户</p>
        <p class="bank-info-p1">合规运营依法维护</p>
        <p class="bank-info-p2">境外公司合规运营，正常做账报税，妥善保管相关业务资料、账册，以备银行不定期抽查</p>
      </div>
      <img class="bank-img1" style="width: 308px;height: 260px;" src="../../assets/img/finance/bank/pic_6.jpg" alt="">
    </div>
    <div class="box" style="height: 360px;background: rgba(242, 245, 250, 1);">
      <p class="box-p1">开户成功案例</p>
      <div class="lunbo-box cer-lunbo-box" style="margin-top: 20px">
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="rgba(47, 114, 235, 1)" >
          <van-swipe-item>
            <div class="cer-case-box">
              <img class="cer-case-img" src="../../assets/img/finance/bank/pic_7_1.jpg" alt="">
              <img class="cer-case-img" src="../../assets/img/finance/bank/pic_7_2.jpg" alt="">
            </div>
          </van-swipe-item>
          <van-swipe-item>
            <div class="cer-case-box">
              <img class="cer-case-img" src="../../assets/img/finance/bank/pic_7_3.jpg" alt="">
              <img class="cer-case-img" src="../../assets/img/finance/bank/pic_7_4.jpg" alt="">
            </div>
          </van-swipe-item>
        </van-swipe>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'bvi-page',
  props: {
    msg: String
  },
  data(){
    return{
      form: {},
      activeName: '1'
    }
  },
  mounted(){
    
  }
}
</script>

<style scoped lang="scss">
  .m-bvi-banner{
    width: 100%;
    height: 460px;
    background: url('../../assets/img/finance/bank/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
  .inner1{
    background: url('../../assets/img/finance/bank/pic_2_1.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
  .inner2{
    background: url('../../assets/img/finance/bank/pic_2_2.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
  .inner3{
    background: url('../../assets/img/finance/bank/pic_2_3.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
  .inner4{
    background: url('../../assets/img/finance/bank/pic_2_4.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
  .inner5{
    background: url('../../assets/img/finance/bank/pic_2_5.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
  .inner6{
    background: url('../../assets/img/finance/bank/pic_2_6.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
  .bank-swiper-inner2{
    background: url('../../assets/img/finance/bank/pic_4_1.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
  .bank-swiper-inner3{
    background: url('../../assets/img/finance/bank/pic_4_2.jpg') no-repeat center center;
    background-size: 100% 100%;
  }





</style>
