<template>
  <div class="home">
    <div class="m-bvi-banner">
      <p class="banner-p1">一站式韩国公司注册服务</p>
      <p class="banner-p2">【快企务专注于涉内外企业服务十余年】</p>
      <p class="banner-btn banner-btn1" @click="toAsk">立即咨询</p>
    </div>

    <div class="box" style="height: 428px;background: rgba(242, 245, 250, 1);">
      <p class="box-p1">注册韩国公司优势</p>
      <div class="ko-adv">
        <div class="ko-adv-inner">
          <p class="ko-adv-p1">01</p>
          <p class="ko-adv-p2">国际IT强国，世界最高的宽带网入网率，高达68%的世界最高水平的移动电话普及率。</p>
        </div>
        <div class="ko-adv-inner">
          <p class="ko-adv-p1">02</p>
          <p class="ko-adv-p2">韩国政府利好的外商投资政策：外商出资形式除了现金、机械设备，还可包括持有的知识产权、以及不动产、股份等；外商符合一定条件，可进行税收减免；对外商购买或租赁公有土地提供支持。</p>
        </div>
        <div class="ko-adv-inner">
          <p class="ko-adv-p1">03</p>
          <p class="ko-adv-p2">亚洲第三大经济中心，绝佳的地理位置，韩国首都首尔占据东京、北京、香港等东南亚主要城市的行程均在3个小时以内，方便企业管理。</p>
        </div>
        <div class="ko-adv-inner">
          <p class="ko-adv-p1">04</p>
          <p class="ko-adv-p2">亚太地区收益中心，外资企业的销售额的净利润远高于国内企业。</p>
        </div>
      </div>
    </div>

    <div class="box" style="height: auto;padding-bottom: 30px;">
      <p class="box-p1">注册韩国公司类型</p>
      <div class="type-content flex center-width">
        <img class="type-img" style="height: 110px;" src="../../assets/img/register/Korea/pic_1.jpg" alt="">
        <img class="type-img" style="height: 110px;" src="../../assets/img/register/Korea/pic_2.jpg" alt="">
      </div>
    </div>

    <div class="box bg-box" style="height: auto;padding-bottom: 15px;">
      <p class="box-p1">注册韩国公司条件/流程/资料</p>
      <div class="tab-box">
        <div class="tab-inner" :class="{'active': tab == 1}" @click="tab = 1">注册条件</div>
        <div class="tab-inner" :class="{'active': tab == 2}" @click="tab = 2">注册流程</div>
        <div class="tab-inner" :class="{'active': tab == 3}" @click="tab = 3">所得资料</div>
      </div>
      <div class="file-content">
        <div v-if="tab == 1">
          <div class="ko-tab1-box">
            <img class="big-img" src="../../assets/img/register/Korea/pic_3_1.jpg" alt="">
            <img class="small-img" src="../../assets/img/register/Korea/pic_3_2.jpg" alt="">
            <img class="small-img" src="../../assets/img/register/Korea/pic_3_3.jpg" alt="">
            <img class="big-img" style="height: 120px;" src="../../assets/img/register/Korea/pic_3_4.jpg" alt="">
          </div>
        </div>
        <div v-if="tab == 2">
          <img class="tab2-img" src="../../assets/img/register/Korea/pic_4.png" alt="">
        </div>
        <div v-if="tab == 3">
          <div class="ko-tab1-box">
            <img class="small-img" src="../../assets/img/register/Korea/pic_5_1.jpg" alt="">
            <img class="small-img" src="../../assets/img/register/Korea/pic_5_2.jpg" alt="">
            <img class="small-img" src="../../assets/img/register/Korea/pic_5_3.jpg" alt="">
            <img class="small-img" src="../../assets/img/register/Korea/pic_5_4.jpg" alt="">
            <img class="small-img" src="../../assets/img/register/Korea/pic_5_5.jpg" alt="">
            <img class="small-img" src="../../assets/img/register/Korea/pic_5_6.jpg" alt="">
            <img class="big-img" style="height: 120px;" src="../../assets/img/register/Korea/pic_5_7.jpg" alt="">
          </div>
        </div>
      </div>
    </div>
    
    <div class="box" style="height: 725px;">
      <p class="box-p1">注册韩国公司后为您提供的后续服务</p>
      <div class="re-serve">
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Korea/pic_6_1.png" alt="">
          <p class="re-serve-p1">年审/续牌</p>
          <p class="re-serve-p2">签署委托协议，递交政府审批。年审后可以拿到商业登记证和韩国公司年检证书</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Korea/pic_6_2.png" alt="">
          <p class="re-serve-p1">税号申请</p>
          <p class="re-serve-p2">注册即有税号，但如果需要做电商，则需要申请VAT税号</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Korea/pic_6_3.png" alt="">
          <p class="re-serve-p1">做账报税</p>
          <p class="re-serve-p2">办公地址，公司周年报表年检，公司董事股东成员名册更新，在职证明与良好证明，代缴政府规费，修改公司章程等</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Korea/pic_6_4.png" alt="">
          <p class="re-serve-p1">银行开户</p>
          <p class="re-serve-p2">一般可以在韩国本地开户或其他地区开设离岸帐户。开立韩国公司银行账户要求公司董事亲临银行办理。</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Korea/pic_6_5.png" alt="">
          <p class="re-serve-p1">秘书服务</p>
          <p class="re-serve-p2">办公地址，公司周年报表年检，公司董事股东成员名册更新，在职证明与良好证明，代缴政府规费，修改公司章程等</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Korea/pic_6_6.png" alt="">
          <p class="re-serve-p1">公司注销</p>
          <p class="re-serve-p2">韩国公司停止经营，需进行公司的解散申告，否则会对将来申请韩国签证，续签以及在韩国申请商业贷款等涉及个人信用的行为产生不利影响。</p>
        </div>
      </div>
    </div>

    <div class="box" style="height: auto;padding-bottom: 30px;background: rgba(242, 245, 250, 1);">
      <p class="box-p1">韩国公司注册常见问题</p>
      <p class="box-p1">解决您的一切疑问</p>
      <div class="question">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item title="能注册一人公司吗？股东可以是一人吗？" name="1">
            <p class="question-p">在韩国是可以注册一人公司的，股东也可以是一个人。但建议大家，在注册时可以再设定一名非股东成员，比如说理事或者监事。主要是设立法人程序中需调查报告人，只有非股东成员才能担任，因此设定这样一名成员能大大降低设立法人的成本。</p>
          </el-collapse-item>
          <el-collapse-item title="注册资金方面有金额要求吗？" name="2">
            <p class="question-p">目前，在韩国注册公司，注册资金方面是没有注册资金限制的，只要有100韩元即可设立法人。但需注意部分行
业依然存在注册资金的要求，所以在注册之前可先了解一下都有哪些行业有限制然后再注册。</p>
          </el-collapse-item>
          <el-collapse-item title="公司名称能用英文或符号吗？" name="3">
            <p class="question-p">原则上在韩国注册公司是只能使用韩文的，另外，公司名称中必须包含“株式会社”。它可以任意放在公司名
称的前后。</p>
          </el-collapse-item>
        </el-collapse>
      </div>
      <p class="to-ask" @click="toAsk"> 
        <img class="to-ask-icon" src="../../assets/img/register/bvi/icon_6_customer_service.png" alt=""> 
        更多问题？立即咨询人工客服</p>
    </div>
    <div class="box" style="height: 480px;">
      <p class="box-p1">快企务的优势</p>
      <p class="box-p2">十余年注册韩国公司经验</p>
      <div class="re-adv">
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_1.png" alt="">
          <p class="re-adv-p1">保密完善</p>
          <p class="re-adv-p2">保密系统完善，客户隐私不外泄</p>
        </div>
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_2.png" alt="">
          <p class="re-adv-p1">全程服务</p>
          <p class="re-adv-p2">一站式专业服务从注册公司到年审、开户，审计报税等</p>
        </div>
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_3.png" alt="">
          <p class="re-adv-p1">专业高效</p>
          <p class="re-adv-p2">根据需求，为客户制定专业快捷的解决方案</p>
        </div>
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_4.png" alt="">
          <p class="re-adv-p1">合同保障</p>
          <p class="re-adv-p2">正规机构，合同保障，费用透明，无隐形消费</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  data(){
    return{
      tab: 1,
      activeName: '1'
    }
  },
  mounted(){
    window.scrollTo(0,0);
  },
  methods:{
    toAsk(){
      window.open('https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn','_blank')
    },
  }
}
</script>
<style scoped>
  .m-bvi-banner{
    width: 100%;
    height: 460px;
    background: url('../../assets/img/register/Korea/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
</style>
