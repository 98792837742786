<template>
  <div class="certification m-bvi">
    <div class="m-bvi-banner">
      <p class="finance-banner-p">美国商标注册</p>
    </div>
    <div class="box" style="height: 635px;background: rgba(242, 245, 250, 1);">
      <p class="box-p1">美国商标注册简介</p>
      <div class="danger-box">
        <div class="danger-inner">
          <p class="danger-p1" style="width: 320px;">美国是一个实行判例法(即不成文法)的国家，因此法院判例在美国处理商标纠纷及商标法的发展中起到了较为重要的作用</p>
        </div>
        <div class="danger-inner">
          <p class="danger-p1" style="width: 320px;">美国采用使用在先的商标保护原则。在美国商标的所有权通常取决于商标在美国的使用。一般来讲，即使先使用者从未在美国注册其商标或后使用者先注册了相同或近似的商标，先实际使用某—商标者有优于在后使用商标者的权利。当然，从商标保护的角度来讲，也不可忽视使用者的商标在美国联邦专利商标局的注册</p>
        </div>
        <div class="danger-inner">
          <p class="danger-p1" style="width: 320px;">根据美国目前适用的商标注册规定，在美国可以注册商品商标、服务商标、集体商标和证明商标</p>
        </div>
        <div class="danger-inner">
          <p class="danger-p1" style="width: 320px;">美国采用商品和服务国际分类。商标注册分为主簿和副簿</p>
        </div>
        <div class="danger-inner">
          <p class="danger-p1" style="width: 320px;">美国参加的国际组织和国际公约有：世界知识产权组织、《保护工业产权巴黎公约》、《商标注册用商和服务国际分类尼斯协定》和《马德里协定议定书》</p>
        </div>
      </div>
    </div>
    <div class="box" style="height: 664px;">
      <p class="box-p1">美国商标注册申请所需资料</p>
      <div class="trademark-file">
        <p class="trademark-file-p1">以法人申请，附《营业执照》或有效登记证明复印件加盖公章1份；以自然人申请附个人身份证明文件1份</p>
        <p class="trademark-file-p1">申请人的详细信息（中英文），包括姓名或名称，性质、国籍以及详细地址、邮编，联系方式</p>
        <p class="trademark-file-p1">电子版商标标样</p>
        <p class="trademark-file-p1">商品名称和类别</p>
        <p class="trademark-file-p1">申请人提供在商业中真实使用商标的声明（实际使用的）</p>
        <p class="trademark-file-p1">签署的委托书</p>
        <img class="trademark-file-img" src="../../assets/img/finance/AmericaTrademark/pic_1.jpg" alt="">
      </div>
    </div>
    <div class="box" style="height: 541px;background: rgba(242, 245, 250, 1);">
      <p class="box-p1">美国商标的申请人资格</p>
      <div class="trademark-file">
        <p class="trademark-file-p1" style="width: 340px;">在美国商业中已使用商标的人可申请商标注册；</p>
        <p class="trademark-file-p1" style="width: 340px;">根据1988年商标修改法令，真诚地打算在美国商业中使用商标的人也可以申请商标注册；</p>
        <p class="trademark-file-p1" style="width: 340px;">巴黎公约成员国国民的商标如在其本国已注册，虽尚未在同美国的商务活动中使用，但已在他国使用或打算按1988年修改法令使用该商标的人可申请商标注册。外国申请人必须指定在美国的代理人代办商标事宜；</p>
        <img class="trademark-file-img" style="width: 280px;height: 214px;" src="../../assets/img/finance/AmericaTrademark/pic_2.jpg" alt="">
      </div>
    </div>
    <div class="box" style="height: 730px;">
      <p class="box-p1">商标注册流程</p>
      <div class="trademark-flow">
        <p class="trademark-flow-p1">
          <img class="trademark-flow-icon" src="../../assets/img/finance/innerTrademark/pic_2_1.png" alt="">
          <span class="trademark-flow-span1">第一步：形式审查</span>
        </p>
        <p class="trademark-flow-p2">申请递交后对提交的申请文件、商标图样、委托书等文件进行的合法性审查；符合规定的，将授予申请日和申请号</p>
        <p class="trademark-flow-p1">
          <img class="trademark-flow-icon" src="../../assets/img/finance/innerTrademark/pic_2_2.png" alt="">
          <span class="trademark-flow-span1">第二步：实质审查</span>
        </p>
        <p class="trademark-flow-p2">根据法律审查商标是否具有可注册性、是否与在先注册的商标相同或近似、是否违背商标法的禁用条款。对于不通过实质审查的商标，审查官将书面通知申请人，并告知驳回理由。申请人在接到该驳回通知之日起限期内可提交复审，否则，该申请将被视为放弃，申请日和申请号均不予保留</p>
        <p class="trademark-flow-p1">
          <img class="trademark-flow-icon" src="../../assets/img/finance/innerTrademark/pic_2_3.png" alt="">
          <span class="trademark-flow-span1">第三步：公告</span>
        </p>
        <p class="trademark-flow-p2">经审查，审查官认为商标申请可以被接受后，便会在美国官方商标公告上刊登公告。自公告日起1个月为异议期</p>
        <p class="trademark-flow-p1">
          <img class="trademark-flow-icon" src="../../assets/img/finance/innerTrademark/pic_2_4.png" alt="">
          <span class="trademark-flow-span1">第四步：提交使用声明</span>
        </p>
        <p class="trademark-flow-p2">经过上述程序后，商标专利局颁发“允许通知”（anoticeofallowance），非注册证；颁发“允许通知”之后的6个月内，申请人必须提供在商业中真实使用商标的声明，或申请预留商标</p>
        <p class="trademark-flow-p1">
          <img class="trademark-flow-icon" src="../../assets/img/finance/innerTrademark/pic_2_5.png" alt="">
          <span class="trademark-flow-span1">第五步：提交使用声明</span>
        </p>
        <p class="trademark-flow-p2">商标专利局接到真实使用声明后，再次进行审查。如果经审查决定接受真实使用的声明，颁发注册证书。整个顺利的申请过程（如果没有驳回、异议等情况出现）大概需要20-24个月</p>
      </div>
    </div>
    <div class="box" style="height: auto;padding-bottom: 30px;background: rgba(242, 245, 250, 1)">
      <p class="box-p1">常见问题</p>
      <div class="question">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item title="美国商标申请人资格？" name="1">
            <p class="question-p">在美国商业中已使用商标的人可申请商标注册。
根据1988年商标修改法令，真诚地打算在美国商业中使用商标的人也可以申请商标注册。
巴黎公约成员国国民的商标如在其本国已注册，虽尚没在同美国的商务活动中使用，但已在他国使用或打算按1988年修改法令使用该商标的人可申请商标注册。
外国申请人必须指定在美国的代理人代办商标事宜。</p>
          </el-collapse-item>
          <el-collapse-item title="美国商标审查流程？" name="2">
            <p class="question-p">意向使用：申请--受理--审查--公告--提交使用证据--注册--发证；
实际使用：申请（提交使用证据）--受理--审查--公告--注册--发证。</p>
          </el-collapse-item>
          <el-collapse-item title="美国商标申请流程？" name="3">
            <p class="question-p">1、递交申请文件。（受理通知时间约为1个月）
2、形式审查与实质审查。（审查时间约为4-6个月）
形式审查与实质审查同时进行，完成审查后美国专利商标局将下发官方审查意见（即驳回通知），该驳回通知
的复审时间为6个月，申请人须在6个月内进行复审。
3、公告。（公告时间为3个月）
4、核准注册。公告期内若无任何第三人提出异议，或者异议裁定不成立的，该商标申请即被核准。
5、提交使用证据。自注册之日起5-6年内，申请人必须提交相关使用证据以证明该商标仍在持续使用，否则该
商标归于无效。</p>
          </el-collapse-item>
          <el-collapse-item title="注册后的使用要求？" name="4">
            <p class="question-p">在美国,使用不是获得商标注册的惟一基础，但商标注册必须通过使用来维持。美国法律要求注册商标必须在商
业中使用，广告使用不被视为使用。商标注册后连续3年不使用，被视为初步放弃，商标注册可因未使用而被撤
销。
另外,根据美国商标法规定，商标注册人在自商标注册之日起第5年至第6年间必须向美国专利商标局提交使用宜
誓书，宜誓注册商标已在美国商业中使用于宣誓书中所述的商品或服务上，该商标注册才继续有效。</p>
          </el-collapse-item>
        </el-collapse>
      </div>
      <p class="to-ask" @click="toAsk"> 
        <img class="to-ask-icon" src="../../assets/img/register/bvi/icon_6_customer_service.png" alt=""> 
        更多问题？立即咨询人工客服</p>
    </div>
    <div class="box" style="height: 418px;background: #fff;">
      <p class="box-p1">为什么选择我们</p>
      <div class="why-us">
        <div class="why-us-inner">
          <img class="why-us-icon" src="../../assets/img/finance/innerTrademark/icon_3_1.png" alt="">
          <p class="why-us-p1">快速</p>
          <p class="why-us-p2">一对一顾问服务，快速响应客户需求</p>
        </div>
        <div class="why-us-inner">
          <img class="why-us-icon" src="../../assets/img/finance/innerTrademark/icon_3_2.png" alt="">
          <p class="why-us-p1">专业</p>
          <p class="why-us-p2">十余年专业代理国内外商标注册相关业务</p>
        </div>
        <div class="why-us-inner">
          <img class="why-us-icon" src="../../assets/img/finance/innerTrademark/icon_3_3.png" alt="">
          <p class="why-us-p1">严谨</p>
          <p class="why-us-p2">商标顾问、质检部经理、客户三重质检、多重把关</p>
        </div>
        <div class="why-us-inner">
          <img class="why-us-icon" src="../../assets/img/finance/innerTrademark/icon_3_4.png" alt="">
          <p class="why-us-p1">保障</p>
          <p class="why-us-p2">知识产权的商标注册成功率高达98.3%</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'bvi-page',
  props: {
    msg: String
  },
  data(){
    return{
      form: {},
      activeName: '1'
    }
  },
  mounted(){
    window.scrollTo(0,0);
  },
  methods:{
    toAsk(){
      window.open('https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn','_blank')
    },
  }
}
</script>

<style scoped lang="scss">
  .m-bvi-banner{
    width: 100%;
    height: 230px;
    background: url('../../assets/img/finance/AmericaTrademark/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
</style>
