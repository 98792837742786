<template>
  <div class="home">
    <div class="m-bvi-banner">
      <p class="banner-p1">首选美国公司注册</p>
      <p class="banner-p2" style="width: 300px;margin: 0 auto;margin-top: 20px;">海外创业/国际品牌/国际贸易/海外上市/跨境电商开业/移民签证</p>
      <p class="banner-btn banner-btn1" @click="toAsk">立即咨询</p>
    </div>

    <div class="box" style="height: 662px;">
      <p class="box-p1">注册美国公司优势</p>
      <img class="ama-adv-img" src="../../assets/img/register/America/pic_1.jpg" alt="">
      <div class="ama-adv-box">
        <div class="ama-adv-inner">
          <p class="ama-adv-p1">国际品牌</p>
          <p class="ama-adv-p2">美国企业在世界市场上实力雄厚，技术先进，注册美国公司面向世界，打造国际化品牌，扩大市场占有率</p>
        </div>
        <div class="ama-adv-inner">
          <p class="ama-adv-p1">无外汇管制</p>
          <p class="ama-adv-p2">美国无外汇管制，可接收外币，资金进出自由，贸易自由化程度高，有利于开展国际贸易</p>
        </div>
        <div class="ama-adv-inner">
          <p class="ama-adv-p1">跨境电商</p>
          <p class="ama-adv-p2">Ebay、亚马逊、沃尔玛作为美国本土企业，注册美国公司，可直接入驻电商平台，不出国门即可离岸经营获取订单</p>
        </div>
        <div class="ama-adv-inner">
          <p class="ama-adv-p1">移民签证</p>
          <p class="ama-adv-p2">注册美国公司提供赴美商务活动的机会，极大方便了赴美签证、组团商务考察，办理绿卡，享有公立学校教育等</p>
        </div>
        <div class="ama-adv-inner">
          <p class="ama-adv-p1">税务负担小</p>
          <p class="ama-adv-p2">美国部分州，税率低、税种少，不在美国本土经营，免收公司所得税，减轻税务负担</p>
        </div>
        <div class="ama-adv-inner">
          <p class="ama-adv-p1">注册简单成本低</p>
          <p class="ama-adv-p2">注册美国公司经营范围受限少，注册程序简单，无需法人股东亲临现场</p>
        </div>
      </div>
    </div>

    <div class="box" style="height: 691px;background: rgba(242, 245, 250, 1);">
      <p class="box-p1">注册美国公司哪些州比较受欢迎</p>
      <p class="box-p2">四大区域年注册量高达<span style="font-weight: bold;color: rgba(47, 114, 235, 1);">600+</span></p>
      <div class="ama-popular">
        <div class="ama-popular-inner">
          <p class="ama-popular-p1">加利福尼亚州</p>
          <p class="ama-popular-p2">世界著名的商业城市，经济前景活跃，知名公司总部林立</p>
          <p class="ama-popular-p3" @click="toAsk">立即咨询</p>
        </div>
        <div class="ama-popular-inner">
          <p class="ama-popular-p1">纽约州</p>
          <p class="ama-popular-p2">交通发达，美国重要的商品中心，国际金融中心</p>
          <p class="ama-popular-p3" @click="toAsk">立即咨询</p>
        </div>
        <div class="ama-popular-inner">
          <p class="ama-popular-p1">特拉华州</p>
          <p class="ama-popular-p2">司法制度健全，效率高，免税州，无销售税等</p>
          <p class="ama-popular-p3" @click="toAsk">立即咨询</p>
        </div>
        <div class="ama-popular-inner">
          <p class="ama-popular-p1">科罗拉多州</p>
          <p class="ama-popular-p2">注册效率高，每年维护费用低，适合中企注册亚马逊账户</p>
          <p class="ama-popular-p3" @click="toAsk">立即咨询</p>
        </div>
      </div>
      <img class="ama-adv-img" style="width: 289px;height: 192px;" src="../../assets/img/register/America/pic_2.jpg" alt="">
      <p class="more-register-btn" @click="toAsk">立即咨询</p>
    </div>

    <div class="box" style="height: 514px;">
      <p class="box-p1">怎样注册美国公司</p>
      <div class="how-box">
        <img class="how-box-img" src="../../assets/img/register/America/pic_3.jpg" alt="">
        <img class="how-box-img" src="../../assets/img/register/America/pic_4.jpg" alt="">
        <img class="how-box-img" style="width: 100%;height: 227px;" src="../../assets/img/register/America/pic_5.jpg" alt="">
      </div>
    </div>
    
    <div class="box">
      <p class="box-p1">一站式美国企业服务</p>
      <div class="re-serve">
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/America/pic_6_1.png" alt="">
          <p class="re-serve-p1">公司年审</p>
          <p class="re-serve-p2">美国公司注册后，每年按时年审，逾期产生罚款，影响公司正常运营</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/America/pic_6_2.png" alt="">
          <p class="re-serve-p1">申请联邦税号</p>
          <p class="re-serve-p2">联邦税号是美国公司申报税务的唯一凭证，同时也是美国银行开户的必备文件</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/America/pic_6_3.png" alt="">
          <p class="re-serve-p1">银行开户</p>
          <p class="re-serve-p2">开设公司离岸账户，随时随地接收国际贸易汇款，绕开外汇管制，资金调度自由（开户行：华美银行、花旗银行等）</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/America/pic_6_4.png" alt="">
          <p class="re-serve-p1">做账审计</p>
          <p class="re-serve-p2">注册美国公司后若在美国经营，需交税做账，若不在美国经营，无需做账和交税，但需要报税，可进行零申报</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/America/pic_6_5.png" alt="">
          <p class="re-serve-p1">变更资料</p>
          <p class="re-serve-p2">美国公司变更包括公司名称、公司注册地址、公司股东股权等美国公司章程里的每—项</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/America/pic_6_6.png" alt="">
          <p class="re-serve-p1">公司注销</p>
          <p class="re-serve-p2">美国公司注销需在营业期有效3个月内申请，申请前需注销公司银行账号与对公司财务进行清算</p>
        </div>
      </div>
    </div>

    <div class="box" style="height: 616px;">
      <p class="box-p1">美国州税图</p>
      <img class="tax-img" src="../../assets/img/register/America/pic_7_1.jpg" alt="">
      <p class="box-p1">美国州税图</p>
      <img class="tax-img" src="../../assets/img/register/America/pic_7_2.jpg" alt="">
    </div>

    <div class="box" style="height: auto;padding-bottom: 30px;background: rgba(242, 245, 250, 1)">
      <p class="box-p1">注册美国公司常见问题</p>
      <p class="box-p1">解决您的一切疑问</p>
      <div class="question">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item title="美国在哪些州是“免税州”?" name="1">
            <p class="question-p">免税州所指的是美国部分州为了鼓励各州创业而推出的相关税务优惠政策。例如特拉华州、德克萨斯州都是具有代表性的免税州。</p>
          </el-collapse-item>
          <el-collapse-item title="注册美国公司需要交哪些税?" name="2">
            <p class="question-p">美国税收分为联邦税和州税，联邦税是公司必须缴纳的，州税视情况而定。</p>
          </el-collapse-item>
          <el-collapse-item title="美国公司如何做零申报？" name="3">
            <p class="question-p">美国公司注册后，需要进行年审和报税，如果刚注册公司没有利润，则不用缴税，但需要进行零申报报税。</p>
          </el-collapse-item>
          <el-collapse-item title="如何注销美国公司?" name="4">
            <p class="question-p">如果美国公司在年审逾期后提交注销美国公司申请，需要在提交注销公司的申请前进行支付年审和报税。</p>
          </el-collapse-item>
        </el-collapse>
      </div>
      <p class="to-ask" @click="toAsk"> 
        <img class="to-ask-icon" src="../../assets/img/register/bvi/icon_6_customer_service.png" alt=""> 
        更多问题？立即咨询人工客服</p>
    </div>
    <div class="box" style="height: 480px;">
      <p class="box-p1">快企务的优势</p>
      <p class="box-p2">十余年注册美国公司经验</p>
      <div class="re-adv">
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_1.png" alt="">
          <p class="re-adv-p1">保密完善</p>
          <p class="re-adv-p2">保密系统完善，客户隐私不外泄</p>
        </div>
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_2.png" alt="">
          <p class="re-adv-p1">全程服务</p>
          <p class="re-adv-p2">一站式专业服务从注册公司到年审、开户，审计报税等</p>
        </div>
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_3.png" alt="">
          <p class="re-adv-p1">专业高效</p>
          <p class="re-adv-p2">根据需求，为客户制定专业快捷的解决方案</p>
        </div>
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_4.png" alt="">
          <p class="re-adv-p1">合同保障</p>
          <p class="re-adv-p2">正规机构，合同保障，费用透明，无隐形消费</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  data(){
    return{
      tab: 1,
      activeName: '1'
    }
  },
 
  mounted(){
    window.scrollTo(0,0);
  },
  methods:{
    toAsk(){
      window.open('https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn','_blank')
    },
  }
}
</script>
<style scoped>
  .m-bvi-banner{
    width: 100%;
    height: 460px;
    background: url('../../assets/img/register/HongKong/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
</style>
