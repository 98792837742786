<template>
  <div class="home">
    <div class="m-bvi-banner">
      <p class="banner-p1">一站式印尼公司注册服务</p>
      <p class="banner-p2">【快企务专注于涉内外企业服务十余年】</p>
      <p class="banner-btn banner-btn1" @click="toAsk">立即咨询</p>
    </div>

    <div class="box" style="height: auto;margin-bottom: 30px;">
      <p class="box-p1">注册印尼公司的优势</p>
      <div class="mal-adv">
        <div class="mal-adv-inner">
          <img class="mal-adv-icon" src="../../assets/img/register/Indonesia/pic_1_1.png" alt="">
          <p class="mal-adv-p" style="font-weight: normal;">企业所得税税率为30%（根据新《所得税法》，2010后为25%），可以在6年之内付清</p>
        </div>
        <div class="mal-adv-inner">
          <img class="mal-adv-icon" src="../../assets/img/register/Indonesia/pic_1_2.png" alt="">
          <p class="mal-adv-p" style="font-weight: normal;">可享受加速摊销偿付及加速折旧法</p>
        </div>
        <div class="mal-adv-inner">
          <img class="mal-adv-icon" src="../../assets/img/register/Indonesia/pic_1_3.png" alt="">
          <p class="mal-adv-p" style="font-weight: normal;">外商投资企业的股利所得税为10%，或者根据现行的有关避免双重征税协议，采用较低的税率缴税</p>
        </div>
        <div class="mal-adv-inner">
          <img class="mal-adv-icon" src="../../assets/img/register/Indonesia/pic_1_4.png" alt="">
          <p class="mal-adv-p" style="font-weight: normal;">损失赔偿期限可超过5年，但不能超过10年</p>
        </div>
        <div class="mal-adv-inner">
          <img class="mal-adv-icon" src="../../assets/img/register/Indonesia/pic_1_5.png" alt="">
          <p class="mal-adv-p" style="font-weight: normal;">为了促进商业发展，特别是在改善农业发展及其竞争力方面，政府取消了对主要农产品的附加所得税</p>
        </div>
        <div class="mal-adv-inner">
          <img class="mal-adv-icon" src="../../assets/img/register/Indonesia/pic_1_6.png" alt="">
          <p class="mal-adv-p" style="font-weight: normal;">董事长和总经理在印尼居住三年以上可以申请印尼绿卡</p>
        </div>
      </div>
    </div>

    <div class="box" style="height: auto;padding-bottom: 30px;">
      <p class="box-p1">注册印尼公司类型</p>
      <div class="type-content flex center-width">
        <img class="type-img" style="height: 110px;" src="../../assets/img/register/Indonesia/pic_2_1.jpg" alt="">
        <img class="type-img" style="height: 110px;" src="../../assets/img/register/Indonesia/pic_2_2.jpg" alt="">
        <img class="type-img" style="height: 110px;" src="../../assets/img/register/Indonesia/pic_2_3.jpg" alt="">
        <img class="type-img" @click="toAsk" style="height: 110px;" src="../../assets/img/register/Indonesia/pic_2_4.jpg" alt="">
      </div>
    </div>

    <div class="box" style="height: auto;padding-bottom: 15px;">
      <p class="box-p1">注册印尼公司的条件/流程/资料</p>
      <div class="tab-box">
        <div class="tab-inner" :class="{'active': tab == 1}" @click="tab = 1">注册条件</div>
        <div class="tab-inner" :class="{'active': tab == 2}" @click="tab = 2">注册流程</div>
        <div class="tab-inner" :class="{'active': tab == 3}" @click="tab = 3">所得资料</div>
      </div>
      <div class="file-content">
        <div v-if="tab == 1">
          <div class="bri-flow" style="justify-content: flex-start;">
            <div class="bri-flow-inner" >
              <img class="bri-flow-icon" src="../../assets/img/register/Indonesia/pic_3_1.png" alt="">
              <p class="bri-flow-p1">公司类型有限公司</p>
            </div>
            <div class="bri-flow-inner">
              <img class="bri-flow-icon" src="../../assets/img/register/Indonesia/pic_3_2.png" alt="">
              <p class="bri-flow-p1">最少2人董事</p>
            </div>
            <div class="bri-flow-inner">
              <img class="bri-flow-icon" src="../../assets/img/register/Indonesia/pic_3_4.png" alt="">
              <p class="bri-flow-p1">注册资本至少100亿印尼盾</p>
            </div>
            <div class="bri-flow-inner">
              <img class="bri-flow-icon" src="../../assets/img/register/Indonesia/pic_3_5.png" alt="">
              <p class="bri-flow-p1">不需要当地人出任董事</p>
            </div>
            <div class="bri-flow-inner">
              <img class="bri-flow-icon" src="../../assets/img/register/Indonesia/pic_3_6.png" alt="">
              <p class="bri-flow-p1">资金注册前无需到位</p>
            </div>
            <div class="bri-flow-inner">
              <img class="bri-flow-icon" src="../../assets/img/register/Indonesia/pic_3_7.png" alt="">
              <p class="bri-flow-p1">最少2人股东</p>
            </div>
            <div class="bri-flow-inner">
              <img class="bri-flow-icon" src="../../assets/img/register/Indonesia/pic_3_8.png" alt="">
              <p class="bri-flow-p1">需要做账和审计</p>
            </div>
            <div class="bri-flow-inner">
              <img class="bri-flow-icon" src="../../assets/img/register/Indonesia/pic_3_9.png" alt="">
              <p class="bri-flow-p1">需要设立注册办事处</p>
            </div>
          </div>
        </div>
        <div v-if="tab == 2">
          <img class="tab2-img" src="../../assets/img/register/Indonesia/pic_4.jpg" alt="">
        </div>
        <div v-if="tab == 3">
          <div class="ger-tab3">
            <div class="ger-tab3-inner fre-tab3">
              <img class="ger-tab3-icon" src="../../assets/img/register/Indonesia/pic_5_1.png" alt="">
              <p class="ger-tab3-p">投资许可证</p>
            </div>
            <div class="ger-tab3-inner fre-tab3">
              <img class="ger-tab3-icon" src="../../assets/img/register/Indonesia/pic_5_2.png" alt="">
              <p class="ger-tab3-p">公司章程公证契据</p>
            </div>
            <div class="ger-tab3-inner fre-tab3">
              <img class="ger-tab3-icon" src="../../assets/img/register/Indonesia/pic_5_3.png" alt="">
              <p class="ger-tab3-p">公司章程司法部批准书</p>
            </div>
            <div class="ger-tab3-inner fre-tab3">
              <img class="ger-tab3-icon" src="../../assets/img/register/Indonesia/pic_5_4.png" alt="">
              <p class="ger-tab3-p"> 税务身份编号</p>
            </div>
            <div class="ger-tab3-inner fre-tab3">
              <img class="ger-tab3-icon" src="../../assets/img/register/Indonesia/pic_5_5.png" alt="">
              <p class="ger-tab3-p"> 增值税登记代号</p>
            </div>
            <div class="ger-tab3-inner fre-tab3">
              <img class="ger-tab3-icon" src="../../assets/img/register/Indonesia/pic_5_6.png" alt="">
              <p class="ger-tab3-p"> 商业执照</p>
            </div>
            <div class="ger-tab3-inner fre-tab3">
              <img class="ger-tab3-icon" src="../../assets/img/register/Indonesia/pic_5_7.png" alt="">
              <p class="ger-tab3-p"> 公司成立证书</p>
            </div>
            <div class="ger-tab3-inner fre-tab3">
              <img class="ger-tab3-icon" src="../../assets/img/register/Indonesia/pic_5_8.png" alt="">
              <p class="ger-tab3-p"> 官报中公布公司章程的副本</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <div class="box" style="height: 725px;background: rgba(242, 245, 250, 1);">
      <p class="box-p1">注册印尼公司后为您提供的后续服务</p>
      <div class="re-serve">
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Vietnam/pic_6_1.png" alt="">
          <p class="re-serve-p1">年审/续牌</p>
          <p class="re-serve-p2">所有公司须按年度定时递交包含股东、董事、公司秘书和其他任何当年可能影响公司行政管理结构详情信息的公司年度报表</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Vietnam/pic_6_2.png" alt="">
          <p class="re-serve-p1">秘书服务</p>
          <p class="re-serve-p2">办公地址，公司周年报表年检，公司董事股东成员名册更新，在职证明与良好证明，代缴政府规费，修改公司章程等 </p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Vietnam/pic_6_3.png" alt="">
          <p class="re-serve-p1">做账报税</p>
          <p class="re-serve-p2">公司注册之后需按照当地政府单位要求进行做账报税</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Vietnam/pic_6_4.png" alt="">
          <p class="re-serve-p1">银行开户</p>
          <p class="re-serve-p2">开设公司注册所在地的银行账户，有利于公司随时接受贸易汇款，享受当地政策或规避外汇风险</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Vietnam/pic_6_5.png" alt="">
          <p class="re-serve-p1">税号申请</p>
          <p class="re-serve-p2">公司经营过程中的公司信息（如地址、股东、经营范围等）变更</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Vietnam/pic_6_6.png" alt="">
          <p class="re-serve-p1">公司注销</p>
          <p class="re-serve-p2">因破产、股东决议解散、存续期届满等原因需要注销的，应及时主动注销公司</p>
        </div>
      </div>
    </div>
    <div class="box" style="height: auto;padding-bottom: 30px;background: rgba(242, 245, 250, 1)">
      <p class="box-p1">印尼公司注册常见问题</p>
      <p class="box-p1">解决您的一切疑问</p>
      <div class="question">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item title="实收资本的缴纳时间及方式?" name="1">
            <p class="question-p">根据印尼法律规定，外商若想在印尼开办外资公司（PMA），必须注册有限责任公司（PT），在完成公司注册
流程后，投资者需要开设银行账户，再将实收资本转入公司的银行账户，投资者将以此为资本开展经营活动。
需要注意的是，对于实收资本而言，其必须是货币资产，不可以用非货币资产的形式替代。
公司注册后，投资者通常需要每六个月向有关部门上报投资进度（部分行业每三个月上报一次）。
</p>
          </el-collapse-item>
          <el-collapse-item title="外资公司所得税与本土公司是否有区别？" name="2">
            <p class="question-p">外资在印尼设立公司即属于印尼法人，所得税与印尼本国公司一样就税前净利课征收25%。</p>
          </el-collapse-item>
          <el-collapse-item title="外国投资人是否能100%独资经营？" name="3">
            <p class="question-p">1、外国投资人在印尼设立公司是否能100%独资经营，须依据投资负面清单（Investment Negative List，简
称DNI）的规定，如果项目未列在DNI，则视为开放项目，外资可以100%持有股份。一般来说，生产制造业可
以100%独资经营。
2、外资公司必须至少要有2位股东，并无身分国籍限制，可为个人或公司。</p>
          </el-collapse-item>
          <el-collapse-item title="印尼公司办理完成后可以拿到哪些文件？" name="2">
            <p class="question-p">办理完成文件：a.公司注册证书；b.公司会议记录；c.股东、董事、秘书名册；d.公司大纲及章程；e.公司股
票；f.公司签名章及钢印。</p>
          </el-collapse-item>
        </el-collapse>
      </div>
      <p class="to-ask" @click="toAsk"> 
        <img class="to-ask-icon" src="../../assets/img/register/bvi/icon_6_customer_service.png" alt=""> 
        更多问题？立即咨询人工客服</p>
    </div>

    <div class="box" style="height: 480px;">
      <p class="box-p1">快企务的优势</p>
      <p class="box-p2">十余年注册印尼公司经验</p>
      <div class="re-adv">
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_1.png" alt="">
          <p class="re-adv-p1">保密完善</p>
          <p class="re-adv-p2">保密系统完善，客户隐私不外泄</p>
        </div>
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_2.png" alt="">
          <p class="re-adv-p1">全程服务</p>
          <p class="re-adv-p2">一站式专业服务从注册公司到年审、开户，审计报税等</p>
        </div>
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_3.png" alt="">
          <p class="re-adv-p1">专业高效</p>
          <p class="re-adv-p2">根据需求，为客户制定专业快捷的解决方案</p>
        </div>
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_4.png" alt="">
          <p class="re-adv-p1">合同保障</p>
          <p class="re-adv-p2">正规机构，合同保障，费用透明，无隐形消费</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  data(){
    return{
      tab: 1,
      activeName: '1'
    }
  },
  mounted(){
    window.scrollTo(0,0);
  },
  methods:{
    toAsk(){
      window.open('https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn','_blank')
    },
  }
}
</script>
<style scoped>
  .m-bvi-banner{
    width: 100%;
    height: 460px;
    background: url('../../assets/img/register/Indonesia/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
</style>
