<template>
    <div class="certification m-bvi">
      <div class="m-bvi-banner" style="height: 460px;">
        <p class="no-banner-p1">【快企务专注于涉内外企业服务十余年】</p>
        <p class="no-banner-p2">国际公证认证服务</p>
        <p class="banner-btn banner-btn1" @click="toAsk">立即咨询</p>
      </div>
      <div class="box" style="height: 473px;">
        <p class="box-p1">与全球公证机构展开合作，是最早进入国际公证认证领域</p>
        <p class="box-p2">办理了 <span class="blue-style">10000+</span> 客户文件，承诺文件无法使用，<span class="blue-style">100%</span> 全额退款</p>
        <div class="box-flex-content">
          <div class="flex-inner">
            <img class="box-flex-icon" src="../../assets/img/notarization/notarization/icon_1_1efficient.png" alt="">
            <p class="box-flex-p1">专业高效</p>
            <p class="box-flex-p2">与全球公证机构开展合作</p>
          </div>
          <div class="flex-inner">
            <img class="box-flex-icon" src="../../assets/img/notarization/notarization/icon_1_2security.png" alt="">
            <p class="box-flex-p1">专业高效</p>
            <p class="box-flex-p2">与全球公证机构开展合作</p>
          </div>
          <div class="flex-inner">
            <img class="box-flex-icon" src="../../assets/img/notarization/notarization/icon_1_3reliable.png" alt="">
            <p class="box-flex-p1">专业高效</p>
            <p class="box-flex-p2">与全球公证机构开展合作</p>
          </div>
          <div class="flex-inner">
            <img class="box-flex-icon" src="../../assets/img/notarization/notarization/icon_1_4refund.png" alt="">
            <p class="box-flex-p1">专业高效</p>
            <p class="box-flex-p2">与全球公证机构开展合作</p>
          </div>
        </div>
      </div>
      <div class="box" style="background: rgba(242, 245, 250, 1);height: auto;padding-bottom: 20px;">
        <p class="box-p1">快企务国际公证认证服务范围</p>
        <p class="box-p2">海牙认证/中国委托公证人公证/使馆认证</p>
        <div class="tab-content-box">
          <div class="tab-box1">
            <p class="tab-inner1" :class="{'active': tab == 1}" @click="tab = 1">海牙认证</p>
            <p class="tab-inner1 inner-width" :class="{'active': tab == 2}" @click="tab = 2">中国委托公证人公证</p>
            <p class="tab-inner1" :class="{'active': tab == 3}" @click="tab = 3">使馆认证</p>
          </div>
          <div class="tab-content-box1" v-if="tab == 1">
            <div class="tabcontent-box">
              <img class="tab-content-img" src="../../assets/img/notarization/notarization/pic_1_1.png" alt="">
              <div class="tabcontent-button">
                <div class="button" @click="toAsk">立即咨询</div>
                <div class="button button1" @click="toUrl('notarizationHague')">查看详情</div>
              </div>
            </div>
            <div class="tabcontent-box">
              <img class="tab-content-img" src="../../assets/img/notarization/notarization/pic_1_2.png" alt="">
              <div class="tabcontent-button">
                <div class="button" @click="toAsk">立即咨询</div>
                <div class="button button1" @click="toUrl('notarizationHague')">查看详情</div>
              </div>
            </div>
            <div class="tabcontent-box">
              <img class="tab-content-img" src="../../assets/img/notarization/notarization/pic_1_3.png" alt="">
              <div class="tabcontent-button">
                <div class="button" @click="toAsk">立即咨询</div>
                <div class="button button1" @click="toUrl('notarizationHague')">查看详情</div>
              </div>
            </div>
          </div>
          <div class="tab-content-box1" v-if="tab == 2">
            <div class="tabcontent-box">
              <img class="tab-content-img" src="../../assets/img/notarization/notarization/pic_2_1.png" alt="">
              <div class="tabcontent-button">
                <div class="button" @click="toAsk">立即咨询</div>
                <div class="button button1" @click="toUrl('notarizationChina')">查看详情</div>
              </div>
            </div>
            <div class="tabcontent-box">
              <img class="tab-content-img" src="../../assets/img/notarization/notarization/pic_2_2.png" alt="">
              <div class="tabcontent-button">
                <div class="button" @click="toAsk">立即咨询</div>
                <div class="button button1" @click="toUrl('notarizationChina')">查看详情</div>
              </div>
            </div>
          </div>
          <div class="tab-content-box1" v-if="tab == 3">
            <div class="tabcontent-box">
              <img class="tab-content-img" src="../../assets/img/notarization/notarization/pic_3_1.png" alt="">
              <div class="tabcontent-button">
                <div class="button" @click="toAsk">立即咨询</div>
                <div class="button button1" @click="toUrl('notarizationEmbassy')">查看详情</div>
              </div>
            </div>
            <div class="tabcontent-box">
              <img class="tab-content-img" src="../../assets/img/notarization/notarization/pic_3_2.png" alt="">
              <div class="tabcontent-button">
                <div class="button" @click="toAsk">立即咨询</div>
                <div class="button button1" @click="toUrl('notarizationEmbassy')">查看详情</div>
              </div>
            </div>
            <div class="tabcontent-box">
              <img class="tab-content-img" src="../../assets/img/notarization/notarization/pic_3_3.png" alt="">
              <div class="tabcontent-button">
                <div class="button" @click="toAsk">立即咨询</div>
                <div class="button button1" @click="toUrl('notarizationEmbassy')">查看详情</div>
              </div>
            </div>
            <div class="tabcontent-box">
              <img class="tab-content-img" src="../../assets/img/notarization/notarization/pic_3_4.png" alt="">
              <div class="tabcontent-button">
                <div class="button" @click="toAsk">立即咨询</div>
                <div class="button button1" @click="toUrl('notarizationEmbassy')">查看详情</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="box" style="height: 368px;">
        <p class="box-p1">快企务公证认证案例</p>
        <p class="box-p2">快企务已累计办理认证业务 <span class="blue-style">10000+</span></p>
        <div class="lunbo-box cer-lunbo-box" style="margin-top: 20px">
          <van-swipe class="my-swipe" :autoplay="3000" indicator-color="rgba(47, 114, 235, 1)" >
            <van-swipe-item>
              <div class="cer-case-box">
                <img class="cer-case-img" src="../../assets/img/notarization/notarization/pic_4_1.jpg" alt="">
                <img class="cer-case-img" src="../../assets/img/notarization/notarization/pic_4_2.jpg" alt="">
              </div>
            </van-swipe-item>
            <van-swipe-item>
              <div class="cer-case-box">
                <img class="cer-case-img" src="../../assets/img/notarization/notarization/pic_4_3.jpg" alt="">
                <img class="cer-case-img" src="../../assets/img/notarization/notarization/pic_4_4.jpg" alt="">
              </div>
            </van-swipe-item>
          </van-swipe>
        </div>
      </div>
    </div>
  </template>
  <script>
  export default {
    name: 'bvi-page',
    props: {
      msg: String
    },
    data(){
      return{
        tab: 1,
      }
    },
    mounted(){
      window.scrollTo(0,0);
    },
    methods: {
      toAsk(){
        window.open('https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn','_blank')
      },
      toUrl(url){
        this.$router.push({name: url})
      }
    }
  }
  </script>
  
  <style scoped lang="scss">
    .m-bvi-banner{
      width: 100%;
      height: 460px;
      background: url('../../assets/img/notarization/notarization/banner.jpg') no-repeat center center;
      background-size: 100% 100%;
    }
  </style>
  