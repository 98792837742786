<template>
  <div class="home">
    <div class="m-bvi-banner">
      <p class="banner-p1">一站式意大利公司注册服务</p>
      <p class="banner-p2">【快企务专注于涉内外企业服务十余年】</p>
      <p class="banner-btn banner-btn1" @click="toAsk">立即咨询</p>
    </div>

    <div class="box" style="height: auto;margin-bottom: 30px;">
      <p class="box-p1">注册意大利公司的优势</p>
      <div class="mal-adv">
        <div class="mal-adv-inner">
          <img class="mal-adv-icon" src="../../assets/img/register/Italy/pic_1_1.png" alt="">
          <p class="mal-adv-p" style="font-weight: normal;">以使用意大利的公司与品牌返销国内与贴牌销售，占据高中档消费市场</p>
        </div>
        <div class="mal-adv-inner">
          <img class="mal-adv-icon" src="../../assets/img/register/Italy/pic_1_2.png" alt="">
          <p class="mal-adv-p" style="font-weight: normal;">成立公司后，以股东身份申请意大利商业居留、移民，并可置业、投资、上市</p>
        </div>
        <div class="mal-adv-inner">
          <img class="mal-adv-icon" src="../../assets/img/register/Italy/pic_1_3.png" alt="">
          <p class="mal-adv-p" style="font-weight: normal;">用意大利的公司与品牌将产品行销世界，进入中高档消费市场</p>
        </div>
        <div class="mal-adv-inner">
          <img class="mal-adv-icon" src="../../assets/img/register/Italy/pic_1_4.png" alt="">
          <p class="mal-adv-p" style="font-weight: normal;">意大利公司的国际诚信度很高，原产地文化各品牌影响力居世界前列，公司审批相对严格，需要有居留权人担任公司法定代表，需要验资或担保等</p>
        </div>
      </div>
    </div>

    <div class="box" style="height: auto;padding-bottom: 30px;">
      <p class="box-p1">注册意大利公司类型</p>
      <div class="type-content flex center-width">
        <img class="type-img" style="height: 110px;" src="../../assets/img/register/Italy/pic_2_1.jpg" alt="">
        <img class="type-img" style="height: 110px;" src="../../assets/img/register/Italy/pic_2_2.jpg" alt="">
        <img class="type-img" style="height: 110px;" src="../../assets/img/register/Italy/pic_2_3.jpg" alt="">
        <img class="type-img" style="height: 110px;" src="../../assets/img/register/Italy/pic_2_4.jpg" alt="">
      </div>
    </div>
    
    <div class="box" style="height: auto;padding-bottom: 15px;">
      <p class="box-p1">注册意大利公司的条件/流程/资料</p>
      <div class="tab-box">
        <div class="tab-inner" :class="{'active': tab == 1}" @click="tab = 1">注册条件</div>
        <div class="tab-inner" :class="{'active': tab == 2}" @click="tab = 2">注册流程</div>
        <div class="tab-inner" :class="{'active': tab == 3}" @click="tab = 3">所得资料</div>
      </div>
      <div class="file-content">
        <div v-if="tab == 1">
          <div class="Malta-tab1">
            <div class="Malta-tab1-box" style="height: 250px;padding-top: 30px;">
              <p class="Malta-tab1-p">股东成员：意大利允许外国自然人或企业法人担任公司股东/董事，要求资信良好，年龄须满18周岁，且至少有2名或2名以上股东；意大利公司法规定，必须有一名或一名以上股东为意大利籍人士或在意大利拥有居留权人士</p>
              <p class="Malta-tab1-p">外国企业法人担任意大利公司股东：外国企业法人担任意大利公司股东的，必须递交所在国有效的企业法人营业执照</p>
              <p class="Malta-tab1-p">法定董事：意大利公司法规定，法定董事必须是意大利籍人士或在意大利拥有十年居留权人士担任，无犯罪记录，商业信用良好</p>
              <p class="Malta-tab1-p">注册资本：意大利普通有限责任公司最低注册资本10,000欧元</p>
             </div>
          </div>
        </div>
        <div v-if="tab == 2">
          <img class="tab2-img" src="../../assets/img/register/Italy/pic_4.jpg" alt="">
        </div>
        <div v-if="tab == 3">
          <div class="ger-tab3">
            <div class="ger-tab3-inner fre-tab3">
              <img class="ger-tab3-icon" src="../../assets/img/register/Italy/pic_5_1.png" alt="">
              <p class="ger-tab3-p">公司注册证书</p>
            </div>
            <div class="ger-tab3-inner fre-tab3">
              <img class="ger-tab3-icon" src="../../assets/img/register/Italy/pic_5_2.png" alt="">
              <p class="ger-tab3-p">公司章程</p>
            </div>
            <div class="ger-tab3-inner fre-tab3">
              <img class="ger-tab3-icon" src="../../assets/img/register/Italy/pic_5_3.png" alt="">
              <p class="ger-tab3-p">公司股东会议记录簿</p>
            </div>
            <div class="ger-tab3-inner fre-tab3">
              <img class="ger-tab3-icon" src="../../assets/img/register/Italy/pic_5_4.png" alt="">
              <p class="ger-tab3-p"> 法人公章、钢印</p>
            </div>
            <div class="ger-tab3-inner fre-tab3">
              <img class="ger-tab3-icon" src="../../assets/img/register/Italy/pic_5_5.png" alt="">
              <p class="ger-tab3-p"> 意大利华侨华人贸易总会出具的《公证书》</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <div class="box" style="height: 725px;background: rgba(242, 245, 250, 1);">
      <p class="box-p1">注册意大利公司后为您提供的后续服务</p>
      <div class="re-serve">
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Vietnam/pic_6_1.png" alt="">
          <p class="re-serve-p1">年审/续牌</p>
          <p class="re-serve-p2">所有公司须按年度定时递交包含股东、董事、公司秘书和其他任何当年可能影响公司行政管理结构详情信息的公司年度报表</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Vietnam/pic_6_2.png" alt="">
          <p class="re-serve-p1">秘书服务</p>
          <p class="re-serve-p2">办公地址，公司周年报表年检，公司董事股东成员名册更新，在职证明与良好证明，代缴政府规费，修改公司章程等 </p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Vietnam/pic_6_3.png" alt="">
          <p class="re-serve-p1">做账报税</p>
          <p class="re-serve-p2">公司注册之后需按照当地政府单位要求进行做账报税</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Vietnam/pic_6_4.png" alt="">
          <p class="re-serve-p1">银行开户</p>
          <p class="re-serve-p2">开设公司注册所在地的银行账户，有利于公司随时接受贸易汇款，享受当地政策或规避外汇风险</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Vietnam/pic_6_5.png" alt="">
          <p class="re-serve-p1">税号申请</p>
          <p class="re-serve-p2">公司经营过程中的公司信息（如地址、股东、经营范围等）变更</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Vietnam/pic_6_6.png" alt="">
          <p class="re-serve-p1">公司注销</p>
          <p class="re-serve-p2">因破产、股东决议解散、存续期届满等原因需要注销的，应及时主动注销公司</p>
        </div>
      </div>
    </div>
    <div class="box" style="height: auto;padding-bottom: 30px;background: rgba(242, 245, 250, 1)">
      <p class="box-p1">意大利公司注册常见问题</p>
      <p class="box-p1">解决您的一切疑问</p>
      <div class="question">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item title="意大利公司对于注册资本有什么要求？" name="1">
            <p class="question-p">意大利普通有限责任公司标准注册资本10000欧元，公司名称以SRL结尾。</p>
          </el-collapse-item>
          <el-collapse-item title="意大利公司名称可以随便起吗？" name="2">
            <p class="question-p">意大利公司名称选择所选名称不能与已注册名称相同或太相似。公司名称也不能含有与法国政府相关或保险等
字样，除非另外行申请相关的牌照。</p>
          </el-collapse-item>
          <el-collapse-item title="名义经理和注册地址要求？" name="3">
            <p class="question-p">注册意大利公司须安排一位或以上名义经理，名义经理为意大利公司合法代表人，必须由意大利当地公民担任；
注册地址须安排在意大利本地。</p>
          </el-collapse-item>
        </el-collapse>
      </div>
      <p class="to-ask" @click="toAsk"> 
        <img class="to-ask-icon" src="../../assets/img/register/bvi/icon_6_customer_service.png" alt=""> 
        更多问题？立即咨询人工客服</p>
    </div>

    <div class="box" style="height: 480px;">
      <p class="box-p1">快企务的优势</p>
      <p class="box-p2">十余年注册意大利公司经验</p>
      <div class="re-adv">
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_1.png" alt="">
          <p class="re-adv-p1">保密完善</p>
          <p class="re-adv-p2">保密系统完善，客户隐私不外泄</p>
        </div>
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_2.png" alt="">
          <p class="re-adv-p1">全程服务</p>
          <p class="re-adv-p2">一站式专业服务从注册公司到年审、开户，审计报税等</p>
        </div>
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_3.png" alt="">
          <p class="re-adv-p1">专业高效</p>
          <p class="re-adv-p2">根据需求，为客户制定专业快捷的解决方案</p>
        </div>
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_4.png" alt="">
          <p class="re-adv-p1">合同保障</p>
          <p class="re-adv-p2">正规机构，合同保障，费用透明，无隐形消费</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  data(){
    return{
      tab: 1,
      activeName: '1'
    }
  },
  mounted(){
    window.scrollTo(0,0);
  },
  methods:{
    toAsk(){
      window.open('https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn','_blank')
    },
  }
}
</script>
<style scoped>
  .m-bvi-banner{
    width: 100%;
    height: 460px;
    background: url('../../assets/img/register/Italy/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
</style>
