<template>
  <div class="home">
    <div style="width: 94%; margin: 0 auto;">
          <div class="table1" style="width: 100%;">
            <div class="table-inner" style="background: rgba(237, 237, 237, 1);">
              <p class="table-left" style="width: 100px;">序号</p>
              <p class="table-right">专业名称</p>
            </div>
            <div class="table-inner1">
              <p class="table-left" style="width: 100px;">1</p>
              <p class="table-right">资产管理合规专才</p>
            </div>
            <div class="table-inner1">
              <p class="table-left" style="width: 100px;">2</p>
              <p class="table-right">环境、社会及管治（ESG）相关财经专才</p>
            </div>
            <div class="table-inner1">
              <p class="table-left" style="width: 100px;">3</p>
              <p class="table-right">废物处理专家</p>
            </div>
            <div class="table-inner1">
              <p class="table-left" style="width: 100px;">4</p>
              <p class="table-right">资产管理专才</p>
            </div>
            <div class="table-inner1">
              <p class="table-left" style="width: 100px;">5</p>
              <p class="table-right">海事保险专才</p>
            </div>
            <div class="table-inner1">
              <p class="table-left" style="width: 100px;">6</p>
              <p class="table-right">精算师</p>
            </div>
            <div class="table-inner1">
              <p class="table-left" style="width: 100px;">7</p>
              <p class="table-right">金融科技专才</p>
            </div>
            <div class="table-inner1">
              <p class="table-left" style="width: 100px;">8</p>
              <p class="table-right">数据科学家及网络安全专家</p>
            </div>
            <div class="table-inner1">
              <p class="table-left" style="width: 100px;">9</p>
              <p class="table-right">创新及科技专家</p>
            </div>
            <div class="table-inner1">
              <p class="table-left" style="width: 100px;">10</p>
              <p class="table-right">造船师</p>
            </div>
            <div class="table-inner1">
              <p class="table-left" style="width: 100px;">11</p>
              <p class="table-right">轮机工程师及船舶总管</p>
            </div>
            <div class="table-inner1">
              <p class="table-left" style="width: 100px;">12</p>
              <p class="table-right">创意产业和表演艺术专才</p>
            </div>
            <div class="table-inner1">
              <p class="table-left" style="width: 100px;">13</p>
              <p class="table-right">争议解决专才及业务交易律师</p>
            </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  data(){
    return{
      activeName: '1',
      Dialog: false,
      tab: 1
    }
  },
 
  mounted(){
    window.scrollTo(0,0);
  },
  methods:{
    toAsk(){
      window.open('https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn','_blank')
    },
    toUrl(url){
      this.$router.push({name: url})
    }
  }
}
</script>
