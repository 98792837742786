<template>
  <div class="center">
    <h1 style="margin-top: 20vh">找不到页面</h1>
    <p style="margin-top: 40px;color: rgb(47, 114, 235);">
      <a style="color: rgb(47, 114, 235)" href="/">返回首页</a>
    </p>
  </div>
</template>

<script>

  export default {
    name: 'NotFound'
  }

</script>

<style scoped>

  .center {
    margin: 15vw;
  }

  h1 {
    color: var(--border);
    font-size: 2em;
  }

</style>
