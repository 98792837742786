<template>
  <div class="certification m-bvi" style="background: rgba(242, 245, 250, 1);">
    <div class="m-bvi-banner">
      <p class="banner-p1">贸促会认证</p>
      <p class="banner-p2" style="width: 304px;margin: 0 auto;margin-top: 20px;">贸促会认证也叫做报关单认证，是由中国国际贸易促进委员会出具签发的（贸促会商事认证，报关单商会认证，CCPIT认证）</p>
      <p class="banner-btn banner-btn1" @click="toAsk">立即咨询</p>
    </div>
    <div class="box" style="height: 373px;background: #fff;">
      <p class="box-p1">贸促会认证办理</p>
      <p class="box-p2">请填写以下表单留下您的信息，我们将第一时间联系您，谢谢！</p>
      <div class="form-box">
        <div class="form-line">
          <p class="form-label">姓名</p>
          <input class="form-input" placeholder="请输入您的姓名" type="text" v-model="form.name">
        </div>
        <div class="form-line">
          <p class="form-label">电话</p>
          <input class="form-input" type="text" placeholder="请输入您的联系方式" v-model="form.mobile">
        </div>
        <div class="form-line">
          <p class="form-label">您的需求</p>
          <input class="form-input" type="text" placeholder="请输入您的需求" v-model="form.remark">
        </div>
      </div>
      <p class="form-submit" @click="toSubmit">提交</p>
    </div>
    <div class="box" style="height: 281px;background: #fff;margin-top: 10px;">
      <p class="box-p1" style="width: 100%;">代办贸促会认证，需要客户提供的资料</p>
      <div class="scroll-box" style="margin-top: 20px;">
        <div class="scroll-inner" style="width: 1500px">
          <img class="scroll-img" style="width: 290px;height: 190px;" src="../../assets/img/notarization/trade/pic_1_1.jpg" alt="">
          <img class="scroll-img" style="width: 290px;height: 190px;" src="../../assets/img/notarization/trade/pic_1_2.jpg" alt="">
          <img class="scroll-img" style="width: 290px;height: 190px;" src="../../assets/img/notarization/trade/pic_1_3.jpg" alt="">
          <img class="scroll-img" style="width: 290px;height: 190px;" src="../../assets/img/notarization/trade/pic_1_4.jpg" alt="">
          <img class="scroll-img" style="width: 290px;height: 190px;" src="../../assets/img/notarization/trade/pic_1_5.jpg" alt="">
        </div>
      </div>
    </div>
    <div class="box" style="height: 1673px;background: #fff;margin-top: 10px;">
      <p class="box-p1">我司办理贸促会认证的优势</p>
      <div class="trade-adv">
        <div class="trade-adv-inner">
          <img class="trade-adv-icon" src="../../assets/img/notarization/trade/icon_1_1.png" alt="">
          <p class="trade-adv-p1">时间优势</p>
          <p class="trade-adv-p2">我司送贸促会的文件，文件合格，当天就会核签，3个工作日之内到达外交部，文件在外交部两天左右，就可以进使馆，我司每天都会到贸促会送取文件，如果文件完成，可以第一时间取回，不会积攒文件</p>
        </div>
        <div class="trade-adv-inner">
          <img class="trade-adv-icon" src="../../assets/img/notarization/trade/icon_1_2.png" alt="">
          <p class="trade-adv-p1">价格优势</p>
          <p class="trade-adv-p2">我司成本：485+对应文件使馆的官费+对应的翻译费</p>
        </div>
        <div class="trade-adv-inner">
          <img class="trade-adv-icon" src="../../assets/img/notarization/trade/icon_1_3.png" alt="">
          <p class="trade-adv-p1">翻译价格优势</p>
          <p class="trade-adv-p2">去使馆的文件，都是需要翻译的，我司目前合作的是江苏省贸促会的官方翻译机构，在保证文件质量的同时，价格比北京贸促会的翻译费要低很多</p>
        </div>
        <div class="trade-adv-inner">
          <img class="trade-adv-icon" src="../../assets/img/notarization/trade/icon_1_4.png" alt="">
          <p class="trade-adv-p1">内容优势</p>
          <p class="trade-adv-p2">客户要认证的文件，如果是客户自己公司翻译的，有译本相符的国家要求的，如俄罗斯，我们可以找翻译公司在客户翻译好的文件上加盖公章，进行认证</p>
        </div>
        <div class="trade-adv-inner">
          <img class="trade-adv-icon" src="../../assets/img/notarization/trade/icon_1_5.png" alt="">
          <p class="trade-adv-p1">经验丰富</p>
          <p class="trade-adv-p2">经常办理文件，各国使馆的案例较多，可以给客户提供的案例</p>
        </div>
        <div class="trade-adv-inner">
          <img class="trade-adv-icon" src="../../assets/img/notarization/trade/icon_1_6.png" alt="">
          <p class="trade-adv-p1">渠道多</p>
          <p class="trade-adv-p2">我司跟中国贸促会总会，有领区限制的，江苏省贸促会，浙江省贸促会，广东省贸促会都有合作，除了北京现场交文件，其他省份都接受我司邮寄办理，费用全部都是官费，没有代理在中间赚差价</p>
        </div>
        <div class="trade-adv-inner">
          <img class="trade-adv-icon" src="../../assets/img/notarization/trade/icon_1_7.png" alt="">
          <p class="trade-adv-p1">时间节点清晰</p>
          <p class="trade-adv-p2">中国贸促会总会受理的文件，可以随时给客户提供时间节点的查询服务，告诉客户那一天，文件进行到哪一步了，大概什么时候可以取到文件</p>
        </div>
        <div class="trade-adv-inner">
          <img class="trade-adv-icon" src="../../assets/img/notarization/trade/icon_1_8.png" alt="">
          <p class="trade-adv-p1">办理方便快速</p>
          <p class="trade-adv-p2">我司办公地址在北京的使馆区，有专人每天窗口送取文件</p>
        </div>
      </div>
    </div>

    
    
    <div class="box" style="height: 450px;background: #fff">
      <p class="box-p1">办理流程</p>
      <img class="cer-img4" style="height: 350px" src="../../assets/img/notarization/trade/pic_3.jpg" alt="">
    </div>
    <div class="box" style="height: 339px;background: #fff;margin-top: 10px;">
      <p class="box-p1">客户案例</p>
      <div class="lunbo-box cer-lunbo-box" style="margin-top: 20px">
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="rgba(47, 114, 235, 1)" >
          <van-swipe-item>
            <div class="cer-case-box">
              <img class="cer-case-img" src="../../assets/img/notarization/trade/pic_4_1.jpg" alt="">
              <img class="cer-case-img" src="../../assets/img/notarization/trade/pic_4_2.jpg" alt="">
            </div>
          </van-swipe-item>
          <van-swipe-item>
            <div class="cer-case-box">
              <img class="cer-case-img" src="../../assets/img/notarization/trade/pic_4_3.jpg" alt="">
              <img class="cer-case-img" src="../../assets/img/notarization/trade/pic_4_4.jpg" alt="">
            </div>
          </van-swipe-item>
        </van-swipe>
      </div>
    </div>
    <div class="box" style="height: 643px;background: #fff;margin-top: 10px;">
      <p class="box-p1">咨询服务</p>
      <p class="box-p2">我们还能为您做</p>
      <div class="ask-box">
        <p class="ask-p1">海牙认证</p>
        <p class="ask-p2">海牙公约组织成员国，目前成员国有102个。如德国、法国、韩国、美国、日本、澳大利亚、中国香港 及中国澳门都是公约成员国</p>
        <p class="ask-p3" @click="toAsk">咨询办理</p>
      </div>
      <div class="ask-box">
        <p class="ask-p1">国际公证认证</p>
        <p class="ask-p2">特聘具有国际公证员资格国际公证人，为香港文件、事实及法律文书等用于海外提供公证认证服务</p>
        <p class="ask-p3" @click="toAsk">咨询办理</p>
      </div>
      <div class="ask-box">
        <p class="ask-p1">使馆认证</p>
        <p class="ask-p2">使馆认证是指一国外交机构及其授权机构在涉外文书上确认所在国公证机关或某些特殊机关的印章或该机关主管人员的签字属实</p>
        <p class="ask-p3" @click="toAsk">咨询办理</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    msg: String
  },
  data(){
    return{
      form: {}
    }
  },
  mounted(){
    window.scrollTo(0,0);
  },
  methods: {
    toAsk(){
      window.open('https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn','_blank')
    },
    toSubmit(){
      this.$message({
          message: '提交成功！',
          type: 'success',
          offset: 100
        });
    }
  }
}
</script>

<style scoped lang="scss">
  .m-bvi-banner{
    width: 100%;
    height: 460px;
    background: url('../../assets/img/notarization/trade/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
</style>
