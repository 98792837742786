<template>
  <div class="home" style="background: rgba(242, 245, 250, 1);">
    <div class="join-banner">
      <p class="japan-visa-p1">日本经营管理签证</p>
      <p class="japan-visa-p2">身份事业双丰收 全家享国民待遇</p>
      <p class="japan-visa-p1" style="padding-top: 20px;">最快<span style="color: rgba(47, 114, 235, 1);">90天</span>获批</p>
      <p class="banner-btn banner-btn1" style="margin-top: 50px" @click="toAsk">立即办理</p>
    </div>
    <div class="box" style="background: #fff;">
      <p class="box-p1">日本经营管理签证</p>
      <p class="box-p2">【投资经营签证】是日本政府吸引海外投资专门开设的一类签证，属于日本工作签证的一种，2015年正式更名为“经营管理签证”。申请人需要在日本作为创业者、高管，经营一家日本公司，从而申请签证。获得该签证后申请人全家可以享受日本国民待遇，续签成功达到要求年限可申请日本国籍和永住。
      </p>
      <img class="japan-visa-img1" src="../assets/img/JapanVisa/pic_1.png" alt="">
    </div>
    <div class="box" >
      <p class="box-p1">日本投资经营签证90天快速获批</p>
      <div class="japan-condi">
        <p class="japan-visa2-p1">申请条件</p>
        <p class="japan-visa2-p2">主申请人：个人信息表、个人章及公证书、护照及身份证复印件；</p>
        <p class="japan-visa2-p2">在日本成立一家公司，日本公司注册金500万日元（约26万人民币）；</p>
        <p class="japan-visa2-p2">公司需要真实运营（后期可加盟、托管）且保持盈利状；</p>
        <div class="japan-con-2">
          <div class="japan-con-2-inner">
            <p class="japan-con-2-p"><span class="blue-style">签证类型：</span>经营管理签证</p>
            <p class="japan-con-2-p"><span class="blue-style">签证概念：</span>在日本开设公司，并进行经营</p>
            <p class="japan-con-2-p"><span class="blue-style">签证申请：</span>1对1量身定制资料</p>
            <p class="japan-con-2-p"><span class="blue-style">资金安全：</span>投资资金可支配</p>
            <p class="japan-con-2-p"><span class="blue-style">年龄要求：</span>20岁以上</p>
            <p class="japan-con-2-p"><span class="blue-style">办理周期：</span>大概3-6个月</p>
          </div>
        </div>
        <img class="japan-visa-img2" src="../assets/img/JapanVisa/pic_2.jpg" alt="">
        <p class="japan-visa2-p3">2013年4月</p>
        <p class="japan-visa2-p4">2005年，日本入国管理局（简称“入管局”）为欢迎在日本创业定居的人士设立了投资经营签证。</p>
        <p class="japan-visa2-p3">2014年6月</p>
        <p class="japan-visa2-p4">日本参院全体会议于2014年6月11日通过《入管法改正案》。</p>
        <p class="japan-visa2-p3">2015年7月</p>
        <p class="japan-visa2-p4">2015年4月1日，放宽外国人在日创业的条件限制。</p>
        <p class="japan-visa2-p3">2018年10月</p>
        <p class="japan-visa2-p4">2018年10月26日，日本首相安倍晋三结束了为期3天的对华访问，有意在2019年1月1日在放宽签证政策。</p>
      </div>
    </div>
    <div class="box" style="background: #fff;">
      <p class="box-p1">日本投资经营签证办理流程</p>
      <p class="box-p2">申请步骤共 <span style="color: rgba(47, 114, 235, 1);font-weight: bold;">8</span> 步，周期为 <span style="color: rgba(47, 114, 235, 1);font-weight: bold;">3-6</span> 个月</p>
      <div class="japan-visa-box3">
        <div class="japan-visa-box3-inner">
          <img class="japan-visa-box3-icon" src="../assets/img/JapanVisa/pic_3_1.png" alt="">
          <p class="japan-visa-box3-p1">第一步：签约</p>
          <p class="japan-visa-box3-p2">评估申请人的条件以及创业意向，符合条件即可签约</p>
        </div>
        <div class="japan-visa-box3-inner">
          <img class="japan-visa-box3-icon" src="../assets/img/JapanVisa/pic_3_2.png" alt="">
          <p class="japan-visa-box3-p1">第二步：评估定位</p>
          <p class="japan-visa-box3-p2">了解客户的情况，如客户的经商管理背景以及去日本的创业计划，资金来源的解释，帮客户做初步的创业规划</p>
        </div>
        <div class="japan-visa-box3-inner">
          <img class="japan-visa-box3-icon" src="../assets/img/JapanVisa/pic_3_3.png" alt="">
          <p class="japan-visa-box3-p1">第三步：商业计划书</p>
          <p class="japan-visa-box3-p2">撰写商业计划书和赴日意向申请书</p>
        </div>
        <div class="japan-visa-box3-inner">
          <img class="japan-visa-box3-icon" src="../assets/img/JapanVisa/pic_3_4.png" alt="">
          <p class="japan-visa-box3-p1">第四步：注册公司</p>
          <p class="japan-visa-box3-p2">客户需要先汇出注册金500万日元（约合26万人民币）至日本境内，快企务会协助客户注册公司，租赁办公室等</p>
        </div>
        <div class="japan-visa-box3-inner">
          <img class="japan-visa-box3-icon" src="../assets/img/JapanVisa/pic_3_5.png" alt="">
          <p class="japan-visa-box3-p1">第五步：递交申请</p>
          <p class="japan-visa-box3-p2">整理客户公司和背景材料递交日本入国管理局</p>
        </div>
        <div class="japan-visa-box3-inner">
          <img class="japan-visa-box3-icon" src="../assets/img/JapanVisa/pic_3_6.png" alt="">
          <p class="japan-visa-box3-p1">第六步：移民局审核</p>
          <p class="japan-visa-box3-p2">审核阶段一般是3个月左右，当然也要看客户的情况以及移民局审批量，快至1个月获批</p>
        </div>
        <div class="japan-visa-box3-inner">
          <img class="japan-visa-box3-icon" src="../assets/img/JapanVisa/pic_3_7.png" alt="">
          <p class="japan-visa-box3-p1">第七步：获批</p>
          <p class="japan-visa-box3-p2">申请成功，下发经营管理许可，即拿到批复函，凭借这个批复函可去日本驻中国大使馆贴签</p>
        </div>
        <div class="japan-visa-box3-inner">
          <img class="japan-visa-box3-icon" src="../assets/img/JapanVisa/pic_3_8.png" alt="">
          <p class="japan-visa-box3-p1">第八步：贴签</p>
          <p class="japan-visa-box3-p2">日本驻中国大使馆下发1年的经营管理签证（也是工作签证的一种）</p>
        </div>
      </div>
    </div>

    <div class="box">
      <p class="box-p1">日本经营管理签证种类</p>
      <div class="cer-lunbo-box" style="margin: 0 auto">
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white" :loop="true" >
          <van-swipe-item>
            <img class="cer-swiper-img" style="width: 345px;height: 160px;" src="../assets/img/JapanVisa/pic_4_1.jpg" alt="">
          </van-swipe-item>
          <van-swipe-item>
            <img class="cer-swiper-img" style="width: 345px;height: 160px;" src="../assets/img/JapanVisa/pic_4_2.jpg" alt="">
          </van-swipe-item>
        </van-swipe>
      </div>
    </div>

    <div class="box box-ce" style="height: 350px;">
      <p class="box-p1" style="color: #fff;">高度经营管理签证的评分规则及申请策略</p>
      <div class="cer-lunbo-box" style="margin: 0 auto;margin-top: 15px;">
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white" :loop="true" >
          <van-swipe-item>
            <img class="cer-swiper-img" style="width: 345px;height: 233px;" src="../assets/img/JapanVisa/pic_5_2.png" alt="">
          </van-swipe-item>
          <van-swipe-item>
            <img class="cer-swiper-img" style="width: 345px;height: 233px;" src="../assets/img/JapanVisa/pic_5_3.png" alt="">
          </van-swipe-item>
          <van-swipe-item>
            <img class="cer-swiper-img" style="width: 345px;height: 233px;" src="../assets/img/JapanVisa/pic_5_4.png" alt="">
          </van-swipe-item>
        </van-swipe>
      </div>
    </div>

    <div class="box" style="background: #fff;">
      <p class="box-p1">日本经营管理签证移民优势</p>
      <div class="odi-add" style="margin-top: 20px">
        <div class="odi-add-inner">
          <img class="odi-add-icon" src="../assets/img/JapanVisa/pic_6_1.png" alt="">
          <p class="odi-add-p" style="width: 128px;margin: 0 auto;margin-top: 30px;margin-bottom: 50px;font-size: 13px;">投资门槛低，无年龄、语言、学历及资产要求限制</p>
        </div>
        <div class="odi-add-inner">
          <img class="odi-add-icon" src="../assets/img/JapanVisa/pic_6_2.png" alt="">
          <p class="odi-add-p" style="width: 128px;margin: 0 auto;margin-top: 30px;margin-bottom: 50px;font-size: 13px;">审批速度快，一般6个月即可完成申请流程</p>
        </div>
        <div class="odi-add-inner">
          <img class="odi-add-icon" src="../assets/img/JapanVisa/pic_6_3.png" alt="">
          <p class="odi-add-p" style="width: 128px;margin: 0 auto;margin-top: 30px;margin-bottom: 50px;font-size: 13px;">投资成本低，且注册资金归属公司自由支配运作</p>
        </div>
        <div class="odi-add-inner">
          <img class="odi-add-icon" src="../assets/img/JapanVisa/pic_6_4.png" alt="">
          <p class="odi-add-p" style="width: 128px;margin: 0 auto;margin-top: 30px;margin-bottom: 50px;font-size: 13px;">距离中国最近，运营成本较低，方便开启国际市场业务</p>
        </div>
        <div class="odi-add-inner">
          <img class="odi-add-icon" src="../assets/img/JapanVisa/pic_6_5.png" alt="">
          <p class="odi-add-p" style="width: 128px;margin: 0 auto;margin-top: 30px;margin-bottom: 50px;font-size: 13px;">一人办理，全家受益。可为家人（配偶者、孩子）办理家族滞在，享受日本国民同等的教育、医疗等福利待遇</p>
        </div>
        <div class="odi-add-inner">
          <img class="odi-add-icon" src="../assets/img/JapanVisa/pic_6_6.png" alt="">
          <p class="odi-add-p" style="width: 128px;margin: 0 auto;margin-top: 30px;margin-bottom: 50px;font-size: 13px;">入籍后可获取护照快速畅达189个免签国家</p>
        </div>
      </div>
    </div>

    <div class="box">
      <p class="box-p1">留日的四个隐形福利</p>
      <div class="vie-adv-box" style="margin-top: 30px;">
        <div class="vie-adv-inner">
          <img class="vie-adv-img" src="../assets/img/JapanVisa/pic_7_1.png" alt="">
          <div>
            <div class="vie-adv-p1">良好的治安环境</div>
            <div class="vie-adv-p2">世界经合组织（OECD）曾经通过住房、收入、治安等11个方面，对36个发达国家和新兴市场国家进行过“国民幸福度”的调查，日本在治安上排在了全球第一。</div>
          </div>
        </div>
        <div class="vie-adv-inner">
          <img class="vie-adv-img" src="../assets/img/JapanVisa/pic_7_2.png" alt="">
          <div>
            <div class="vie-adv-p1">优渥的社会福利</div>
            <div class="vie-adv-p2">在日本生活，可以享受丰富的社会保险制度，无论生老病死都会有国家的支持，在这里，有伴随一生的各种补助。例如，生育补助、产假补助、育儿补助、单亲家庭补助、医疗补助等等。拿到经营管理签证的外国人及其配偶子女，享受的福利和日本当地国民是一样的。除政治权利之外，无差别对待，更不必等到拿到日本国籍或绿卡后才能享受。</div>
          </div>
        </div>
        <div class="vie-adv-inner">
          <img class="vie-adv-img" src="../assets/img/JapanVisa/pic_7_3.png" alt="">
          <div>
            <div class="vie-adv-p1">世界领先的医疗技术</div>
            <div class="vie-adv-p2">日本是世界第一的长寿之国。除了寿命长之外，在抗击癌症方面，也是世界第一，每年癌症死亡率低，5年生存率高达68%。世界卫生组织（WHO）在2016年的《World Health Report》中，在“医疗水平”、“接受医疗服务的难度”和“医药费负担公平性”等方面，对世界各国的医疗体系进行了综合比较，日本因为“高品质的医疗服务”和“医疗负担的平等程度”等原因，在此排行榜中再次获得第一。根据世界卫生组织2018年的全球医疗评估报告，日本再次蝉联第一。</div>
          </div>
        </div>
        <div class="vie-adv-inner">
          <img class="vie-adv-img" src="../assets/img/JapanVisa/pic_7_4.png" alt="">
          <div>
            <div class="vie-adv-p1">优良的教育环境</div>
            <div class="vie-adv-p2">重视学前教育，师资团队均衡，无学区房概念，入学简单。日本的教育质量在整个亚洲排名第一，不仅将教育放在基础性的重要地位，更视教育为引导经济走向强盛的根本。进入21世纪以来，日本在18年内拿到了18个诺贝尔学奖，足以证明日本的教育水平之高。日本政府在教育上的投入也相对较大，教育开支占GDP的4.1%。日本的大学例如东京大学、京都大学等在世界上也排名前列。</div>
          </div>
        </div>
      </div>
    </div>

    <div class="box" style="background: #fff;">
      <p class="box-p1">常见问题</p>
      <div class="question">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item title="外国人在日本设立公司及开设银行账户的具体条件和必要资料 ?" name="1">
            <p class="question-p">设立公司时的资本金必须是500万日元以上才可以申请投资类的签证，其具体资料：如果是申请投资经营签证，只需要
私章公证书两份；如果是申请高级经营管理人才签证，还需要中国公司的营业执照及公章的公证书各两份。
在日本开设银行账号的条件是：开设个人账户时，申请人必须拥有一年以上的居留资格；开设法人账户时，公司的董事
当中必须有一位拥有日本的居留资格。开设个人账户时，只需要准备护照及居留卡即可；开设法人账户时，需要准备公
司的营业执照，公司公章证明书等文件，根据开户行的要求，还有可能需要提交公司介绍等其他文件。</p>
          </el-collapse-item>
          <el-collapse-item title="公司经营限制，纳税种类及税率?" name="2">
            <p class="question-p">在日本几乎没有经营眼制，在营业范围里写什么都可以，只是国家规定需要许可证的，需要申请许可证方可以经营。税
种主要有法人税，每年7万日元左右，以后根据营业额的增加，有可能增长。
一般来说综合税率为10-40%不等，提供税务师节税，可以控制在10%左右，还可以用减免30%的交际费等。</p>
          </el-collapse-item>
          <el-collapse-item title="每年申请人在日本生活的费用？" name="3">
            <p class="question-p">因人而异，一般除了房租以外，一家三口一个月的生活费10-15万日元应该够了。</p>
          </el-collapse-item>
          <el-collapse-item title="日本移民项目的合法性与安全性?" name="4">
            <p class="question-p">首先，日本移民项目完全合法。日本是个岛国，担心外来人口大量涌入，所以没有设置移民法。但是，它的出入国管理
法里设置的投资经营签证及高级经营管理人才签证，可以从有期限的居留资格申请日本国籍，或者永久居留，实质上是
一种投资移民。
至于安全性，由于这是一种为外国人在日本经商的居留资格，只要做出一定的营业额，续签是不成问题的。如果会日语
的话，可以做任何生意；即便是不会日语，通过投资日本的房产也能取得并维持其居留资格。只是需要注意的是，首先
要购买能够产出利益的，带租约的房产，而且至少创造300万日元/年的房产，以确保顺利续签。</p>
          </el-collapse-item>
          <el-collapse-item title="日本移民项目的总体成本是否能够通过投资支撑？" name="5">
            <p class="question-p">通常申请人的日本公司需要做到每年500万日元-26万元人民币以上的营业额。如果单纯通过投资房产来达到这个营业
额，通常需要投资5000万日元一300万人民币左右的房产。而维护一家公司每年需要的资金在5-8万元。也就是说，假
如申请人有能力投资300万元人民币的房产，每年产出10万人民币的收入，创除8万人民币的成本，剩余的10多万元足
够在日本生活。因此可以说，这个项目的收入足以支撑其成本。</p>
          </el-collapse-item>
          <el-collapse-item title="申请人获得永居之后可能会关闭其公司，在关闭后是否还能得到失业金和养老金？" name="6">
            <p class="question-p">申请人一家在获得永居后，通常我们会建议他们将公司休眠，以避免不必要的税费。但是失业金就没有了，养老金可以
转加入国民年金系统，将来还是可以取得的。</p>
          </el-collapse-item>
          <el-collapse-item title="移民日本后可能得到的利益？" name="7">
            <p class="question-p">取得日本的居留资格后，可以完全享受与几乎与日本人同样的福利待遇。日本的医疗福利如问题5所述；另外孩子可以
享受免费的义务教育，日本政府宣布2020年开始实现教育免费化。如果加入日本的年金体系，退休后可以享受日本的
养老金。</p>
          </el-collapse-item>
        </el-collapse>
      </div>
      <p class="to-ask" @click="toAsk"> 
        <img class="to-ask-icon" src="../assets/img/register/bvi/icon_6_customer_service.png" alt=""> 
        更多问题？立即咨询人工客服</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  data(){
    return{
      activeName: '1',
      Dialog: false,
      tab: 1
    }
  },
 
  mounted(){
    // window.scrollTo(0,0);
  },
  methods:{
    toAsk(){
      window.open('https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn','_blank')
    },
    toUrl(url){
      this.$router.push({name: url})
    }
  }
}
</script>
<style scoped>
  .join-banner{
    background: url('../assets/img/JapanVisa/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
  .box-ce{
    background: url('../assets/img/JapanVisa/pic_5_1.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
</style>
