<template>
  <div class="home">
    <div class="m-bvi-banner">
      <!-- <p></p> -->
      <p class="banner-p1">新加坡公司注册</p>
      <p class="banner-p2">国际金融优势  外汇自由 贸易地缘优势 开户简单</p>
      <p class="banner-btn banner-btn1" @click="toAsk">立即咨询</p>
    </div>
    <div class="box" style="height: 228px;">
      <p class="box-p1">选择注册新加坡公司的好处</p>
      <div class="sin-good">
        <div class="sin-good-inner">
          <img class="sin-good-icon" src="../../assets/img/register/Singapore/pic_1_1.png" alt="">
          <p class="sin-good-p1">国际金融中心</p>
          <p class="sin-good-p2">外汇自由，贸易自由</p>
        </div>
        <div class="sin-good-inner">
          <img class="sin-good-icon" src="../../assets/img/register/Singapore/pic_1_2.png" alt="">
          <p class="sin-good-p1">贸易群体东南亚</p>
          <p class="sin-good-p2">地缘优势，同属欧盟</p>
        </div>
        <div class="sin-good-inner">
          <img class="sin-good-icon" src="../../assets/img/register/Singapore/pic_1_3.png" alt="">
          <p class="sin-good-p1">开户问题</p>
          <p class="sin-good-p2">新加坡开户简单，无烦恼</p>
        </div>
      </div>
    </div>
    <div class="box bg-box" style="height: 513px;">
      <p class="box-p1">选择注册新加坡公司的类型</p>
      <p class="box-p2"><span style="color: rgba(47, 114, 235, 1);font-weight: bold;">70%</span> 的客户选择公正公司（基金会）</p>
      <!-- <div class="">
        <img class="sin-type-img" src="../../assets/img/register/Singapore/pic_2_1.jpg" alt="">
      </div> -->
      <div class="lunbo-box">
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white" :width="375" :loop="true">
          <van-swipe-item>
            <img class="sin-type-img" src="../../assets/img/register/Singapore/pic_2_1.jpg" alt="">
          </van-swipe-item>
          <van-swipe-item>
            <img class="sin-type-img" src="../../assets/img/register/Singapore/pic_2_2.png" alt="">
          </van-swipe-item>
        </van-swipe>
      </div>
    </div>

    <div class="box" style="height: 378px;">
      <p class="box-p1">注册新加坡公司优享服务</p>
      <p class="box-p2">为您提供从企业初期到后期的全流程服务</p>
      <div class="sin-good">
        <div class="sin-good-inner">
          <img class="sin-good-icon" src="../../assets/img/register/Singapore/pic_3_1.png" alt="">
          <p class="sin-good-p1">注册地址</p>
          <p class="sin-good-p2">提供公司注册地址</p>
        </div>
        <div class="sin-good-inner">
          <img class="sin-good-icon" src="../../assets/img/register/Singapore/pic_3_2.png" alt="">
          <p class="sin-good-p1">新加坡董事</p>
          <p class="sin-good-p2">提供新加坡董事</p>
        </div>
        <div class="sin-good-inner">
          <img class="sin-good-icon" src="../../assets/img/register/Singapore/pic_3_3.png" alt="">
          <p class="sin-good-p1">秘书服务</p>
          <p class="sin-good-p2">年审/报税</p>
        </div>
        <div class="sin-good-inner">
          <img class="sin-good-icon" src="../../assets/img/register/Singapore/pic_3_4.png" alt="">
          <p class="sin-good-p1">协助开户</p>
          <p class="sin-good-p2">直接当地开户</p>
        </div>
        <div class="sin-good-inner">
          <img class="sin-good-icon" src="../../assets/img/register/Singapore/pic_3_5.png" alt="">
          <p class="sin-good-p1">会计师协助</p>
          <p class="sin-good-p2">提供新加坡董事</p>
        </div>
      </div>
    </div>

    <div class="box bg-box" style="height: 198px; background: rgba(242, 245, 250, 1);;">
      <p class="box-p1">注册新加坡公司流程</p>
      <div class="" style="margin: 20px;">
        <img class="sin-type-img" style="height: 103px;" src="../../assets/img/register/Singapore/pic_4.png" alt="">
      </div>
    </div>


    <div class="box" style="height: auto;padding-bottom: 30px;">
      <p class="box-p1">新加坡公司更多服务</p>
      <p class="box-p2">秘书服务</p>
      <div class="type-content flex center-width">
        <img class="type-img" style="height: 124px;" src="../../assets/img/register/Singapore/pic_5_1.jpg" alt="">
        <img class="type-img" style="height: 124px;" src="../../assets/img/register/Singapore/pic_5_2.jpg" alt="">
        <img class="type-img" style="height: 124px;" src="../../assets/img/register/Singapore/pic_5_3.jpg" alt="">
        <div class="type-more-box">
          <p class="type-more-p">更多秘书服务 > </p>
        </div>
      </div>
      <p class="box-p2">银行开户</p>
      <div class="type-content flex center-width">
        <img class="type-img" style="height: 124px;" src="../../assets/img/register/Singapore/pic_6_1.jpg" alt="">
        <img class="type-img" style="height: 124px;" src="../../assets/img/register/Singapore/pic_6_2.jpg" alt="">
        <img class="type-img" style="height: 124px;" src="../../assets/img/register/Singapore/pic_6_3.jpg" alt="">
        <div class="type-more-box">
          <p class="type-more-p">更多银行开户 > </p>
        </div>
      </div>
    </div>
    
    <div class="box" style="height: auto;padding-bottom: 30px;background: rgba(242, 245, 250, 1)">
      <p class="box-p1">新加坡公司注册常见问题</p>
      <div class="question" style="margin-top: 20px">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item title="为什么注册新加坡公司?" name="1">
            <p class="question-p">1、国际总部优势：新加坡向来是美国、欧洲与日本公司设立亚洲总部的首选地点；
2、金融中心优势：新加坡不仅在国际金融、贸易融资、海事金融、保险、财务运作方面拥有领先地位；
3、研发中心优势：新加坡已建立了健全的研发(R&D)生态系统和完善的知识产权(IP)保障制度；
4、国际品牌和知识产权保护优势：新加坡是各大知识产权国际公约与条约如专利合作与贸易有关的知识产权协议</p>
          </el-collapse-item>
          <el-collapse-item title="新加坡如何征收消费税?" name="2">
            <p class="question-p">1、应该纳税的商品和服务营业额在新加坡超过一百新币。
2、应该纳税的商品和服务的营业额很可能会超过一百万新币。每季度申报一次，季度结束后一个月内完成申报(如果
没有及时申报，就会逐月产生200新币的罚款，最高可累计达到1万元新币的罚款)。</p>
          </el-collapse-item>
          <el-collapse-item title="新加坡公司注册后投资海外其他国家，是否需要公证?" name="3">
            <p class="question-p">1、注册新加坡公司投资海外，需对新加坡的主体资格进行公证。
2、需要公证的主要文件包括:
A. Bizfie(表格式文件，记录了股东、董事、登记地址、注册资本等纽节)
B. CI(开立证书，相当于中国企业营业执照)
C. 会议纪要等(针对海外投资的高级决议)</p>
          </el-collapse-item>
          <el-collapse-item title="注册新加坡公司的要求？" name="4">
            <p class="question-p">1、公司名称:新加坡公司的名称必须是英文名，以XXX PTE.TLD.结尾；
2、注册地址:必须提供一个新加坡本地的注册地址；
3、营业范围:新加坡公司最多可以选择2个营业项目；
4、注册资本:注册资本最低从1元新币起，最高没有上限，无需验资；
5、公司董事:至少包含一名18岁以上的本地董事，如需银行开户，建议似定两位董事；
6、股东资格:股东组成无任何限制，可以是白然人，也可以是公司；
7、法定秘书:新加坡公司必须委托法定秘书；
8、提供材料:提供董事、股东相关材料以及股权分配比例说明；</p>
          </el-collapse-item>
        </el-collapse>
      </div>
      <p class="to-ask" @click="toAsk"> 
        <img class="to-ask-icon" src="../../assets/img/register/bvi/icon_6_customer_service.png" alt=""> 
        更多问题？立即咨询人工客服</p>
    </div>
    <div class="box" style="height: 480px;">
      <p class="box-p1">快企务的优势</p>
      <p class="box-p2">十余年注册新加坡公司经验</p>
      <div class="re-adv">
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_1.png" alt="">
          <p class="re-adv-p1">保密完善</p>
          <p class="re-adv-p2">保密系统完善，客户隐私不外泄</p>
        </div>
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_2.png" alt="">
          <p class="re-adv-p1">全程服务</p>
          <p class="re-adv-p2">一站式专业服务从注册公司到年审、开户，审计报税等</p>
        </div>
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_3.png" alt="">
          <p class="re-adv-p1">专业高效</p>
          <p class="re-adv-p2">根据需求，为客户制定专业快捷的解决方案</p>
        </div>
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_4.png" alt="">
          <p class="re-adv-p1">合同保障</p>
          <p class="re-adv-p2">正规机构，合同保障，费用透明，无隐形消费</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  data(){
    return{
      tab: 1,
      activeName: '1'
    }
  },
  mounted(){
    window.scrollTo(0,0);
  },
  methods:{
    toAsk(){
      window.open('https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn','_blank')
    },
  }
}
</script>
<style scoped>
  .m-bvi-banner{
    width: 100%;
    height: 460px;
    background: url('../../assets/img/register/Singapore/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
</style>
