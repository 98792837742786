<template>
  <div class="certification m-bvi">
    <div class="m-bvi-banner">
      <p class="f1-banner-p1">十余年专业财税筹划</p>
      <p class="f1-banner-p2">香港专业会计师团队<span style="color: rgba(47, 114, 235, 1)">1对1</span> 为您服务</p>
      <p class="f1-banner-p3">正规审计报告 解决税务难题</p>
      <p class="banner-btn banner-btn1" @click="toAsk">立即咨询</p>
    </div>
    <div class="box">
      <p class="box-p1" style="width: 100vw;">在香港成立公司不做账报税有什么影响</p>
      <p class="box-p2">情节严重最高监禁6个月</p>
      <div class="finance-box">
        <div class="finance-inner">
          <img class="finance-box-img" src="../../assets/img/finance/HongKong/icon_1_1.png" alt="">
          <p class="finance-box-p">一次超期罚款1200</p>
        </div>
        <div class="finance-inner">
          <img class="finance-box-img" src="../../assets/img/finance/HongKong/icon_1_2.png" alt="">
          <p class="finance-box-p">二次超期罚款3000起</p>
        </div>
        <div class="finance-inner">
          <img class="finance-box-img" src="../../assets/img/finance/HongKong/icon_1_3.png" alt="">
          <p class="finance-box-p">收到法院传票</p>
        </div>
        <div class="finance-inner">
          <img class="finance-box-img" src="../../assets/img/finance/HongKong/icon_1_4.png" alt="">
          <p class="finance-box-p">公司被强制注销</p>
        </div>
        <div class="finance-inner">
          <img class="finance-box-img" src="../../assets/img/finance/HongKong/icon_1_5.png" alt="">
          <p class="finance-box-p">董事会列入黑名单最高监禁6个月</p>
        </div>
        <div class="finance-inner">
          <img class="finance-box-img" src="../../assets/img/finance/HongKong/icon_1_6.png" alt="">
          <p class="finance-box-p">银行账户强制扣税款/冻结</p>
        </div>
      </div>
    </div>
    <div class="box" style="background: rgba(242, 245, 250, 1);">
      <p class="box-p1">哪些香港公司必须做账审计</p>
      <p class="box-p2" style="margin-bottom: 0;">只要您以公司名义进行商业上的交易或活动， 您已经开始经营业务了。可以从以下几个方面来判断是否已经营业务，符合其中一方面的即为有经营业务,只要以公司名义进行商业上的交易或活动，贵司就需要做账审计</p>
      <img class="hk-img1" src="../../assets/img/finance/HongKong/pic_1.jpg" alt="">
      <p class="hk-p1">银行账户已经留下经营记录</p>
      <p class="hk-p1">政府海关、物流公司已经留下进出口记录</p>
      <p class="hk-p1">与香港客商发生购销头条</p>
      <p class="hk-p1">在香港已经聘请雇员</p>
      <p class="hk-p1">容许或授权在香港使用专利商标设计等资料</p>
      <p class="hk-p1">容许或授权在香港使用动产收取租金，租赁费等款项</p>
      <p class="hk-p1">委托在香港代销</p>
      <p class="hk-p1">其它得自或产生于香港的利润</p>
      <p class="form-submit" style="width: 96px;margin-top: 30px;margin-left: 5%;">立即咨询</p>
    </div>
    <div class="box hk-form-bg">
      <p class="box-p1" style="color: rgba(255, 255, 255, 1)">香港公司报税费用</p>
      <p class="box-p2" style="color: rgba(214, 231, 255, 1);">请填写以下表单留下您的信息，我们将第一时间联系您，谢谢！</p>
      <div class="form-box">
        <div class="form-line" style="background: rgba(255, 255, 255, 0.1);border: none;">
          <p class="form-label" style="background: rgba(36, 94, 201, 1);">姓名</p>
          <input class="form-input form-input-bg" type="text" placeholder="请输入您的姓名" style="color: #fff;" v-model="form.name">
        </div>
        <div class="form-line" style="background: rgba(255, 255, 255, 0.1);border: none;">
          <p class="form-label" style="background: rgba(36, 94, 201, 1);">电话</p>
          <input class="form-input form-input-bg" type="text" placeholder="请输入您的联系方式" style="color: #fff;" v-model="form.mobile">
        </div>
        <div class="form-line" style="background: rgba(255, 255, 255, 0.1);border: none;">
          <p class="form-label" style="background: rgba(36, 94, 201, 1);">您的需求</p>
          <input class="form-input form-input-bg" type="text" placeholder="请输入您的需求" style="color: #fff;" v-model="form.remark">
        </div>
      </div>
      <p class="form-submit" @click="toSubmit" style="background: rgba(255, 255, 255, 0.2);border: 1px solid rgba(255, 255, 255, 1);  ">提交</p>
    </div>
    <div class="box">
      <p class="box-p1">如何规避香港银行调查和CRS税务信息影响</p>
      <div class="hk-box2">
        <div class="hk-box2-inner hk-box2-inner-bg1">
          <p class="hk-box2-p">选择在香港进行税务申报的优势</p>
        </div>
        <div class="hk-box2-inner hk-box2-inner-bg2">
          <p class="hk-box2-p">选择正规的香港会计师行做账审计</p>
        </div>
        <div class="hk-box2-inner hk-box2-inner-bg3">
          <p class="hk-box2-p">出具正规的审计报告让税务局无处挑剔</p>
        </div>
      </div>
    </div>

    <div class="box" style="height: auto;padding-bottom: 30px;background: rgba(242, 245, 250, 1)">
      <p class="box-p1">财税筹划专业指导</p>
      <p class="box-p2">财税筹划中常见的专业问题</p>
      <div class="question">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item title="香港公司什么情况下无需纳税？" name="1">
            <p class="question-p">香港公司是根据利润来源地原则征收税收，如果利润不来源于香港，可以不用交税。香港税务局一般都要求纳税人提供足够之有关文件以证明上述问题之真实性，以确定纳税人所得利润之来源</p>
          </el-collapse-item>
          <el-collapse-item title="香港公司成立后何时需要报税？" name="2">
            <p class="question-p">每一家香港公司约成立18个月后，会收到香港税务局发出的利得税报税表，香港税务局发放税表的时间是每年的四月份，此税表不是每家公司每年都有，是税务局以抽查的形式下发</p>
          </el-collapse-item>
          <el-collapse-item title="香港税务申报是否意味着缴纳大量的税金？" name="3">
            <p class="question-p">如您收到香港税表，必须交给香港会计师，根据公司银行资金往来情况评估如何报税， CRS新政策下，凡有香港银行账户企业，简单零申报将面临巨大的风险:
申报是法律规定的义务，收到税表就必须申报，否则将会收到法院传票和被冻结账户。
缴税多少是由会计师根据公司情况决定，盈利额做多少，或者直接做亏损</p>
          </el-collapse-item>
          <el-collapse-item title="香港公司怎么申请延期纳税？" name="4">
            <p class="question-p">在香港设立的公司可依据自己的结账日期，申请延期提交报税表，一般可通过以下两种方式进行:
申请整批延期，如果香港公司业务每年结账日在11月30日后，而且税务代表在整批延期计划下，那么在办妥相关的延交手续后，提交报税表的期限可按结账日期的不同类别延至不同的时间。
自行书面申请，除整批延期计划以外，也可以自行用书面方式申请延交。在申请时需说明未能及时提交的原因，并附上所有的证明文件</p>
          </el-collapse-item>
        </el-collapse>
      </div>
      <p class="to-ask" @click="toAsk"> 
        <img class="to-ask-icon" src="../../assets/img/register/bvi/icon_6_customer_service.png" alt=""> 
        更多问题？立即咨询人工客服</p>
    </div>
    <div class="box">
      <p class="box-p1">记帐/核数/报税流程</p>
      <p class="box-p2">全程跟进</p>
      <img class="hk-box5-img" src="../../assets/img/finance/HongKong/pic_4_1.jpg" alt="">
      <div class="trademark-flow">
        <p class="trademark-flow-p1" style="margin-left: 0;margin-bottom: 10px;">
          <img class="trademark-flow-icon" src="../../assets/img/finance/innerTrademark/pic_2_1.png" alt="">
          <span class="trademark-flow-span1">第一步：</span>
          <span class="trademark-flow-span2">评估、报价、商洽后做报账协议</span> 
        </p>
        <p class="trademark-flow-p1" style="margin-left: 0;margin-bottom: 10px;">
          <img class="trademark-flow-icon" src="../../assets/img/finance/innerTrademark/pic_2_2.png" alt="">
          <span class="trademark-flow-span1">第二步：</span>
          <span class="trademark-flow-span2">预交款项</span> 
        </p>
        <p class="trademark-flow-p1" style="margin-left: 0;margin-bottom: 10px;">
          <img class="trademark-flow-icon" src="../../assets/img/finance/innerTrademark/pic_2_3.png" alt="">
          <span class="trademark-flow-span1">第三步：</span>
          <span class="trademark-flow-span2">整理单据税务处理</span> 
        </p>
        <p class="trademark-flow-p1" style="margin-left: 0;margin-bottom: 10px;">
          <img class="trademark-flow-icon" src="../../assets/img/finance/innerTrademark/pic_2_4.png" alt="">
          <span class="trademark-flow-span1">第四步：</span>
          <span class="trademark-flow-span2">做账完毕</span> 
        </p>
        <p class="trademark-flow-p1" style="margin-left: 0;margin-bottom: 10px;">
          <img class="trademark-flow-icon" src="../../assets/img/finance/innerTrademark/pic_2_5.png" alt="">
          <span class="trademark-flow-span1">第五步：</span>
          <span class="trademark-flow-span2">付尾款</span> 
        </p>
        <p class="trademark-flow-p1" style="margin-left: 0;margin-bottom: 10px;">
          <img class="trademark-flow-icon" src="../../assets/img/finance/innerTrademark/pic_2_6.png" alt="">
          <span class="trademark-flow-span1">第六步：</span>
          <span class="trademark-flow-span2">核数师审计</span> 
        </p>
        <p class="trademark-flow-p1" style="margin-left: 0;margin-bottom: 10px;">
          <img class="trademark-flow-icon" src="../../assets/img/finance/innerTrademark/pic_2_7.png" alt="">
          <span class="trademark-flow-span1">第七步：</span>
          <span class="trademark-flow-span2">审计完成</span> 
        </p>
        <p class="trademark-flow-p1" style="margin-left: 0;margin-bottom: 10px;">
          <img class="trademark-flow-icon" src="../../assets/img/finance/innerTrademark/pic_2_8.png" alt="">
          <span class="trademark-flow-span1">第八步：</span>
          <span class="trademark-flow-span2">股东签署审计报告</span> 
        </p>
        <p class="trademark-flow-p1" style="margin-left: 0;margin-bottom: 10px;">
          <img class="trademark-flow-icon" src="../../assets/img/finance/HongKong/pic_5_9.png" alt="">
          <span class="trademark-flow-span1">第九步：</span>
          <span class="trademark-flow-span2">会计师持审计报告证报税</span> 
        </p>
        <p class="trademark-flow-p1" style="margin-left: 0;margin-bottom: 10px;">
          <img class="trademark-flow-icon" src="../../assets/img/finance/HongKong/pic_5_10.png" alt="">
          <span class="trademark-flow-span1">第十步：</span>
          <span class="trademark-flow-span2">相关文件返还客户</span> 
        </p>
      </div>
    </div>

    <div class="box" style="background: rgba(242, 245, 250, 1);">
      <p class="box-p1">一站式专业审计服务</p>
      <p class="box-p2">我们为您提供</p>
      <div class="hk-box3">
        <div class="hk-box3-inner box3-bg1">
          <p class="hk-box3-inner-p">做账报税</p>
        </div>
        <div class="hk-box3-inner box3-bg2">
          <p class="hk-box3-inner-p">税务筹划</p>
        </div>
        <div class="hk-box3-inner box3-bg3">
          <p class="hk-box3-inner-p">审计报告</p>
        </div>
        <div class="hk-box3-inner box3-bg4">
          <p class="hk-box3-inner-p">税务指南</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'bvi-page',
  props: {
    msg: String
  },
  data(){
    return{
      form: {},
      activeName: '1'
    }
  },
  mounted(){
    window.scrollTo(0,0);
  },
  methods:{
    toAsk(){
      window.open('https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn','_blank')
    },
    toSubmit(){
      this.$message({
          message: '提交成功！',
          type: 'success',
          offset: 100
        });
    }
  }
}
</script>

<style scoped lang="scss">
  .m-bvi-banner{
    width: 100%;
    height: 460px;
    background: url('../../assets/img/finance/HongKong/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
  .hk-form-bg{
    background: url('../../assets/img/finance/HongKong/pic_2.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
  .hk-box2-inner-bg1{
    background: url('../../assets/img/finance/HongKong/pic_3_1.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
  .hk-box2-inner-bg2{
    background: url('../../assets/img/finance/HongKong/pic_3_2.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
  .hk-box2-inner-bg3{
    background: url('../../assets/img/finance/HongKong/pic_3_3.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
  .box3-bg1{
    background: url('../../assets/img/finance/HongKong/pic_6_1.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
  .box3-bg2{
    background: url('../../assets/img/finance/HongKong/pic_6_2.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
  .box3-bg3{
    background: url('../../assets/img/finance/HongKong/pic_6_3.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
  .box3-bg4{
    background: url('../../assets/img/finance/HongKong/pic_6_4.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
</style>
