<template>
  <div class="home">
    <div class="m-bvi-banner">
      <p class="banner-p1">一站式BVI公司注册服务</p>
      <p class="banner-p2">快企务专注于涉内外企业服务十余年</p>
      <p class="banner-btn banner-btn1" @click="toAsk">立即咨询</p>
    </div>
    <div class="box" style="height: 473px;background: rgba(242, 245, 250, 1);">
      <p class="box-p1">注册BVI公司的优势</p>
      <div class="adv-content">
        <div class="adv-line">
          <img class="adv-icon" src="../../assets/img/register/bvi/icon_1_1.png" alt="">
          <p class="adv-p">公司不受股东人数限制</p>
        </div>
        <div class="adv-line">
          <img class="adv-icon" src="../../assets/img/register/bvi/icon_1_2.png" alt="">
          <p class="adv-p">政治、经济和贸易环境稳定</p>
        </div>
        <div class="adv-line">
          <img class="adv-icon" src="../../assets/img/register/bvi/icon_1_3.png" alt="">
          <p class="adv-p">不在岸经营可不交当任何税</p>
        </div>
        <div class="adv-line">
          <img class="adv-icon" src="../../assets/img/register/bvi/icon_1_4.png" alt="">
          <p class="adv-p">世界各地均可开立银行账户</p>
        </div>
        <div class="adv-line">
          <img class="adv-icon" src="../../assets/img/register/bvi/icon_1_5.png" alt="">
          <p class="adv-p">董事资料保密，金融法律良好</p>
        </div>
      </div>
    </div>
    <div class="box" style="height: auto;padding-bottom: 30px;">
      <p class="box-p1">注册BVI公司的类型</p>
      <div class="type-content flex center-width">
        <img class="type-img" src="../../assets/img/register/bvi/pic_1_1.jpg" alt="">
        <img class="type-img" src="../../assets/img/register/bvi/pic_1_2.jpg" alt="">
      </div>
    </div>
    <div class="box bg-box" style="height: auto;padding-bottom: 15px;">
      <p class="box-p1">注册BVI公司条件/流程/所得资料</p>
      <div class="tab-box">
        <div class="tab-inner" :class="{'active': tab == 1}" @click="tab = 1">注册条件</div>
        <div class="tab-inner" :class="{'active': tab == 2}" @click="tab = 2">注册流程</div>
        <div class="tab-inner" :class="{'active': tab == 3}" @click="tab = 3">所得资料</div>
      </div>
      <div class="file-content flex center-width">
        <div v-if="tab == 1">
          <div class="tab1-inner">
            <img class="tab1-icon" src="../../assets/img/register/bvi/icon_2_1.png" alt="">
            <div class="tab1-right">
              <p class="tab1-p1">名称</p>
              <p class="tab1-p2">公司名称必须以“有限公司”结尾，如：LIMITED、CORPORATION、
INCORPORATED或缩写。公司名称不能以“信托公司”、“银行”
或其他被认定为意思相似的词结尾，除非这些公司在英属京群岛（bvi）获得相应的执照</p>
            </div>
          </div>
          <div class="tab1-inner">
            <img class="tab1-icon" src="../../assets/img/register/bvi/icon_2_2.png" alt="">
            <div class="tab1-right">
              <p class="tab1-p1">董事</p>
              <p class="tab1-p2">一个或多个董事，可以是法人实体或者任何国籍的自然人</p>
            </div>
          </div>
          <div class="tab1-inner">
            <img class="tab1-icon" src="../../assets/img/register/bvi/icon_2_3.png" alt="">
            <div class="tab1-right">
              <p class="tab1-p1">地址</p>
              <p class="tab1-p2">公司注册地址必须在英属维京群岛，快企务可提供</p>
            </div>
          </div>
          <div class="tab1-inner">
            <img class="tab1-icon" src="../../assets/img/register/bvi/icon_2_4.png" alt="">
            <div class="tab1-right">
              <p class="tab1-p1">注册资金</p>
              <p class="tab1-p2">BVI公司标准注册资金为50000美元（资金无需验资到位），分为50,000股，每股为1美元；如资金超过50,000美元，政府需收取1350美元印花税</p>
            </div>
          </div>
          <div class="tab1-inner">
            <img class="tab1-icon" src="../../assets/img/register/bvi/icon_2_5.png" alt="">
            <div class="tab1-right">
              <p class="tab1-p1">董事会议</p>
              <p class="tab1-p2">董事可在任何一个国家举行会议，或通过代理参加这样的会议</p>
            </div>
          </div>
          <div class="tab1-inner">
            <img class="tab1-icon" src="../../assets/img/register/bvi/icon_2_6.png" alt="">
            <div class="tab1-right">
              <p class="tab1-p1">公司印鉴</p>
              <p class="tab1-p2">英属维京群岛公司必须有自己的公司印鉴，并且公司印鉴的式样需上报公司注册署</p>
            </div>
          </div>
        </div>
        <div v-if="tab == 2">
          <img class="tab2-img" src="../../assets/img/register/bvi/pic_2.png" alt="">
        </div>
        <div v-if="tab == 3">
          <div class="tab3-box">
            <div class="tab3-inner">
              <img class="tab3-icon" src="../../assets/img/register/bvi/icon_3_1.png" alt="">
              <p class="tab3-p">注册证书</p>
            </div>
            <div class="tab3-inner">
              <img class="tab3-icon" src="../../assets/img/register/bvi/icon_3_2.png" alt="">
              <p class="tab3-p">公司章程</p>
            </div>
            <div class="tab3-inner">
              <img class="tab3-icon" src="../../assets/img/register/bvi/icon_3_3.png" alt="">
              <p class="tab3-p">代理人证书</p>
            </div>
            <div class="tab3-inner">
              <img class="tab3-icon" src="../../assets/img/register/bvi/icon_3_4.png" alt="">
              <p class="tab3-p">董事会议记录</p>
            </div>
            <div class="tab3-inner">
              <img class="tab3-icon" src="../../assets/img/register/bvi/icon_3_5.png" alt="">
              <p class="tab3-p">董事委托书</p>
            </div>
            <div class="tab3-inner">
              <img class="tab3-icon" src="../../assets/img/register/bvi/icon_3_6.png" alt="">
              <p class="tab3-p">股东名册</p>
            </div>
            <div class="tab3-inner">
              <img class="tab3-icon" src="../../assets/img/register/bvi/icon_3_7.png" alt="">
              <p class="tab3-p">同意出任董事、股东书</p>
            </div>
            <div class="tab3-inner">
              <img class="tab3-icon" src="../../assets/img/register/bvi/icon_3_8.png" alt="">
              <p class="tab3-p">董事名册</p>
            </div>
            <div class="tab3-inner">
              <img class="tab3-icon" src="../../assets/img/register/bvi/icon_3_9.png" alt="">
              <p class="tab3-p">股票本</p>
            </div>
            <div class="tab3-inner">
              <img class="tab3-icon" src="../../assets/img/register/bvi/icon_3_10.png" alt="">
              <p class="tab3-p">公司印章</p>
            </div>
            <div class="tab3-inner">
              <img class="tab3-icon" src="../../assets/img/register/bvi/icon_3_11.png" alt="">
              <p class="tab3-p">文件盒子</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="box" style="height: 669px;">
      <p class="box-p1">BVI公司注册后续服务</p>
      <div class="re-serve">
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/bvi/icon_4_1.png" alt="">
          <p class="re-serve-p1">年审/续牌</p>
          <p class="re-serve-p2">年审或续牌根据国家地区的要求，如期进行合规申报，否则产生罚款</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/bvi/icon_4_2tax_declaration.png" alt="">
          <p class="re-serve-p1">做账报税</p>
          <p class="re-serve-p2">公司注册之后需按照当地政府单位要求进行做账报税</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/bvi/icon_4_3change.png" alt="">
          <p class="re-serve-p1">公司变更</p>
          <p class="re-serve-p2">公司经营过程中的公司信息（如地址、股东、经营范围等）变更</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/bvi/icon_4_4.png" alt="">
          <p class="re-serve-p1">银行开户</p>
          <p class="re-serve-p2">开设公司注册所在地的银行账户，有利于公司随时接受贸易汇款，享受当地政策或规避外汇风险</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/bvi/icon_4_5secretarial_services.png" alt="">
          <p class="re-serve-p1">秘书服务</p>
          <p class="re-serve-p2">为公司提供完善的支持服务，包括但不限于代收及处理政府往来函件、提供年审报税等服务</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/bvi/icon_4_6.png" alt="">
          <p class="re-serve-p1">公司注销</p>
          <p class="re-serve-p2">公司确认不继续经营之后，需及时提交相关资料进行注销，避免产生后续罚款</p>
        </div>
      </div>
    </div>
    
    <div class="box" style="height: 745px;">
      <p class="box-p1">注册BVI公司的利弊</p>
      <div class="libi">
        <div class="li-box">
          <p class="li-p1">注册BVI公司的利</p>
          <p class="li-p2">BVI（英属维京群岛）是政治、经济和贸易环境非常稳定的地方</p>
          <p class="li-p2">有良好的金融法律设施，方便建立各种金融机构或基金会的成立与发展</p>
          <p class="li-p2">政府保护股东利益，不需要公布受益人身份</p>
          <p class="li-p2">当地政府为了鼓励经济发展，吸引外资，一个人可以完全拥有一家有限公司</p>
          <p class="li-p2">政府为各企业提供私隐保护，董事资料绝对保密</p>
          <p class="li-p2">低税率，岛屿国际有限公司所受的税务管制非常少</p>
          <p class="li-p2">在外地经营所得利润无须交利得税，达到合理避税的目的</p>
          <p class="li-p2">岛上企业在世界各地均可开立银行账户</p>
          <p class="li-p2">后续服务简单，保密性极强，免税，政治经济环境稳定，全球融资上市</p>
        </div>
        <div class="bi-box">
          <p class="bi-p1">注册BVI公司的弊</p>
          <p class="bi-p2">数据不公开： 注册的公司股东身份、董事名册、股权比例、收益状况高度保密并受法律保护，公众人士不能查询，法律禁止任意泄露公司的有关材料</p>
          <p class="bi-p2">成立时间：公司成立时间会比香港公司多3个工作日</p>
          <p class="bi-p2">费用：BVI费用会比香港公司高，年审费用也高</p>
          <p class="bi-p2">公司结束：连续两年没有交年费，公司自动注销，所有资产归政府所有</p>
        </div>
      </div>
    </div>


    <div class="box" style="height: auto;padding-bottom: 30px;background: rgba(242, 245, 250, 1)">
      <p class="box-p1">BVI公司注册常见问题</p>
      <div class="question" style="margin-top: 20px">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item title="注册BVI公司是否一定要有中文公司名称?" name="1">
            <p class="question-p">不一定。法例只规定必须有英文公司名称，可自行决定是否采用中文BVI公司名称。而且,并不是每个BVI公司注册地
都允许采用中文名称的。</p>
          </el-collapse-item>
          <el-collapse-item title="注册bvi公司经营范围注意事项？" name="2">
            <p class="question-p">1.不可从事信托、银行、保险、军事或其他类似中介业务
2.不得在当地作为其它公司之代理人及提供BVI之注册地址
3.不得从事违法犯罪活动</p>
          </el-collapse-item>
          <el-collapse-item title="英属维尔京群岛是一个免税的法区吗?" name="3">
            <p class="question-p">英属维尔京群岛（BVI）有一个开放的经济，主要由金融业驱动，而税法亦有一个强大的监管框架，非常有利于在该地
区做生意。
因此，英属维尔京群岛的离岸公司不需要支付所得税。也没有应税资本利得税，也没有赠与税、继承税、销售税或增值
税。</p>
          </el-collapse-item>
          <el-collapse-item title="是否必须亲自到英属维尔京群岛去注册公司?" name="4">
            <p class="question-p">不需要。你可以在专业服务机构的帮助下，从国外成立你的英属维尔京群岛公司。</p>
          </el-collapse-item>
        </el-collapse>
      </div>
      <p class="to-ask" @click="toAsk"> 
        <img class="to-ask-icon" src="../../assets/img/register/bvi/icon_6_customer_service.png" alt=""> 
        更多问题？立即咨询人工客服</p>
    </div>
    <div class="box" style="height: 480px;">
      <p class="box-p1">快企务的优势</p>
      <p class="box-p2">十余年注册BVI公司经验</p>
      <div class="re-adv">
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_1.png" alt="">
          <p class="re-adv-p1">保密完善</p>
          <p class="re-adv-p2">保密系统完善，客户隐私不外泄</p>
        </div>
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_2.png" alt="">
          <p class="re-adv-p1">全程服务</p>
          <p class="re-adv-p2">一站式专业服务从注册公司到年审、开户，审计报税等</p>
        </div>
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_3.png" alt="">
          <p class="re-adv-p1">专业高效</p>
          <p class="re-adv-p2">根据需求，为客户制定专业快捷的解决方案</p>
        </div>
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_4.png" alt="">
          <p class="re-adv-p1">合同保障</p>
          <p class="re-adv-p2">正规机构，合同保障，费用透明，无隐形消费</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  data(){
    return{
      tab: 1,
      activeName: '1'
    }
  },
 
  mounted(){
    window.scrollTo(0,0);
  },
  methods:{
    toAsk(){
      window.open('https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn','_blank')
    },
  }
}
</script>
<style scoped>
  .m-bvi-banner{
    width: 100%;
    height: 460px;
    background: url('../../assets/img/register/bvi/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
</style>
