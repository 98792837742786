import { render, staticRenderFns } from "./Mauritius.vue?vue&type=template&id=b9cf87ec&scoped=true&"
import script from "./Mauritius.vue?vue&type=script&lang=js&"
export * from "./Mauritius.vue?vue&type=script&lang=js&"
import style0 from "./Mauritius.vue?vue&type=style&index=0&id=b9cf87ec&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b9cf87ec",
  null
  
)

export default component.exports