<template>
  <div class="certification m-bvi">
    <div class="odi-banner">
      <p class="law-banner-p1">美国法律意见书</p>
      <p class="banner-btn banner-btn1">立即办理</p>
    </div>
    <div class="box" style="height: 477px;">
      <p class="box-p1">美国公司法律意见书</p>
      <img class="odi-img1" style="margin-top: 30px" src="../../assets/img/finance/AmericaLaw/pic_1.jpg" alt="">
      <p class="odi-p1">美国公司法律意见书，是由美国执业律师对美国公司的真实性，目前状态，财务况等各种数据收集整理之后，从法律专业角度给出的综合评价。<br />
法律意见书是律师执业中的重要文书。一份形式严谨、结构缜密、分析准确、论证精当的法律意见书不仅是律师为客户提供专业法律服务的工作指南，同时也是律师对纷繁复杂的各种法律关系宏观驾驭能力和微观操作技巧的充分展示。</p>
    <p class="form-submit" style="width: 96px;margin-left: 5%;">立即咨询</p>
    </div>

    <div class="box" style="height: 429px;background: rgba(242, 245, 250, 1);">
      <p class="box-p1">哪些情况需要“美国公司法律意见书”</p>
      <p class="box-p2">美国公司跟国内公司进行合作、投资或者办理银行贷款主体授信、融资上市、股权质押、提供保函等情况下，通常会要求提供“美国公司法律意见书”作为背景调查的其中一项证明！</p>
      <div class="law-swipe">
        <van-swipe class="law-swipe-box" :width="286" :height="174" :autoplay="3000" indicator-color="white" loop="true">
          <van-swipe-item>
            <div class="law-swipe-inner">
              <img class="law-swiper-img" src="../../assets/img/finance/AmericaLaw/pic_2_1.jpg" alt="">
              <p class="law-swiper-p">美国公司跟国内公司进行合作、投资</p>
            </div>
          </van-swipe-item>
          <van-swipe-item>
            <div class="law-swipe-inner">
              <img class="law-swiper-img" src="../../assets/img/finance/AmericaLaw/pic_2_2.jpg" alt="">
              <p class="law-swiper-p">办理银行贷款主体授信</p>
            </div>
          </van-swipe-item>
          <van-swipe-item>
            <div class="law-swipe-inner">
              <img class="law-swiper-img" src="../../assets/img/finance/AmericaLaw/pic_2_3.jpg" alt="">
              <p class="law-swiper-p">融资上市</p>
            </div>
          </van-swipe-item>
          <van-swipe-item>
            <div class="law-swipe-inner">
              <img class="law-swiper-img" src="../../assets/img/finance/AmericaLaw/pic_2_4.jpg" alt="">
              <p class="law-swiper-p">股权质押</p>
            </div>
          </van-swipe-item>
          <van-swipe-item>
            <div class="law-swipe-inner">
              <img class="law-swiper-img" src="../../assets/img/finance/AmericaLaw/pic_2_5.jpg" alt="">
              <p class="law-swiper-p">提供保函</p>
            </div>
          </van-swipe-item>
        </van-swipe>
        <p class="law-swiper-tips">
          （美国公司法律意见书需要出具的内容不同，办理时间会有所差异，通常来讲，不需要更改资料的情况下，办理美国公司法律意见书”需要 
          <span>20</span>
          个工作日左右！）
        </p>
      </div>
    </div>

    <div class="box" style="height: 1127px;">
      <p class="box-p1">“美国公司法律意见书”可以发表意见的内容</p>
      <div class="odi-pass" style="margin-top: 20px;">
        <div class="odi-pass-inner" style="background: rgba(242, 245, 250, 1);height: 164px;">
          <img class="odi-pass-icon" src="../../assets/img/finance/AmericaLaw/icon_2_1.png" alt="">
          <p class="odi-pass-p" style="width: 309px;margin: 0 auto;margin-top: 20px;">公司的基本情况，包括成立时间、公司类型、注册地址、法定代表人、注册资本、主要办公地点、董事情况、经营范围等。</p>
        </div>
        <div class="odi-pass-inner" style="background: rgba(242, 245, 250, 1);height: 146px;">
          <img class="odi-pass-icon" src="../../assets/img/finance/AmericaLaw/icon_2_2.png" alt="">
          <p class="odi-pass-p" style="width: 309px;margin: 0 auto;margin-top: 20px;">公司是否为依法设立且合法存续，公司的章程是否符合当地法律法规的规定，是否存在破产、清算的情形。</p>
        </div>
        <div class="odi-pass-inner" style="background: rgba(242, 245, 250, 1);height: 182px;">
          <img class="odi-pass-icon" src="../../assets/img/finance/AmericaLaw/icon_2_3.png" alt="">
          <p class="odi-pass-p" style="width: 309px;margin: 0 auto;margin-top: 20px;">公司成立至注销前的股本和股东情况，已发行的股份是否已被缴足，股东所持公司股权是否存在抵押、质押、留置等任何担保权益，是否存在纠纷或者潜在纠纷。</p>
        </div>
        <div class="odi-pass-inner" style="background: rgba(242, 245, 250, 1);height: 146px;">
          <img class="odi-pass-icon" src="../../assets/img/finance/AmericaLaw/icon_2_4.png" alt="">
          <p class="odi-pass-p" style="width: 309px;margin: 0 auto;margin-top: 20px;">公司成立至注销前的历次股权变动情况（如发生过股权变动，请将变动情况逐一说明）。</p>
        </div>
        <div class="odi-pass-inner" style="background: rgba(242, 245, 250, 1);height: 164px;">
          <img class="odi-pass-icon" src="../../assets/img/finance/AmericaLaw/icon_2_5.png" alt="">
          <p class="odi-pass-p" style="width: 309px;margin: 0 auto;margin-top: 20px;">成立以来，公司是否涉及诉讼、仲裁和行政处罚（包括已经完结的和尚未了结的），是否正在面临行政主管部门、司法机关的调查，如有，请逐一说明。</p>
        </div>
        <div class="odi-pass-inner" style="background: rgba(242, 245, 250, 1);height: 164px;">
          <img class="odi-pass-icon" src="../../assets/img/finance/AmericaLaw/icon_2_6.png" alt="">
          <p class="odi-pass-p" style="width: 309px;margin: 0 auto;margin-top: 20px;">公司注销生效的时间，是否按照公司章程及当地法律的规定和要求完成了相应的注销手续。与其他股东（如有）之间是否存在纠纷及潜在纠纷。</p>
        </div>
      </div>
    </div>
    <div class="box" style="height: 667px;background: rgba(242, 245, 250, 1);">
      <p class="box-p1">“美国公司法律意见书”出具流程</p>
      <div class="odi-flo" style="height: 570px;margin-top: 20px;">
        <p class="odi-flo-p3">第一步：由客户根据自身需要，提出需要出具含有哪些内容的法律意见书</p>
        <p class="odi-flo-p3" style="padding-top: 61px">第二步：美国律师初审后，给出初步意见，是否能够出具，需要提供哪些支持文件</p>
        <p class="odi-flo-p3" style="padding-top: 61px">第三步：客户按照律师要求提供相关文件，如注册证书，存续证明，章程等</p>
        <p class="odi-flo-p3" style="padding-top: 55px">第四步：美国律师审阅相关文件，出具法律意见书草稿</p>
        <p class="odi-flo-p3" style="padding-top: 81px">第五步：客户提出修改意见</p>
        <p class="odi-flo-p3" style="padding-top: 61px">第六步：客户确定无误，美国律师出具最终意见书</p>
      </div>
    </div>
    <div class="box" style="height: 675px;">
      <p class="box-p1">增值服务</p>
      <p class="box-p2">我们还为您提供</p>
      <div class="odi-add">
        <div class="odi-add-inner">
          <img class="odi-add-icon" src="../../assets/img/finance/ODI/icon_3_1.png" alt="">
          <p class="odi-add-p">境外公司注册</p>
        </div>
        <div class="odi-add-inner">
          <img class="odi-add-icon" src="../../assets/img/finance/ODI/icon_3_2.png" alt="">
          <p class="odi-add-p">境外银行开户</p>
        </div>
        <div class="odi-add-inner">
          <img class="odi-add-icon" src="../../assets/img/finance/ODI/icon_3_3.png" alt="">
          <p class="odi-add-p">IPO业务咨询</p>
        </div>
        <div class="odi-add-inner">
          <img class="odi-add-icon" src="../../assets/img/finance/ODI/icon_3_4.png" alt="">
          <p class="odi-add-p">税务筹划</p>
        </div>
        <div class="odi-add-inner">
          <img class="odi-add-icon" src="../../assets/img/finance/ODI/icon_3_5.png" alt="">
          <p class="odi-add-p">VAT税号的申请和申报</p>
        </div>
        <div class="odi-add-inner">
          <img class="odi-add-icon" src="../../assets/img/finance/ODI/icon_3_6.png" alt="">
          <p class="odi-add-p">全球商标申请</p>
        </div>
        <div class="odi-add-inner">
          <img class="odi-add-icon" src="../../assets/img/finance/ODI/icon_3_7.png" alt="">
          <p class="odi-add-p">香港及境外公证</p>
        </div>
        <div class="odi-add-inner">
          <img class="odi-add-icon" src="../../assets/img/finance/ODI/icon_3_8.png" alt="">
          <p class="odi-add-p">代持和法律意见书等服务</p>
        </div>
      </div>
    </div>
  
  </div>
</template>
<script>
export default {
  name: 'bvi-page',
  props: {
    msg: String
  },
  data(){
    return{
      form: {},
      activeName: '1'
    }
  },
  mounted(){
    
  }
}
</script>

<style scoped lang="scss">
  .odi-banner{
    width: 100%;
    height: 460px;
    background: url('../../assets/img/finance/AmericaLaw/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
  .odi-flo{
    background: url('../../assets/img/finance/AmericaLaw/pic_3.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
  


</style>
