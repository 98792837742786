<template>
  <div class="home">
    <div class="home-banner">
      <p class="banner-p1">国际公证认证</p>
      <p class="banner-p2">全球服务超过<span class="blue-style">3000+</span>客户</p>
      <p class="banner-p3">全程代办/一站式服务/业务全面，覆盖全球</p>
      <p class="banner-btn" @click="toAsk()">免费咨询</p>
    </div>
    <div class="box">
      <p class="box-p1">全球公司设立</p>
      <p class="box-p2">快速提供全球企业综合服务，助力企业扬帆起航，定制化的离岸架构，为您的生意打开世界之门</p>
      <img class="map-img" src="../assets/img/home/pic_1_map.jpg" alt="">
    </div>
    <div class="box bg-box" style="height: 788px">
      <p class="box-p1">秘书服务</p>
      <p class="box-p2">一站式全程代办，主流企业事项，代办项多至11项</p>
      <div class="box-content flex center-width">
        <img class="box1-img" src="../assets/img/home/pic_2_1.png" alt="">
        <img class="box1-img" src="../assets/img/home/pic_2_2.png" alt="">
        <img class="box1-img" src="../assets/img/home/pic_2_3.png" alt="">
        <img class="box1-img" src="../assets/img/home/pic_2_4.png" alt="">
        <img class="box1-img" src="../assets/img/home/pic_2_5.png" alt="">
        <img class="box1-img" src="../assets/img/home/pic_2_6.png" alt="">
        <img class="box1-img" src="../assets/img/home/pic_2_7.png" alt="">
        <img class="box1-img" src="../assets/img/home/pic_2_8.png" alt="">
      </div>
    </div>
    <div class="box" style="height: 447px;">
      <p class="box-p1">国际公证认证</p>
      <p class="box-p2">国际公证认证，十余年行业品牌</p>
      <div class="lunbo-box">
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white" :width="275" :loop="true">
          <van-swipe-item>
            <img class="swiper-img" @click="toUrl('notarizationHague')" style="width: 265px;" src="../assets/img/home/pic_3_1.jpg" alt="">
          </van-swipe-item>
          <van-swipe-item>
            <img class="swiper-img" @click="toUrl('notarizationChina')" style="width: 265px;" src="../assets/img/home/pic_3_2.jpg" alt="">
          </van-swipe-item>
          <van-swipe-item>
            <img class="swiper-img" @click="toUrl('notarizationEmbassy')" style="width: 265px;" src="../assets/img/home/pic_3_3.jpg" alt="">
          </van-swipe-item>
          <van-swipe-item>
            <img class="swiper-img" @click="toUrl('notarizationTrade')" style="width: 265px;" src="../assets/img/home/pic_3_4.jpg" alt="">
          </van-swipe-item>
        </van-swipe>
      </div>
    </div>
    <div class="box bg-box" style="height: 920px;">
      <p class="box-p1">银行开户</p>
      <p class="box-p2">全球任选开户，不成功退全款</p>
      <div class="lunbo-box" style="width: 94%;">
        <div class="box-content flex center-width" style="width: 100%;">
        <img class="box1-img img-1" @click="toAsk()" src="../assets/img/home/pic_4_1.png" alt="">
        <img class="box1-img img-1" @click="toAsk()" src="../assets/img/home/pic_4_2.png" alt="">
        <img class="box1-img img-1" @click="toAsk()" src="../assets/img/home/pic_4_3.png" alt="">
        <img class="box1-img img-1" @click="toAsk()" src="../assets/img/home/pic_4_4.png" alt="">
        <img class="box1-img img-1" @click="toAsk()" src="../assets/img/home/pic_4_5.png" alt="">
        <img class="box1-img img-1" @click="toAsk()" src="../assets/img/home/pic_4_6.png" alt="">
        <img class="box1-img img-1" @click="toAsk()" src="../assets/img/home/pic_4_7.png" alt="">
        <img class="box1-img img-1" @click="toUrl('financeBank')" src="../assets/img/home/pic_4_8.png" alt="">
      </div>
      </div>
    </div>

    <div class="box" style="height: auto;padding-bottom: 40px;">
      <p class="box-p1">财税筹划</p>
      <p class="box-p2">专业会计师团队，全程规范、透明</p>
      <div class="lunbo-box">
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white" :width="275" :loop="true">
          <van-swipe-item>
            <img class="swiper-img" @click="toAsk()" style="width: 265px;box-shadow: 0px 2px 8px 0px rgba(0, 52, 148, 0.08);" src="../assets/img/home/pic_5_1.jpg" alt="">
          </van-swipe-item>
          <van-swipe-item>
            <img class="swiper-img" @click="toAsk()" style="width: 265px;box-shadow: 0px 2px 8px 0px rgba(0, 52, 148, 0.08);" src="../assets/img/home/pic_5_2.jpg" alt="">
          </van-swipe-item>
          <van-swipe-item>
            <img class="swiper-img" @click="toAsk()" style="width: 265px;box-shadow: 0px 2px 8px 0px rgba(0, 52, 148, 0.08);" src="../assets/img/home/pic_5_3.jpg" alt="">
          </van-swipe-item>
          <van-swipe-item>
            <img class="swiper-img" @click="toAsk()" style="width: 265px;box-shadow: 0px 2px 8px 0px rgba(0, 52, 148, 0.08);" src="../assets/img/home/pic_5_4.jpg" alt="">
          </van-swipe-item>
          <van-swipe-item>
            <img class="swiper-img" @click="toAsk()" style="width: 265px;box-shadow: 0px 2px 8px 0px rgba(0, 52, 148, 0.08);" src="../assets/img/home/pic_5_5.jpg" alt="">
          </van-swipe-item>
          <van-swipe-item>
            <img class="swiper-img" @click="toAsk()" style="width: 265px;box-shadow: 0px 2px 8px 0px rgba(0, 52, 148, 0.08);" src="../assets/img/home/pic_5_6.jpg" alt="">
          </van-swipe-item>
        </van-swipe>
      </div>
    </div>

    <div class="box" style="height: auto;padding-bottom: 30px;background: rgba(242, 245, 250, 1);">
      <p class="box-p1">财税筹划</p>
      <div class="home-odi">
        <p class="home-odi-p1">企业ODI备案</p>
        <p class="home-odi-p2">境外投资备案，英文全称Overseas Direct Investment，也就是我们通常所说的ODI，是指在中华人民共和国境内依法设立的企业通过新设、并购及其他方式在境外拥有非金融企业或取得既有非金融企业所有权、控制权、经营管理权及其他权益的行为。</p>
        <p class="home-odi-p2">管理办法：商务部和省级商务主管部门按照企业境外投资的不同情形，分别实行备案和核准管理。企业境外投资涉及敏感国家和地区、敏感行业的，实行核准管理。核准管理向国家商务部提出申请。企业其他情形的境外投资，实行备案管理，备案向地方商务部提出申请。</p>
        <div class="home-odi-button">
          <p class="btn1" @click="toAsk()">立即咨询</p>
          <p class="btn1 btn2" @click="toUrl('ODI')">查看详情</p>
        </div>
        <img class="home-odi-img" src="../assets/img/home/pic_6.jpg" alt="">
      </div>
    </div>

    <div class="box" style="height: auto;padding-bottom: 30px;">
      <p class="box-p1">知识产权</p>
      <p class="box-p2"><span style="color: rgba(237, 41, 65, 1);font-weight: bold;">1000+ </span> 每日查询，免费查询商标，极速出结果</p>
      <div class="question">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item title="国内商标注册" name="1">
            <p class="question-p1">普通商标注册</p>
            <p class="question-p2">价格实惠，快企务爆款</p>
            <p class="question-btn" @click="toAsk()">立即咨询</p>
            <p class="question-p1">商标极速注册</p>
            <p class="question-p2">59分钟递送商标局</p>
            <p class="question-btn" @click="toAsk()">立即咨询</p>
            <p class="question-p1">极速双保注册</p>
            <p class="question-p2">59分钟报件，如驳回免费再注册一个</p>
            <p class="question-btn" @click="toAsk()">立即咨询</p>
          </el-collapse-item>
          <el-collapse-item title="商标服务" name="2">
            <p class="question-p1">商标驳回复审</p>
            <p class="question-p2">商标申请被驳回，申请复审力挽狂澜</p>
            <p class="question-btn" @click="toAsk()">立即咨询</p>
            <p class="question-p1">商标续展</p>
            <p class="question-p2">面临被注销风险，及时续展保护权利</p>
            <p class="question-btn" @click="toAsk()">立即咨询</p>
            <p class="question-p1">商标异议答辩</p>
            <p class="question-p2">商标被他人异议，答辩应对助成功</p>
            <p class="question-btn" @click="toAsk()">立即咨询</p>
          </el-collapse-item>
          <el-collapse-item title="国际商标注册" name="3">
            <p class="question-p1">美国商标注册</p>
            <p class="question-p2">注册时间：12-18个月 有效期：10年</p>
            <p class="question-btn" @click="toAsk()">立即咨询</p>
            <p class="question-p1">新加坡商标注册</p>
            <p class="question-p2" >注册时间：9-12个月 有效期：10年</p>
            <p class="question-btn" @click="toAsk()">立即咨询</p>
            <p class="question-p1">香港商标注册</p>
            <p class="question-p2">注册时间：6-8个月；有效期：10年</p>
            <p class="question-btn" @click="toAsk()">立即咨询</p>
            <p class="question-p1">欧盟商标注册</p>
            <p class="question-p2">注册时间：12-15个月；有效期：10年</p>
            <p class="question-btn" @click="toAsk()">立即咨询</p>
            <p class="question-p1">英国商标注册</p>
            <p class="question-p2">注册时间：4-6个月；有效期：10年</p>
            <p class="question-btn" @click="toAsk()">立即咨询</p>
            <p class="question-p1">德国商标注册</p>
            <p class="question-p2">注册时间：12-18个月；有效期：10年</p>
            <p class="question-btn" @click="toAsk()">立即咨询</p>
            <p class="question-p1">全部国际商标注册</p>
            <p class="question-p3" @click="toAsk()">查看更多 > </p>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>

    <div class="box" style="height: auto;padding-bottom: 30px;background: rgba(242, 245, 250, 1);">
      <p class="box-p1" style="width: 100%;">用心服务，为每一位客户创造非凡价值</p>
      <p class="box-p2">快企务客户满意度高达<span style="color: rgba(237, 41, 65, 1);font-weight: bold;">98%</span></p>
      <div class="lunbo-box" style="height: 200px;">
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white" :loop="true">
          <van-swipe-item>
            <div class="person-box">
              <img class="person-box-img" src="../assets/img/home/pic_7_1.png" alt="">
              <img class="person-box-img" src="../assets/img/home/pic_7_2.png" alt="">
            </div>
          </van-swipe-item>
          <van-swipe-item>
            <div class="person-box">
              <img class="person-box-img" src="../assets/img/home/pic_7_3.png" alt="">
              <img class="person-box-img" src="../assets/img/home/pic_7_4.png" alt="">
            </div>
          </van-swipe-item>
          <van-swipe-item>
            <div class="person-box">
              <img class="person-box-img" src="../assets/img/home/pic_7_5.png" alt="">
              <img class="person-box-img" src="../assets/img/home/pic_7_6.png" alt="">
            </div>
          </van-swipe-item>
          <van-swipe-item>
            <div class="person-box">
              <img class="person-box-img" src="../assets/img/home/pic_7_7.png" alt="">
              <img class="person-box-img" src="../assets/img/home/pic_7_8.png" alt="">
            </div>
          </van-swipe-item>
          <van-swipe-item>
            <div class="person-box">
              <img class="person-box-img" src="../assets/img/home/pic_7_9.png" alt="">
              <img class="person-box-img" src="../assets/img/home/pic_7_10.png" alt="">
            </div>
          </van-swipe-item>
          <van-swipe-item>
            <div class="person-box">
              <img class="person-box-img" src="../assets/img/home/pic_7_11.png" alt="">
              <img class="person-box-img" src="../assets/img/home/pic_7_12.png" alt="">
            </div>
          </van-swipe-item>
          <van-swipe-item>
            <div class="person-box">
              <img class="person-box-img" src="../assets/img/home/pic_7_13.png" alt="">
              <img class="person-box-img" src="../assets/img/home/pic_7_14.png" alt="">
            </div>
          </van-swipe-item>
          <van-swipe-item>
            <div class="person-box">
              <img class="person-box-img" src="../assets/img/home/pic_7_15.png" alt="">
              <img class="person-box-img" src="../assets/img/home/pic_7_16.png" alt="">
            </div>
          </van-swipe-item>
          <van-swipe-item>
            <div class="person-box">
              <!-- <img class="person-box-img" src="../assets/img/home/pic_7_17.png" alt=""> -->
              <img class="person-box-img" src="../assets/img/home/pic_7_18.png" alt="">
              <img class="person-box-img" src="../assets/img/home/pic_7_19.png" alt="">
            </div>
          </van-swipe-item>
          <van-swipe-item>
            <div class="person-box">
              <!-- <img class="person-box-img" src="../assets/img/home/pic_7_19.png" alt=""> -->
              <img class="person-box-img" src="../assets/img/home/pic_7_20.png" alt="">
            </div>
          </van-swipe-item>
        </van-swipe>
      </div>
    </div>

    <div class="box" style="height: auto;padding-bottom: 20px;">
      <p class="box-p1">快企务服务特色</p>
      <p class="box-p2"><span style="color: rgba(237, 41, 65, 1);font-weight: bold;">95% </span>客户选择长期合作</p>
      <div class="home-special">
        <img class="home-special-img" src="../assets/img/home/pic_8_1.jpg" alt="">
        <img class="home-special-img" src="../assets/img/home/pic_8_2.jpg" alt="">
        <img class="home-special-img" src="../assets/img/home/pic_8_3.jpg" alt="">
        <img class="home-special-img" src="../assets/img/home/pic_8_4.jpg" alt="">
      </div>
    </div>

    <div class="box" style="height: auto;padding-bottom: 20px;background: rgba(242, 245, 250, 1);">
      <p class="box-p1">客户案例</p>
      <img class="home-case" src="../assets/img/home/pic_9.jpg" alt="">
    </div>

    <el-dialog :visible.sync="Dialog" title="" width="95%">
        <div style="width: 300px;height: 600px;">
            <iframe
              src="https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn"
              width="300px"
              height="100%"
              frameborder="0"
              scrolling="no">
            </iframe>
        </div>
      </el-dialog>

  </div>
</template>

<script>
export default {
  name: 'HomeView',
  data(){
    return{
      activeName: '1',
      Dialog: false
    }
  },
 
  mounted(){
    window.scrollTo(0,0);
  },
  methods:{
    // https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn
    toAsk(){
      window.open('https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn','_blank')
    },
    toUrl(url){
      this.$router.push({name: url})
    }
  }
}
</script>
<style>
  .home-banner{
    background: url('../assets/img/home/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
</style>
