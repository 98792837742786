<template>
  <div class="home">
    <div class="m-bvi-banner">
      <p class="banner-p1">一站式英国公司注册服务</p>
      <p class="banner-p2">【快企务专注于涉内外企业服务十余年】</p>
      <p class="banner-btn banner-btn1" @click="toAsk">立即咨询</p>
    </div>

    <div class="box" style="height: 522px;">
      <p class="box-p1">注册英国公司优势</p>
      <div class="bri-adv">
        <div class="bri-adv-inner">
          <p class="bri-adv-p">注册时间短，所需材料少，注册灵活性大</p>
        </div>
        <div class="bri-adv-inner">
          <p class="bri-adv-p">股东可一位，国籍不限</p>
        </div>
        <div class="bri-adv-inner">
          <p class="bri-adv-p">不在当地经营，合理避税，公司管理便捷</p>
        </div>
        <div class="bri-adv-inner">
          <p class="bri-adv-p">外汇进出自由，可在英国欧洲投资</p>
        </div>
        <div class="bri-adv-inner">
          <p class="bri-adv-p">设立国际品牌企业国际形象，国际法律地位高</p>
        </div>
      </div>
      <img class="bri-adv-img" src="../../assets/img/register/Britain/pic_1.jpg" alt="">
    </div>

    <div class="box" style="height: auto;padding-bottom: 30px;">
      <p class="box-p1">注册英国公司类型</p>
      <div class="type-content flex center-width">
        <img class="type-img" style="height: 110px;" src="../../assets/img/register/Britain/pic_2_1.jpg" alt="">
        <img class="type-img" style="height: 110px;" src="../../assets/img/register/Britain/pic_2_2.jpg" alt="">
        <img class="type-img" style="height: 110px;" src="../../assets/img/register/Britain/pic_2_3.jpg" alt="">
        <img class="type-img" style="height: 110px;" @click="toAsk" src="../../assets/img/register/Britain/pic_2_4.jpg" alt="">
      </div>
    </div>
    <div class="box" style="height: auto;padding-bottom: 15px;">
      <p class="box-p1">注册英国公司条件/流程/所得资料</p>
      <div class="tab-box">
        <div class="tab-inner" :class="{'active': tab == 1}" @click="tab = 1">注册条件</div>
        <div class="tab-inner" :class="{'active': tab == 2}" @click="tab = 2">注册流程</div>
        <div class="tab-inner" :class="{'active': tab == 3}" @click="tab = 3">所得资料</div>
      </div>
      <div class="file-content">
        <div v-if="tab == 1">
          <div class="bri-flow">
            <div class="bri-flow-inner">
              <img class="bri-flow-icon" src="../../assets/img/register/Britain/pic_3_1.png" alt="">
              <p class="bri-flow-p">名称</p>
              <p class="bri-flow-p1">确定拟注册的三个英文公司名称</p>
            </div>
            <div class="bri-flow-inner">
              <img class="bri-flow-icon" src="../../assets/img/register/Britain/pic_3_2.png" alt="">
              <p class="bri-flow-p">股东</p>
              <p class="bri-flow-p1">提供董事/股东人数及身份信息</p>
            </div>
            <div class="bri-flow-inner">
              <img class="bri-flow-icon" src="../../assets/img/register/Britain/pic_3_3.png" alt="">
              <p class="bri-flow-p">注资</p>
              <p class="bri-flow-p1">标准注册资金（最少1英镑）</p>
            </div>
            <div class="bri-flow-inner">
              <img class="bri-flow-icon" src="../../assets/img/register/Britain/pic_3_4.png" alt="">
              <p class="bri-flow-p">地址</p>
              <p class="bri-flow-p1">确认公司的注册地址（我司可提供）</p>
            </div>
            <div class="bri-flow-inner">
              <img class="bri-flow-icon" src="../../assets/img/register/Britain/pic_3_5.png" alt="">
              <p class="bri-flow-p">经营范围</p>
              <p class="bri-flow-p1">确认公司的经营范围（我司协助选择）</p>
            </div>
            <div class="bri-flow-inner">
              <img class="bri-flow-icon" src="../../assets/img/register/Britain/pic_3_6.png" alt="">
              <p class="bri-flow-p">法定秘书</p>
              <p class="bri-flow-p1">确认公司的法定秘书（我司可提供）</p>
            </div>
          </div>
        </div>
        <div v-if="tab == 2">
          <img class="tab2-img" src="../../assets/img/register/Britain/pic_4.jpg" alt="">
        </div>
        <div v-if="tab == 3">
          <img class="tab2-img" src="../../assets/img/register/Britain/pic_5.jpg" alt="">
        </div>
      </div>
    </div>
    
    <div class="box" style="height: 676px;background: rgba(242, 245, 250, 1);">
      <p class="box-p1">注册英国公司后为您提供的后续服务</p>
      <div class="re-serve">
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Britain/pic_6_1.png" alt="">
          <p class="re-serve-p1">年审/续牌</p>
          <p class="re-serve-p2">年审或续牌根据国家地区的要求，如期进行合规申报，否则产生罚款年审或续牌根据国家地区的要求，如期进行</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Britain/pic_6_2.png" alt="">
          <p class="re-serve-p1">银行开户</p>
          <p class="re-serve-p2">开设公司注册所在地的银行账户，有利于公司随时接受贸易汇款，享受当地政策或规避外汇风险</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Britain/pic_6_3.png" alt="">
          <p class="re-serve-p1">做账报税</p>
          <p class="re-serve-p2">公司注册之后需按照当地政府单位要求进行做账报税</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Britain/pic_6_4.png" alt="">
          <p class="re-serve-p1">公司变更</p>
          <p class="re-serve-p2">公司经营过程中的公司信息（如地址、股东、经营范围等）变更</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Britain/pic_6_5.png" alt="">
          <p class="re-serve-p1">秘书服务</p>
          <p class="re-serve-p2">为公司提供完善的支持服务，包括但不限于代收及处理政府往来函件、提供年审报税等服务</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Britain/pic_6_6.png" alt="">
          <p class="re-serve-p1">公司注销</p>
          <p class="re-serve-p2">公司确认不继续经营之后，需及时提交相关资料进行注销，避免产生后续罚款</p>
        </div>
      </div>
    </div>

    <div class="box" style="height: auto;padding-bottom: 30px;">
      <p class="box-p1">英国公司注册常见问题</p>
      <p class="box-p1">解决您的一切疑问</p>
      <div class="question">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item title="注册英国公司需要验资吗？" name="1">
            <p class="question-p">注册英国公司，注册资本不需到位，无需验资，税务安排便利，品牌信誉度高，成立费用不高，每年维护成本低。</p>
          </el-collapse-item>
          <el-collapse-item title="英国公司税收高吗?" name="2">
            <p class="question-p">英国公司与中国香港公司的税务几乎一致，不是在英国本地获得的收入，是可以不用在英国进行税务缴纳。</p>
          </el-collapse-item>
          <el-collapse-item title="英国公司注册成功后可以查证吗？需要多久？" name="3">
            <p class="question-p">一般在注册完成后的24小时内就可以在英国工商局网站上查到注册信息(英国工商局在英国时间早上7点到晚上12点提供网上免费查询服务.</p>
          </el-collapse-item>
          <el-collapse-item title="英国公司的的经济效应?" name="4">
            <p class="question-p">从全球经济和资本运作看来，英国是继美国之后的第二大金融中心，再加上其在欧洲的重要地位和与北美及整个
英联邦国家的密切经济关系，英国的公司往往在世界上有着较好的声誉。</p>
          </el-collapse-item>
        </el-collapse>
      </div>
      <p class="to-ask" @click="toAsk"> 
        <img class="to-ask-icon" src="../../assets/img/register/bvi/icon_6_customer_service.png" alt=""> 
        更多问题？立即咨询人工客服</p>
    </div>
    <div class="box" style="height: 480px;">
      <p class="box-p1">快企务的优势</p>
      <p class="box-p2">十余年注册英国公司经验</p>
      <div class="re-adv">
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_1.png" alt="">
          <p class="re-adv-p1">保密完善</p>
          <p class="re-adv-p2">保密系统完善，客户隐私不外泄</p>
        </div>
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_2.png" alt="">
          <p class="re-adv-p1">全程服务</p>
          <p class="re-adv-p2">一站式专业服务从注册公司到年审、开户，审计报税等</p>
        </div>
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_3.png" alt="">
          <p class="re-adv-p1">专业高效</p>
          <p class="re-adv-p2">根据需求，为客户制定专业快捷的解决方案</p>
        </div>
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_4.png" alt="">
          <p class="re-adv-p1">合同保障</p>
          <p class="re-adv-p2">正规机构，合同保障，费用透明，无隐形消费</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  data(){
    return{
      tab: 1,
      activeName: '1'
    }
  },
  mounted(){
    window.scrollTo(0,0);
  },
  methods:{
    toAsk(){
      window.open('https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn','_blank')
    },
  }
}
</script>
<style scoped>
  .m-bvi-banner{
    width: 100%;
    height: 460px;
    background: url('../../assets/img/register/Britain/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
</style>
