import { render, staticRenderFns } from "./Germany.vue?vue&type=template&id=4c53ec24&scoped=true&"
import script from "./Germany.vue?vue&type=script&lang=js&"
export * from "./Germany.vue?vue&type=script&lang=js&"
import style0 from "./Germany.vue?vue&type=style&index=0&id=4c53ec24&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c53ec24",
  null
  
)

export default component.exports