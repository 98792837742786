<template>
  <div class="home">
    <div class="m-bvi-banner">
      <p class="banner-p1">一站式中国台湾公司注册服务</p>
      <p class="banner-p2">【快企务专注于涉内外企业服务十余年】</p>
      <p class="banner-btn banner-btn1" @click="toAsk">立即咨询</p>
    </div>

    <div class="box" style="height: 240px;">
      <p class="box-p1">注册中国台湾公司优势</p>
      <div class="taiwan-adv">
        <div class="taiwan-adv-inner">
          <img class="taiwan-adv-icon" src="../../assets/img/register/Taiwan/pic_1_1.png" alt="">
          <p class="taiwan-adv-p">台湾商品在市场上很有竞争力</p>
        </div>
        <div class="taiwan-adv-inner">
          <img class="taiwan-adv-icon" src="../../assets/img/register/Taiwan/pic_1_2.png" alt="">
          <p class="taiwan-adv-p">有利于公司品牌宣传，提升公司品牌形象及公司信誉</p>
        </div>
        <div class="taiwan-adv-inner">
          <img class="taiwan-adv-icon" src="../../assets/img/register/Taiwan/pic_1_3.png" alt="">
          <p class="taiwan-adv-p">大陆政府无法查到台湾公司的税</p>
        </div>
        <div class="taiwan-adv-inner">
          <img class="taiwan-adv-icon" src="../../assets/img/register/Taiwan/pic_1_4.png" alt="">
          <p class="taiwan-adv-p">台湾政府针对出口有减税的优惠</p>
        </div>
      </div>
    </div>

    <div class="box" style="height: 319px;">
      <p class="box-p1">注册中国台湾公司类型</p>
      <div class="lunbo-box" style="margin-top: 20px;">
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
          <van-swipe-item>
            <img class="swiper-img" style="height: 200px;" src="../../assets/img/register/Taiwan/pic_2_1.jpg" alt="">
          </van-swipe-item>
          <van-swipe-item>
            <img class="swiper-img" style="height: 200px;" src="../../assets/img/register/Taiwan/pic_2_2.jpg" alt="">
          </van-swipe-item>
          <van-swipe-item>
            <img class="swiper-img" style="height: 200px;" src="../../assets/img/register/Taiwan/pic_2_3.jpg" alt="">
          </van-swipe-item>
        </van-swipe>
      </div>
    </div>

    <div class="box" style="height: 1101px;">
      <p class="box-p1">注册中国台湾公司条件/流程/所得资料</p>
      <img class="taiwan-file-img" src="../../assets/img/register/Taiwan/pic_3.jpg" alt="">
      <img class="taiwan-file-img" style="height: 455px;" src="../../assets/img/register/Taiwan/pic_4.jpg" alt="">
      <img class="taiwan-file-img" style="height: 259px;" src="../../assets/img/register/Taiwan/pic_5.jpg" alt="">
    </div>
    
    <div class="box" style="height: 475px;background: rgba(242, 245, 250, 1);">
      <p class="box-p1">注册中国台湾公司后为您提供的后续服务</p>
      <div class="re-serve">
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Taiwan/pic_6_1.png" alt="">
          <p class="re-serve-p1">公司续牌</p>
          <p class="re-serve-p2">在台湾，年审年报被称为公司续牌，指台湾公司注册后已发办理续期经营的</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Taiwan/pic_6_2.png" alt="">
          <p class="re-serve-p1">秘书服务</p>
          <p class="re-serve-p2">具体包含公司秘书、电话代接代转、信件代接代转、货物代接代转等等</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Taiwan/pic_6_3.png" alt="">
          <p class="re-serve-p1">做账报税</p>
          <p class="re-serve-p2">台湾公司注册后，税务交给会计师管理，每2个月要报一次营业税，按5%收取。年底再报一次营利事业所得税，按20%收取。</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Taiwan/pic_6_4.png" alt="">
          <p class="re-serve-p1">公司注销</p>
          <p class="re-serve-p2">公司决定不再续牌之后，及时注销公司</p>
        </div>
      </div>
    </div>

    <div class="box" style="height: auto;padding-bottom: 30px;">
      <p class="box-p1">中国台湾公司注册常见问题</p>
      <p class="box-p1">解决您的一切疑问</p>
      <div class="question">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item title="注册台湾公司的优势、好处？" name="1">
            <p class="question-p">1、中国台湾具有优越的设计能力,丰沛的创新研发能力。
2、中国台湾是全球科技产业重镇,坚实的产业聚落,软硬基建环境兼备,亚太运输枢纽。
3、国际机构对中国台湾的肯定,中国台湾产业竞争力和中国台湾投资环境佳。</p>
          </el-collapse-item>
          <el-collapse-item title="中国台湾公司对股东的要求？" name="2">
            <p class="question-p">股份有限公司须至少有法人股东1人或自然人股东2人（于有限公司之情形，1人以上之自然人或法人股东），且
均得为居住于中国台湾境外之投资人。</p>
          </el-collapse-item>
          <el-collapse-item title="台湾公司经营范围有哪些限制？" name="3">
            <p class="question-p">1、台湾公司经营范围较宽松，注册时需要体现经营范围，除特殊行业，如不动产、传播、广告、金融等不得
经营，其它都可经营。
2、股东是大陆公司的称为陆资企业，只能从事许可范围内的业务。</p>
          </el-collapse-item>
          <el-collapse-item title="台湾公司注册股东和董事的要求?" name="4">
            <p class="question-p">1、年满18周岁有身份证明文件的人士均可以申请台湾公司注册
2、股东至少一位，股东和董事可为同一人，可以是自然人或法人担任，没有国籍限制；</p>
          </el-collapse-item>
        </el-collapse>
      </div>
      <p class="to-ask" @click="toAsk"> 
        <img class="to-ask-icon" src="../../assets/img/register/bvi/icon_6_customer_service.png" alt=""> 
        更多问题？立即咨询人工客服</p>
    </div>
    <div class="box" style="height: 480px;">
      <p class="box-p1">快企务的优势</p>
      <p class="box-p2">十余年注册台湾公司经验</p>
      <div class="re-adv">
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_1.png" alt="">
          <p class="re-adv-p1">保密完善</p>
          <p class="re-adv-p2">保密系统完善，客户隐私不外泄</p>
        </div>
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_2.png" alt="">
          <p class="re-adv-p1">全程服务</p>
          <p class="re-adv-p2">一站式专业服务从注册公司到年审、开户，审计报税等</p>
        </div>
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_3.png" alt="">
          <p class="re-adv-p1">专业高效</p>
          <p class="re-adv-p2">根据需求，为客户制定专业快捷的解决方案</p>
        </div>
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_4.png" alt="">
          <p class="re-adv-p1">合同保障</p>
          <p class="re-adv-p2">正规机构，合同保障，费用透明，无隐形消费</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  data(){
    return{
      tab: 1,
      activeName: '1'
    }
  },
  mounted(){
    window.scrollTo(0,0);
  },
  methods:{
    toAsk(){
      window.open('https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn','_blank')
    },
  }
}
</script>
<style scoped>
  .m-bvi-banner{
    width: 100%;
    height: 460px;
    background: url('../../assets/img/register/Taiwan/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
</style>
