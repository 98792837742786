<template>
  <div class="certification m-bvi">
    <div class="m-bvi-banner">
      <p class="f1-banner-p1">香港公司年审</p>
      <p class="f1-banner-p2">一站式全程代办<br />最快1天完成</p>
      <p class="banner-btn banner-btn1" @click="toAsk">立即咨询</p>
    </div>

    <div class="box" style="height: auto;margin-bottom: 30px;">
      <p class="box-p1">为什么要按时年审</p>
      <p class="box-p2">逾期年审影响与罚款细则</p>
      <div class="why-exma">
        <p class="why-exma-p1">政府罚款</p>
        <p class="why-exma-p2">政府罚款或起诉，还有法院下发传票</p>
        <p class="why-exma-p1">账户冻结</p>
        <p class="why-exma-p2">银行将会被冻结，无法收支款，无法取出余额，以后再无法开户</p>
        <p class="why-exma-p1">信用受损</p>
        <p class="why-exma-p2">可能会导致股东董事被记录不良信用，影响出入境和航班转机等</p>
        <p class="why-exma-p3">了解详细罚款额度 > </p>
        <img class="why-exma-img" src="../../assets/img/examination/HK/pic_1.jpg" alt="">
      </div>
    </div>

    <div class="box" style="height: auto;padding-bottom: 10px;background: rgba(242, 245, 250, 1);">
      <p class="box-p1">香港公司的年审什么时候办理</p>
      <p class="box-p2">年审办理时间与注意事项</p>
      <div class="when-exma">
        <p class="when-exma-p">年审办理时间：</p>
        <p class="why-exma-p1">安排年审</p>
        <p class="why-exma-p2">香港公司每年在成立日期到期前都需要安排年审工作</p>
        <p class="why-exma-p1">提前一周</p>
        <p class="why-exma-p2">一般会提前一周以上准备办理，以免造成年审超期产生罚款</p>
        <p class="when-exma-p" style="color: rgba(237, 41, 65, 1);">注意事项：</p>
        <p class="why-exma-p1">罚款累加</p>
        <p class="why-exma-p2">起期没年审的公司随着时间越长，罚款会越来越多</p>
        <p class="why-exma-p1">影响使用</p>
        <p class="why-exma-p2">甚至还会影响到银行账户的正常使用</p>
        <img class="why-exma-img" src="../../assets/img/examination/HK/pic_2.png" alt="">
      </div>
    </div>

    <div class="box" style="height: auto;padding-bottom: 20px;">
      <p class="box-p1">香港公司年审事项</p>
      <p class="box-p2">年审内容：更换最新年度商业登记证和递交周年申报表</p>
      <div class="exma-things">
        <div class="exma-things-inner">
          <img class="exma-things-icon" src="../../assets/img/examination/HK/icon_1_1.png" alt="">
          <p class="exma-things-p1">年审周期</p>
          <p class="exma-things-p2">一年一审；成立日期前一个月办理，我司将有专人电话通知贵司，如联系方式有变动请及时与我司联系</p>
        </div>
        <div class="exma-things-inner">
          <img class="exma-things-icon" src="../../assets/img/examination/HK/icon_1_2.png" alt="">
          <p class="exma-things-p1">所需资料</p>
          <p class="exma-things-p2">快企务成立的公司不需额外提供资料， 快企务投资公司是贵司在香港的法定秘书，快企务代为申报所以贵司年审不需要提供额外资料</p>
        </div>
        <div class="exma-things-inner">
          <img class="exma-things-icon" src="../../assets/img/examination/HK/icon_1_3.png" alt="">
          <p class="exma-things-p1">年审费用</p>
          <p class="exma-things-p2">快企务全年秘书服务费用+政府费用实报实销（一次性支付）</p>
        </div>
        <div class="exma-things-inner">
          <img class="exma-things-icon" src="../../assets/img/examination/HK/icon_1_4.png" alt="">
          <p class="exma-things-p1">办理时间</p>
          <p class="exma-things-p2">办理时间为上年度年审到期后的3至5个工作日</p>
        </div>
        <div class="exma-things-inner">
          <img class="exma-things-icon" src="../../assets/img/examination/HK/icon_1_5.png" alt="">
          <p class="exma-things-p1">年审完成后所得数据</p>
          <p class="exma-things-p2">最新年度商业登记证BR，周年申报表NAR1，会计师认证文件，收据等年审资料一起免费快递到您的手中</p>
        </div>
      </div>
      <p class="exma-things-tips">
        <span style="font-weight: bold;color: rgba(47, 114, 235, 1);">温馨提示：</span>
        如超过到期日42天未年审，政府会按逾期的时间进行罚款。请在收到快企务年审通知后尽早办理！
      </p>
    </div>

    <div class="box" style="height: auto;background: rgba(242, 245, 250, 1);">
      <p class="box-p1">快企务优质秘书服务</p>
      <p class="box-p2">快企务为您提供更多服务</p>
      <div class="exma-serve">
        <img class="exma-serve-img" src="../../assets/img/examination/HK/pic_3_1.jpg" alt="">
        <img class="exma-serve-img" src="../../assets/img/examination/HK/pic_3_2.jpg" alt="">
        <img class="exma-serve-img" src="../../assets/img/examination/HK/pic_3_3.jpg" alt="">
        <img class="exma-serve-img" src="../../assets/img/examination/HK/pic_3_4.jpg" alt="">
        <img class="exma-serve-img" src="../../assets/img/examination/HK/pic_3_5.jpg" alt="">
        <img class="exma-serve-img" src="../../assets/img/examination/HK/pic_3_6.jpg" alt="">

      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'bvi-page',
  props: {
    msg: String
  },
  data(){
    return{
      form: {},
      activeName: '1'
    }
  },
  mounted(){
    window.scrollTo(0,0);
  },
  methods:{
    toAsk(){
      window.open('https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn','_blank')
    },
  }
}
</script>

<style scoped lang="scss">
  .m-bvi-banner{
    width: 100%;
    height: 460px;
    background: url('../../assets/img/examination/HK/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
</style>
