<template>
  <div class="home">
    <div class="m-bvi-banner">
      <p class="banner-p1">一站式越南公司注册服务</p>
      <p class="banner-p2">【快企务专注于涉内外企业服务十余年】</p>
      <p class="banner-btn banner-btn1" @click="toAsk">立即咨询</p>
    </div>

    <div class="box" style="height: 630px;">
      <p class="box-p1">注册越南公司的优势</p>
      <div class="mal-adv">
        <div class="mal-adv-inner">
          <img class="mal-adv-icon" src="../../assets/img/register/Vietnam/pic_1_1.png" alt="">
          <p class="mal-adv-p" style="font-weight: normal;">人口结构年轻，劳动成本低，处于人口红利</p>
        </div>
        <div class="mal-adv-inner">
          <img class="mal-adv-icon" src="../../assets/img/register/Vietnam/pic_1_2.png" alt="">
          <p class="mal-adv-p" style="font-weight: normal;">经济增长强劲，内需市场扩大</p>
        </div>
        <div class="mal-adv-inner">
          <img class="mal-adv-icon" src="../../assets/img/register/Vietnam/pic_1_3.png" alt="">
          <p class="mal-adv-p" style="font-weight: normal;">产业结构转型，第三产业增长迅速</p>
        </div>
        <div class="mal-adv-inner">
          <img class="mal-adv-icon" src="../../assets/img/register/Vietnam/pic_1_4.png" alt="">
          <p class="mal-adv-p" style="font-weight: normal;">电商行业增长快速，上升空间极大</p>
        </div>
      </div>
    </div>

    <div class="box" style="height: auto;padding-bottom: 30px;">
      <p class="box-p1">注册越南公司类型</p>
      <div class="type-content flex center-width">
        <img class="type-img" style="height: 110px;" src="../../assets/img/register/Vietnam/pic_2_1.jpg" alt="">
        <img class="type-img" style="height: 110px;" src="../../assets/img/register/Vietnam/pic_2_2.jpg" alt="">
        <img class="type-img" style="height: 110px;" src="../../assets/img/register/Vietnam/pic_2_3.jpg" alt="">
        <img class="type-img" @click="toAsk" style="height: 110px;" src="../../assets/img/register/Vietnam/pic_2_4.jpg" alt="">
      </div>
    </div>

    <div class="box" style="height: auto;padding-bottom: 15px;">
      <p class="box-p1">注册越南公司的条件/流程/资料</p>
      <div class="tab-box">
        <div class="tab-inner" :class="{'active': tab == 1}" @click="tab = 1">注册条件</div>
        <div class="tab-inner" :class="{'active': tab == 2}" @click="tab = 2">注册流程</div>
        <div class="tab-inner" :class="{'active': tab == 3}" @click="tab = 3">所得资料</div>
      </div>
      <div class="file-content">
        <div v-if="tab == 1">
          <div class="bri-flow">
            <div class="bri-flow-inner">
              <img class="bri-flow-icon" src="../../assets/img/register/Vietnam/pic_3_1.png" alt="">
              <p class="bri-flow-p">名称</p>
              <p class="bri-flow-p1">须为英文</p>
            </div>
            <div class="bri-flow-inner">
              <img class="bri-flow-icon" src="../../assets/img/register/Vietnam/pic_3_2.png" alt="">
              <p class="bri-flow-p">股东董事</p>
              <p class="bri-flow-p1">可以100%外国人</p>
            </div>
            <div class="bri-flow-inner">
              <img class="bri-flow-icon" src="../../assets/img/register/Vietnam/pic_3_3.png" alt="">
              <p class="bri-flow-p">注册资金</p>
              <p class="bri-flow-p1">10,000 ~ 300,000美金（还需看注册行业）</p>
            </div>
          </div>
        </div>
        <div v-if="tab == 2">
          <img class="tab2-img" src="../../assets/img/register/Vietnam/pic_4.jpg" alt="">
        </div>
        <div v-if="tab == 3">
          <div class="ger-tab3">
            <div class="ger-tab3-inner">
              <img class="ger-tab3-icon" src="../../assets/img/register/Vietnam/pic_5_1.png" alt="">
              <p class="ger-tab3-p">公司注册证书</p>
            </div>
            <div class="ger-tab3-inner">
              <img class="ger-tab3-icon" src="../../assets/img/register/Vietnam/pic_5_2.png" alt="">
              <p class="ger-tab3-p">公司章程</p>
            </div>
            <div class="ger-tab3-inner">
              <img class="ger-tab3-icon" src="../../assets/img/register/Vietnam/pic_5_3.png" alt="">
              <p class="ger-tab3-p">公司股东会议记录簿</p>
            </div>
            <div class="ger-tab3-inner">
              <img class="ger-tab3-icon" src="../../assets/img/register/Vietnam/pic_5_4.png" alt="">
              <p class="ger-tab3-p"> 法人公章、钢印</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <div class="box" style="height: 725px;background: rgba(242, 245, 250, 1);">
      <p class="box-p1">注册越南公司后为您提供的后续服务</p>
      <div class="re-serve">
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Vietnam/pic_6_1.png" alt="">
          <p class="re-serve-p1">年审/续牌</p>
          <p class="re-serve-p2">所有公司须按年度定时递交包含股东、董事、公司秘书和其他任何当年可能影响公司行政管理结构详情信息的公司年度报表</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Vietnam/pic_6_2.png" alt="">
          <p class="re-serve-p1">秘书服务</p>
          <p class="re-serve-p2">办公地址，公司周年报表年检，公司董事股东成员名册更新，在职证明与良好证明，代缴政府规费，修改公司章程等 </p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Vietnam/pic_6_3.png" alt="">
          <p class="re-serve-p1">做账报税</p>
          <p class="re-serve-p2">公司注册之后需按照当地政府单位要求进行做账报税</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Vietnam/pic_6_4.png" alt="">
          <p class="re-serve-p1">银行开户</p>
          <p class="re-serve-p2">开设公司注册所在地的银行账户，有利于公司随时接受贸易汇款，享受当地政策或规避外汇风险</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Vietnam/pic_6_5.png" alt="">
          <p class="re-serve-p1">税号申请</p>
          <p class="re-serve-p2">公司经营过程中的公司信息（如地址、股东、经营范围等）变更</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Vietnam/pic_6_6.png" alt="">
          <p class="re-serve-p1">公司注销</p>
          <p class="re-serve-p2">因破产、股东决议解散、存续期届满等原因需要注销的，应及时主动注销公司</p>
        </div>
      </div>
    </div>
    <div class="box" style="height: auto;padding-bottom: 30px;background: rgba(242, 245, 250, 1)">
      <p class="box-p1">越南公司注册常见问题</p>
      <p class="box-p1">解决您的一切疑问</p>
      <div class="question">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item title="越南公司注册的方式有哪些?" name="1">
            <p class="question-p">绝大多数国外公司或个人在越南开公司、建工厂，一般采用如下三种注册方式。
a.通过—家控股公司间接设立(单层控股)。
b.通过两家控股公司间接设立(双层控股)。
c.以个人名义或公司名义直接设立。</p>
          </el-collapse-item>
          <el-collapse-item title="注册越南公司的资金需要实际到位吗?" name="2">
            <p class="question-p">需要，一般需要1万美金到10万美金之间，越南公司注册成功后的3个月内验资到位。</p>
          </el-collapse-item>
          <el-collapse-item title="注册越南公司需要哪些资料?" name="3">
            <p class="question-p">a.注册资本（在取得公司注册证书90天内验资到位）；
b.注册地址（可由我方协助当地寻找）；c.营业范围；
d.法人名单（1位即可）；
e.董事名单（无国籍限制，外籍人士需在当地取得工作证及暂住证）；
f.股东名单、股份金额及股东财务证明（最少1位股东，股份金额无上限）；
g.所有股东、董事及法人护照扫描件/ 地址证明 （股东如是公司，请提供公司注册证书,）；
h.股东身份证明及财务证明需国内公证及越南驻中国使领馆认证。</p>
          </el-collapse-item>
        </el-collapse>
      </div>
      <p class="to-ask" @click="toAsk"> 
        <img class="to-ask-icon" src="../../assets/img/register/bvi/icon_6_customer_service.png" alt=""> 
        更多问题？立即咨询人工客服</p>
    </div>

    <div class="box" style="height: 480px;">
      <p class="box-p1">快企务的优势</p>
      <p class="box-p2">十余年注册越南公司经验</p>
      <div class="re-adv">
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_1.png" alt="">
          <p class="re-adv-p1">保密完善</p>
          <p class="re-adv-p2">保密系统完善，客户隐私不外泄</p>
        </div>
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_2.png" alt="">
          <p class="re-adv-p1">全程服务</p>
          <p class="re-adv-p2">一站式专业服务从注册公司到年审、开户，审计报税等</p>
        </div>
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_3.png" alt="">
          <p class="re-adv-p1">专业高效</p>
          <p class="re-adv-p2">根据需求，为客户制定专业快捷的解决方案</p>
        </div>
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_4.png" alt="">
          <p class="re-adv-p1">合同保障</p>
          <p class="re-adv-p2">正规机构，合同保障，费用透明，无隐形消费</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  data(){
    return{
      tab: 1,
      activeName: '1'
    }
  },
  mounted(){
    window.scrollTo(0,0);
  },
  methods:{
    toAsk(){
      window.open('https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn','_blank')
    },
  }
}
</script>
<style>
  .m-bvi-banner{
    width: 100%;
    height: 460px;
    background: url('../../assets/img/register/Vietnam/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
</style>
