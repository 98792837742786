<template>
  <div class="certification m-bvi">
    <div class="odi-banner">
      <p class="odi-banner-p1">ODI企业境外投资备案一站式服务</p>
      <p class="odi-banner-p2">让您的资金进出境合法合规，为企业走出去保驾护航</p>
      <p class="odi-banner-p3">新设海外子公司丨海外并购/参股丨资金合法出入境</p>
      <p class="banner-btn banner-btn1" style="margin-top: 50px">立即办理</p>
    </div>
    
    <div class="box" style="height: 638px;">
      <p class="box-p1">ODI企业境外投资备案介绍</p>
      <p class="box-p2">为企业提供专业的ODI企业境外投资备案服务，精准、高效地为您的企业提供帮助</p>
      <img class="odi-img1" src="../../assets/img/finance/ODI/pic_1.jpg" alt="">
      <p class="odi-p1">企业境外投资证书，简称“ODI境外投资备案"，根据《企业境外投资投资管理办法》规定，凡是内地企业想要到香港澳门或者国外地区进行新设子公司、并购/参股等投资行为，都必须办理ODI境外投资备案，否则就是违规操作，银行将限制其境内境外公司的资金进出入境，这就是为什么很多企业到银行转账出入境时，会被银行告知要先办理ODI境外投资备案的原因。<br />
自2016年11月开始，ODI企业境外投资备案相关审批部门要求企业提供前期工作落实情况说明包括：尽职调查、可行性研究报告、投资资金来源情况说明、投资环境分析评价等，舒心企服充分理解相关审批部门的着重关注点及不同阶段审批过程的不同政策倾向，同时具备编写境外投资项目备案材料的丰富经验，我们有能力向我们的客户提供一站式全流程办理服务，办理完成ODI境外投资备案后，客户即可直接把投资款打到境外，境外公司后期盈利了，也可以通过ODI直接结汇回来。
</p>
    <p class="form-submit" style="width: 96px;margin-left: 5%;">立即咨询</p>
    </div>
    <!-- <div class="box" style="height: 375px;background: rgba(242, 245, 250, 1);">
      <p class="box-p1">哪些企业需要办理ODI企业境外投资备案</p>
      <p class="box-p2">为企业提供专业的ODI企业境外投资备案服务，精准、高效地为您的企业提供帮助</p>

    </div> -->

    <div class="box" style="height: 550px;">
      <p class="box-p1">境外投资备案全程服务</p>
      <p class="box-p2">为企业提供专业的ODI企业境外投资备案服务，精准、高效地为您的企业提供帮助</p>
      <div class="odi-serve">
        <div class="odi-serve-inner">
          <img class="odi-serve-img" src="../../assets/img/finance/ODI/pic_3_1.jpg" alt="">
          <p class="odi-serve-p">新设类ODI境外投资备案</p>
        </div>
        <div class="odi-serve-inner">
          <img class="odi-serve-img" src="../../assets/img/finance/ODI/pic_3_2.jpg" alt="">
          <p class="odi-serve-p">新设类ODI境外投资备案</p>
        </div>
        <div class="odi-serve-inner">
          <img class="odi-serve-img" src="../../assets/img/finance/ODI/pic_3_3.jpg" alt="">
          <p class="odi-serve-p">新设类ODI境外投资备案</p>
        </div>
        <div class="odi-serve-inner">
          <img class="odi-serve-img" src="../../assets/img/finance/ODI/pic_3_4.jpg" alt="">
          <p class="odi-serve-p">境外投资变更备案</p>
        </div>
        <div class="odi-serve-inner">
          <img class="odi-serve-img" src="../../assets/img/finance/ODI/pic_3_5.jpg" alt="">
          <p class="odi-serve-p">ODI境外投资备案补办</p>
        </div>
        <div class="odi-serve-inner">
          <img class="odi-serve-img" src="../../assets/img/finance/ODI/pic_3_6.jpg" alt="">
          <p class="odi-serve-p">37号文个人境外投资备案</p>
        </div>
        <div class="odi-serve-inner">
          <img class="odi-serve-img" src="../../assets/img/finance/ODI/pic_3_7.jpg" alt="">
          <p class="odi-serve-p">海外公司注册</p>
        </div>
        <div class="odi-serve-inner">
          <img class="odi-serve-img" src="../../assets/img/finance/ODI/pic_3_8.jpg" alt="">
          <p class="odi-serve-p">离岸账户开户</p>
        </div>
      </div>
    </div>

    <div class="box" style="height: 564px;background: rgba(242, 245, 250, 1);">
      <p class="box-p1">ODI境外投资备案流程</p>
      <p class="box-p2">为企业提供专业的ODI企业境外投资备案服务，精准、高效地为您的企业提供帮助</p>
      <div class="odi-flo" >
        <p class="odi-flo-p1">第一步：撰写说明书</p>
        <p class="odi-flo-p2">说明书包含内容：尽职调查报告、可行性研究报告、项目行业分析、投资地投资分析、项目方案、境内组织架构</p>
        <p class="odi-flo-p1" style="padding-top: 60px;">第二步：提交商务部</p>
        <p class="odi-flo-p2">整理商务部盖章资料,提交商务部网上信息,领取回执</p>
        <p class="odi-flo-p1" style="padding-top: 55px;">第三步：提交发改委</p>
        <p class="odi-flo-p2">整理发改委盖章资料,提交发改委网上信息,领取回执</p>
        <p class="odi-flo-p1" style="padding-top: 60px;"  >第四步：银行外汇咨询</p>
        <p class="odi-flo-p2">银行外汇业务咨询</p>
      </div>
    </div>

    <div class="box" style="height: 719px;">
      <p class="box-p1">所需提供资料</p>
      <p class="box-p2">申请部门与所需资料</p>
      <div class="odi-file-box">
        <p class="odi-file-title">申请部门</p>
        <p class="odi-file-p">
          <img class="odi-file-icon" src="../../assets/img/finance/ODI/icon_1_1.png" alt="">
          市商务部
        </p>
        <p class="odi-file-p">
          <img class="odi-file-icon" src="../../assets/img/finance/ODI/icon_1_1.png" alt="">
          发改委(深圳发展和改革委员会)
        </p>
        <p class="odi-file-p">
          <img class="odi-file-icon" src="../../assets/img/finance/ODI/icon_1_1.png" alt="">
          银行开户行
        </p>
      </div>
      <div class="odi-file-box" style="height: 352px;">
        <p class="odi-file-title" style="background: rgba(237, 41, 65, 1);">所需材料</p>
        <p class="odi-file-p">
          <img class="odi-file-icon" src="../../assets/img/finance/ODI/icon_1_2.png" alt="">
          境内企业审计报告
        </p>
        <p class="odi-file-p1">审计报告必须盈利，所有者权益接近投资额（深圳企业，报告必须备案）</p>
        <p class="odi-file-p">
          <img class="odi-file-icon" src="../../assets/img/finance/ODI/icon_1_2.png" alt="">
          发改委(深圳发展和改革委员会)
        </p>
        <p class="odi-file-p1">内容为存款证明，证明上余额必须大于投资额</p>
        <p class="odi-file-p">
          <img class="odi-file-icon" src="../../assets/img/finance/ODI/icon_1_2.png" alt="">
          银行开户行
        </p>
        <p class="odi-file-p1">未注册前必须核名，避免ODI办理完成，名称无法注册章程必须提供中外文版的</p>
        <p class="odi-file-p">
          <img class="odi-file-icon" src="../../assets/img/finance/ODI/icon_1_2.png" alt="">
          项目信息
        </p>
        <p class="odi-file-p1">需客户提供境外企业项目开展业务内容、费用预计支出，由我司完善撰写说明书</p>
      </div>
    </div>

    <div class="box" style="height: 540px;background: rgba(242, 245, 250, 1);">
      <p class="box-p1">涉及如下境外投资备案类型很难通过</p>
      <p class="box-p2">无法判断?咨询顾问为您优化分析</p>
      <div class="odi-pass">
        <div class="odi-pass-inner">
          <img class="odi-pass-icon" src="../../assets/img/finance/ODI/icon_2_1.png" alt="">
          <p class="odi-pass-p">合伙企业，母小子大、快设快出</p>
        </div>
        <div class="odi-pass-inner">
          <img class="odi-pass-icon" src="../../assets/img/finance/ODI/icon_2_2.png" alt="">
          <p class="odi-pass-p">资金来源、合伙人背景说不清</p>
        </div>
        <div class="odi-pass-inner">
          <img class="odi-pass-icon" src="../../assets/img/finance/ODI/icon_2_3.png" alt="">
          <p class="odi-pass-p">境外投资项目无法说明真实性</p>
        </div>
      </div>
    </div>
   

    <div class="box" style="height: auto;padding-bottom: 30px;">
      <p class="box-p1">财税筹划专业指导</p>
      <p class="box-p2">财税筹划中常见的专业问题</p>
      <div class="question">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item title="国内主体公司不满1年可以对外投资吗？" name="1">
            <p class="question-p">可以，但备案金额受限</p>
          </el-collapse-item>
          <el-collapse-item title="国内主体公司股东有金融公司或外资公司也能做境外投资吗？" name="2">
            <p class="question-p"></p>
          </el-collapse-item>
          <el-collapse-item title="境外主体股东出现自然人可以吗？" name="3">
            <p class="question-p">可以，但自然人不能是中国人或曾经是中国人</p>
          </el-collapse-item>
          <el-collapse-item title="先注册国外子公司还是先办理境外投资证书？程序有要求吗？" name="4">
            <p class="question-p">可同时进行，没具体要求</p>
          </el-collapse-item>
          <el-collapse-item title="对外投资办下来后续还要做什么？" name="5">
            <p class="question-p">需要每年在商务部，发改委做年审，如有资金汇出，要做月报。</p>
          </el-collapse-item>
        </el-collapse>
      </div>
      <p class="to-ask" @click="toAsk"> 
        <img class="to-ask-icon" src="../../assets/img/register/bvi/icon_6_customer_service.png" alt=""> 
        更多问题？立即咨询人工客服</p>
    </div>

    <div class="box" style="height: 675px;background: rgba(242, 245, 250, 1);">
      <p class="box-p1">增值服务</p>
      <p class="box-p2">我们还为您提供</p>
      <div class="odi-add">
        <div class="odi-add-inner">
          <img class="odi-add-icon" src="../../assets/img/finance/ODI/icon_3_1.png" alt="">
          <p class="odi-add-p">境外公司注册</p>
        </div>
        <div class="odi-add-inner">
          <img class="odi-add-icon" src="../../assets/img/finance/ODI/icon_3_2.png" alt="">
          <p class="odi-add-p">境外银行开户</p>
        </div>
        <div class="odi-add-inner">
          <img class="odi-add-icon" src="../../assets/img/finance/ODI/icon_3_3.png" alt="">
          <p class="odi-add-p">IPO业务咨询</p>
        </div>
        <div class="odi-add-inner">
          <img class="odi-add-icon" src="../../assets/img/finance/ODI/icon_3_4.png" alt="">
          <p class="odi-add-p">税务筹划</p>
        </div>
        <div class="odi-add-inner">
          <img class="odi-add-icon" src="../../assets/img/finance/ODI/icon_3_5.png" alt="">
          <p class="odi-add-p">VAT税号的申请和申报</p>
        </div>
        <div class="odi-add-inner">
          <img class="odi-add-icon" src="../../assets/img/finance/ODI/icon_3_6.png" alt="">
          <p class="odi-add-p">全球商标申请</p>
        </div>
        <div class="odi-add-inner">
          <img class="odi-add-icon" src="../../assets/img/finance/ODI/icon_3_7.png" alt="">
          <p class="odi-add-p">香港及境外公证</p>
        </div>
        <div class="odi-add-inner">
          <img class="odi-add-icon" src="../../assets/img/finance/ODI/icon_3_8.png" alt="">
          <p class="odi-add-p">代持和法律意见书等服务</p>
        </div>
      </div>
    </div>
  
  </div>
</template>
<script>
export default {
  name: 'bvi-page',
  props: {
    msg: String
  },
  data(){
    return{
      form: {},
      activeName: '1'
    }
  },
  mounted(){
    window.scrollTo(0,0);
  },
  methods:{
    toAsk(){
      window.open('https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn','_blank')
    },
  }
}
</script>

<style scoped lang="scss">
  .odi-banner{
    width: 100%;
    height: 460px;
    background: url('../../assets/img/finance/ODI/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
  .odi-flo{
    background: url('../../assets/img/finance/ODI/pic_4.png') no-repeat center center;
    background-size: 100% 100%;
  }
  


</style>
