<template>
  <div class="certification m-bvi">
    <div class="m-bvi-banner">
      <p class="finance-banner-p" style="padding-top: 63px">欧盟商标注册</p>
      <p class="finance-banner-p2">迄今为止，欧盟共有27个成员国，分别是：英国、法国、德国、意大利、荷兰、比利时、卢森堡、丹麦、爱尔兰、希腊、葡萄牙、西班牙、奥地利、瑞典、芬兰、马耳他、塞浦路斯、波兰、匈牙利、捷克、斯洛伐克、斯洛文尼亚、爱沙尼亚、拉脱维亚、立陶宛、罗马尼亚、保加利亚</p>
    </div>
    <div class="box" style="height: 844px;background: rgba(242, 245, 250, 1);">
      <p class="box-p1">欧盟商标特点</p>
      <div class="danger-box">
        <div class="danger-inner">
          <p class="danger-p1">费用低</p>
          <p class="danger-p2">只须申请注册一次，即可在整个欧盟的十五个成员国使用该商标。较之于在各个成员国分别提出申请，费用大幅度减少</p>
        </div>
        <div class="danger-inner">
          <p class="danger-p1">保护程序集中化</p>
          <p class="danger-p2">一件商标注册可获得欧盟15个成员国的保护，有关商标案件的裁决将在欧盟所有的国家得到执行</p>
        </div>
        <div class="danger-inner">
          <p class="danger-p1">已注册之商标可仅在一个欧盟国家使用</p>
          <p class="danger-p2">商标在欧盟任何一个国家的使用就足以对抗以未使用商标为由提出的撤销申请</p>
        </div>
        <div class="danger-inner">
          <p class="danger-p1">享有巴黎公约优先权</p>
          <p class="danger-p2">同一商标用于一种或多种指定商品或服务名称，在巴黎公约成员国申请后6个月申请共同体商标时，可享有优先权</p>
        </div>
        <div class="danger-inner">
          <p class="danger-p1">拥有优先权</p>
          <p class="danger-p2">已经在一个欧盟成员国公布的注册商标，在申请欧盟商标时，可请求优先权</p>
        </div>
        <div class="danger-inner">
          <p class="danger-p1">注册种类多</p>
          <p class="danger-p2">不但词语、标识等传统商标可获得注册，声音、气味、产品外观及构造等新型商标，也可申请注册</p>
        </div>
        <div class="danger-inner">
          <p class="danger-p1">商标可涵盖3类</p>
          <p class="danger-p2">一件商标申请最多可涵盖3类商品或服务，超过3类的，每类费用须另加</p>
        </div>
      </div>
    </div>
    <div class="box" style="height: 662px;">
      <p class="box-p1">办理欧盟商标申请所需材料</p>
      <div class="trademark-file">
        <p class="trademark-file-p1" style="width: 340px;">以法人申请，附《营业执照》或有效登记证明复印件1份；以自然人申请附个人身份证明文件1份；</p>
        <p class="trademark-file-p1" style="width: 340px;">商标图样5份，要求清晰、纸张质量好。如是彩色商标，需递交1份黑白稿，5份彩稿；规格不得小于 5cm×5cm，最大不超过 10cm×20cm；</p>
        <p class="trademark-file-p1" style="width: 340px;">填写申请表1份，申请表必须由申请人签署或由代理人签署；</p>
        <p class="trademark-file-p1" style="width: 340px;">列出寻求注册的商品或者服务，指出商标类别，可从本网站，商标分类表，中查询；</p>
        <p class="trademark-file-p1" style="width: 340px;">商标注册委托书，申请人必须在该委托书上签字盖章；</p>
        <img class="trademark-file-img" style="width: 280px;height: 256px;" src="../../assets/img/finance/EUTrademark/pic_1.jpg" alt="">
      </div>
    </div>
    <div class="box" style="height: 614px;background: rgba(242, 245, 250, 1);">
      <p class="box-p1">欧盟可注册的商标</p>
      <div class="trademark-file">
        <p class="trademark-file-p1" style="width: 340px;">欧盟商标可包括任何可用图表代表的标记，特别是文字，包括人名、图案、字母、数字、商品形状</p>
        <p class="trademark-file-p1" style="width: 340px;">其包装外观，只要这些标记能够把一种商品或服务用途同其它种类的用途区分开来。 因此，以下标记可以用来作为商标进行注册：</p>
        <p class="trademark-file-p2">文字标记包括字母、数字、或字母、数字和文字的组合；</p>
        <p class="trademark-file-p2">包含或不包含文字的图形标记</p>
        <p class="trademark-file-p2">彩色图形标记</p>
        <p class="trademark-file-p2">颜色或多种颜色的组合</p>
        <p class="trademark-file-p2">三维立体标记</p>
        <p class="trademark-file-p2">声音标记等</p>
        <img class="trademark-file-img" style="width: 280px;height: 214px;" src="../../assets/img/finance/EUTrademark/pic_2.jpg" alt="">
      </div>
    </div>
    <div class="box" style="height: 449px;">
      <p class="box-p1">欧盟商标的注册程序</p>
      <div class="trademark-flow">
        <p class="trademark-flow-p1">
          <img class="trademark-flow-icon" src="../../assets/img/finance/innerTrademark/pic_2_1.png" alt="">
          <span class="trademark-flow-span1">第一步：</span>
          <span class="trademark-flow-span2">申请人向欧盟商标局或欧盟成员国商标局提出申请</span> 
        </p>
        <p class="trademark-flow-p1">
          <img class="trademark-flow-icon" src="../../assets/img/finance/innerTrademark/pic_2_2.png" alt="">
          <span class="trademark-flow-span1">第二步：</span>
          <span class="trademark-flow-span2">欧盟商标局将申请通知各成员国的商标主管机关，以进行各国的商标审查</span> 
        </p>
        <p class="trademark-flow-p1">
          <img class="trademark-flow-icon" src="../../assets/img/finance/innerTrademark/pic_2_3.png" alt="">
          <span class="trademark-flow-span1">第三步：</span>
          <span class="trademark-flow-span2">审查通过后，进行三个月的公告。在此期间第三者可提出各种异议</span> 
        </p>
        <p class="trademark-flow-p1">
          <img class="trademark-flow-icon" src="../../assets/img/finance/innerTrademark/pic_2_4.png" alt="">
          <span class="trademark-flow-span1">第四步：</span>
          <span class="trademark-flow-span2">如果没有第三者提出异议，申请的商标在一年内获准注册</span> 
        </p>
        <p class="trademark-flow-p1">
          <img class="trademark-flow-icon" src="../../assets/img/finance/innerTrademark/pic_2_5.png" alt="">
          <span class="trademark-flow-span1">第五步：</span>
          <span class="trademark-flow-span2">需时12-15个月</span> 
        </p>
        <p class="tips">（商标检索： 由于欧盟商标注册涵盖的国家众多，导致潜在的商标异议人大量存在。因此，我司极力推荐申请人在提出欧洲商标注册申请前，进行商标检索，以确定是否有与拟注册商标相同或太相似的商标已被第三者注册，及避免不必要的损失）</p>
      </div>
    </div>
    <div class="box" style="height: auto;padding-bottom: 30px;background: rgba(242, 245, 250, 1)">
      <p class="box-p1">常见问题</p>
      <div class="question">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item title="欧盟成员国名单？" name="1">
            <p class="question-p">德国、法国、意大利、比利时、卢森堡、丹麦、瑞典、西班牙、葡萄牙、芬兰、希腊、奥地利、荷兰、爱尔兰、塞浦路斯、捷克、爱沙尼亚、匈牙利、拉脱维亚、立陶宛、马耳他、波兰、斯洛伐克、斯洛维尼亚、罗马尼亚、保加利亚、克罗地亚。</p>
          </el-collapse-item>
          <el-collapse-item title="注册欧盟商标还是注册单一国？" name="2">
            <p class="question-p">二者都是商标在其他国家保护的手段，尽量选择逐一国注册，当出现纠纷的时候逐一国注册的商标对申请人
更有利。欧盟在一次性申请国家比较多有价格优势。</p>
          </el-collapse-item>
          <el-collapse-item title="欧盟商标注册容易通过吗？" name="3">
            <p class="question-p">任何国家或者组织联盟的商标注册之前都需要经过查询之后才能知道商标是否可以注册成功。如果需要注册请
联系顾问查询。</p>
          </el-collapse-item>
          <el-collapse-item title="商标只有10年有效吗？" name="4">
            <p class="question-p">10年到期以后可以申请续展，理论上商标是持续有效的。</p>
          </el-collapse-item>
        </el-collapse>
      </div>
      <p class="to-ask" @click="toAsk"> 
        <img class="to-ask-icon" src="../../assets/img/register/bvi/icon_6_customer_service.png" alt=""> 
        更多问题？立即咨询人工客服</p>
    </div>
    <div class="box" style="height: 418px;background: #fff;">
      <p class="box-p1">为什么选择我们</p>
      <div class="why-us">
        <div class="why-us-inner">
          <img class="why-us-icon" src="../../assets/img/finance/innerTrademark/icon_3_1.png" alt="">
          <p class="why-us-p1">快速</p>
          <p class="why-us-p2">一对一顾问服务，快速响应客户需求</p>
        </div>
        <div class="why-us-inner">
          <img class="why-us-icon" src="../../assets/img/finance/innerTrademark/icon_3_2.png" alt="">
          <p class="why-us-p1">专业</p>
          <p class="why-us-p2">十余年专业代理国内外商标注册相关业务</p>
        </div>
        <div class="why-us-inner">
          <img class="why-us-icon" src="../../assets/img/finance/innerTrademark/icon_3_3.png" alt="">
          <p class="why-us-p1">严谨</p>
          <p class="why-us-p2">商标顾问、质检部经理、客户三重质检、多重把关</p>
        </div>
        <div class="why-us-inner">
          <img class="why-us-icon" src="../../assets/img/finance/innerTrademark/icon_3_4.png" alt="">
          <p class="why-us-p1">保障</p>
          <p class="why-us-p2">知识产权的商标注册成功率高达98.3%</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'bvi-page',
  props: {
    msg: String
  },
  data(){
    return{
      form: {},
      activeName: '1'
    }
  },
  mounted(){
    window.scrollTo(0,0);
  },
  methods:{
    toAsk(){
      window.open('https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn','_blank')
    },
  }
}
</script>

<style scoped lang="scss">
  .m-bvi-banner{
    width: 100%;
    height: 230px;
    background: url('../../assets/img/finance/EUTrademark/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
</style>
