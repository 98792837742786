<template>
  <div class="home">
    <div class="m-bvi-banner">
      <p class="banner-p1">一站式德国公司注册服务</p>
      <p class="banner-p2">【快企务专注于涉内外企业服务十余年】</p>
      <p class="banner-btn banner-btn1" @click="toAsk">立即咨询</p>
    </div>

    <div class="box" style="height:612px;background: rgba(242, 245, 250, 1);">
      <p class="box-p1">注册德国公司优势</p>
      <div class="adv-content">
        <div class="adv-line" style="height: 97px;align-items: flex-start;">
          <img class="adv-icon" style="width: 42px;margin-top: 20px;" src="../../assets/img/register/Germany/pic_1_1.png" alt="">
          <div>
            <p class="adv-p" style="font-weight: bold;padding-top: 20px;">国际品牌</p>
            <p class="adv-p5">德国品牌以品质精良著称，技术领先，做工细致</p>
          </div>
        </div>
        <div class="adv-line" style="height: 97px;">
          <img class="adv-icon" style="width: 42px;" src="../../assets/img/register/Germany/pic_1_2.png" alt="">
          <div>
            <p class="adv-p">跨境电商</p>
            <p class="adv-p5">德国电子商务市场在欧洲是最强大的力量，占欧洲电子商务营业额的25%</p>
          </div>
        </div>
        <div class="adv-line" style="height: 97px;"> 
          <img class="adv-icon" style="width: 42px;" src="../../assets/img/register/Germany/pic_1_3.png" alt="">
          <div>
            <p class="adv-p">开设境外账户</p>
            <p class="adv-p5">非德国公民在注册成立多公司后，可自由在境内或境外经营</p>
          </div>
        </div>
        <div class="adv-line" style="height: 97px;">
          <img class="adv-icon" style="width: 42px;" src="../../assets/img/register/Germany/pic_1_4.png" alt="">
          <div>
            <p class="adv-p">实地经营</p>
            <p class="adv-p5">德国非常重视对外投资和招商引资，也很重视与其他国家的合作</p>
          </div>
        </div>
        <div class="adv-line" style="height: 97px;">
          <img class="adv-icon" style="width: 42px;" src="../../assets/img/register/Germany/pic_1_5.png" alt="">
          <div>
            <p class="adv-p">移民及签证</p>
            <p class="adv-p5">德国投资移民要求投资人在德国成立一家公司，最少注册资金是25,000欧元</p>
          </div>
        </div>
      </div>
    </div>

    <div class="box" style="height: auto;padding-bottom: 30px;">
      <p class="box-p1">注册德国公司类型</p>
      <div class="type-content flex center-width">
        <img class="type-img" style="height: 110px;" src="../../assets/img/register/Germany/pic_2_1.jpg" alt="">
        <img class="type-img" style="height: 110px;" src="../../assets/img/register/Germany/pic_2_2.jpg" alt="">
      </div>
    </div>
    <div class="box bg-box" style="height: auto;padding-bottom: 15px;">
      <p class="box-p1">注册德国公司条件/流程/资料</p>
      <div class="tab-box">
        <div class="tab-inner" :class="{'active': tab == 1}" @click="tab = 1">注册条件</div>
        <div class="tab-inner" :class="{'active': tab == 2}" @click="tab = 2">注册流程</div>
        <div class="tab-inner" :class="{'active': tab == 3}" @click="tab = 3">所得资料</div>
      </div>
      <div class="file-content flex center-width">
        <div v-if="tab == 1">
          <div class="tab1-inner">
            <img class="tab1-icon" src="../../assets/img/register/Germany/pic_3_1.png" alt="">
            <div class="tab1-right">
              <p class="tab1-p1">公司名称</p>
              <p class="tab1-p2">企业名称需经审查，确保不涉及侵权</p>
            </div>
          </div>
          <div class="tab1-inner">
            <img class="tab1-icon" src="../../assets/img/register/Germany/pic_3_2.png" alt="">
            <div class="tab1-right">
              <p class="tab1-p1">法人股东要求</p>
              <p class="tab1-p2">一名或一名以上自然人或法人担任股东，任命至少一名自然人作为公司法人代表</p>
            </div>
          </div>
          <div class="tab1-inner">
            <img class="tab1-icon" src="../../assets/img/register/Germany/pic_3_3.png" alt="">
            <div class="tab1-right">
              <p class="tab1-p1">签字委托书</p>
              <p class="tab1-p2">管理人员签名的委任书，授权当地代理人向主管的商业和公司登记处提交登记文件</p>
            </div>
          </div>
          <div class="tab1-inner">
            <img class="tab1-icon" src="../../assets/img/register/Germany/pic_3_4.png" alt="">
            <div class="tab1-right">
              <p class="tab1-p1">注册资本</p>
              <p class="tab1-p2">注册资本金不低于25,000欧元（可到账50%进行验资）</p>
            </div>
          </div>
          <div class="tab1-inner">
            <img class="tab1-icon" src="../../assets/img/register/Germany/pic_3_5.png" alt="">
            <div class="tab1-right">
              <p class="tab1-p1">身份信息</p>
              <p class="tab1-p2">至少提供1名股东或高级职员的护照复印件</p>
            </div>
          </div>
          <div class="tab1-inner">
            <img class="tab1-icon" src="../../assets/img/register/Germany/pic_3_6.png" alt="">
            <div class="tab1-right">
              <p class="tab1-p1">经营范围</p>
              <p class="tab1-p2">营业范围需经审查，不能涉及违禁品、需特殊批准的货品贸易</p>
            </div>
          </div>
        </div>
        <div v-if="tab == 2">
          <div class="ger-tab2">
            <div class="ger-tab2-inner">
              <img class="ger-tab2-icon" src="../../assets/img/register/Germany/pic_4_1.png" alt="">
              <p class="ger-tab2-p1">步骤一</p>
              <p class="ger-tab2-p2">签署委托协议</p>
            </div>
            <div class="ger-tab2-inner">
              <img class="ger-tab2-icon" src="../../assets/img/register/Germany/pic_4_2.png" alt="">
              <p class="ger-tab2-p1">步骤二</p>
              <p class="ger-tab2-p2">提供注册文件</p>
            </div>
            <div class="ger-tab2-inner">
              <img class="ger-tab2-icon" src="../../assets/img/register/Germany/pic_4_3.png" alt="">
              <p class="ger-tab2-p1">步骤三</p>
              <p class="ger-tab2-p2">交付费用</p>
            </div>
            <div class="ger-tab2-inner">
              <img class="ger-tab2-icon" src="../../assets/img/register/Germany/pic_4_4.png" alt="">
              <p class="ger-tab2-p1">步骤四</p>
              <p class="ger-tab2-p2">专业律师团队办理</p>
            </div>
            <div class="ger-tab2-inner">
              <img class="ger-tab2-icon" src="../../assets/img/register/Germany/pic_4_5.png" alt="">
              <p class="ger-tab2-p1">步骤五</p>
              <p class="ger-tab2-p2">德国政府验资</p>
            </div>
            <div class="ger-tab2-inner">
              <img class="ger-tab2-icon" src="../../assets/img/register/Germany/pic_4_6.png" alt="">
              <p class="ger-tab2-p1">步骤六</p>
              <p class="ger-tab2-p2">成立拥有德国公司</p>
            </div>
          </div>
        </div>
        <div v-if="tab == 3">
         <div class="ger-tab3">
          <div class="ger-tab3-inner">
            <img class="ger-tab3-icon" src="../../assets/img/register/Germany/pic_5_1.png" alt="">
            <p class="ger-tab3-p">德国公司注册证书（营业执照）</p>
          </div>
          <div class="ger-tab3-inner">
            <img class="ger-tab3-icon" src="../../assets/img/register/Germany/pic_5_2.png" alt="">
            <p class="ger-tab3-p">德国公司注册证书（营业执照）</p>
          </div>
          <div class="ger-tab3-inner">
            <img class="ger-tab3-icon" src="../../assets/img/register/Germany/pic_5_3.png" alt="">
            <p class="ger-tab3-p">德国公司注册证书（营业执照）</p>
          </div>
          <div class="ger-tab3-inner">
            <img class="ger-tab3-icon" src="../../assets/img/register/Germany/pic_5_4.png" alt="">
            <p class="ger-tab3-p">德国公司注册证书（营业执照）</p>
          </div>
          <div class="ger-tab3-inner">
            <img class="ger-tab3-icon" src="../../assets/img/register/Germany/pic_5_5.png" alt="">
            <p class="ger-tab3-p">德国公司注册证书（营业执照）</p>
          </div>
         </div>
        </div>
      </div>
    </div>
    
    <div class="box" style="height: 725px;">
      <p class="box-p1">注册德国公司后为您提供的后续服务</p>
      <div class="re-serve">
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Germany/pic_6_1.png" alt="">
          <p class="re-serve-p1">年审/续牌</p>
          <p class="re-serve-p2">每年缴纳一定金额的续牌费用，并换领新一年度的公司注册证书</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Germany/pic_6_2.png" alt="">
          <p class="re-serve-p1">做账报税</p>
          <p class="re-serve-p2">在德国公司所在地税务局进行申报。公司营业税可采用月报、季报和年报三种方式</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Germany/pic_6_3.png" alt="">
          <p class="re-serve-p1">银行开户</p>
          <p class="re-serve-p2">德国银行账号类型较多，可分为流通账户、活期存款账户、定期存款账户以及普通存款账户等</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Germany/pic_6_4.png" alt="">
          <p class="re-serve-p1">税号申请</p>
          <p class="re-serve-p2">在德国开设的新企业应在当地的税务局申请欧盟税号，它相当于德国税务制度的公司编号。我司可全程代理申请德国VAT税号</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Germany/pic_6_5.png" alt="">
          <p class="re-serve-p1">秘书服务</p>
          <p class="re-serve-p2">注册代理人或本地人代理人服务，办公地址，公司周年报表年检，公司董事股东成员名册更新，在职证明与良好证明，代缴政府规费，修改公司章程等</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Germany/pic_6_6.png" alt="">
          <p class="re-serve-p1">公司注销</p>
          <p class="re-serve-p2">公司不经营或经营业务已结束，需要及时主动注销，否则会被列入德国工商异常经营名单和税务黑名单，将影响法人股东在德国的诚信黑名单</p>
        </div>
      </div>
    </div>

    <div class="box" style="height: auto;padding-bottom: 30px;background: rgba(242, 245, 250, 1)">
      <p class="box-p1">德国公司注册常见问题</p>
      <p class="box-p1">解决您的一切疑问</p>
      <div class="question">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item title="注册德国公司需要什么资料？" name="1">
            <p class="question-p">注册公司你提供公司名称和有效证件，我司可以代为办理。具体事项可以点击咨询在线顾问。</p>
          </el-collapse-item>
          <el-collapse-item title="注册德国公司注册时间需要多久？" name="2">
            <p class="question-p">客户无需亲自到海外办理，可委托代办一切申办手续，完成注册一般需时5-6周。由于文件的寄送和具体问题的往返可能会有所延误，但一般都不会发生这种问题，具体时间可以点击咨询在线顾问。 </p>
          </el-collapse-item>
        </el-collapse>
      </div>
      <p class="to-ask" @click="toAsk"> 
        <img class="to-ask-icon" src="../../assets/img/register/bvi/icon_6_customer_service.png" alt=""> 
        更多问题？立即咨询人工客服</p>
    </div>
    <div class="box" style="height: 480px;">
      <p class="box-p1">快企务的优势</p>
      <p class="box-p2">十余年注册德国公司经验</p>
      <div class="re-adv">
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_1.png" alt="">
          <p class="re-adv-p1">保密完善</p>
          <p class="re-adv-p2">保密系统完善，客户隐私不外泄</p>
        </div>
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_2.png" alt="">
          <p class="re-adv-p1">全程服务</p>
          <p class="re-adv-p2">一站式专业服务从注册公司到年审、开户，审计报税等</p>
        </div>
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_3.png" alt="">
          <p class="re-adv-p1">专业高效</p>
          <p class="re-adv-p2">根据需求，为客户制定专业快捷的解决方案</p>
        </div>
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_4.png" alt="">
          <p class="re-adv-p1">合同保障</p>
          <p class="re-adv-p2">正规机构，合同保障，费用透明，无隐形消费</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  data(){
    return{
      tab: 1,
      activeName: '1'
    }
  },
  mounted(){
    window.scrollTo(0,0);
  },
  methods:{
    toAsk(){
      window.open('https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn','_blank')
    },
  }
}
</script>
<style scoped>
  .m-bvi-banner{
    width: 100%;
    height: 460px;
    background: url('../../assets/img/register/Germany/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
</style>
