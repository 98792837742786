<template>
  <div class="home">
    <div class="m-bvi-banner">
      <p class="banner-p2" style="margin-top: 0;padding-top: 80px">快企务祝您</p>
      <p class="banner-p1" style="padding-top: 20px;width: 318px;margin: 0 auto;">在全球最热门的国家/地区注册公司</p>
      <p class="banner-p2">与国际接轨</p>
      <p class="banner-btn banner-btn1" @click="toAsk">立即咨询</p>
    </div>
    
    <div class="box" style="height: auto;">
      <p class="box-p1">注册海外公司的用途</p>
      <div class="global-use">
        <div class="global-use-inner">
          <img class="global-use-icon" src="../../assets/img/register/overseas/pic_1_1.png" alt="">
          <p class="global-use-p">税务筹划</p>
        </div>
        <div class="global-use-inner">
          <img class="global-use-icon" src="../../assets/img/register/overseas/pic_1_2.png" alt="">
          <p class="global-use-p">控股其它公司</p>
        </div>
        <div class="global-use-inner">
          <img class="global-use-icon" src="../../assets/img/register/overseas/pic_1_3.png" alt="">
          <p class="global-use-p">国际贸易</p>
        </div>
        <div class="global-use-inner">
          <img class="global-use-icon" src="../../assets/img/register/overseas/pic_1_4.png" alt="">
          <p class="global-use-p">商业性投资</p>
        </div>
        <div class="global-use-inner">
          <img class="global-use-icon" src="../../assets/img/register/overseas/pic_1_5.png" alt="">
          <p class="global-use-p">境外上市</p>
        </div>
        <div class="global-use-inner">
          <img class="global-use-icon" src="../../assets/img/register/overseas/pic_1_6.png" alt="">
          <p class="global-use-p">提升国际形象</p>
        </div>
      </div>
    </div>

    <div class="box" style="height: auto;padding-bottom: 10px;background: rgba(242, 245, 250, 1);">
      <p class="box-p1">热门公司注册地</p>
      <div class="lunbo-box" style="height: 436px;margin-top: 20px;">
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white" :loop="true">
          <van-swipe-item>
            <div class="global-place" style="height: 436px;">
              <img class="person-box-img" src="../../assets/img/register/overseas/pic_2_1.jpg" alt="">
              <img class="person-box-img" src="../../assets/img/register/overseas/pic_2_2.jpg" alt="">
              <img class="person-box-img" src="../../assets/img/register/overseas/pic_2_3.jpg" alt="">
              <img class="person-box-img" src="../../assets/img/register/overseas/pic_2_4.jpg" alt="">
            </div>
          </van-swipe-item>
          <van-swipe-item>
            <div class="global-place" style="height: 436px;">
              <img class="person-box-img" src="../../assets/img/register/overseas/pic_2_5.jpg" alt="">
              <img class="person-box-img" src="../../assets/img/register/overseas/pic_2_6.jpg" alt="">
              <img class="person-box-img" src="../../assets/img/register/overseas/pic_2_7.jpg" alt="">
              <img class="person-box-img" src="../../assets/img/register/overseas/pic_2_8.jpg" alt="">
            </div>
          </van-swipe-item>
          <van-swipe-item>
            <div class="global-place" style="height: 436px;">
              <img class="person-box-img" src="../../assets/img/register/overseas/pic_2_9.jpg" alt="">
              <img class="person-box-img" src="../../assets/img/register/overseas/pic_2_10.jpg" alt="">
              <img class="person-box-img" src="../../assets/img/register/overseas/pic_2_11.jpg" alt="">
              <img class="person-box-img" src="../../assets/img/register/overseas/pic_2_12.jpg" alt="">
            </div>
          </van-swipe-item>
          <van-swipe-item>
            <div class="global-place" style="height: 436px;">
              <img class="person-box-img" src="../../assets/img/register/overseas/pic_2_13.jpg" alt="">
              <img class="person-box-img" src="../../assets/img/register/overseas/pic_2_14.jpg" alt="">
              <img class="person-box-img" src="../../assets/img/register/overseas/pic_2_15.jpg" alt="">
              <img class="person-box-img" src="../../assets/img/register/overseas/pic_2_16.jpg" alt="">
            </div>
          </van-swipe-item>
          <van-swipe-item>
            <div class="global-place" style="height: 436px;">
              <img class="person-box-img" src="../../assets/img/register/overseas/pic_2_17.jpg" alt="">
              <img class="person-box-img" src="../../assets/img/register/overseas/pic_2_18.jpg" alt="">
            </div>
          </van-swipe-item>
        </van-swipe>
      </div>
    </div>

    <div class="box" style="height: auto;padding-bottom: 30px;">
      <p class="box-p1">常见问题</p>
      <p class="box-p2">资深顾问<span style="color: rgba(47, 114, 235, 1);font-weight: bold;">1</span>对<span style="color: rgba(47, 114, 235, 1);font-weight: bold;">1</span>专业指导</p>
      <div class="global-question"> 
        <p class="lobal-question-title">常见问题</p>
        <div class="lobal-question-box">
          <p class="lobal-question-p">****名称是否被注册？</p>
          <p class="lobal-question-p">需要本人到场吗？</p>
          <p class="lobal-question-p">费用是多少？</p>
          <p class="lobal-question-p">需要提供股东信息吗?</p>
          <p class="lobal-question-p">需要什么条件？</p>
          <p class="lobal-question-p">流程是怎样的？</p>
          <p class="lobal-question-p">注册资金需要实缴吗？</p>
          <p class="lobal-question-p">注册完成后可以开户吗？</p>
        </div>
        <p class="lobal-question-title">资深顾问</p>
        <div class="lunbo-box" style="height: 200px;">
          <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white" :loop="true">
            <van-swipe-item>
              <div class="person-box">
                <img class="person-box-img" src="../../assets/img/register/overseas/pic_3_1.jpg" alt="">
                <img class="person-box-img" src="../../assets/img/register/overseas/pic_3_2.jpg" alt="">
              </div>
            </van-swipe-item>
            <van-swipe-item>
              <div class="person-box">
                <img class="person-box-img" src="../../assets/img/register/overseas/pic_3_3.jpg" alt="">
                <img class="person-box-img" src="../../assets/img/register/overseas/pic_3_4.jpg" alt="">
              </div>
            </van-swipe-item>
            <van-swipe-item>
              <div class="person-box">
                <img class="person-box-img" src="../../assets/img/register/overseas/pic_3_5.jpg" alt="">
                <img class="person-box-img" src="../../assets/img/register/overseas/pic_3_6.jpg" alt="">
              </div>
            </van-swipe-item>
            <van-swipe-item>
              <div class="person-box">
                <img class="person-box-img" src="../../assets/img/register/overseas/pic_3_7.jpg" alt="">
                <img class="person-box-img" src="../../assets/img/register/overseas/pic_3_8.jpg" alt="">
              </div>
            </van-swipe-item>
            <van-swipe-item>
              <div class="person-box">
                <img class="person-box-img" src="../../assets/img/register/overseas/pic_3_9.jpg" alt="">
                <img class="person-box-img" src="../../assets/img/register/overseas/pic_3_10.jpg" alt="">
              </div>
            </van-swipe-item>
            <van-swipe-item>
              <div class="person-box">
                <img class="person-box-img" src="../../assets/img/register/overseas/pic_3_11.jpg" alt="">
                <img class="person-box-img" src="../../assets/img/register/overseas/pic_3_12.jpg" alt="">
              </div>
            </van-swipe-item>
            <van-swipe-item>
              <div class="person-box">
                <img class="person-box-img" src="../../assets/img/register/overseas/pic_3_13.jpg" alt="">
                <img class="person-box-img" src="../../assets/img/register/overseas/pic_3_14.jpg" alt="">
              </div>
            </van-swipe-item>
            <van-swipe-item>
              <div class="person-box">
                <img class="person-box-img" src="../../assets/img/register/overseas/pic_3_15.jpg" alt="">
              </div>
            </van-swipe-item>
          </van-swipe>
        </div>
        <p class="global-question-tips">任何疑问请拨打: <span style="color: rgba(47, 114, 235, 1);font-size: 16px;font-weight: bold;">400 086 0769</span></p>
      </div>
    </div>

    <div class="box" style="height:auto;background: rgba(242, 245, 250, 1);padding-bottom: 20px;">
      <p class="box-p1">快企务 · 全球公司注册优势</p>
      <div class="adv-content">
        <div class="adv-line" style="height: 97px;align-items: flex-start;">
          <img class="adv-icon" style="width: 42px;margin-top: 20px;" src="../../assets/img/register/overseas/pic_4_1.png" alt="">
          <div>
            <p class="adv-p" style="font-weight: bold;padding-top: 20px;">十余年经验</p>
            <p class="adv-p5">十余年海外公司注册服务经验，专业的海外注册业务</p>
          </div>
        </div>
        <div class="adv-line" style="height: 97px;">
          <img class="adv-icon" style="width: 42px;" src="../../assets/img/register/overseas/pic_4_2.png" alt="">
          <div>
            <p class="adv-p">安全保密</p>
            <p class="adv-p5">我司拥有完善的保密系统，保护客户隐私不外泄</p>
          </div>
        </div>
        <div class="adv-line" style="height: 97px;"> 
          <img class="adv-icon" style="width: 42px;" src="../../assets/img/register/overseas/pic_4_3.png" alt="">
          <div>
            <p class="adv-p">全程服务</p>
            <p class="adv-p5">一站式专业服务，从注册到年审、银行开户、审计报税提供全方面协助</p>
          </div>
        </div>
        <div class="adv-line" style="height: 97px;">
          <img class="adv-icon" style="width: 42px;" src="../../assets/img/register/overseas/pic_4_4.png" alt="">
          <div>
            <p class="adv-p">公正认证</p>
            <p class="adv-p5">价格透明，统一报价无隐形消费</p>
          </div>
        </div>
        <div class="adv-line" style="height: 97px;">
          <img class="adv-icon" style="width: 42px;" src="../../assets/img/register/overseas/pic_4_5.png" alt="">
          <div>
            <p class="adv-p">专业高效</p>
            <p class="adv-p5">根据不同需求，为客户制定专业快捷的解决方案</p>
          </div>
        </div>
      </div>
    </div>

    <div class="box" style="height: 373px;background: #fff;">
      <p class="box-p1">专业顾问为您的企业保驾护航</p>
      <p class="box-p2">十余年老品牌 国家正规备案代理公司</p>
      <div class="form-box">
        <div class="form-line">
          <p class="form-label">姓名</p>
          <input class="form-input" placeholder="请输入您的姓名" type="text" v-model="form.name">
        </div>
        <div class="form-line">
          <p class="form-label">电话</p>
          <input class="form-input" type="text" placeholder="请输入您的联系方式" v-model="form.mobile">
        </div>
        <div class="form-line">
          <p class="form-label">您的需求</p>
          <input class="form-input" type="text" placeholder="请输入您的需求" v-model="form.remark">
        </div>
      </div>
      <p class="form-submit" @click="toSubmit">提交</p>
    </div>

    
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  data(){
    return{
      tab: 1,
      activeName: '1',
      form: {}
    }
  },
  mounted(){
    window.scrollTo(0,0);
  },
  methods:{
    toAsk(){
      window.open('https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn','_blank')
    },
    toSubmit(){
      this.$message({
          message: '提交成功！',
          type: 'success',
          offset: 100
        });
    }
  }
}
</script>
<style scoped>
  .m-bvi-banner{
    width: 100%;
    height: 460px;
    background: url('../../assets/img/register/overseas/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
</style>
