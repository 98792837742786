var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"certification m-bvi"},[_vm._m(0),_c('div',{staticClass:"box",staticStyle:{"height":"auto","padding":"20px 0"}},[_c('p',{staticClass:"box-p1"},[_vm._v("商标服务")]),_c('p',{staticClass:"box-p2"},[_vm._v("企业没商标，等于帮别人做品牌，拿什么保护创业中的您")]),_c('div',{staticClass:"tab-content-box"},[_c('div',{staticClass:"tab-box1"},[_c('p',{staticClass:"tab-inner1",class:{'active': _vm.tab == 1},staticStyle:{"width":"30%"},on:{"click":function($event){_vm.tab = 1}}},[_vm._v("国际商标注册")]),_c('p',{staticClass:"tab-inner1",class:{'active': _vm.tab == 2},staticStyle:{"width":"30%"},on:{"click":function($event){_vm.tab = 2}}},[_vm._v("国内商标注册")]),_c('p',{staticClass:"tab-inner1",class:{'active': _vm.tab == 3},staticStyle:{"width":"40%"},on:{"click":function($event){_vm.tab = 3}}},[_vm._v("商标服务")])]),(_vm.tab == 1)?_c('div',{staticClass:"know-tab-content"},[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8)]):_vm._e(),(_vm.tab == 2)?_c('div',{staticClass:"know-tab-content"},[_vm._m(9),_vm._m(10),_vm._m(11)]):_vm._e(),(_vm.tab == 3)?_c('div',{staticClass:"know-tab-content"},[_vm._m(12),_vm._m(13),_vm._m(14)]):_vm._e()])]),_vm._m(15),_vm._m(16),_vm._m(17)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"m-bvi-banner"},[_c('p',{staticClass:"know-p1"},[_vm._v("商标查询")]),_c('div',{staticClass:"know-num"},[_c('div',{staticClass:"know-num-inner"},[_c('p',{staticClass:"know-num-p1"},[_vm._v("66575734")]),_c('p',{staticClass:"know-num-p2"},[_vm._v("累计查询")])]),_c('div',{staticClass:"know-num-inner"},[_c('p',{staticClass:"know-num-p1"},[_vm._v("308")]),_c('p',{staticClass:"know-num-p2"},[_vm._v("昨日成交")])]),_c('div',{staticClass:"know-num-inner"},[_c('p',{staticClass:"know-num-p1"},[_vm._v("98.3%")]),_c('p',{staticClass:"know-num-p2"},[_vm._v("商标注册成功率")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"know-tab-inner"},[_c('img',{staticClass:"know-tab-bg",attrs:{"src":require("../../assets/img/finance/knowledge/pic_1_1.jpg"),"alt":""}}),_c('p',{staticClass:"know-tab-p1"},[_vm._v("美国商标注册")]),_c('p',{staticClass:"know-tab-p2"},[_vm._v("注册时间：12-18个月")]),_c('p',{staticClass:"know-tab-p3"},[_vm._v("有效期：10年")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"know-tab-inner"},[_c('img',{staticClass:"know-tab-bg",attrs:{"src":require("../../assets/img/finance/knowledge/pic_1_2.jpg"),"alt":""}}),_c('p',{staticClass:"know-tab-p1"},[_vm._v("新加坡商标注册")]),_c('p',{staticClass:"know-tab-p2"},[_vm._v("注册时间：9-12个月")]),_c('p',{staticClass:"know-tab-p3"},[_vm._v("有效期：10年")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"know-tab-inner"},[_c('img',{staticClass:"know-tab-bg",attrs:{"src":require("../../assets/img/finance/knowledge/pic_1_3.jpg"),"alt":""}}),_c('p',{staticClass:"know-tab-p1"},[_vm._v("欧盟商标注册")]),_c('p',{staticClass:"know-tab-p2"},[_vm._v("注册时间：12-15个月")]),_c('p',{staticClass:"know-tab-p3"},[_vm._v("有效期：10年")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"know-tab-inner"},[_c('img',{staticClass:"know-tab-bg",attrs:{"src":require("../../assets/img/finance/knowledge/pic_1_4.jpg"),"alt":""}}),_c('p',{staticClass:"know-tab-p1"},[_vm._v("中国香港商标注册")]),_c('p',{staticClass:"know-tab-p2"},[_vm._v("注册时间：6-8个月")]),_c('p',{staticClass:"know-tab-p3"},[_vm._v("有效期：10年")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"know-tab-inner"},[_c('img',{staticClass:"know-tab-bg",attrs:{"src":require("../../assets/img/finance/knowledge/pic_1_5.jpg"),"alt":""}}),_c('p',{staticClass:"know-tab-p1"},[_vm._v("英国商标注册")]),_c('p',{staticClass:"know-tab-p2"},[_vm._v("注册时间：4-6个月")]),_c('p',{staticClass:"know-tab-p3"},[_vm._v("有效期：10年")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"know-tab-inner"},[_c('img',{staticClass:"know-tab-bg",attrs:{"src":require("../../assets/img/finance/knowledge/pic_1_6.jpg"),"alt":""}}),_c('p',{staticClass:"know-tab-p1"},[_vm._v("德国商标注册")]),_c('p',{staticClass:"know-tab-p2"},[_vm._v("注册时间：12-18个月")]),_c('p',{staticClass:"know-tab-p3"},[_vm._v("有效期：10年")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"know-tab-inner"},[_c('img',{staticClass:"know-tab-bg",attrs:{"src":require("../../assets/img/finance/knowledge/pic_1_7.jpg"),"alt":""}}),_c('p',{staticClass:"know-tab-p1"},[_vm._v("日本商标注册")]),_c('p',{staticClass:"know-tab-p2"},[_vm._v("注册时间：9-11个月")]),_c('p',{staticClass:"know-tab-p3"},[_vm._v("有效期：10年")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"know-tab-inner"},[_c('img',{staticClass:"know-tab-bg",attrs:{"src":require("../../assets/img/finance/knowledge/pic_1_8.jpg"),"alt":""}}),_c('p',{staticClass:"know-tab-p1"},[_vm._v("更多国际商标")]),_c('p',{staticClass:"know-tab-p2"},[_vm._v("人工客服在线")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"know-tab-inner"},[_c('img',{staticClass:"know-tab-bg",attrs:{"src":require("../../assets/img/finance/knowledge/pic_2_1.jpg"),"alt":""}}),_c('p',{staticClass:"know-tab-p1"},[_vm._v("普通商标注册")]),_c('p',{staticClass:"know-tab-p2"},[_vm._v("价格实惠，快企务爆款")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"know-tab-inner"},[_c('img',{staticClass:"know-tab-bg",attrs:{"src":require("../../assets/img/finance/knowledge/pic_2_2.jpg"),"alt":""}}),_c('p',{staticClass:"know-tab-p1"},[_vm._v("商标极速注册")]),_c('p',{staticClass:"know-tab-p2"},[_vm._v("59分钟递送商标局")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"know-tab-inner"},[_c('img',{staticClass:"know-tab-bg",attrs:{"src":require("../../assets/img/finance/knowledge/pic_2_3.jpg"),"alt":""}}),_c('p',{staticClass:"know-tab-p1"},[_vm._v("极速双保注册")]),_c('p',{staticClass:"know-tab-p2"},[_vm._v("59分钟报件，如驳回免费再注册一个")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"know-tab-inner"},[_c('img',{staticClass:"know-tab-bg",attrs:{"src":require("../../assets/img/finance/knowledge/pic_3_1.jpg"),"alt":""}}),_c('p',{staticClass:"know-tab-p1"},[_vm._v("商标驳回复审")]),_c('p',{staticClass:"know-tab-p2"},[_vm._v("商标申请被驳回，申请复审力挽狂澜")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"know-tab-inner"},[_c('img',{staticClass:"know-tab-bg",attrs:{"src":require("../../assets/img/finance/knowledge/pic_3_2.jpg"),"alt":""}}),_c('p',{staticClass:"know-tab-p1"},[_vm._v("商标续展")]),_c('p',{staticClass:"know-tab-p2"},[_vm._v("面临被注销风险，及时续展保护权利")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"know-tab-inner"},[_c('img',{staticClass:"know-tab-bg",attrs:{"src":require("../../assets/img/finance/knowledge/pic_3_3.jpg"),"alt":""}}),_c('p',{staticClass:"know-tab-p1"},[_vm._v("商标异议答辩")]),_c('p',{staticClass:"know-tab-p2"},[_vm._v("商标被他人异议，答辩应助成功")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box",staticStyle:{"height":"588px","background":"rgba(242, 245, 250, 1)"}},[_c('p',{staticClass:"box-p1"},[_vm._v("快企务助您职业路上后顾无忧！")]),_c('p',{staticClass:"box-p2"},[_vm._v("一站式知识产权整合服务")]),_c('div',{staticClass:"bank-info"},[_c('p',{staticClass:"bank-info-p1"},[_vm._v("免费商标寄售")]),_c('p',{staticClass:"bank-info-p2"},[_vm._v("免费上传通道，支持批量导入，极速审核")]),_c('p',{staticClass:"bank-info-p1"},[_vm._v("商标价值评估")]),_c('p',{staticClass:"bank-info-p2"},[_vm._v("大数据测算，保障评估更有意义")]),_c('p',{staticClass:"bank-info-p1"},[_vm._v("商标续展")]),_c('p',{staticClass:"bank-info-p2"},[_vm._v("商标有效期到期前一年，可对商标申请续展")])]),_c('img',{staticClass:"bank-img1",staticStyle:{"width":"308px","height":"260px"},attrs:{"src":require("../../assets/img/finance/knowledge/pic_4.jpg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box",staticStyle:{"height":"348px"}},[_c('p',{staticClass:"box-p1"},[_vm._v("一站式企业服务")]),_c('p',{staticClass:"box-p2"},[_vm._v("十余年专业涉内外企业服务综合平台")]),_c('div',{staticClass:"bank-serve"},[_c('div',{staticClass:"bank-serve-inner"},[_c('img',{staticClass:"bank-serve-bg",attrs:{"src":require("../../assets/img/finance/knowledge/pic_4_1.jpg"),"alt":""}}),_c('p',{staticClass:"bank-serve-p"},[_vm._v("全球公司注册")])]),_c('div',{staticClass:"bank-serve-inner"},[_c('img',{staticClass:"bank-serve-bg",attrs:{"src":require("../../assets/img/finance/knowledge/pic_4_2.jpg"),"alt":""}}),_c('p',{staticClass:"bank-serve-p"},[_vm._v("国际公证认证")])]),_c('div',{staticClass:"bank-serve-inner"},[_c('img',{staticClass:"bank-serve-bg",attrs:{"src":require("../../assets/img/finance/knowledge/pic_4_3.jpg"),"alt":""}}),_c('p',{staticClass:"bank-serve-p"},[_vm._v("全球银行开户")])]),_c('div',{staticClass:"bank-serve-inner"},[_c('img',{staticClass:"bank-serve-bg",attrs:{"src":require("../../assets/img/finance/knowledge/pic_4_4.jpg"),"alt":""}}),_c('p',{staticClass:"bank-serve-p"},[_vm._v("秘书服务")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box",staticStyle:{"height":"159px","background":"#fff"}},[_c('p',{staticClass:"box-p1"},[_vm._v("他们都选择快企务")]),_c('img',{staticClass:"bank-img1",staticStyle:{"width":"94%","height":"60px"},attrs:{"src":require("../../assets/img/finance/knowledge/pic_5.jpg"),"alt":""}})])
}]

export { render, staticRenderFns }