<template>
  <div class="home">
    <div class="m-bvi-banner">
      <p class="banner-p1">一站式荷兰公司注册服务</p>
      <p class="banner-p2">【快企务专注于涉内外企业服务十余年】</p>
      <p class="banner-btn banner-btn1" @click="toAsk">立即咨询</p>
    </div>

    <div class="box" style="height:612px;background: rgba(242, 245, 250, 1);">
      <p class="box-p1">注册荷兰公司优势</p>
      <div class="adv-content">
        <div class="adv-line" style="height: 97px;align-items: flex-start;">
          <img class="adv-icon" style="width: 42px;margin-top: 20px;" src="../../assets/img/register/Netherlands/pic_1_1.png" alt="">
          <div>
            <p class="adv-p" style="font-weight: bold;padding-top: 20px;">荷兰实行低税收制度</p>
            <p class="adv-p5">企业所得税低至25.5%，低于英国、法国、德国、比利时等</p>
          </div>
        </div>
        <div class="adv-line" style="height: 97px;">
          <img class="adv-icon" style="width: 42px;" src="../../assets/img/register/Netherlands/pic_1_2.png" alt="">
          <div>
            <p class="adv-p">荷兰拥有欧洲优质的经商环境</p>
            <p class="adv-p5">荷兰位于欧洲西部，是欧盟成员国，东与德国为邻，南邻比利时</p>
          </div>
        </div>
        <div class="adv-line" style="height: 97px;"> 
          <img class="adv-icon" style="width: 42px;" src="../../assets/img/register/Netherlands/pic_1_3.png" alt="">
          <div>
            <p class="adv-p">荷兰公司关税递延</p>
            <p class="adv-p5">关税和增值税实行递延政策，缓解企业资金压力</p>
          </div>
        </div>
        <div class="adv-line" style="height: 97px;">
          <img class="adv-icon" style="width: 42px;" src="../../assets/img/register/Netherlands/pic_1_4.png" alt="">
          <div>
            <p class="adv-p">荷兰无外汇管制</p>
            <p class="adv-p5">荷兰公司注册后方便从事国际贸易的客户</p>
          </div>
        </div>
        <div class="adv-line" style="height: 97px;">
          <img class="adv-icon" style="width: 42px;" src="../../assets/img/register/Netherlands/pic_1_5.png" alt="">
          <div>
            <p class="adv-p">荷兰公司参股所得免税</p>
            <p class="adv-p5">即对从合格持股所得红利和转让合同股权的资本收益不征企业所得税</p>
          </div>
        </div>
      </div>
    </div>

    <div class="box" style="height: auto;padding-bottom: 30px;">
      <p class="box-p1">注册荷兰公司类型</p>
      <div class="type-content flex center-width">
        <img class="type-img" style="height: 110px;" src="../../assets/img/register/Netherlands/pic_2_1.jpg" alt="">
        <img class="type-img" style="height: 110px;" src="../../assets/img/register/Netherlands/pic_2_2.jpg" alt="">
        <img class="type-img" style="height: 110px;" src="../../assets/img/register/Netherlands/pic_2_3.jpg" alt="">
        <img class="type-img" style="height: 110px;" src="../../assets/img/register/Netherlands/pic_2_4.jpg" alt="">
      </div>
    </div>
    <div class="box bg-box" style="height: auto;padding-bottom: 15px;">
      <p class="box-p1">注册荷兰公司条件/流程/资料</p>
      <div class="tab-box">
        <div class="tab-inner" :class="{'active': tab == 1}" @click="tab = 1">注册条件</div>
        <div class="tab-inner" :class="{'active': tab == 2}" @click="tab = 2">注册流程</div>
        <div class="tab-inner" :class="{'active': tab == 3}" @click="tab = 3">所得资料</div>
      </div>
      <div class="file-content">
        <div v-if="tab == 1">
          <div class="french-flow">
            <div class="french-flow-inner">
              <img class="french-flow-icon" src="../../assets/img/register/Netherlands/pic_3_1.png" alt="">
              <p class="french-flow-p1">公司名称</p>
              <p class="french-flow-p2">建议3个名称以供查册备选，经查册后无重名即可注册</p>
            </div>
            <div class="french-flow-inner">
              <img class="french-flow-icon" src="../../assets/img/register/Netherlands/pic_3_2.png" alt="">
              <p class="french-flow-p1">股东和董事</p>
              <p class="french-flow-p2">至少一个股东或董事，股东及董事可为同一人，任何国籍的自然人和法人公司都可以担任</p>
            </div>
            <div class="french-flow-inner">
              <img class="french-flow-icon" src="../../assets/img/register/Netherlands/pic_3_3.png" alt="">
              <p class="french-flow-p1">公司经理</p>
              <p class="french-flow-p2">没有限制必须由荷兰公民，或拥有欧盟国家的国籍的公民来担任</p>
            </div>
            <div class="french-flow-inner">
              <img class="french-flow-icon" src="../../assets/img/register/Netherlands/pic_3_4.png" alt="">
              <p class="french-flow-p1">注册资本</p>
              <p class="french-flow-p2">标准注册资本：18,000欧元</p>
            </div>
            <div class="french-flow-inner">
              <img class="french-flow-icon" src="../../assets/img/register/Netherlands/pic_3_5.png" alt="">
              <p class="french-flow-p1">注册地址</p>
              <p class="french-flow-p2">由我司提供注册地址（若自身已有办公地址，可用自有地址）</p>
            </div>
            <div class="french-flow-inner">
              <img class="french-flow-icon" src="../../assets/img/register/Netherlands/pic_3_6.png" alt="">
              <p class="french-flow-p1">经营范围</p>
              <p class="french-flow-p2">经营范围没有限制，除了少数几个特殊行业需要特批</p>
            </div>
          </div>
        </div>
        <div v-if="tab == 2">
          <img class="tab2-img" src="../../assets/img/register/Netherlands/pic_4.png" alt="">
        </div>
        <div v-if="tab == 3">
          <img class="tab2-img" src="../../assets/img/register/Netherlands/pic_5.png" alt="">
        </div>
      </div>
    </div>

    
    <div class="box" style="height: 758px;">
      <p class="box-p1">注册荷兰公司后为您提供的后续服务</p>
      <div class="re-serve">
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Netherlands/pic_6_1.png" alt="">
          <p class="re-serve-p1">年审/续牌</p>
          <p class="re-serve-p2">1.提前一个月通知公司年审，准备相关年审文件
2.递交年审文件，政府审核，约5-7个工作日3.年审完成，邮寄领取年检证书等文件。</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Netherlands/pic_6_2.png" alt="">
          <p class="re-serve-p1">做账报税</p>
          <p class="re-serve-p2">主要氛围直接税和间接税。直接税包括企业所得税、预提税；间接税包括增值税、消费税、房产税、关税、环境税、保险税等</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Netherlands/pic_6_3.png" alt="">
          <p class="re-serve-p1">银行开户</p>
          <p class="re-serve-p2">在荷兰注册公司，必须开立当地银行帐户。公司注册成立后，公司股东董事无须亲临银行办理，我们会备妥开户文件并全程协助协调</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Netherlands/pic_6_4.png" alt="">
          <p class="re-serve-p1">税号申请</p>
          <p class="re-serve-p2">依照荷兰的税务管理法律规定，个人并不能申请荷兰的VAT税号，申请荷兰公司的VAT税号也需要提供较多资料</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Netherlands/pic_6_5.png" alt="">
          <p class="re-serve-p1">秘书服务</p>
          <p class="re-serve-p2">办公地址，公司周年报表年检，公司董事股东成员名册更新，在职证明与良好证明，代缴政府规费，修改公司章程等</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Netherlands/pic_6_6.png" alt="">
          <p class="re-serve-p1">公司注销</p>
          <p class="re-serve-p2">因破产、股东决议解散、存续期届满等原因需要注销的，应及时主动注销公司</p>
        </div>
      </div>
    </div>

    <div class="box" style="height: auto;padding-bottom: 30px;background: rgba(242, 245, 250, 1);">
      <p class="box-p1">荷兰公司注册常见问题</p>
      <p class="box-p1">解决您的一切疑问</p>
      <div class="question">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item title="注册荷兰公司，公司名称有何限制？" name="1">
            <p class="question-p">公司名需要是英文名，注册前需要先核名，查询是否近似或重复</p>
          </el-collapse-item>
          <el-collapse-item title="公司董事股东必须是荷兰人吗？" name="2">
            <p class="question-p">不是必须条件。董事股东的所有资料文件需要翻译成英文，做公证后才能用于公司注册，需要做最终受益人(UBO）
登记备案</p>
          </el-collapse-item>
          <el-collapse-item title="母子公司的业务范围要完全相同么？" name="3">
            <p class="question-p">子公司的经营范围不一定要和母公司相同，可以适当扩宽经营范围。申请人登陆到荷兰拿到5年居留卡后，还可
以对荷兰公司经营范围进行增项。</p>
          </el-collapse-item>
        </el-collapse>
      </div>
      <p class="to-ask" @click="toAsk"> 
        <img class="to-ask-icon" src="../../assets/img/register/bvi/icon_6_customer_service.png" alt=""> 
        更多问题？立即咨询人工客服</p>
    </div>
    <div class="box" style="height: 480px;">
      <p class="box-p1">快企务的优势</p>
      <p class="box-p2">十余年注册荷兰公司经验</p>
      <div class="re-adv">
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_1.png" alt="">
          <p class="re-adv-p1">保密完善</p>
          <p class="re-adv-p2">保密系统完善，客户隐私不外泄</p>
        </div>
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_2.png" alt="">
          <p class="re-adv-p1">全程服务</p>
          <p class="re-adv-p2">一站式专业服务从注册公司到年审、开户，审计报税等</p>
        </div>
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_3.png" alt="">
          <p class="re-adv-p1">专业高效</p>
          <p class="re-adv-p2">根据需求，为客户制定专业快捷的解决方案</p>
        </div>
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_4.png" alt="">
          <p class="re-adv-p1">合同保障</p>
          <p class="re-adv-p2">正规机构，合同保障，费用透明，无隐形消费</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  data(){
    return{
      tab: 1,
      activeName: '1'
    }
  },
  mounted(){
    window.scrollTo(0,0);
  },
  methods:{
    toAsk(){
      window.open('https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn','_blank')
    },
  }
}
</script>
<style scoped>
  .m-bvi-banner{
    width: 100%;
    height: 460px;
    background: url('../../assets/img/register/Netherlands/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
</style>
