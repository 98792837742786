<template>
  <div class="certification m-bvi">
    <div class="m-bvi-banner">
      <!-- <p class="banner-certification">公司文件认证/个人文件认证</p> -->
      <img class="video-banner-1" src="../../assets/img/video/banner_1.png" alt="">
      <p class="video-banner-p1">公证认证一站式服务</p>
      <p class="banner-p1" style="padding-top: 20px">视频委托公证认证</p>
      <p class="banner-p2">加急一天出件丨全国受理  不限户籍丨 <br />10+年办理经验</p>
      <p class="banner-btn banner-btn1" style="margin-top: 60px" @click="toAsk">立即咨询</p>
    </div>
    <div class="box">
      <p class="box-p1">什么情况下需要办理视频委托公证？</p>
      <p class="box-p2">由于其他原因，人在国外/异地，无法亲自办理，可以视频委托进行相关事项的代办</p>
      <div class="video-box1">
        <div class="video-box1-inner">
          <img class="video-box1-icon" src="../../assets/img/video/pic_1_1.png" alt="">
          <p class="video-box1-p1">线上办理</p>
          <p class="video-box1-p2">无需本人亲自到场，高效便捷</p>
        </div>
        <div class="video-box1-inner">
          <img class="video-box1-icon" src="../../assets/img/video/pic_1_2.png" alt="">
          <p class="video-box1-p1">无需预约</p>
          <p class="video-box1-p2">无需原件、无需排队</p>
        </div>
        <div class="video-box1-inner">
          <img class="video-box1-icon" src="../../assets/img/video/pic_1_3.png" alt="">
          <p class="video-box1-p1">全国办理</p>
          <p class="video-box1-p2">不限户籍，1对1服务</p>
        </div>
        <div class="video-box1-inner">
          <img class="video-box1-icon" src="../../assets/img/video/pic_1_4.png" alt="">
          <p class="video-box1-p1">当场审核</p>
          <p class="video-box1-p2">效率高，出证快</p>
        </div>
      </div>
    </div>
   
    <div class="box">
      <p class="box-p1">电子签名公证适用的场景</p>
      <p class="box-p2">（涉及继承、人身关系类的事项不能适用电子签名）</p>
      <div class="cer-lunbo-box" style="margin: 0 auto">
        <van-swipe  :autoplay="3000" indicator-color="white" :height="204" :loop="true">
          <van-swipe-item>
            <div class="video-swiper">
              <img class="video-swiper-img" style="width: 100%;height: 100%;" src="../../assets/img/video/pic_2_1.jpg" alt="">
              <div class="video-swiper-inner">
                <p class="video-swiper-inner-p1">声明书/同意书</p>
                <p class="video-swiper-inner-p2">未成年人出国留学、父母资助</p>
              </div>
            </div>
          </van-swipe-item>
          <van-swipe-item>
            <div class="video-swiper">
              <img class="video-swiper-img" style="width: 100%;height: 100%;" src="../../assets/img/video/pic_2_2.jpg" alt="">
              <div class="video-swiper-inner">
                <p class="video-swiper-inner-p1">委托书</p>
                <p class="video-swiper-inner-p2">处理各类财产类/非财产类事务</p>
              </div>
            </div>
          </van-swipe-item>
          <van-swipe-item>
            <div class="video-swiper">
              <img class="video-swiper-img" style="width: 100%;height: 100%;" src="../../assets/img/video/pic_2_3.jpg" alt="">
              <div class="video-swiper-inner">
                <p class="video-swiper-inner-p1">债权文书赋予强制执行效力</p>
                <p class="video-swiper-inner-p2">经公证的借款、抵押等债权文书，当债务人不履行还款义务时，债权人不用到法院起诉，可直接向公证处申请出具执行证书，直接向人民法院申请强制执行。</p>
              </div>
            </div>
          </van-swipe-item>
          <van-swipe-item>
            <div class="video-swiper">
              <img class="video-swiper-img" style="width: 100%;height: 100%;" src="../../assets/img/video/pic_2_4.jpg" alt="">
              <div class="video-swiper-inner">
                <p class="video-swiper-inner-p1">离婚财产分割补充/变更协议</p>
                <p class="video-swiper-inner-p2">对离婚时未分割的财产进行补充约定或重新约定财产归属</p>
              </div>
            </div>
          </van-swipe-item>
          <van-swipe-item>
            <div class="video-swiper">
              <img class="video-swiper-img" style="width: 100%;height: 100%;" src="../../assets/img/video/pic_2_5.jpg" alt="">
              <div class="video-swiper-inner">
                <p class="video-swiper-inner-p1">夫妻财产协议</p>
                <p class="video-swiper-inner-p2">对婚前或婚后取得的财产归属进行约定</p>
              </div>
            </div>
          </van-swipe-item>
        </van-swipe>
      </div>
    </div>
    <div class="box">
      <p class="box-p1">电子签名公证事项简介</p>
      <div class="video-box3">
        <img class="video-box3-img" src="../../assets/img/video/pic_3.jpg" alt="">
        <p class="video-box3-p1">当您身处外地或国外，不便亲自办理相关事务（设立或注销公司、买卖房产、领取公积金、未成年人出国签证、签订合同、夫妻分割财产等），电子签名公证可以帮您解决。您不用前往公证处，也不用前往中国驻国外的使领馆，只需一部手机就能轻松搞定。</p>
        <p class="video-box3-p2">立即咨询</p>
      </div>
    </div>
    <div class="box" style="background: rgba(242, 245, 250, 1);">
      <p class="box-p1">公证分类</p>
      <p class="box-p2">以客户需求和产品发展理念为驱动力</p>
      <div class="video-swiper-2">
        <van-swipe :autoplay="3000" indicator-color="white" :height="345" :loop="true">
          <van-swipe-item>
            <div class="video-swiper-2-box">
              <div class="video-swiper-2-inner">
                <img class="video-swiper-2-inner-img" src="../../assets/img/video/pic_4_1.jpg" alt="">
                <p class="video-swiper-2-inner-p">委托书公证 </p>
              </div>
              <div class="video-swiper-2-inner">
                <img class="video-swiper-2-inner-img" src="../../assets/img/video/pic_4_2.jpg" alt="">
                <p class="video-swiper-2-inner-p">营业执照公证 </p>
              </div>
              <div class="video-swiper-2-inner">
                <img class="video-swiper-2-inner-img" src="../../assets/img/video/pic_4_3.jpg" alt="">
                <p class="video-swiper-2-inner-p">护照公证 </p>
              </div>
              <div class="video-swiper-2-inner">
                <img class="video-swiper-2-inner-img" src="../../assets/img/video/pic_4_4.jpg" alt="">
                <p class="video-swiper-2-inner-p">放弃继承声明公证书 </p>
              </div>
            </div>
          </van-swipe-item>
          <van-swipe-item>
            <div class="video-swiper-2-box">
              <div class="video-swiper-2-inner">
                <img class="video-swiper-2-inner-img" src="../../assets/img/video/pic_4_5.jpg" alt="">
                <p class="video-swiper-2-inner-p">房产证公证 </p>
              </div>
              <div class="video-swiper-2-inner">
                <img class="video-swiper-2-inner-img" src="../../assets/img/video/pic_4_6.jpg" alt="">
                <p class="video-swiper-2-inner-p">签名公证书 </p>
              </div>
              <div class="video-swiper-2-inner">
                <img class="video-swiper-2-inner-img" src="../../assets/img/video/pic_4_7.jpg" alt="">
                <p class="video-swiper-2-inner-p">公司章程公证书 </p>
              </div>
              <div class="video-swiper-2-inner">
                <img class="video-swiper-2-inner-img" src="../../assets/img/video/pic_4_8.jpg" alt="">
                <p class="video-swiper-2-inner-p">在职证明公证 </p>
              </div>
            </div>
          </van-swipe-item>
        </van-swipe>
      </div>
    </div>
    <div class="box">
      <p class="box-p1">办理流程</p>
      <p class="box-p2">以客户需求和产品发展理念为驱动力</p>
      <div class="video-box5">
        <img class="video-box5-icon" src="../../assets/img/video/pic_5_1.png" alt="">
        <p class="video-box5-p">第一步：客户咨询下单 </p>
        <img class="video-box5-down" src="../../assets/img/video/pic_5_5.png" alt="">
        <img class="video-box5-icon" src="../../assets/img/video/pic_5_2.png" alt="">
        <p class="video-box5-p">第二步：审核材料 </p>
        <img class="video-box5-down" src="../../assets/img/video/pic_5_5.png" alt="">
        <img class="video-box5-icon" src="../../assets/img/video/pic_5_3.png" alt="">
        <p class="video-box5-p">第三步：线上视频审核 </p>
        <img class="video-box5-down" src="../../assets/img/video/pic_5_5.png" alt="">
        <img class="video-box5-icon" src="../../assets/img/video/pic_5_4.png" alt="">
        <p class="video-box5-p">第四步：最快当天出公证书 </p>
      </div>
    </div>
    <div class="box">
      <p class="box-p1">我司办理的优势</p>
      <p class="box-p2">十余年行业代办经验</p>
      <div class="cer-adv-box">
        <div class="cer-adv-inner">
          <img class="cer-adv-icon" src="../../assets/img/certification/Singapore/icon_1_1.png" alt="">
          <p class="cer-adv-p1">严格保密</p>
          <p class="cer-adv-p2">严密保存材料，公证书办理完成即刻销毁所有材料文件</p>
        </div>
        <div class="cer-adv-inner">
          <img class="cer-adv-icon" src="../../assets/img/certification/Singapore/icon_1_2.png" alt="">
          <p class="cer-adv-p1">透明公开</p>
          <p class="cer-adv-p2">价格公开透明，公证认证办理费用公开透明可查</p>
        </div>
        <div class="cer-adv-inner">
          <img class="cer-adv-icon" src="../../assets/img/certification/Singapore/icon_1_3.png" alt="">
          <p class="cer-adv-p1">全天跟进</p>
          <p class="cer-adv-p2">24小时跟进，每份公证材料专员全天候跟进反馈</p>
        </div>
        <div class="cer-adv-inner">
          <img class="cer-adv-icon" src="../../assets/img/certification/Singapore/icon_1_4.png" alt="">
          <p class="cer-adv-p1">退费承诺</p>
          <p class="cer-adv-p2">公证拒办退款，客户公证材料被拒全额退还费用</p>
        </div>
      </div>
    </div>
    <div class="box" style="height: auto;padding-bottom: 30px;background: rgba(242, 245, 250, 1)">
      <p class="box-p1">常见问题</p>
      <div class="question">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item title="什么是在线远程公证？" name="1">
            <p class="question-p">在线公证是通过视频连线，人脸识别等技术手段，发起、受理、缴费及出具公证书等全部流程都无需到与公证员碰面，也无需亲赴公证处现场，仅一部手机即可完成办理。事后通过快递寄送公证书。在线公证大幅提高了群众办事效率和便捷性。是近几年技术成熟后，国内少数公证处新推出的公证便民服务。</p>
          </el-collapse-item>
          <el-collapse-item title="网上公证和线下一样吗？" name="2">
            <p class="question-p">线上办理公证是一个新生事物，很多人并没有接触过。担心网上公证和公证
处现场公证的是不一样，但实际上，不论是网上公证还是现场公证都是一样的，
区别在于办理渠道不同，网上公证材料需要在线提交，而现场申请需要客户带着
材料到公正机构的柜台办理。两种没有本质区别。
目前国内只有少数公证处开放线上业务，并且每家受理范围和受理条件不一样，
我们作为第三方协办，会根据你的需求，选择不同的接口，无需客户本人到场，节约时间。</p>
          </el-collapse-item>
          <el-collapse-item title="可申请办理海外远程视频公证的事项包括哪些？" name="3">
            <p class="question-p">声明、委托（包括涉房产、股权、继承等财产类事务）、婚姻状况、国籍、姓名、
出生、死亡、亲属关系、无犯罪记录、经历、学历、证书（执照）、文书上的签名、印
鉴、文本相符公证等。</p>
          </el-collapse-item>
        </el-collapse>
      </div>
      <p class="to-ask" @click="toAsk"> 
        <img class="to-ask-icon" src="../../assets/img/register/bvi/icon_6_customer_service.png" alt=""> 
        更多问题？立即咨询人工客服</p>
    </div>
    <div class="box" style="height: 339px;">
      <p class="box-p1">电子签名公证案例</p>
      <div class="lunbo-box cer-lunbo-box" style="margin-top: 20px">
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="rgba(47, 114, 235, 1)" >
          <van-swipe-item>
            <div class="cer-case-box">
              <img class="cer-case-img" src="../../assets/img/video/pic_7_1.jpg" alt="">
              <img class="cer-case-img" src="../../assets/img/video/pic_7_2.jpg" alt="">
            </div>
          </van-swipe-item>
          <van-swipe-item>
            <div class="cer-case-box">
              <img class="cer-case-img" src="../../assets/img/video/pic_7_3.jpg" alt="">
              <img class="cer-case-img" src="../../assets/img/video/pic_7_4.jpg" alt="">
            </div>
          </van-swipe-item>
        </van-swipe>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'bvi-page',
  props: {
    msg: String
  },
  data(){
    return{
      activeName: '1'
    }
  },
  mounted(){
    window.scrollTo(0,0);
  },
  methods: {
    toAsk(){
      window.open('https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn','_blank')
    }
  }
}
</script>

<style scoped lang="scss">
  .m-bvi-banner{
    width: 100%;
    height: 460px;
    background: url('../../assets/img/video/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
</style>
