import { render, staticRenderFns } from "./Swiss.vue?vue&type=template&id=012f6a3c&scoped=true&"
import script from "./Swiss.vue?vue&type=script&lang=js&"
export * from "./Swiss.vue?vue&type=script&lang=js&"
import style0 from "./Swiss.vue?vue&type=style&index=0&id=012f6a3c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "012f6a3c",
  null
  
)

export default component.exports