import Vue from 'vue'
import VueRouter from 'vue-router'
// 首页
import HomeView from '../views/HomeView.vue'

// 28个
import registerglobal from '../views/register/global.vue'
import registerBvi from '../views/register/bvi.vue'
import registerKaiman from '../views/register/kaiman.vue'
import registerSingapore from '../views/register/singapore.vue'
import registerHongKong from '../views/register/HongKong.vue'
import registerAmerica from "../views/register/America.vue"
import registerBritain from "../views/register/Britain.vue"
import registerTaiwan from '../views/register/Taiwan.vue'
import registerMarshall from '../views/register/Marshall.vue'
import registerGermany from '../views/register/Germany.vue'
import registerFrench from '../views/register/French.vue'
import registerNetherlands from '../views/register/Netherlands.vue'
import registerMacau from '../views/register/Macau.vue'
import registerSwiss from '../views/register/Swiss.vue'
import registerCyprus from '../views/register/Cyprus.vue'
import registerDubai from '../views/register/dubai.vue'
import registerAnquila from '../views/register/Anquila.vue'
import registerKorea from '../views/register/Korea.vue'
import registerMalta from '../views/register/Malta.vue'
import registerJapan from '../views/register/Japan.vue'
import registerLuxembourg from '../views/register/Luxembourg.vue'
import registerSamoa from '../views/register/Samoa.vue'
import registerVietnam from '../views/register/Vietnam.vue'
import registerCanada from '../views/register/Canada.vue'
import registerItaly from '../views/register/Italy.vue'
import registerIndonesia from '../views/register/Indonesia.vue' 
import registerMauritius from '../views/register/Mauritius.vue'



// 16个
import certificationSingapore from '../views/certification/Singapore.vue'
import certificationAmerica from '../views/certification/America.vue'
import certificationAustralia from '../views/certification/Australia.vue'
import certificationBritain from '../views/certification/Britain.vue'
import certificationBvi from '../views/certification/bvi.vue'
import certificationCanada from '../views/certification/Canada.vue'
import certificationcayman from '../views/certification/cayman.vue'
import certificationFrench from '../views/certification/French.vue'
import certificationGermany from '../views/certification/Germany.vue'
import certificationKorea from '../views/certification/Korea.vue'
import certificationMalaysia from '../views/certification/Malaysia.vue'
import certificationNetherlands from '../views/certification/Netherlands.vue'
import certificationRussia from '../views/certification/Russia.vue'
import certificationSamoa from '../views/certification/Samoa.vue'
import certificationVietnam from '../views/certification/Vietnam.vue'
import certificationSeychelles from '../views/certification/Seychelles.vue'
import certificationVideo from '../views/certification/video.vue'
// 10个
import notarization from '../views/notarization/index.vue'
import notarizationHague from '../views/notarization/hague.vue'
import notarizationChina from '../views/notarization/china.vue'
import notarizationEmbassy from '../views/notarization/embassy.vue'
import notarizationTrade from '../views/notarization/trade.vue'
import noCriminalAmerica from '../views/noCriminal/America.vue'
import noCriminalAustralia from '../views/noCriminal/Australia.vue'
import noCriminalBritain from '../views/noCriminal/Britain.vue'
import noCriminalCanada from '../views/noCriminal/Canada.vue'
import noCriminalSingapore from '../views/noCriminal/Singapore.vue'

// 13个
import financeBank from '../views/finance/bank.vue'
import FranceTrademark from '../views/finance/FranceTrademark.vue'
import innerTrademark from '../views/finance/innerTrademark.vue'
import financeAmerica from '../views/finance/America.vue'
import AmericaLaw from '../views/finance/AmericaLaw.vue'
import AmericaTrademark from '../views/finance/AmericaTrademark.vue'
import EUTrademark from '../views/finance/EUTrademark.vue'
import JapanTrademark from '../views/finance/JapanTrademark.vue'
import financeHongKong from '../views/finance/HongKong.vue'
import HongKongLaw from '../views/finance/HongKongLaw.vue'
import knowledge from '../views/finance/knowledge.vue'
import ODI from '../views/finance/ODI.vue'
import VIE from '../views/finance/VIE.vue'

import examinationAmerica from '../views/examination/America.vue'
import examinationBvi from '../views/examination/bvi.vue'
import examinationKaiman from '../views/examination/Kaiman.vue'
import examinationHongKong from '../views/examination/HongKong.vue'
import examinationSingapore from '../views/examination/Singapore.vue'

import about from '../views/about.vue'
import join from '../views/join.vue'
import news from '../views/news.vue'

import JapanVisa from '../views/JapanVisa.vue'
import plan from '../views/plan.vue'
import point from '../views/point.vue'
import list from '../views/list.vue'
import contact from '../views/contact.vue'
import notFound from '../views/404.vue'

Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: about
  },
  {
    path: '/join',
    name: 'join',
    component: join
  },
  {
    path: '/news',
    name: 'news',
    component: news
  },
  {
    path: '/JapanVisa',
    name: 'JapanVisa',
    component: JapanVisa
  },
  {
    path: '/plan',
    name: 'plan',
    component: plan
  },
  {
    path: '/point',
    name: 'point',
    component: point
  },
  {
    path: '/list',
    name: 'list',
    component: list
  },
  {
    path: '/contact',
    name: 'contact',
    component: contact
  },
  {
    path: '/examination/America',
    name: 'examinationAmerica',
    component: examinationAmerica
  },
  {
    path: '/examination/bvi',
    name: 'examinationBvi',
    component: examinationBvi
  },
  {
    path: '/examination/Kaiman',
    name: 'examinationKaiman',
    component: examinationKaiman
  },
  {
    path: '/examination/HongKong',
    name: 'examinationHongKong',
    component: examinationHongKong
  },
  {
    path: '/examination/Singapore',
    name: 'examinationSingapore',
    component: examinationSingapore
  },


  {
    path: '/finance/bank',
    name: 'financeBank',
    component: financeBank
  },
  {
    path: '/finance/FranceTrademark',
    name: 'FranceTrademark',
    component: FranceTrademark
  },
  {
    path: '/finance/innerTrademark',
    name: 'innerTrademark',
    component: innerTrademark
  },
  {
    path: '/finance/America',
    name: 'financeAmerica',
    component: financeAmerica
  },
  {
    path: '/finance/AmericaLaw',
    name: 'AmericaLaw',
    component: AmericaLaw
  },
  {
    path: '/finance/AmericaTrademark',
    name: 'AmericaTrademark',
    component: AmericaTrademark
  },
  {
    path: '/finance/EUTrademark',
    name: 'EUTrademark',
    component: EUTrademark
  },
  {
    path: '/finance/JapanTrademark',
    name: 'JapanTrademark',
    component: JapanTrademark
  },
  {
    path: '/finance/HongKong',
    name: 'financeHongKong',
    component: financeHongKong
  },
  {
    path: '/finance/HongKongLaw',
    name: 'HongKongLaw',
    component: HongKongLaw
  },
  {
    path: '/finance/knowledge',
    name: 'knowledge',
    component: knowledge
  },
  {
    path: '/finance/ODI',
    name: 'ODI',
    component: ODI
  },
  {
    path: '/finance/VIE',
    name: 'VIE',
    component: VIE
  },
  {
    path: '/notarization/index',
    name: 'notarization',
    component: notarization
  },
  {
    path: '/notarization/trade',
    name: 'notarizationTrade',
    component: notarizationTrade
  },
  {
    path: '/notarization/hague',
    name: 'notarizationHague',
    component: notarizationHague
  },
  {
    path: '/notarization/china',
    name: 'notarizationChina',
    component: notarizationChina
  },
  {
    path: '/notarization/embassy',
    name: 'notarizationEmbassy',
    component: notarizationEmbassy
  },

  {
    path: '/noCriminal/America',
    name: 'noCriminalAmerica',
    component: noCriminalAmerica
  },
  {
    path: '/noCriminal/Australia',
    name: 'noCriminalAustralia',
    component: noCriminalAustralia
  },
  {
    path: '/noCriminal/Britain',
    name: 'noCriminalBritain',
    component: noCriminalBritain
  },
  {
    path: '/noCriminal/Canada',
    name: 'noCriminalCanada',
    component: noCriminalCanada
  },
  {
    path: '/noCriminal/Singapore',
    name: 'noCriminalSingapore',
    component: noCriminalSingapore
  },
  {
    path: '/register/bvi',
    name: 'registerBvi',
    component: registerBvi
  },
  {
    path: '/register/kaiman',
    name: 'registerKaiman',
    component: registerKaiman
  },
  {
    path: '/register/singapore',
    name: 'registerSingapore',
    component: registerSingapore
  },
  {
    path: '/register/HongKong',
    name: 'registerHongKong',
    component: registerHongKong
  },
  {
    path: '/register/America',
    name: 'registerAmerica',
    component: registerAmerica
  },
  {
    path: '/register/Britain',
    name: 'registerBritain',
    component: registerBritain
  },
  {
    path: '/register/Taiwan',
    name: 'registerTaiwan',
    component: registerTaiwan
  },
  {
    path: '/register/Marshall',
    name: 'registerMarshall',
    component: registerMarshall
  },
  {
    path: '/register/global',
    name: 'registerglobal',
    component: registerglobal
  },
  {
    path: '/register/Germany',
    name: 'registerGermany',
    component: registerGermany
  },
  {
    path: '/register/Anquila',
    name: 'registerAnquila',
    component: registerAnquila
  },
  {
    path: '/register/Macau',
    name: 'registerMacau',
    component: registerMacau
  },
  {
    path: '/register/dubai',
    name: 'registerDubai',
    component: registerDubai
  },
  {
    path: '/register/French',
    name: 'registerFrench',
    component: registerFrench
  },
  {
    path: '/register/Korea',
    name: 'registerKorea',
    component: registerKorea
  },
  {
    path: '/register/Swiss',
    name: 'registerSwiss',
    component: registerSwiss
  },
  {
    path: '/register/Malta',
    name: 'registerMalta',
    component: registerMalta
  },
  {
    path: '/register/Netherlands',
    name: 'registerNetherlands',
    component: registerNetherlands
  },
  {
    path: '/register/Cyprus',
    name: 'registerCyprus',
    component: registerCyprus
  },
  {
    path: '/register/Japan',
    name: 'registerJapan',
    component: registerJapan
  },
  {
    path: '/register/Luxembourg',
    name: 'registerLuxembourg',
    component: registerLuxembourg
  },
  {
    path: '/register/Samoa',
    name: 'registerSamoa',
    component: registerSamoa
  },
  {
    path: '/register/Vietnam',
    name: 'registerVietnam',
    component: registerVietnam
  },
  {
    path: '/register/Canada',
    name: 'registerCanada',
    component: registerCanada
  },
  {
    path: '/register/Italy',
    name: 'registerItaly',
    component: registerItaly
  },
  {
    path: '/register/Indonesia',
    name: 'registerIndonesia',
    component: registerIndonesia
  },
  {
    path: '/register/Mauritius',
    name: 'registerMauritius',
    component: registerMauritius
  },







  {
    path: '/certification/Singapore',
    name: 'certificationSingapore',
    component: certificationSingapore
  },
  {
    path: '/certification/America',
    name: 'certificationAmerica',
    component: certificationAmerica
  },
  {
    path: '/certification/Australia',
    name: 'certificationAustralia',
    component: certificationAustralia
  },
  {
    path: '/certification/Britain',
    name: 'certificationBritain',
    component: certificationBritain
  },
  {
    path: '/certification/Bvi',
    name: 'certificationBvi',
    component: certificationBvi
  },
  {
    path: '/certification/Canada',
    name: 'certificationCanada',
    component: certificationCanada
  },
  {
    path: '/certification/cayman',
    name: 'certificationcayman',
    component: certificationcayman
  },
  {
    path: '/certification/French',
    name: 'certificationFrench',
    component: certificationFrench
  },
  {
    path: '/certification/Germany',
    name: 'certificationGermany',
    component: certificationGermany
  },
  {
    path: '/certification/Korea',
    name: 'certificationKorea',
    component: certificationKorea
  },
  {
    path: '/certification/Malaysia',
    name: 'certificationMalaysia',
    component: certificationMalaysia
  },
  {
    path: '/certification/Netherlands',
    name: 'certificationNetherlands',
    component: certificationNetherlands
  },
  {
    path: '/certification/Russia',
    name: 'certificationRussia',
    component: certificationRussia
  },
  {
    path: '/certification/Samoa',
    name: 'certificationSamoa',
    component: certificationSamoa
  },
  {
    path: '/certification/Seychelles',
    name: 'certificationSeychelles',
    component: certificationSeychelles
  },
  {
    path: '/certification/Vietnam',
    name: 'certificationVietnam',
    component: certificationVietnam
  },
  {
    path: '/certification/video',
    name: 'certificationVideo',
    component: certificationVideo
  },
  {
    path: '*',
    name: '404',
    component: notFound
  }
  
]

const router = new VueRouter({
  routes
})

export default router
