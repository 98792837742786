<template>
  <div class="home">
    <div class="m-bvi-banner">
      <p class="banner-p1">一站式迪拜公司注册服务</p>
      <p class="banner-p2">【快企务专注于涉内外企业服务十余年】</p>
      <p class="banner-btn banner-btn1" @click="toAsk">立即咨询</p>
    </div>

    <div class="box" style="height: 380px;background: rgba(242, 245, 250, 1);">
      <p class="box-p1">注册迪拜公司优势</p>
      <div class="cy-adv">
        <div class="cy-adv-inner" style="align-items: flex-start;height: 90px;">
          <span class="circle"></span>
          <p class="cy-adv-p" style="width: 130px;margin-top: 8px;">自由区与在岸相辅的离岸公司在法律方面有豁免权，当局会提供持续的行政协助</p>
        </div>
        <div class="cy-adv-inner" style="align-items: flex-start;height: 90px;">
          <span class="circle"></span>
          <p class="cy-adv-p" style="width: 130px;margin-top: 8px;margin-right: 0;">个人所得税豁免；除石油公司和银行，迪拜的境外公司可豁免利得税、资本收益等一切税收</p>
        </div>
        <div class="cy-adv-inner" style="align-items: flex-start;height: 74px;">
          <span class="circle"></span>
          <p class="cy-adv-p" style="width: 130px;margin-top: 8px;margin-right: 0;">公司所有权完全来自于国外，且享受迪拜当地进口税收的优惠待遇</p>
        </div>
        <div class="cy-adv-inner" style="align-items: flex-start;height: 74px;">
          <span class="circle"></span>
          <p class="cy-adv-p" style="width: 130px;margin-top: 8px;margin-right: 0;">对绝大多数商品进口和再出口免税，允许所有利润的收入回国</p>
        </div>
        <div class="cy-adv-inner" style="align-items: flex-start;height: 41px;">
          <span class="circle"></span>
          <p class="cy-adv-p" style="width: 130px;margin-top: 8px;margin-right: 0;">地理位置优越</p>
        </div>
        <div class="cy-adv-inner" style="align-items: flex-start;height: 41px;">
          <span class="circle"></span>
          <p class="cy-adv-p" style="width: 130px;margin-top: 8px;margin-right: 0;">宽松的移民政策</p>
        </div>
        <div class="cy-adv-inner" style="align-items: flex-start;height: 57px;">
          <span class="circle"></span>
          <p class="cy-adv-p" style="width: 130px;margin-top: 8px;margin-right: 0;">公司免税经营期长达15到20年</p>
        </div>
        <div class="cy-adv-inner" style="height: 57px;">
          <p class="cy-adv-p1" @click="toAsk">立即咨询 ></p>
        </div>
      </div>
    </div>

    <div class="box" style="height: auto;padding-bottom: 30px;">
      <p class="box-p1">注册迪拜公司类型</p>
      <div class="type-content flex center-width">
        <img class="type-img" style="height: 110px;" src="../../assets/img/register/Dubai/pic_2_1.jpg" alt="">
        <img class="type-img" style="height: 110px;" src="../../assets/img/register/Dubai/pic_2_2.jpg" alt="">
        <img class="type-img" style="height: 110px;" src="../../assets/img/register/Dubai/pic_2_3.jpg" alt="">
        <img class="type-img" @click="toAsk" style="height: 110px;" src="../../assets/img/register/Dubai/pic_2_4.jpg" alt="">
      </div>
    </div>
    <div class="box bg-box" style="height: auto;padding-bottom: 15px;">
      <p class="box-p1">注册迪拜公司条件/流程/所得资料</p>
      <div class="tab-box">
        <div class="tab-inner" :class="{'active': tab == 1}" @click="tab = 1">注册条件</div>
        <div class="tab-inner" :class="{'active': tab == 2}" @click="tab = 2">注册流程</div>
        <div class="tab-inner" :class="{'active': tab == 3}" @click="tab = 3">所得资料</div>
      </div>
      <div class="file-content" style="width: 94%;margin: 0 auto;margin-top: 20px;background: #fff;">
        <div v-if="tab == 1">
          <div class="tab1-inner" style="align-items: center;">
            <img class="tab1-icon" src="../../assets/img/register/Dubai/pic_3_1.png" alt="">
            <div class="tab1-right">
              <p class="tab1-p2">拟定1-3个公司英文名称，避开当地政府禁止的字眼（详细可咨询我司专业顾问）</p>
            </div>
          </div>
          <div class="tab1-inner" style="align-items: center;">
            <img class="tab1-icon" src="../../assets/img/register/Dubai/pic_3_2.png" alt="">
            <div class="tab1-right">
              <p class="tab1-p2">公司股东至少一人</p>
            </div>
          </div>
          <div class="tab1-inner" style="align-items: center;">
            <img class="tab1-icon" src="../../assets/img/register/Dubai/pic_3_3.png" alt="">
            <div class="tab1-right">
              <p class="tab1-p2">迪拜自由区中的杰贝阿里自由区（JAFZA）,董事至少2人；哈伊马角酋长管辖区内的哈伊马角自由区（RAK）,董事至少1人</p>
            </div>
          </div>
          <div class="tab1-inner" style="align-items: center;">
            <img class="tab1-icon" src="../../assets/img/register/Dubai/pic_3_4.png" alt="">
            <div class="tab1-right">
              <p class="tab1-p2">须有当地注册地址和注册代理人</p>
            </div>
          </div>
          <div class="tab1-inner" style="align-items: center;">
            <img class="tab1-icon" src="../../assets/img/register/Dubai/pic_3_5.png" alt="">
            <div class="tab1-right">
              <p class="tab1-p2">公司秘书可以由注册代理人担任</p>
            </div>
          </div>
        </div>
        <div v-if="tab == 2">
          <img class="tab2-img" src="../../assets/img/register/Dubai/pic_4.png" alt="">
        </div>
        <div v-if="tab == 3">
          <div class="ger-tab3">
            <div class="ger-tab3-inner fre-tab3">
              <img class="ger-tab3-icon" src="../../assets/img/register/Dubai/pic_5_1.png" alt="">
              <p class="ger-tab3-p">营业执照</p>
            </div>
            <div class="ger-tab3-inner fre-tab3">
              <img class="ger-tab3-icon" src="../../assets/img/register/Dubai/pic_5_2.png" alt="">
              <p class="ger-tab3-p">股权证书</p>
            </div>
            <div class="ger-tab3-inner fre-tab3">
              <img class="ger-tab3-icon" src="../../assets/img/register/Dubai/pic_5_3.png" alt="">
              <p class="ger-tab3-p">公司章程大纲</p>
            </div>
            <div class="ger-tab3-inner fre-tab3">
              <img class="ger-tab3-icon" src="../../assets/img/register/Dubai/pic_5_4.png" alt="">
              <p class="ger-tab3-p">公司章程</p>
            </div>
            <div class="ger-tab3-inner fre-tab3">
              <img class="ger-tab3-icon" src="../../assets/img/register/Dubai/pic_5_5.png" alt="">
              <p class="ger-tab3-p">公司卡</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <div class="box" style="height: 725px;background: rgba(242, 245, 250, 1);">
      <p class="box-p1">注册迪拜公司后为您提供的后续服务</p>
      <div class="re-serve">
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Dubai/pic_6_1.png" alt="">
          <p class="re-serve-p1">年审/续牌</p>
          <p class="re-serve-p2">公司年审：每年必须更新公司执照</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Dubai/pic_6_2.png" alt="">
          <p class="re-serve-p1">税号申请</p>
          <p class="re-serve-p2">集团公司的增值税注册、亚马逊卖家的增值税注册 </p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Dubai/pic_6_3.png" alt="">
          <p class="re-serve-p1">做账报税</p>
          <p class="re-serve-p2">对于特殊行业必须要每月报税，其他常规行业的公司做账不是强制但必须的，是一个公司治理良好的重要指标</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Dubai/pic_6_4.png" alt="">
          <p class="re-serve-p1">银行开户</p>
          <p class="re-serve-p2">迪拜国民银行、阿布扎比国民银行、阿布扎比商业银行和马士里格银行</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Dubai/pic_6_5.png" alt="">
          <p class="re-serve-p1">秘书服务</p>
          <p class="re-serve-p2">办公地址，公司周年报表年检，公司董事股东成员名册更新，在职证明与良好证明，代缴政府规费，修改公司章程等</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Dubai/pic_6_6.png" alt="">
          <p class="re-serve-p1">公司注销</p>
          <p class="re-serve-p2">因破产、股东决议解散、存续期届满等原因需要注销的，应及时主动注销公司，同时需要取消各项许可证</p>
        </div>
      </div>
    </div>

    <div class="box" style="height: auto;padding-bottom: 30px;">
      <p class="box-p1">迪拜公司注册常见问题</p>
      <p class="box-p1">解决您的一切疑问</p>
      <div class="question">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item title="注册迪拜公司所需要注意哪些事项？" name="1">
            <p class="question-p">首先需要明确公司运营的实际用途，确定好注册公司的类型。其次，需要了解银行开户对公司以及对股东的相关要求和限制。</p>
          </el-collapse-item>
          <el-collapse-item title="注册迪拜公司的注册名称有哪些要求？" name="2">
            <p class="question-p">在公司命名方面并没有太多限制，公司名称可以以“有限公司”结尾。但是一定不能包含下列文字，且不得与
上市业务活动相同。如“银行”、“保险”、和“再保险”等。</p>
          </el-collapse-item>
          <el-collapse-item title="注册迪拜公司的注册董事及公司秘书要求？" name="3">
            <p class="question-p">公司需要一名股东、董事和秘书。允许法人股东和法人董事。没有要求规定指定一名迪拜本地人为董事，且股
东和董事可为同一人。董事身份不受国籍限制，也不要求董事常驻注册地。</p>
          </el-collapse-item>
          <el-collapse-item title="注册迪拜公司需要验资吗？" name="4">
            <p class="question-p">标准授权资本及实际资本无最低要求，不用验资。</p>
          </el-collapse-item>
        </el-collapse>
      </div>
      <p class="to-ask" @click="toAsk"> 
        <img class="to-ask-icon" src="../../assets/img/register/bvi/icon_6_customer_service.png" alt=""> 
        更多问题？立即咨询人工客服</p>
    </div>
    <div class="box" style="height: 480px;">
      <p class="box-p1">快企务的优势</p>
      <p class="box-p2">十余年注册迪拜公司经验</p>
      <div class="re-adv">
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_1.png" alt="">
          <p class="re-adv-p1">保密完善</p>
          <p class="re-adv-p2">保密系统完善，客户隐私不外泄</p>
        </div>
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_2.png" alt="">
          <p class="re-adv-p1">全程服务</p>
          <p class="re-adv-p2">一站式专业服务从注册公司到年审、开户，审计报税等</p>
        </div>
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_3.png" alt="">
          <p class="re-adv-p1">专业高效</p>
          <p class="re-adv-p2">根据需求，为客户制定专业快捷的解决方案</p>
        </div>
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_4.png" alt="">
          <p class="re-adv-p1">合同保障</p>
          <p class="re-adv-p2">正规机构，合同保障，费用透明，无隐形消费</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  data(){
    return{
      tab: 1,
      activeName: '1'
    }
  },
  mounted(){
    window.scrollTo(0,0);
  },
  methods:{
    toAsk(){
      window.open('https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn','_blank')
    },
  }
}
</script>
<style scoped>
  .m-bvi-banner{
    width: 100%;
    height: 460px;
    background: url('../../assets/img/register/Dubai/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
</style>
