<template>
  <div class="footer1">
    <!-- <div class="link">
      <p class="link-p1">联系我们</p>
      <p class="link-p2">专业、高效、敬业的团队为您服务</p>
    </div> -->
    <div class="footer-inner">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item title="全球公司注册" name="1">
            <div class="footer-content">
              <p class="info-page" @click="toUrl('registerBvi')">BVI公司注册</p>
            <p class="info-page" @click="toUrl('registerKaiman')">开曼公司注册</p>
            <p class="info-page" @click="toUrl('registerSingapore')">新加坡公司注册</p>
            <p class="info-page" @click="toUrl('registerHongKong')">中国香港公司注册</p>
            <p class="info-page" @click="toUrl('registerAmerica')">美国公司注册</p>
            <p class="info-page" @click="toUrl('registerBritain')">英国公司注册</p>
            <p class="info-page" @click="toUrl('registerGermany')">德国公司注册</p>
            <p class="info-page" @click="toUrl('registerFrench')">法国公司注册</p>
            <p class="info-page" @click="toUrl('registerJapan')">日本公司注册</p>
            <p class="info-page" @click="toUrl('registerKorea')">韩国公司注册</p>
            <p class="info-page" @click="toUrl('registerTaiwan')">中国台湾公司注册</p>
            <p class="info-page" @click="toUrl('registerMarshall')">马绍尔公司注册 </p>
            <p class="info-page" @click="toUrl('registerMacau')">中国澳门公司注册 </p>
            <p class="info-page" @click="toUrl('registerSwiss')">瑞士公司注册</p>
            <p class="info-page" @click="toUrl('registerCyprus')">塞浦路斯公司注册 </p>
            <p class="info-page" @click="toUrl('registerDubai')">迪拜公司注册 </p>
            <p class="info-page" @click="toUrl('registerAnquila')">安圭拉公司注册</p>
            <p class="info-page" @click="toUrl('registerMalta')">马耳他公司注册 </p>
            <p class="info-page" @click="toUrl('registerLuxembourg')">卢森堡公司注册 </p>
            <p class="info-page" @click="toUrl('registerVietnam')">越南公司注册</p>
            <p class="info-page" @click="toUrl('registerIndonesia')">印尼公司注册 </p>
            <p class="info-page" @click="toUrl('registerMauritius')">毛里求斯公司注册 </p>
            <p class="info-page" @click="toUrl('registerCanada')">加拿大公司注册</p>
            <p class="info-page" @click="toUrl('registerSamoa')">萨摩亚公司注册 </p>
            <p class="info-page" @click="toUrl('registerItaly')">意大利公司注册 </p>
            <p class="info-page" @click="toUrl('registerNetherlands')">荷兰公司注册</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="国际公证认证" name="2">
            <div class="footer-content">
              <p class="info-page" @click="toUrl('notarizationHague')">海牙认证</p>
              <p class="info-page" @click="toUrl('notarizationEmbassy')">使馆认证</p>
              <p class="info-page" @click="toUrl('notarizationChina')">中国委托公证人认证</p>
              <p class="info-page" @click="toUrl('notarizationTrade')">贸促会认证</p>
              <p class="info-page" @click="toUrl('certificationSamoa')">中国驻萨摩亚使馆认证</p>
              <p class="info-page" @click="toUrl('certificationSingapore')">中国驻新加坡使馆认证</p>
              <p class="info-page" @click="toUrl('certificationCanada')">中国驻加拿大使馆认证</p>
              <p class="info-page" @click="toUrl('certificationBvi')">中国驻BVI使馆认证</p>
              <p class="info-page" @click="toUrl('certificationRussia')">中国驻俄罗斯使馆认证</p>
              <p class="info-page" @click="toUrl('certificationGermany')">中国驻德国使馆认证</p>
              <p class="info-page" @click="toUrl('certificationAmerica')">中国驻美国使馆认证</p>
              <p class="info-page" @click="toUrl('certificationAustralia')">中国驻澳大利亚使馆认证</p>
              <p class="info-page" @click="toUrl('certificationcayman')">中国驻开曼使馆认证</p>
              <p class="info-page" @click="toUrl('certificationBritain')">中国驻英国使馆认证</p>
              <p class="info-page" @click="toUrl('certificationSeychelles')">中国驻塞舌尔使馆认证</p>
              <p class="info-page" @click="toUrl('certificationFrench')">中国驻法国使馆认证</p>
              <p class="info-page" @click="toUrl('certificationMalaysia')">中国驻马来西亚使馆认证</p>
              <p class="info-page" @click="toUrl('certificationVietnam')">中国驻越南使馆认证</p>
              <p class="info-page" @click="toUrl('certificationKorea')">中国驻韩国使馆认证</p>
              <p class="info-page" @click="toUrl('certificationNetherlands')">中国驻荷兰使馆认证</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="秘书服务" name="3">
            <div class="footer-content">
              <p class="info-page" @click="toUrl('examinationHongKong')">香港公司年审</p>
              <p class="info-page" @click="toUrl('examinationAmerica')">美国公司年审</p>
              <p class="info-page" @click="toUrl('examinationSingapore')">新加坡公司年审</p>
              <p class="info-page" @click="toUrl('examinationKaiman')">开曼公司年审</p>
              <p class="info-page" @click="toUrl('examinationBvi')">BVI公司年审</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="财税筹划" name="4">
            <div class="footer-content">
              <p class="info-page" @click="toUrl('financeHongKong')">香港公司财税筹划</p>
              <p class="info-page" @click="toUrl('financeAmerica')">美国公司财税筹划</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="境外投资备案" name="5">
            <div class="footer-content">
              <p class="info-page" @click="toUrl('ODI')">境外投资备案</p>
              <p class="info-page" @click="toUrl('VIE')">VIE架构</p>
              <p class="info-page" @click="toUrl('AmericaLaw')">美国法律意见书</p>
              <p class="info-page" @click="toUrl('HongKongLaw')">香港法律意见书</p>
              <p class="info-page" @click="toUrl('JapanTrademark')">日本签证</p>
              <p class="info-page" @click="toUrl('registerBvi')">香港优才计划</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="无犯罪记录证明" name="6">
            <div class="footer-content">
              <p class="info-page" @click="toUrl('noCriminalAmerica')">美国无犯罪记录证明</p>
              <p class="info-page" @click="toUrl('noCriminalSingapore')">新加坡无犯罪记录证明</p>
              <p class="info-page" @click="toUrl('noCriminalBritain')">英国无犯罪记录证明</p>
              <p class="info-page" @click="toUrl('noCriminalCanada')">加拿大无犯罪记录证明</p>
              <p class="info-page" @click="toUrl('noCriminalAustralia')">澳大利亚无犯罪记录证明</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="海外银行开户" name="7">
            <div class="footer-content">
              <p class="info-page" @click="toUrl('financeBank')">海外银行开户</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="知识产权" name="8">
            <div class="footer-content">
              <p class="info-page" @click="toUrl('innerTrademark')">国内商标注册</p>
              <p class="info-page" @click="toUrl('EUTrademark')">欧盟商标注册</p>
              <p class="info-page" @click="toUrl('AmericaTrademark')">美国商标注册</p>
              <p class="info-page" @click="toUrl('FranceTrademark')">法国商标注册</p>
              <p class="info-page" @click="toUrl('JapanTrademark')">日本商标注册</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="关于" name="9">
            <div class="footer-content">
              <p class="info-page" @click="toUrl('about')">关于快企务</p>
              <p class="info-page" @click="toUrl('join')">加入我们</p>
              <p class="info-page" @click="toUrl('news')">快企务资讯</p>
            </div>
          </el-collapse-item>
        </el-collapse>
    </div>

    <div class="phone-box" @click="callphone">
      <p class="phone-inner">全国销售热线: 400 860 5600</p>
    </div>

    <div class="copyright">
      <p class="copyright-p">Copyright © 2019 深圳金百利国际商务顾问有限公司<br />
        版权所有 All Rights Reserved.<br />
        粤ICP备12035854号-7</p>
    </div>

    <div class="phone-call">
      <p class="phone-call-inner" @click="toAsk">在线咨询</p>
      <p class="phone-call-inner phone-call-inner1" @click="callphone">电话咨询</p>
    </div>

  </div>
</template>

<script>
export default {
  name: 'Footer-page',
  props: {
    msg: String
  },
  data(){
    return{
      activeName: '0'
    }
  },
  methods: {
    toUrl(url){
      this.$router.push({name: url})
    },
    callphone () {
      // console.log(111)
      // window.location.href = 'tel://' + '400 860 5600'
      setTimeout(() => {
        window.location.href = 'tel://'+ '400 860 5600'
      },0)
    },
    toAsk(){
      window.open('https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn','_blank')
    },
  }
}
</script>
