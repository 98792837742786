<template>
  <div class="home">
    <div class="m-bvi-banner">
      <p class="banner-p1">一站式马绍尔公司注册服务</p>
      <p class="banner-p2">【快企务专注于涉内外企业服务十余年】</p>
      <p class="banner-btn banner-btn1" @click="toAsk">立即咨询</p>
    </div>

    <div class="box" style="height: 653px;">
      <p class="box-p1">注册马绍尔公司优势</p>
      <div class="mall-box">
        <p class="mall-box-p1">房地产投资</p>
        <p class="mall-box-p2">资料绝对保密；资产转移极为容易；极佳的财产投资计划的工具</p>
        <p class="mall-box-p1">合资企业</p>
        <p class="mall-box-p2">马绍尔公司可由不同国籍人士共同经营，法律制度及公司法获国际认可</p>
        <p class="mall-box-p1">马绍尔公司税收</p>
        <p class="mall-box-p2">海外离岸马绍尔公司无需缴税，不受外汇规定管制</p>
        <p class="mall-box-p1">证券/银行账目</p>
        <p class="mall-box-p2">马绍尔公司可以在美国证券市场上市，获主要金融市场认证</p>
        <p class="mall-box-p1">上市公司</p>
        <p class="mall-box-p2">马绍尔公司是集资的极佳工具，可用于主要证券交易所上市</p>
      </div>
      <img class="mall-box-img" src="../../assets/img/register/Marshall/pic_1.jpg" alt="">
    </div>

    <div class="box" style="height: 387px;background: rgba(242, 245, 250, 1);">
      <p class="box-p1">注册马绍尔公司类型</p>
      <div class="lunbo-box" style="margin-top: 20px;">
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
          <van-swipe-item>
            <img class="swiper-img" style="height: 274px;" src="../../assets/img/register/Marshall/pic_2_1.jpg" alt="">
          </van-swipe-item>
          <van-swipe-item>
            <img class="swiper-img" style="height: 274px;" src="../../assets/img/register/Marshall/pic_2_2.jpg" alt="">
          </van-swipe-item>
        </van-swipe>
      </div>
    </div>

    <div class="box" style="height: auto;padding-bottom: 15px;">
      <p class="box-p1">注册马绍尔公司条件/流程/所得资料</p>
      <div class="tab-box">
        <div class="tab-inner" :class="{'active': tab == 1}" @click="tab = 1">注册条件</div>
        <div class="tab-inner" :class="{'active': tab == 2}" @click="tab = 2">注册流程</div>
        <div class="tab-inner" :class="{'active': tab == 3}" @click="tab = 3">所得资料</div>
      </div>
      <div class="file-content flex center-width" style="background: rgba(242, 245, 250, 1);margin-top: 20px;">
        <div v-if="tab == 1">
          <div class="tab1-inner" style="background: rgba(242, 245, 250, 1);">
            <img class="tab1-icon" src="../../assets/img/register/Marshall/pic_3_1.png" alt="">
            <div class="tab1-right">
              <p class="tab1-p1">名称</p>
              <p class="tab1-p2">马绍尔公司名称必须以Limited、Corporation、Incorporated、Societe Anonyme上述字眼之缩写作结束语</p>
            </div>
          </div>
          <div class="tab1-inner" style="background: rgba(242, 245, 250, 1);">
            <img class="tab1-icon" src="../../assets/img/register/Marshall/pic_3_2.png" alt="">
            <div class="tab1-right">
              <p class="tab1-p1">董事</p>
              <p class="tab1-p2">马绍尔群岛公司并无董事人数的规定，法人也可以出任董事</p>
            </div>
          </div>
          <div class="tab1-inner" style="background: rgba(242, 245, 250, 1);">
            <img class="tab1-icon" src="../../assets/img/register/Marshall/pic_3_3.png" alt="">
            <div class="tab1-right">
              <p class="tab1-p1">股东</p>
              <p class="tab1-p2">一位或以上的股东，无国籍要求，若多人股东，确定好股份比例</p>
            </div>
          </div>
          <div class="tab1-inner" style="background: rgba(242, 245, 250, 1);">
            <img class="tab1-icon" src="../../assets/img/register/Marshall/pic_3_4.png" alt="">
            <div class="tab1-right">
              <p class="tab1-p1">注册资本</p>
              <p class="tab1-p2">通常授权的股份资本为USD 50,000，不需验资，不需到位</p>
            </div>
          </div>
          <div class="tab1-inner" style="background: rgba(242, 245, 250, 1);">
            <img class="tab1-icon" src="../../assets/img/register/Marshall/pic_3_5.png" alt="">
            <div class="tab1-right">
              <p class="tab1-p1">注册地址及秘书</p>
              <p class="tab1-p2">注册地址及法定秘书可以是自然人或者法人担任，若需要可由快企务提供</p>
            </div>
          </div>
          <div class="tab1-inner" style="background: rgba(242, 245, 250, 1);">
            <img class="tab1-icon" src="../../assets/img/register/Marshall/pic_3_6.png" alt="">
            <div class="tab1-right">
              <p class="tab1-p1">贸易限制</p>
              <p class="tab1-p2">马绍尔国际商业公司不允许从事保险和银行业</p>
            </div>
          </div>
        </div>
        <div v-if="tab == 2">
          <img class="tab2-img" src="../../assets/img/register/Marshall/pic_4.jpg" alt="">
        </div>
        <div v-if="tab == 3">
          <img class="tab2-img" src="../../assets/img/register/Marshall/pic_5.jpg" alt="">
        </div>
      </div>
    </div>
    
    <div class="box" style="height: 273px;background: rgba(242, 245, 250, 1);">
      <p class="box-p1" style="width: 100vw;">注册马绍尔公司后为您提供的后续服务</p>
      <div class="re-serve">
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Taiwan/pic_6_1.png" alt="">
          <p class="re-serve-p1">公司续牌</p>
          <p class="re-serve-p2">无经营时无税金，可续牌，逾期不续牌或超时将被罚款或注销牌照，详情咨询顾问</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Taiwan/pic_6_2.png" alt="">
          <p class="re-serve-p1">年度维护服务</p>
          <p class="re-serve-p2">费用:4800元人民币（提供公司注册地址使用及秘书服务)</p>
        </div>
      </div>
    </div>

    <div class="box" style="height: auto;padding-bottom: 30px;">
      <p class="box-p1">马绍尔公司注册常见问题</p>
      <p class="box-p1">解决您的一切疑问</p>
      <div class="question">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item title="马绍尔公司名称有什么要求？" name="1">
            <p class="question-p">公司名称不能与已注册公司的名称相同或太相似。公司必须向有关当局另行申请才可才用以下字样: bank,
chartered,establishment,foundation, insurance，partnership or trust 作为公司名称。</p>
          </el-collapse-item>
          <el-collapse-item title="马绍尔公司是不需要缴税吗？" name="2">
            <p class="question-p">1、公司不必就其环球收入或利润缴纳任何税项，目前马绍尔未与任何国家或地区签订任何双重税务条约
2、财政报表无须审核也不必提交予任何部门，但是公司仍然要编制足以反映其财务情况的财务报表；</p>
          </el-collapse-item>
          <el-collapse-item title="马绍尔公司优势？" name="3">
            <p class="question-p">1、房地产投资、证券买卖、信托及财产投资;提供私隐保护，不需公开股东、董事身份;
2、以普通法为依据，紧贴市场脉搏，深受外商欢迎;商业运作方便，可以在美国证券市场上市:
3、无需申报受益者、年利润及财务状况;
4、无须呈递周年报表或经审计帐目，无须召开周年大会;
5、海外离岸马绍尔公司无需缴税和申报税务，不受外汇规定管制;最大限度地财产保护，资金转移便利;
6、马绍尔群岛也允许公司迁册进来或者迁册出去，若马绍尔群岛公司并非由原居民成立，则可合法免除一切税项;</p>
          </el-collapse-item>
          <el-collapse-item title="马绍尔公司的股东是否有国籍限制？" name="4">
            <p class="question-p">股东和董事最少一个，既可以是自然人，也可以是法人，没有国籍上的限制。</p>
          </el-collapse-item>
        </el-collapse>
      </div>
      <p class="to-ask" @click="toAsk"> 
        <img class="to-ask-icon" src="../../assets/img/register/bvi/icon_6_customer_service.png" alt=""> 
        更多问题？立即咨询人工客服</p>
    </div>
    <div class="box" style="height: 480px;">
      <p class="box-p1">快企务的优势</p>
      <p class="box-p2">十余年注册马绍尔公司经验</p>
      <div class="re-adv">
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_1.png" alt="">
          <p class="re-adv-p1">保密完善</p>
          <p class="re-adv-p2">保密系统完善，客户隐私不外泄</p>
        </div>
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_2.png" alt="">
          <p class="re-adv-p1">全程服务</p>
          <p class="re-adv-p2">一站式专业服务从注册公司到年审、开户，审计报税等</p>
        </div>
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_3.png" alt="">
          <p class="re-adv-p1">专业高效</p>
          <p class="re-adv-p2">根据需求，为客户制定专业快捷的解决方案</p>
        </div>
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_4.png" alt="">
          <p class="re-adv-p1">合同保障</p>
          <p class="re-adv-p2">正规机构，合同保障，费用透明，无隐形消费</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  data(){
    return{
      tab: 1,
      activeName: '1'
    }
  },
  mounted(){
    window.scrollTo(0,0);
  },
  methods:{
    toAsk(){
      window.open('https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn','_blank')
    },
  }
}
</script>
<style scoped>
  .m-bvi-banner{
    width: 100%;
    height: 460px;
    background: url('../../assets/img/register/Marshall/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
</style>
