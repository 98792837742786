<template>
  <div class="certification m-bvi">
    <div class="m-bvi-banner">
      <p class="f1-banner-p1">开曼公司年审</p>
      <p class="f1-banner-p2">一站式全程代办<br />最快两周完成</p>
      <p class="banner-btn banner-btn1" @click="toAsk">立即咨询</p>
    </div>

    <div class="box" style="height: auto;padding-bottom: 20px;">
      <p class="box-p1">为什么要按时年审</p>
      <p class="box-p2">逾期年审影响</p>
      <div class="why-exma">
        <div class="why-exma-inner">
          <img class="why-exma-inner-icon" src="../../assets/img/examination/Cayman/icon_1_1.png" alt="">
          <p class="why-exma-inner-p">产生逾期的罚款</p>
        </div>
        <div class="why-exma-inner">
          <img class="why-exma-inner-icon" src="../../assets/img/examination/Cayman/icon_1_2.png" alt="">
          <p class="why-exma-inner-p">不能申请及得到良好地位证书(CERTIFICATE OF GOOD STANDING)</p>
        </div>
        <div class="why-exma-inner">
          <img class="why-exma-inner-icon" src="../../assets/img/examination/Cayman/icon_1_3.png" alt="">
          <p class="why-exma-inner-p">如果一家逾期提交申报表或缴纳申报费的时间超过一年，则该公司将会被除名，而公司的资产则归开曼群岛政府所有</p>
        </div>
      </div>
    </div>

    <div class="box" style="height: auto;padding-bottom: 20px;background: rgba(242, 245, 250, 1);">
      <p class="box-p1">开曼公司逾期年审的处罚</p>
      <div class="k-exma-time">
        <p class="k-exma-time-p1">开曼公司注册在3月31日需要递交周年申报表或申报费用</p>
        <p class="k-exma-time-p2">
          <img class="k-exma-time-p2-icon" src="../../assets/img/examination/Cayman/icon.png" alt="">
          在4月1日或之后但在6月30日或之前处罚<span class="red-style">33.33%</span>的年费
        </p>
        <p class="k-exma-time-p2">
          <img class="k-exma-time-p2-icon" src="../../assets/img/examination/Cayman/icon.png" alt="">
          在4月1日或之后但在6月30日或之前处罚<span class="red-style">66.67%</span>的年费
        </p>
        <p class="k-exma-time-p2">
          <img class="k-exma-time-p2-icon" src="../../assets/img/examination/Cayman/icon.png" alt="">
          在4月1日或之后但在6月30日或之前处罚<span class="red-style">100%</span>的年费
        </p>
        <img class="k-exma-time-img" src="../../assets/img/examination/Cayman/pic_1.png" alt="">
      </div>
    </div>

    <div class="box" style="height: auto;padding-bottom: 20px;">
      <p class="box-p1">开曼公司年审事项</p>
      <div class="exma-things" style="padding-top: 20px;">
        <div class="exma-things-inner">
          <img class="exma-things-icon" src="../../assets/img/examination/Cayman/icon_2_1.png" alt="">
          <p class="exma-things-p1">年审周期</p>
          <p class="exma-things-p2">在12月份开始提交下一年的年审</p>
        </div>
        <div class="exma-things-inner">
          <img class="exma-things-icon" src="../../assets/img/examination/Cayman/icon_2_2.png" alt="">
          <p class="exma-things-p1">所需资料</p>
          <p class="exma-things-p2">我司注册的不需要提供文件即可办理年审，其他公司注册的开曼公司需先办理转秘书之后办理年审业务</p>
        </div>
        <div class="exma-things-inner">
          <img class="exma-things-icon" src="../../assets/img/examination/Cayman/icon_2_3.png" alt="">
          <p class="exma-things-p1">年审费用</p>
          <p class="exma-things-p2">2400美元</p>
        </div>
        <div class="exma-things-inner">
          <img class="exma-things-icon" src="../../assets/img/examination/Cayman/icon_2_4.png" alt="">
          <p class="exma-things-p1">办理时间</p>
          <p class="exma-things-p2">一般回执在年审到期之后10-15个工作日提供</p>
        </div>
        <div class="exma-things-inner">
          <img class="exma-things-icon" src="../../assets/img/examination/Cayman/icon_2_5.png" alt="">
          <p class="exma-things-p1">年审完成后所得数据或文件</p>
          <p class="exma-things-p2">年审回执</p>
        </div>
      </div>
    </div>

    <div class="box" style="height: auto;background: rgba(242, 245, 250, 1);">
      <p class="box-p1">快企务优质秘书服务</p>
      <p class="box-p2">快企务为您提供更多服务</p>
      <div class="exma-serve">
        <img class="exma-serve-img" src="../../assets/img/examination/HK/pic_3_1.jpg" alt="">
        <img class="exma-serve-img" src="../../assets/img/examination/HK/pic_3_2.jpg" alt="">
        <img class="exma-serve-img" src="../../assets/img/examination/HK/pic_3_3.jpg" alt="">
        <img class="exma-serve-img" src="../../assets/img/examination/HK/pic_3_4.jpg" alt="">
        <img class="exma-serve-img" src="../../assets/img/examination/HK/pic_3_5.jpg" alt="">
        <img class="exma-serve-img" src="../../assets/img/examination/HK/pic_3_6.jpg" alt="">

      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'bvi-page',
  props: {
    msg: String
  },
  data(){
    return{
      form: {},
      activeName: '1'
    }
  },
  mounted(){
    window.scrollTo(0,0);
  },
  methods:{
    toAsk(){
      window.open('https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn','_blank')
    },
  }
}
</script>

<style scoped lang="scss">
  .m-bvi-banner{
    width: 100%;
    height: 460px;
    background: url('../../assets/img/examination/Cayman/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
</style>
