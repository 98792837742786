<template>
  <div class="certification m-bvi">
    <div class="m-bvi-banner">
      <p class="banner-certification">公司文件认证/个人文件认证</p>
      <p class="banner-p1" style="padding-top: 20px">中国驻萨摩亚使馆认证</p>
      <p class="banner-p2">七天取件丨一对一服务丨合同保障</p>
      <p class="banner-btn banner-btn1" @click="toAsk">立即咨询</p>
    </div>
    <div class="box" style="height: 449px;">
      <p class="box-p1">中国驻萨摩亚使馆认证简介</p>
      <p class="box-p2">萨摩亚使馆认证是萨摩亚文件拿到中国使用的前提，如办理萨摩亚外商投资企业变更、萨摩亚公司在国内法院办理诉讼、萨摩亚公司名义在国内设立公司、用萨摩亚持有国内商标专利及其变更等，都需要办理中国驻萨摩亚使馆认证，其真实性才会被国内相关政府部门认可。</p>
      <img class="cer-img1" src="../../assets/img/certification/Samoa/pic_1.jpg" alt="">
    </div>
    <div class="box" style="height: 590px;background: rgba(242, 245, 250, 1);">
      <p class="box-p1">中国驻萨摩亚使馆认证范围</p>
      <img class="cer-img2" src="../../assets/img/certification/Singapore/pic_2_1.png" alt="">
      <img class="cer-img2 cer-img3" src="../../assets/img/certification/Singapore/pic_2_1.png" alt="">
    </div>
    <div class="box" style="height: 290px;">
      <p class="box-p1">中国驻萨摩亚使馆认证所需资料</p>
      <div class="cer-lunbo-box">
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white" :width="300" :height="190" :loop="tr
        ">
          <van-swipe-item>
            <img class="cer-swiper-img" src="../../assets/img/certification/Singapore/pic_3_1.jpg" alt="">
          </van-swipe-item>
          <van-swipe-item>
            <img class="cer-swiper-img" src="../../assets/img/certification/Singapore/pic_3_2.jpg" alt="">
          </van-swipe-item>
          <van-swipe-item>
            <img class="cer-swiper-img" src="../../assets/img/certification/Singapore/pic_3_3.jpg" alt="">
          </van-swipe-item>
        </van-swipe>
      </div>
    </div>
    <div class="box" style="height: 330px;background: rgba(242, 245, 250, 1);">
      <p class="box-p1">中国驻萨摩亚使馆认证流程</p>
      <p class="box-p2" style="width: 100%;">中国驻萨摩亚使馆认证的时间一般为 <span class="blue-title-style">7~15</span> 个工作日</p>
      <img class="cer-img4" src="../../assets/img/certification/Samoa/pic_4.png" alt="">
    </div>
    <div class="box" style="height: 520px;">
      <p class="box-p1">快企务优势</p>
      <p class="box-p2">十余年中国驻萨摩亚使馆认证经验</p>
      <div class="cer-adv-box">
        <div class="cer-adv-inner">
          <img class="cer-adv-icon" src="../../assets/img/certification/Singapore/icon_1_1.png" alt="">
          <p class="cer-adv-p1">严格保密</p>
          <p class="cer-adv-p2">严密保存材料，公证书办理完成即刻销毁所有材料文件</p>
        </div>
        <div class="cer-adv-inner">
          <img class="cer-adv-icon" src="../../assets/img/certification/Singapore/icon_1_2.png" alt="">
          <p class="cer-adv-p1">透明公开</p>
          <p class="cer-adv-p2">价格公开透明，公证认证办理费用公开透明可查</p>
        </div>
        <div class="cer-adv-inner">
          <img class="cer-adv-icon" src="../../assets/img/certification/Singapore/icon_1_3.png" alt="">
          <p class="cer-adv-p1">全天跟进</p>
          <p class="cer-adv-p2">24小时跟进，每份公证材料专员全天候跟进反馈</p>
        </div>
        <div class="cer-adv-inner">
          <img class="cer-adv-icon" src="../../assets/img/certification/Singapore/icon_1_4.png" alt="">
          <p class="cer-adv-p1">退费承诺</p>
          <p class="cer-adv-p2">公证拒办退款，客户公证材料被拒全额退还费用</p>
        </div>
      </div>
    </div>
    <div class="box" style="height: 339px;background: rgba(242, 245, 250, 1);">
      <p class="box-p1">客户案例</p>
      <div class="lunbo-box cer-lunbo-box" style="margin-top: 20px">
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="rgba(47, 114, 235, 1)" >
          <van-swipe-item>
            <div class="cer-case-box">
              <img class="cer-case-img" src="../../assets/img/certification/Samoa/pic_5_1.jpg" alt="">
              <img class="cer-case-img" src="../../assets/img/certification/Samoa/pic_5_2.jpg" alt="">
            </div>
          </van-swipe-item>
          <van-swipe-item>
            <div class="cer-case-box">
              <img class="cer-case-img" src="../../assets/img/certification/Samoa/pic_5_3.jpg" alt="">
              <img class="cer-case-img" src="../../assets/img/certification/Samoa/pic_5_4.jpg" alt="">
            </div>
          </van-swipe-item>
        </van-swipe>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'bvi-page',
  props: {
    msg: String
  },
  data(){
    return{
     
    }
  },
  mounted(){
    window.scrollTo(0,0);
  },
  methods: {
    toAsk(){
      window.open('https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn','_blank')
    }
  }
}
</script>

<style scoped lang="scss">
  .m-bvi-banner{
    width: 100%;
    height: 460px;
    background: url('../../assets/img/certification/Samoa/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
</style>
