module.exports = {
    home:{
        'home-1' : '服务客户',
        'home-2' : '累计成交',
        'home-3' : '合作企业',
        'home-4' : '全球公司设立',
        'home-5' : '快速提供全球企业综合服务，助力企业扬帆起航，定制化的离岸架构，为您的生意打开世界之门',
        'home-6' : '注册办理',
        'home-7' : '秘书服务',
        'home-8' : '一站式全程代办，主流企业事务，代办项多至11项',
        'home-9' : '咨询办理',
        'home-10' : '全球各离岸属地注册地址服务',
        'home-11' : '立即咨询',
        'home-12' : '代理人法定秘书注册代理人或本地服务',
        'home-13' : '虚拟或实体办公室服务',
        'home-14' : '公司周年报表年检',
        'home-15' : '公司董事股东成员名册更新',
        'home-16' : '在职证明与良好证明',
        'home-17' : '公证认证与尽职调查',
        'home-18' : '更多秘书服务',
        'home-19' : '国际公证认证', 
        'home-20' : '国际公证认证，十余年行业品牌',
        'home-21' : '海牙认证',
        'home-22' : '中国委托公证人公证',
        'home-23' : '使馆认证',
        'home-24' : '贸促会认证',
        'home-25' : '“apostille”特指1961年《海牙关于取消外国公文认证要求的公约》签约国/地区之间相互承认的，特定的官方机构对公文上其主管部门所做签字、盖章的真实性予以确认的行为过程和结果，即是对原认证的签发人进行的二级认证，是一种特定的“认证”，这个进程叫做海牙认证，也称加签。',
        'home-26' : '查看详情',
        'home-27' : '公司类文件（商务）',
        'home-28' : '注册证书',
        'home-29' : '法人资格',
        'home-30' : '协议书',
        'home-31' : '更多',
        'home-32' : '个人类文件',
        'home-33' : '结婚证',
        'home-34' : '出生证',
        'home-35' : '离婚书',
        'home-36' : '护照认证',
        'home-37' : '第三方机构类文件',
        'home-38' : 'SO证书', 
        'home-39' : 'CE证书',
        'home-40' : '自由销售证书',
        'home-41' : '其他文件',
        'home-42' : '海关报告',
        'home-43' : '政府报告',
        'home-44' : '中国委托公证人公证',
        'home-45' : '在香港，内地使用的证明文书是由中国委托公证人根据当事人的申请，依法证明发生在香港地区的法律行为、有法律意义的文书和事实的真实性、合法性。',
        'home-46' : '查看详情',
        'home-47' : '咨询办理',
        'home-48' : '公证文书类型',
        'home-49' : '声明书',
        'home-50' : '证明单方签署的法律文书',
        'home-51' : '证明法律事实',
        'home-52' : '证明文件的原本及复印本',
        'home-53' : 'CEPA类公证文书',
        'home-54' : '证明双方或多方面签署的法律文书',
        'home-55' : '文书具体内容',
        'home-56' : '申请同内地人士结婚声明书',
        'home-57' : '申请内地亲属来港声明书', 
        'home-58' : '继承 (放弃继承) 遗产声明书',
        'home-59' : '个人委托书',
        'home-60' : '赠与书',
        'home-61' : '申请回内地收养子女声明书',
        'home-62' : '使馆认证',
        'home-63' : '属于三级认证，即国内公证机关公证——外交部认证——使馆/领事馆认证。领事认证是指领事认证机构根据自然人、法人或其他组织的申请，对国内涉外公证书、其他证明文书或国外有关文书上最后一个印鉴、签名的真实性予以确认的活动。',
        'home-64' : '中国驻外国使馆认证',
        'home-65' : '韩国',
        'home-66' : '英国',
        'home-67' : '美国三级认证',
        'home-68' : '南非',
        'home-69' : '澳大利亚',
        'home-70' : '印度',
        'home-71' : '德国',
        'home-72' : '加拿大',
        'home-73' : '摩洛哥',
        'home-74' : '新西兰',
        'home-75' : '新加坡',
        'home-76' : '法国',
        'home-77' : '哥伦比亚',
        'home-78' : '赞比亚',
        'home-79' : '斐济',
        'home-80' : '外国驻中国使馆认证',
        'home-81' : '美国',
        'home-82' : '肯尼亚',
        'home-83' : '澳大利亚',
        'home-84' : '阿联酋',
        'home-85' : '日本',
        'home-86' : '巴西',
        'home-87' : '利比亚',
        'home-88' : '新加坡',
        'home-89' : '澳大利亚',
        'home-90' : '意大利',
        'home-91' : '墨西哥',
        'home-92' : '贸促会认证',
        'home-93' : '贸促会认证也叫做报关单认证，是由中国国际贸易促进委员会出具签发的（贸促会商事认证，报关单商会认证，CCPIT认证）。主要申请办理各种货物出口原产地证证明书,不可抗力事实性证明，ATA单证册，自由销售证明书，国际商事认证等相关业务。根据使用国或地区的需求，部分国家或地区仅需贸促会认证即可，仍有部分国家或地区除做贸促会认证后还需“双认证”（即使馆认证/领事认证）',
        'home-94' : '不可抗力事实性证明',
        'home-95' : '商事证明书',
        'home-96' : '原产地证书',
        'home-97' : 'ATA单证册',
        'home-98' : '自由销售证明书',
        'home-99' : '出口商品品牌认证',
        'home-100' : '货物出口原产地证证明书',
        'home-101' : '银行开户',
        'home-102' : '全球任选开户，不成功退全款',
        'home-103' : '汇丰银行开户',
        'home-104' : '可配合完善开户条件',
        'home-105' : '恒生银行开户',
        'home-106' : '无需购买理财产品',
        'home-107' : '中国香港中银开户',
        'home-108' : '随时客预约开户',
        'home-109' : '花旗银行开户',
        'home-110' : '可当天预约开户',
        'home-111' : '大新银行开户',
        'home-112' : '无需大陆公司，门槛低',
        'home-113' : '渣打银行开户',
        'home-114' : '豁免各项费用，节约成本',
        'home-115' : '星展银行开户',
        'home-116' : '不受账户记录被关影响',
        'home-117' : '财务筹划',
        'home-118' : '专业香港会计师团队，全流程规范、透明',
        'home-119' : '中国香港公司做账',
        'home-120' : '专业顾问，一对一服务，诚信可靠',
        'home-121' : '中国香港公司报税',
        'home-122' : '无隐形收费，办理快，全程一对一服务',
        'home-123' : '中国香港公司审计',
        'home-124' : 'CRS税务启动，中国香港公司报税，必...',
        'home-125' : 'CRS全球税务交换',
        'home-126' : '专业顾问，一对一服务，诚信可靠',
        'home-127' : '中国香港银行调查',
        'home-128' : '无隐形收费，办理快，全程一对一服务',
        'home-129' : '合理规避税务',
        'home-130' : 'CRS税务启动，中国香港公司报税，必......',
        'home-131' : '境外投资备案',
        'home-132' : '四大步骤快速审批，专业的信息资源',
        'home-133' : '企业ODI备案',
        'home-134' : '境外投资备案，英文全称Overseas Direct Investment，也就是我们通常所说的ODI，是指在中华人民共和国境内依法设立的企业通过新设、并购及其他方式在境外拥有非金融企业或取得既有非金融企业所有权、控制权、经营管理权及其他权益的行为。',
        'home-135' : '管理办法：商务部和省级商务主管部门按照企业境外投资的不同情形，分别实行备案和核准管理。企业境外投资涉及敏感国家和地区、敏感行业的，实行核准管理。核准管理向国家商务部提出申请。企业其他情形的境外投资，实行备案管理，备案向地方商务部提出申请。',
        'home-136' : '37号文',
        'home-137' : '内地企业境外融资、境外上市，个人股东需要办理37号登记。37号文，即《国家外汇管理局关于境内居民通过特殊目的公司境外投融资及返程投资外汇管理有关问题的通知》(汇发[2014]37号)，由外汇管理局于2014年7月14日发布，该文所规定的境外投资外汇登记普遍被简称为“37号文登记”。',
        'home-138' : '知识产权',
        'home-139' : '每日查询，免费查询商标，极速出结果',
        'home-140' : '商标服务',
        'home-141' : '版权登记',
        'home-142' : '专利申请',
        'home-143' : '项目申报',
        'home-144' : '国内商标服务',
        'home-145' : '商标服务',
        'home-146' : '国际商标注册',
        'home-147' : '普通商标注册',
        'home-148' : '价格实惠，快企务爆款',
        'home-149' : '商标极速注册',
        'home-150' : '59分钟递送商标局',
        'home-151' : '极速双保注册',
        'home-152' : '59分钟报件，如驳回免费再注册一个',
        'home-153' : '商标驳回复审',
        'home-154' : '商标申请被驳回，申请复审力挽狂澜',
        'home-155' : '商标续展',
        'home-156' : '面临被注销风险，及时续展保护权利',
        'home-157' : '商标异议答辩',
        'home-158' : '商标被他人异议，答辩应对助成功',
        'home-159' : '美国商标注册',
        'home-160' : '注册时间：12-18个月',
        'home-162' : '有效期：10年',
        'home-163' : '新加坡商标注册',
        'home-164' : '注册时间：9-12个月',
        'home-165' : '有效期：10年',
        'home-166' : '更多国际商标注册',
        'home-167' : '版权服务',
        'home-168' : '软件著作权登记',
        'home-169' : '小程序/H5/APP/应用软件站',
        'home-170' : '美术作品著作权登记',
        'home-171' : '摄影作品/艺术作品/视频/图形/音乐/',
        'home-172' : '作品著作权登记',
        'home-173' : '文学著作/散文/论文/诗歌/剧本',
        'home-174' : '专利服务',
        'home-175' : '实用新型专利申请',
        'home-176' : '保护结构形状创新审查周期短',
        'home-177' : '发明专利申请',
        'home-178' : '保护产品创新专利权维持20年',
        'home-179' : '外观设计专利申请',
        'home-180' : '保护产品独特设计，审查周期短',
        'home-181' : '项目申报',
        'home-182' : '高新企业培育入库',
        'home-183' : '对入库、出库企业给予研发支持',
        'home-184' : '高新企业技术申报',
        'home-185' : '按企业上年度研发实际支出，予以一定比例资助',
        'home-186' : '高新企业研发资助',
        'home-187' : '按企业上年度研发实际支出，予以一定比例资助',
        'home-188' : '用心服务，为每一位客户创造非凡价值',
        'home-189' : '快企务客户满意度高达',
        'home-190' : '高级顾问经理',
        'home-191' : '熟悉香港、美国、新加坡、英国等地资本市场，精通各国财税实务在上市公司审计、财务风险管控、境外上市融资等方面均有独到见解和丰富经验',
        'home-192' : '高级顾问',
        'home-193' : '十余年离岸公司注册、国际公证认证以及股权架构搭建的丰富经验，涉外企业咨询经理丰富，获得各新老客户的一致好评',
        'home-194' : '高级顾问',
        'home-195' : '精通国内外税务制度，熟悉全球100多个国家及地区离岸/在岸公司设立，能为客户量身定制税务规划、海外上市架构搭建等方案',
        'home-196' : '快企务服务特色',
        'home-197' : '客户选择长期合作',
        'home-198' : '时间要求最快',
        'home-199' : '效率保证最高',
        'home-200' : '业内首家提出涉外综合服务的专业服务商，满足客户全方位的需求，提供多元化一站式专业服务',
        'home-201' : '整合全球资源',
        'home-202' : '提供综合服务',
        'home-203' : '最快时间调取客户所需资源不分节假日，第一时间高效回复，减少客户的等待时间',
        'home-204' : '了解客户需求',
        'home-205' : '创新产品方案',
        'home-206' : '紧盯客户与市场的需求，不断创新产品，高效升级方案多维度满足客户的需要',
        'home-207' : '拥抱合规合法',
        'home-208' : '尊重忠诚客户',
        'home-209' : '我们配合并要求客户提供合规合法之文件，严格保密客户的任何隐私并忠诚于客户',
        'home-210' : '最新资讯',
        'home-211' : '行业资讯 企业动态',
        'home-212' : '更多企业动态',
        'home-213' : '客户案例',
        'home-214' : '更多案例',
        'home-215' : '查看更多',

        // 'home-215' : '查看更多',
        // 'home-216' : '查看更多',
        // 'home-217' : '查看更多',
        // 'home-218' : '查看更多',
        // 'home-219' : '查看更多',
        // 'home-220' : '查看更多',
    },
    bvi:{
       'bvi-ask': '立即咨询',
       'bvi-1': '快企务专注于涉内外企业服务十余年',
       'bvi-2': '一站式BVI公司注册服务',
       'bvi-3': '注册BVI公司优势',
       'bvi-4': '公司不受股东人数限制',
       'bvi-5': '政治、经济和贸易环境稳定',
       'bvi-6': '不在岸经营可不交当任何税',
       'bvi-7': '世界各地均可开立银行账户',
       'bvi-8': '董事资料保密金融法律良好',
       'bvi-9': '注册BVI公司类型',
       'bvi-10': '本土公司',
       'bvi-11': '非本土公司',
       'bvi-12': '豁免公司',
       'bvi-13': '有期限公司',
       'bvi-14': '海外公司',
       'bvi-15': '注册BVI公司条件/流程/资料',
       'bvi-16': '注册BVI公司条件',
       'bvi-17': '注册BVI公司流程',
       'bvi-18': '注册完成后所得资料',
       'bvi-19': '名称',
       'bvi-20': '公司名称必须以“有限公司”结尾，如：LIMITED、CORPORATION、INCORPORATED或缩写。公司名称不能以“信托公司”、“银行”或其他被认定为意思相似的词结尾，除非这些公司在英属京群岛（bvi）获得相应的执照。',
       'bvi-21': '董事',
       'bvi-22': '一个或多个董事，可以是法人实体或者任何国籍的自然人。',
       'bvi-23': '地址',
       'bvi-24': '公司注册地址必须在英属维京群岛，快企务可提供。',
       'bvi-25': '注册资金',
       'bvi-26': 'BVI公司标准注册资金为50000美元（资金无需验资到位），分为50,000股，每股为1美元；如资金超过50000美元，政府需收取1350美元印花税。',
       'bvi-27': '董事会议',
       'bvi-28': '董事可在任何一个国家举行会议，或通过代理参加这样的会议。',
       'bvi-29': '公司印鉴',
       'bvi-30': '英属维京群岛公司必须有自己的公司印鉴，并且公司印鉴的式样需上报公司注册署。',
       'bvi-31': '要注册的公司名称查询是否可以注册，一般1-2个工作日',
       'bvi-32': '签订授权委托书和合同',
       'bvi-33': '客户提供一系列注册公司所需要的文件及资料',
       'bvi-34': '将注册完成后的公司文件快递给客户',
       'bvi-35': '注册完成客户收到注册成功后文件的扫描件',
       'bvi-36': '开始注册约8个月工作日完成注册',
       'bvi-37': '公司注册后续服务',
       'bvi-38': '年审/续牌',
       'bvi-39': '年审或续牌根据国家地区的要求，如期进行合规申报，否则产生罚款',
       'bvi-40': '做账报税',
       'bvi-41': '公司注册之后需按照当地政府单位要求进行做账报税',
       'bvi-42': '公司变更',
       'bvi-43': '公司经营过程中的公司信息（如地址、股东、经营范围等）变更',
       'bvi-44': '公司注销',
       'bvi-45': '公司确认不继续经营之后，需及时提交相关资料进行注销，避免产生后续罚款',
       'bvi-46': '银行开户',
       'bvi-47': '开设公司注册所在地的银行账户，有利于公司随时接受贸易汇款，享受当地政策或...',
       'bvi-48': '秘书服务',
       'bvi-49': '为公司提供完善的支持服务，包括但不限于代收及处理政府往来函件、提供年审报...',
       'bvi-50': 'BVI公司注册常见问题',
       'bvi-51': '注册BVI公司是否一定要有中文公司名称?',
       'bvi-52': '不一定。法例只规定必须有英文公司名称，可自行决定是否采用中文BVI公司名称。而且,并不是每个BVI公司注册地',
       'bvi-53': '都允许采用中文名称的',
       'bvi-54': '注册bvi公司经营范围注意事项？',
       'bvi-55': '注册BVI公司的优势有哪些？',
       'bvi-56': '1、BVI公司设立条件非常宽松，成本也很低廉，只要一美金即可成立BVI公司；',
       'bvi-57': '2、由于BVI的国际业务公司法与税法中，特别为BVI以外的投资人设计了一套非常灵活与具有弹性的制度，因此被列为“避税天堂”；',
       'bvi-58': '3、一般从事控股、金融、保险及海外基金的公司，都会选择将离岸公司注册于这类税务天堂。',
       'bvi-59': '注册BVI公司的优势有哪些?',
       'bvi-60': '1、BVI公司设立条件非常宽松，成本也很低廉，只要一美金即可成立BVI公司；',

       'bvi-61': '2、由于BVI的国际业务公司法与税法中，特别为BVI以外的投资人设计了一套非常灵活与具有弹性的制度，因此被列为“避税天堂”；',
       'bvi-62': '3、一般从事控股、金融、保险及海外基金的公司，都会选择将离岸公司注册于这类税务天堂。弹性的制度，因此被列为“避税天堂”；',
       'bvi-63': '3、一般从事控股、金融、保险及海外基金的公司，都会选择将离岸公司注册于这类税务天堂。',
       'bvi-64': '注册BVI公司是哪个地区的公司?',
       'bvi-65': '英属维尔京群岛（(The Britis Virgin lslands, B.V.l)是世界上发展最快的海外离岸投资中心之一，在此注册的公司就被',
       'bvi-66': '称作BV公司，常见于为在境外或香港上市而搭建的VIE交易架构中。',
       'bvi-67': '快企务的优势',
       'bvi-68': '十余年注册BVI公司经验',
       'bvi-69': '公正',
       'bvi-70': '费用透明，无隐形消费',
       'bvi-71': '公正',
       'bvi-72': '',
       'bvi-73': '',
       'bvi-74': '',
       'bvi-75': '',
       'bvi-76': '',
       'bvi-77': '',
       'bvi-78': '',
       'bvi-79': '',
       'bvi-80': '',

       'bvi-61': '',
       'bvi-62': '',
       'bvi-63': '',
       'bvi-64': '',
       'bvi-65': '',
       'bvi-66': '',
       'bvi-67': '',
       'bvi-68': '',
       'bvi-69': '',
       'bvi-70': '',
       'bvi-71': '',
       'bvi-72': '',
       'bvi-73': '',
       'bvi-74': '',
       'bvi-75': '',
       'bvi-76': '',
       'bvi-77': '',
       'bvi-78': '',
       'bvi-79': '',
       'bvi-80': '',
    }
}