<template>
  <div class="certification m-bvi">
    <div class="m-bvi-banner">
      <p class="finance-banner-p" style="padding-top: 63px">法国商标注册</p>
      <p class="finance-banner-p2">法国是商标制度比较完善的国家,审查效率是世界上最快的国家之一。申请时以自然人或者法人身份委托法国当地代理，向法国国家工业产权局提出申请，其一份申请可以指定多个类别，商品分类采用尼斯协定分为45类。商标专用权从申请日起算，有效期十年，每十年可申请续展一次，续期申请应在注册有效期的最后 6个月内提交并缴纳续展费用。</p>
    </div>
    <div class="box" style="height: 656px;background: rgba(242, 245, 250, 1);">
      <p class="box-p1" style="width: 100vw;">下列文字和图形一般不能作为注册商标：</p>
      <div class="danger-box">
        <div class="danger-inner">
          <p class="danger-p1" style="width: 320px;">某种商品通用名称</p>
        </div>
        <div class="danger-inner">
          <p class="danger-p1" style="width: 320px;">含有误导或欺骗消费者的名称不能作为商标</p>
        </div>
        <div class="danger-inner">
          <p class="danger-p1" style="width: 320px;">名称或图形不能冒犯公共道德及亵渎宗教信仰</p>
        </div>
        <div class="danger-inner">
          <p class="danger-p1" style="width: 320px;">在有关商标保护的国际公约中明确规定，任何一个主权国家的国旗、国徽及国际组织的会旗、会徽不能作为商标图形</p>
        </div>
        <div class="danger-inner">
          <p class="danger-p1" style="width: 320px;">违反公共秩序及公共道德的标记</p>
        </div>
        <div class="danger-inner">
          <p class="danger-p1" style="width: 320px;">违反《巴黎公约》关于禁用标记的规定的标记</p>
        </div>
        <div class="danger-inner">
          <p class="danger-p1" style="width: 320px;">对商品或服务项目的内容或品质带有说明性质的标记</p>
        </div>
        <div class="danger-inner">
          <p class="danger-p1" style="width: 320px;">有欺骗公众之嫌的标记</p>
        </div>
      </div>
    </div>
    <div class="box" style="height: 586px;">
      <p class="box-p1">法国商标注册申请资料</p>
      <div class="trademark-file">
        <p class="trademark-file-p1" style="width: 340px;">以法人申请，附《营业执照》或有效登记证明复印件1份；以自然人申请附个人身份证明文件1 份</p>
        <p class="trademark-file-p1" style="width: 340px;">申请人名义、地址中英文</p>
        <p class="trademark-file-p1" style="width: 340px;">商标图样</p>
        <p class="trademark-file-p1" style="width: 340px;">需保护商品 / 服务名称和类别</p>
        <p class="trademark-file-p1" style="width: 340px;">优先权声明（如需要）</p>
        <img class="trademark-file-img" style="width: 280px;height: 256px;" src="../../assets/img/finance/FranceTrademark/pic_1.jpg" alt="">
      </div>
    </div>
    <div class="box" style="height: 830px;background: rgba(242, 245, 250, 1);">
      <p class="box-p1">法国商标注册程序时间</p>
      <div class="trademark-flow">
        <p class="trademark-flow-p1">
          <img class="trademark-flow-icon" src="../../assets/img/finance/innerTrademark/pic_2_1.png" alt="">
          <span class="trademark-flow-span1">第一步：商标注册审查</span>
        </p>
        <p class="trademark-flow-p2">法国商标主管机关对递交的商标注册申请首先进行形式审查，即审查申请文件是否齐备，商标图样是否清晰，商品或服务类别是否准确。对于通过形式审查的商标申请，审查员仅仅审查商标是否具有显著特征，是否包含不得作为商标的名称或标记，而不对在先权利冲突进行检索。如果商标不存在禁止注册的情况，商标将被公告</p>
        <p class="trademark-flow-p1">
          <img class="trademark-flow-icon" src="../../assets/img/finance/innerTrademark/pic_2_2.png" alt="">
          <span class="trademark-flow-span1">第二步：驳回和复审</span>
        </p>
        <p class="trademark-flow-p2">通过审查的商标将被刊登在商标公告上。公告期为两个月。注册商标所有人或在先申请或享有优先权日的申请人，以及驰名商标所有人均可以对公告商标提出异议</p>
        <p class="trademark-flow-p1">
          <img class="trademark-flow-icon" src="../../assets/img/finance/innerTrademark/pic_2_3.png" alt="">
          <span class="trademark-flow-span1">第三步：公告和异议</span>
        </p>
        <p class="trademark-flow-p2">经审查，审查官认为商标申请可以被接受后，便会在美国官方商标公告上刊登公告。自公告日起1个月为异议期</p>
        <p class="trademark-flow-p1">
          <img class="trademark-flow-icon" src="../../assets/img/finance/innerTrademark/pic_2_4.png" alt="">
          <span class="trademark-flow-span1">第四步：注册</span>
        </p>
        <p class="trademark-flow-p2">经异议被裁定可以注册的商标，或经公告没有异议的商标将获准注册并下发注册证。整个顺利的注册过程，大约需要10-12个月</p>
        <p class="box-p1" style="padding-top: 10px;font-weight: bold;">法国商标注册程序时间</p>
        <p class="tips" style="margin-top: 10px">法国的商标主管部门是<span style="color: rgba(47, 114, 235, 1)">国家工业产权局(INTI: INSTITUT NATIONAL DE LAPROPRIETE INEUSTRIELLE)。</span>
          该局除在巴黎设有总部外，还在波尔多、里昂、马赛、南希、尼斯、雷恩、斯特拉斯堡等大中城市设有七个分局。受理商标注册的部门是国家工业产权局及各地的商业法院。
因此，在法国的商标注册登记有两种途径：一是商标申请人到国家工业产权局（巴黎总部或上述七个分局之一）登记；二是到公司所在的商业法院（TRIBUNAL DE COMMERCE）登记。这两种登记方式具有同等法律效力。</p>
      </div>
    </div>
    <div class="box" style="height: 700px;">
      <p class="box-p1">商标异议申请</p>
      <div class="trademark-file">
        <p class="trademark-file-p1" style="width: 340px;">商标异议的内容范围很广，既包括初步审定的商标与申请在先的商标相同或近似，也包括初步审定的商标违反了《商标法》的禁用条款或商标不具显著性，还包括申请人不具备申请资格等；</p>
        <p class="trademark-file-p1" style="width: 340px;">提出商标异议的可以是任何人，即：既可以是商标注册人，也可以是非商标注册人，既可以是企业、事业单位，也可以是个人，既可以是法人，也可以是非法人；</p>
        <p class="trademark-file-p1">商标的异议期为初步审定的商标自公告之日起三个月内，商标异议申请日期，以商标局请书件的日期为准。异议期的最后一天是节假日的，可顺延至节假日后的第收到申一个工作日；</p>
        <p class="trademark-file-p1">商标异议人须交送《商标异议书》，在异议书中要将被异议商标的申请人、商品类别、商标刊登初审公告的日期、公告期号和初审号填写清楚。反之，当别人对自己商标注册提出异议时，被异议人可以在期限内作出答辩</p>
        <img class="trademark-file-img" style="width: 280px;height: 214px;" src="../../assets/img/finance/FranceTrademark/pic_3.jpg" alt="">
      </div>
    </div>
    
    <div class="box" style="height: auto;padding-bottom: 30px;background: rgba(242, 245, 250, 1)">
      <p class="box-p1">常见问题</p>
      <div class="question">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item title="注册法国商标的程序？" name="1">
            <p class="question-p">1、向法国国家工业产权局递交申请。
2、形式审查（1-2个月内拿到申请号及申请文件），提交申请后约两周内受理。申请受理后起1-2个月内进行形式审查，对于违反法律规定的商标或是缺乏显著性
的商标，法国工业产权局拒绝注册。
3、公告：公告期为2个月，任何人可在此期间内提出异议。4、核准注册。
如果无人在公告期提出异议，或是异议不被支持，则商标予以核准注册。
整个顺利的注册过程，大约需要8-10月。商标的有效期为十年，自申请日起算。</p>
          </el-collapse-item>
          <el-collapse-item title="法国商标权利的丧失？" name="2">
            <p class="question-p">1、对于属于商标法中所列的不具备显著性的商标和不得作为商标注册的商标，均被视为无效商标，将丧失其
商标权利。
2、商标的撤销。如果商标申请是以对第三人权利的欺诈手段或者以违反法律责任或协议不正当方式取得的，
认为对该商标享有权利的当事人，可以向法院起诉，请求撤销该商标。提出撤销商标的请求期限是3年，但对
于恶意申请的商标，不受3年时间的限定。
3、商标所有权人无正当理由，连续5年没有在注册商标指定的产品或服务上使用其商标，将丧失其商标权。</p>
          </el-collapse-item>
          <el-collapse-item title="法国商标生效日期？" name="3">
            <p class="question-p">商标生效的日期以国家工业产权局或商业法院收到申请书为准。如果某项商标最终获得注册批准，其商标生效
日期从国家工业产权局或商业法院收到申请书开始计算。</p>
          </el-collapse-item>
          <el-collapse-item title="法国商标注册的审查？" name="4">
            <p class="question-p">重点审查以下几个方面：
1、商标的合法性规定：国家工业产权在收到申请后有4个月期限来审查该商标是否违背了有关规定，该商标文
字及图形是否适合于作为商标。
2、商标的合法性审查：国家工业产权局在收到商标申请后6周内该申请对外公布，所有认为已经注册了相同或
类似商标公司都可以提出异议。其程序是到国家工业产权局填写一张“反对注册表”，详细说明自己的理由及
依据。
3、申请表填写是否正确：国家工业产权局如果认为所申请商标不符合上述两条标准，将拒绝批准该商标。如某
一商标申请被国家工业产权局驳回，申请人向上诉法院（COURD'APPEL）提出申诉，要求国家工业产权局重新
审理。
4、法国商标注册证书：如国家工业产权局批准某一商标注册的申请，将发给申请人一个“商标注册号”及“商
标注册证书”，并对外公布。商标生效的日期以国家工业产权局或商业法院收到的申请书为准。如果某项商标
最终获得注册批准，其商标生效日期从为家工业产权局或商业法院收到申请书开始计算。
5、法国商标保护期： 法国商标保护期为10年。期满后半年内可以续展。</p>
          </el-collapse-item>
        </el-collapse>
      </div>
      <p class="to-ask" @click="toAsk"> 
        <img class="to-ask-icon" src="../../assets/img/register/bvi/icon_6_customer_service.png" alt=""> 
        更多问题？立即咨询人工客服</p>
    </div>
    <div class="box" style="height: 418px;background: #fff;">
      <p class="box-p1">为什么选择我们</p>
      <div class="why-us">
        <div class="why-us-inner">
          <img class="why-us-icon" src="../../assets/img/finance/innerTrademark/icon_3_1.png" alt="">
          <p class="why-us-p1">快速</p>
          <p class="why-us-p2">一对一顾问服务，快速响应客户需求</p>
        </div>
        <div class="why-us-inner">
          <img class="why-us-icon" src="../../assets/img/finance/innerTrademark/icon_3_2.png" alt="">
          <p class="why-us-p1">专业</p>
          <p class="why-us-p2">十余年专业代理国内外商标注册相关业务</p>
        </div>
        <div class="why-us-inner">
          <img class="why-us-icon" src="../../assets/img/finance/innerTrademark/icon_3_3.png" alt="">
          <p class="why-us-p1">严谨</p>
          <p class="why-us-p2">商标顾问、质检部经理、客户三重质检、多重把关</p>
        </div>
        <div class="why-us-inner">
          <img class="why-us-icon" src="../../assets/img/finance/innerTrademark/icon_3_4.png" alt="">
          <p class="why-us-p1">保障</p>
          <p class="why-us-p2">知识产权的商标注册成功率高达98.3%</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'bvi-page',
  props: {
    msg: String
  },
  data(){
    return{
      form: {},
      activeName: '1'
    }
  },
  mounted(){
    window.scrollTo(0,0);
  },
  methods:{
    toAsk(){
      window.open('https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn','_blank')
    },
  }
}
</script>

<style scoped lang="scss">
  .m-bvi-banner{
    width: 100%;
    height: 230px;
    background: url('../../assets/img/finance/FranceTrademark/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
</style>
