<template>
  <div class="certification m-bvi">
    <div class="odi-banner">
      <p class="odi-banner-p1">VIE架构搭建</p>
      <p class="odi-banner-p2">互联网企业都选择在群岛注册公司</p>
      <p class="odi-banner-p3">快企务为您提供公司注册+注册地址+法定秘书一站式服务</p>
      <p class="banner-btn banner-btn1">立即办理</p>
    </div>
    
    <div class="box" style="height: auto;">
      <p class="box-p1">什么是VIE架构？</p>
      <p class="odi-p1" style="margin-top: 10px;margin-bottom: 30px;">VIE架构（Variable Interest Equity，直译为“可变利益实体”）是一种境内企业间接实现境外上市目的的一种模式。在VIE架构下，境外上市主体与境内经营实体相分离，境外上市主体通过协议的方式控制境内经营实体，并将境内经营实体的会计报表并入境外上市主体，从而实现使用境内经营主体的业绩在境外挂牌上市。</p>
      <img class="odi-img1" style="width: 300px;height: 300px;" src="../../assets/img/finance/VIE/pic_1.jpg" alt="">
    </div>
    <div class="box" style="height: auto;background: rgba(242, 245, 250, 1);">
      <p class="box-p1">马云、张勇等大佬海外上市咋这么钟情VIE架构？</p>
      <div class="vie-swiper-box" >
          <van-swipe class="my-swipe" :autoplay="3000" indicator-color="rgba(47, 114, 235, 1)" >
            <van-swipe-item>
              <div class="vie-swiper vie-bg1" >
                <p class="vie-swiper-p1">腾讯</p>
                <p class="vie-swiper-p2">腾讯虽然是在香港上市，但同样搭建了完善的VIE结构。从下图中及港交所公报可以看出，腾讯的经营实体是深圳市腾讯计算机系统有限公司（以下简称腾讯计算机系统）和深圳市世纪凯旋科技有限公司（以下简称世纪凯旋），这两家公司的股权结构完全一样，由马化腾控股54.29%，其余股东为张志东、许晨晔、陈一丹。
WOFE公司为腾讯科技（深圳）有限公司（以下简称腾讯科技），该公司由注册在英属维京群岛的中霸集团有限公司全资控股，同样经营利润通过WFOE腾讯科技最终转移到上市主体开曼公司</p>
              </div>
            </van-swipe-item>
            <van-swipe-item>
              <div class="vie-swiper vie-bg2" >
                <p class="vie-swiper-p1">阿里巴巴</p>
                <p class="vie-swiper-p2">阿里巴巴目前的体系太非常庞杂，阿里巴巴在境内的经营实体是最底层的浙江淘宝网络有限公司和浙江天猫网络有限公司。

浙江淘宝网络和浙江天猫网络的法定代表人都是蒋凡，两家公司的股东是杭州臻希投资管理有限公司，杭州臻希投资管理有限公司的股东两家内资的有限合伙企业，合伙人都是阿里的高管。而这两家公司的利润通过WFOE公司最终输送到阿里巴巴开曼公司。</p>
              </div>
            </van-swipe-item>
            <van-swipe-item>
              <div class="vie-swiper vie-bg3" >
                <p class="vie-swiper-p1">京东</p>
                <p class="vie-swiper-p2">京东的主体结构相对简单，实际经营主体是北京京东叁佰陆拾度电子商务有限公司，原法定代表人为刘强东（2020年11月25日变更为张奇），股东是刘强东等三位高管；而WOFE公司北京京东世纪贸易有限公司，该公司由京东香港国际有限公司全资控股，同样经营利润通过WFOE北京京东世纪贸易有限公司转移到上市主体开曼公司阿里巴巴集团。</p>
              </div>
            </van-swipe-item>
            <van-swipe-item>
              <div class="vie-swiper vie-bg4" >
                <p class="vie-swiper-p1">百度</p>
                <p class="vie-swiper-p2">百度的经营主体是北京百度网讯科技有限公司（以下简称百度网讯），李彦宏持股99.5%，百度网讯在各地控股27家子公司（四家已经注销）负责当地的运营。而WOFE公司为百度在线网络技术（北京）有限公司（以下简称百度在线），该公司由注册在英属维京群岛的百度控股有限公司全资控股，同样经营利润通过WFOE百度在线最终转移到上市主体开曼公司。

百度在线这家WFOE由百度控股全资控股，百度网讯将利润通过百度在线转移到上市开曼公司。</p>
              </div>
            </van-swipe-item>
          </van-swipe>
        </div>
    </div>

    <div class="box" style="height: auto;">
      <p class="box-p1">VIE架构有哪些好处？</p>
      <p class="box-p2">VIE架构实现了公司“经营权”和“收益权”的分离，境外实体获得了收入和资产，境内实体负责经营和管理。通过VIE协议，企业就能一举两得，既满足国内监管的要求，又满足境外上市要求，同时满足双重标准。</p>
      <img class="vie-adv-img" src="../../assets/img/finance/VIE/pic_3.jpg" alt="">
      <div class="vie-adv-box">
        <div class="vie-adv-inner">
          <img class="vie-adv-img" src="../../assets/img/finance/VIE/icon_1_1.png" alt="">
          <div>
            <div class="vie-adv-p1">方便融资</div>
            <div class="vie-adv-p2">中国大陆有外汇管制政策，如果在海外有架构，就更容易获得海外资本；</div>
          </div>
        </div>
        <div class="vie-adv-inner">
          <img class="vie-adv-img" src="../../assets/img/finance/VIE/icon_1_2.png" alt="">
          <div>
            <div class="vie-adv-p1">规避了限制外资的领域</div>
            <div class="vie-adv-p2">中国法律对部分领域是禁止或限制外资进入的，如互联网信息服务、旅游、网络出版、教育、娱乐等；</div>
          </div>
        </div>
        <div class="vie-adv-inner">
          <img class="vie-adv-img" src="../../assets/img/finance/VIE/icon_1_3.png" alt="">
          <div>
            <div class="vie-adv-p1">躲过证监会的苛刻条件</div>
            <div class="vie-adv-p2">根据中国证监会的文件，境内企业要到境外融资，要满足“四五六”原则，即企业总资产不得少于4亿，上一年利润不少于6000万，上市的融资额度不少于5000万美元；</div>
          </div>
        </div>
        <div class="vie-adv-inner">
          <img class="vie-adv-img" src="../../assets/img/finance/VIE/icon_1_4.png" alt="">
          <div>
            <div class="vie-adv-p1">简化上市程序</div>
            <div class="vie-adv-p2">避免了烦琐冗长的境内企业境外上市的审批程序；即便千辛万苦通过了如商务部、外管局等N个部门的审批成功在境外上市，万一业务扩张要增发股份获发行债券，还得要再报批；</div>
          </div>
        </div>
        <div class="vie-adv-inner">
          <img class="vie-adv-img" src="../../assets/img/finance/VIE/icon_1_5.png" alt="">
          <div>
            <div class="vie-adv-p1">财富最大化</div>
            <div class="vie-adv-p2">上市之后，没有流通上的限制，容易把股票抛售变现；</div>
          </div>
        </div>
      </div>
    </div>

    <div class="box" style="height: auto;background: rgba(242, 245, 250, 1);">
      <p class="box-p1">架构搭建流程（以VIE架构为例）</p>
      <p class="box-p2">由于不同行业特征及境内运营主体股权结构的特殊性，不同项目有不同的搭建流程。以VIE架构为例，最常规的架构流程如下。</p>
      <div class="odi-flo" style="height: 550px;">
        <p class="odi-flo-p1">第一步：SPV设立</p>
        <p class="odi-flo-p2">注册第一层SPV、注册融资主体、注册第二层SPV（如需）、注册返程外商主体</p>
        <p class="odi-flo-p1" style="padding-top: 50px;">第二步：37号文登记</p>
        <p class="odi-flo-p2">为创始人及个人投资者完成37号文登记、为股权激励员工完成37号文登记（或预留期权池）</p>
        <p class="odi-flo-p1" style="padding-top: 55px;">第三步：WFOE注册</p>
        <p class="odi-flo-p2">完成外商主体资格认证、完成返程投资WFOE注册、完成FDI登记并开立资本金账户</p>
        <p class="odi-flo-p1" style="padding-top: 60px;"  >第四步：签订VIE协议</p>
        <p class="odi-flo-p2">独家管理顾问与业务合作协议、独家认购权协议、股权质押协议、配偶承诺书等</p>
        <p class="odi-flo-p1" style="padding-top: 60px;"  >第五步：向机构投资人发股</p>
        <p class="odi-flo-p2">境外机构投资人发股、境内机构投资人认股权证确权、境外出资境内减资（如需）</p>
      </div>
    </div>

    <div class="box" style="height: auto;">
      <p class="box-p1">服务流程</p>
      <p class="box-p2">在双方确定合作后，我们将组建由律师、税务师及行业咨询师等专业人员构成的项目组，分别协调银行、外管局、商务局及发改委等多部门工作，全流程完成架构搭建。</p>
      <div class="odi-flo odi-flo1" style="height: 500px;">
        <p class="odi-flo-p1">第一步：确定合作</p>
        <p class="odi-flo-p2">签署项目合同、签署保密协议、支付项目款项</p>
        <p class="odi-flo-p1" style="padding-top: 50px;">第二步：项目启动</p>
        <p class="odi-flo-p2">建立项目组、双方确定对接人、提交基础资料清单、确定合理的投资方案</p>
        <p class="odi-flo-p1" style="padding-top: 55px;">第三步：项目执行</p>
        <p class="odi-flo-p2">完成注册、开户及相关部门合规手续、签订VIE协议、机构投资人发股等</p>
        <p class="odi-flo-p1" style="padding-top: 50px;"  >第四步：项目验收</p>
        <p class="odi-flo-p2">境内外多线并行推进、分阶段确认工作成果</p>
        <p class="odi-flo-p1" style="padding-top: 47px;"  >第五步：项目完成</p>
        <p class="odi-flo-p2">境外SPV全套文件、37号文登记凭证、相关审批证书、融资主体股票本</p>
      </div>
    </div>

  
    <div class="box" style="height: auto;background: rgba(242, 245, 250, 1);">
      <p class="box-p1">增值服务</p>
      <p class="box-p2">我们还为您提供</p>
      <div class="odi-add">
        <div class="odi-add-inner">
          <img class="odi-add-icon" src="../../assets/img/finance/ODI/icon_3_1.png" alt="">
          <p class="odi-add-p">境外公司注册</p>
        </div>
        <div class="odi-add-inner">
          <img class="odi-add-icon" src="../../assets/img/finance/ODI/icon_3_2.png" alt="">
          <p class="odi-add-p">境外银行开户</p>
        </div>
        <div class="odi-add-inner">
          <img class="odi-add-icon" src="../../assets/img/finance/ODI/icon_3_3.png" alt="">
          <p class="odi-add-p">IPO业务咨询</p>
        </div>
        <div class="odi-add-inner">
          <img class="odi-add-icon" src="../../assets/img/finance/ODI/icon_3_4.png" alt="">
          <p class="odi-add-p">税务筹划</p>
        </div>
        <div class="odi-add-inner">
          <img class="odi-add-icon" src="../../assets/img/finance/ODI/icon_3_5.png" alt="">
          <p class="odi-add-p">VAT税号的申请和申报</p>
        </div>
        <div class="odi-add-inner">
          <img class="odi-add-icon" src="../../assets/img/finance/ODI/icon_3_6.png" alt="">
          <p class="odi-add-p">全球商标申请</p>
        </div>
        <div class="odi-add-inner">
          <img class="odi-add-icon" src="../../assets/img/finance/ODI/icon_3_7.png" alt="">
          <p class="odi-add-p">香港及境外公证</p>
        </div>
        <div class="odi-add-inner">
          <img class="odi-add-icon" src="../../assets/img/finance/ODI/icon_3_8.png" alt="">
          <p class="odi-add-p">代持和法律意见书等服务</p>
        </div>
      </div>
    </div>
  
  </div>
</template>
<script>
export default {
  name: 'bvi-page',
  props: {
    msg: String
  },
  data(){
    return{
      form: {},
      activeName: '1'
    }
  },
  mounted(){
    
  }
}
</script>

<style scoped lang="scss">
  .odi-banner{
    width: 100%;
    height: 460px;
    background: url('../../assets/img/finance/VIE/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
  .vie-bg1{
    background: url('../../assets/img/finance/VIE/pic_2_1.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
  .vie-bg2{
    background: url('../../assets/img/finance/VIE/pic_2_4.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
  .vie-bg3{
    background: url('../../assets/img/finance/VIE/pic_2_3.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
  .vie-bg4{
    background: url('../../assets/img/finance/VIE/pic_2_2.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
  .odi-flo{
    background: url('../../assets/img/finance/VIE/pic_4.png') no-repeat center center;
    background-size: 100% 100%;
  }
  .odi-flo1{
    background: url('../../assets/img/finance/VIE/pic_5.png') no-repeat center center;
    background-size: 100% 100%;
  }
  


</style>
