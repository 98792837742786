<template>
  <div class="home">
    <div class="m-bvi-banner">
      <p class="banner-p1">一站式安圭拉公司注册服务</p>
      <p class="banner-p2">【快企务专注于涉内外企业服务十余年】</p>
      <p class="banner-btn banner-btn1" @click="toAsk">立即咨询</p>
    </div>

    <div class="box" style="height: auto;padding-bottom: 30px;">
      <p class="box-p1">注册安圭拉公司优势</p>
      <div class="an-box">
        <p class="an-box-p">所有注册公司可以享受零税率</p>
        <p class="an-box-p">免除任何在安圭拉岛当地以外从事经营活动的税收</p>
        <p class="an-box-p">没有实收资本要求，没有报告要求</p>
        <p class="an-box-p">公司名称可为任何语言，如需加中文名，需增加费用</p>
        <p class="an-box-p">董事及股东名称不公开，甲股东名册的副本必须存放在境外的公司的注册办事处</p>
        <p class="an-box-p">公司会议不一定要在安圭拉岛举行，可通过电话或其他电子方式举行</p>
      </div>
      <img class="type-img" style="height: 166px;width: 297px;margin: 0 auto;display: block;" src="../../assets/img/register/Anquila/pic_1.jpg" alt="">
    </div>

    <div class="box" style="height: auto;padding-bottom: 30px;">
      <p class="box-p1">注册迪拜公司类型</p>
      <div class="type-content flex center-width">
        <img class="type-img" style="width: 100%;height: 146px;" src="../../assets/img/register/Anquila/pic_2.jpg" alt="">
      </div>
    </div>


    <div class="box" style="height: auto;padding-bottom: 15px;">
      <p class="box-p1">注册安圭拉公司条件/流程/资料</p>
      <div class="tab-box">
        <div class="tab-inner" :class="{'active': tab == 1}" @click="tab = 1">注册条件</div>
        <div class="tab-inner" :class="{'active': tab == 2}" @click="tab = 2">注册流程</div>
        <div class="tab-inner" :class="{'active': tab == 3}" @click="tab = 3">所得资料</div>
      </div>
      <div class="file-content">
        <div v-if="tab == 1">
          <div class="an-tab1">
            <div class="an-tab1-inner">
              <img class="an-tab1-icon" src="../../assets/img/register/Anquila/pic_3_1.png" alt="">
              <p class="an-tab1-p">拟1-3个公司名称（英文为准，中文为辅）</p>
            </div>
            <div class="an-tab1-inner">
              <img class="an-tab1-icon" src="../../assets/img/register/Anquila/pic_3_2.png" alt="">
              <p class="an-tab1-p">股东/董事身份证或者护照复印件</p>
            </div>
            <div class="an-tab1-inner">
              <img class="an-tab1-icon" src="../../assets/img/register/Anquila/pic_3_3.png" alt="">
              <p class="an-tab1-p">政府规定有限公司必须有一名法定秘书</p>
            </div>
            <div class="an-tab1-inner">
              <img class="an-tab1-icon" src="../../assets/img/register/Anquila/pic_3_4.png" alt="">
              <p class="an-tab1-p">公司地址必须位于安圭拉</p>
            </div>
            <div class="an-tab1-inner">
              <img class="an-tab1-icon" src="../../assets/img/register/Anquila/pic_3_5.png" alt="">
              <p class="an-tab1-p">注册资金一般为100万美元，可以任何流通货币表达，可以发行不记名股票</p>
            </div>
            <div class="an-tab1-inner">
              <img class="an-tab1-icon" src="../../assets/img/register/Anquila/pic_3_6.png" alt="">
              <p class="an-tab1-p">所有安圭拉岛公司必须在安圭拉岛设立注册办事处和注册代理人</p>
            </div>
          </div>
        </div>
        <div v-if="tab == 2">
          <img class="tab2-img" src="../../assets/img/register/Anquila/pic_4.jpg" alt="">
        </div>
        <div v-if="tab == 3">
          <div class="ger-tab3">
            <div class="ger-tab3-inner fre-tab3">
              <img class="ger-tab3-icon" src="../../assets/img/register/Anquila/pic_5_1.png" alt="">
              <p class="ger-tab3-p">注册证书</p>
            </div>
            <div class="ger-tab3-inner fre-tab3">
              <img class="ger-tab3-icon" src="../../assets/img/register/Anquila/pic_5_2.png" alt="">
              <p class="ger-tab3-p">公司董事及股东名册</p>
            </div>
            <div class="ger-tab3-inner fre-tab3">
              <img class="ger-tab3-icon" src="../../assets/img/register/Anquila/pic_5_3.png" alt="">
              <p class="ger-tab3-p">公司章程</p>
            </div>
            <div class="ger-tab3-inner fre-tab3">
              <img class="ger-tab3-icon" src="../../assets/img/register/Anquila/pic_5_4.png" alt="">
              <p class="ger-tab3-p"> 公司签证章公司钢印</p>
            </div>
            <div class="ger-tab3-inner fre-tab3">
              <img class="ger-tab3-icon" src="../../assets/img/register/Anquila/pic_5_5.png" alt="">
              <p class="ger-tab3-p">公司股票</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    
    <div class="box" style="height: 759px;background: rgba(242, 245, 250, 1);">
      <p class="box-p1">注册安圭拉公司后为您提供的后续服务</p>
      <div class="re-serve">
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Anquila/pic_6_1.png" alt="">
          <p class="re-serve-p1">年审/续牌</p>
          <p class="re-serve-p2">每年须要续牌费用，期限是公司成立周年。因为是离岸公司，在本地没有经营，不须要做账报税；每年除了续牌费用外，并不须要支付其它费用，也不需递交年报。</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Anquila/pic_6_2.png" alt="">
          <p class="re-serve-p1">做账报税</p>
          <p class="re-serve-p2">安圭拉暂未与任何国度签署双边税务协定，依据1987年版本的国际公司法注册成立公司并不须要就营业收入作出申报或缴纳任何税项，会计报表可无需递交。 </p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Anquila/pic_6_3.png" alt="">
          <p class="re-serve-p1">银行开户</p>
          <p class="re-serve-p2">目前安圭拉公司可以开香港少部分银行、美国银行、内地离岸账户(NRA离岸账户和FTN离岸账户）等银行账户</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Anquila/pic_6_4.png" alt="">
          <p class="re-serve-p1">商标注册</p>
          <p class="re-serve-p2">商标权自英国注册日开始生效，若在该地区遇到商标侵权纠纷时，须在当地政府也核准保护的情况下才能发起维权</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Anquila/pic_6_5.png" alt="">
          <p class="re-serve-p1">秘书服务</p>
          <p class="re-serve-p2">办公地址，公司周年报表年检，公司董事股东成员名册更新，在职证明与良好证明，代缴政府规费，修改公司章程等</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Anquila/pic_6_6.png" alt="">
          <p class="re-serve-p1">公司注销</p>
          <p class="re-serve-p2">因破产、股东决议解散、存续期届满等原因需要注销的，应及时主动注销公司</p>
        </div>
      </div>
    </div>

    <div class="box" style="height: auto;padding-bottom: 30px;">
      <p class="box-p1">安圭拉公司注册常见问题</p>
      <p class="box-p1">解决您的一切疑问</p>
      <div class="question">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item title="注册安圭拉公司的注册名称可以用中文吗？" name="1">
            <p class="question-p">公司名称可用英文或/和加上中文注册。公司的英文名称必须以“LIMITED、CORPORATION、INCORPORATED或它们的缩写”结尾，公司名称不能以“信托公司”或“银行”或其它被认为意思相似的词结尾，除非这些公司
获得相应的执照。</p>
          </el-collapse-item>
          <el-collapse-item title="无当地地址是否能注册公司？" name="2">
            <p class="question-p">地址是必须要有才能注册公司的，并且是必须位于安圭拉当地持牌注册代理人的地址。注册地址我司可提供，费
用已包含在注册费或年费中。</p>
          </el-collapse-item>
          <el-collapse-item title="安圭拉公司的税务是如何核算的呢？都有什么税种？" name="3">
            <p class="question-p">安圭拉暂未与任何国家签订双边税务协议，根据1987年版本的国际公司法注册成立公司并不需要就营业收入作
出申报或缴纳任何税项，会计报表无需递交。</p>
          </el-collapse-item>
        </el-collapse>
      </div>
      <p class="to-ask" @click="toAsk"> 
        <img class="to-ask-icon" src="../../assets/img/register/bvi/icon_6_customer_service.png" alt=""> 
        更多问题？立即咨询人工客服</p>
    </div>
    <div class="box" style="height: 480px;">
      <p class="box-p1">快企务的优势</p>
      <p class="box-p2">十余年注册安圭拉公司经验</p>
      <div class="re-adv">
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_1.png" alt="">
          <p class="re-adv-p1">保密完善</p>
          <p class="re-adv-p2">保密系统完善，客户隐私不外泄</p>
        </div>
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_2.png" alt="">
          <p class="re-adv-p1">全程服务</p>
          <p class="re-adv-p2">一站式专业服务从注册公司到年审、开户，审计报税等</p>
        </div>
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_3.png" alt="">
          <p class="re-adv-p1">专业高效</p>
          <p class="re-adv-p2">根据需求，为客户制定专业快捷的解决方案</p>
        </div>
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_4.png" alt="">
          <p class="re-adv-p1">合同保障</p>
          <p class="re-adv-p2">正规机构，合同保障，费用透明，无隐形消费</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  data(){
    return{
      tab: 1,
      activeName: '1'
    }
  },
  mounted(){
    window.scrollTo(0,0);
  },
  methods:{
    toAsk(){
      window.open('https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn','_blank')
    },
  }
}
</script>
<style scoped>
  .m-bvi-banner{
    width: 100%;
    height: 460px;
    background: url('../../assets/img/register/Anquila/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
</style>
