<template>
  <div class="certification m-bvi">
    <div class="m-bvi-banner">
      <p class="know-p1">商标查询</p>
      <div class="know-num">
        <div class="know-num-inner">
          <p class="know-num-p1">66575734</p>
          <p class="know-num-p2">累计查询</p>
        </div>
        <div class="know-num-inner">
          <p class="know-num-p1">308</p>
          <p class="know-num-p2">昨日成交</p>
        </div>
        <div class="know-num-inner">
          <p class="know-num-p1">98.3%</p>
          <p class="know-num-p2">商标注册成功率</p>
        </div>
      </div>
    </div>
    <div class="box" style="height: auto;padding: 20px 0">
      <p class="box-p1">商标服务</p>
      <p class="box-p2">企业没商标，等于帮别人做品牌，拿什么保护创业中的您</p>
      <div class="tab-content-box">
          <div class="tab-box1">
            <p class="tab-inner1" style="width: 30%;" :class="{'active': tab == 1}" @click="tab = 1">国际商标注册</p>
            <p class="tab-inner1" style="width: 30%;" :class="{'active': tab == 2}" @click="tab = 2">国内商标注册</p>
            <p class="tab-inner1" style="width: 40%;" :class="{'active': tab == 3}" @click="tab = 3">商标服务</p>
          </div>
          <div class="know-tab-content" v-if="tab == 1">
            <div class="know-tab-inner">
              <img class="know-tab-bg" src="../../assets/img/finance/knowledge/pic_1_1.jpg" alt="">
              <p class="know-tab-p1">美国商标注册</p>
              <p class="know-tab-p2">注册时间：12-18个月</p>
              <p class="know-tab-p3">有效期：10年</p>
            </div>
            <div class="know-tab-inner">
              <img class="know-tab-bg" src="../../assets/img/finance/knowledge/pic_1_2.jpg" alt="">
              <p class="know-tab-p1">新加坡商标注册</p>
              <p class="know-tab-p2">注册时间：9-12个月</p>
              <p class="know-tab-p3">有效期：10年</p>
            </div>
            <div class="know-tab-inner">
              <img class="know-tab-bg" src="../../assets/img/finance/knowledge/pic_1_3.jpg" alt="">
              <p class="know-tab-p1">欧盟商标注册</p>
              <p class="know-tab-p2">注册时间：12-15个月</p>
              <p class="know-tab-p3">有效期：10年</p>
            </div>
            <div class="know-tab-inner">
              <img class="know-tab-bg" src="../../assets/img/finance/knowledge/pic_1_4.jpg" alt="">
              <p class="know-tab-p1">中国香港商标注册</p>
              <p class="know-tab-p2">注册时间：6-8个月</p>
              <p class="know-tab-p3">有效期：10年</p>
            </div>
            <div class="know-tab-inner">
              <img class="know-tab-bg" src="../../assets/img/finance/knowledge/pic_1_5.jpg" alt="">
              <p class="know-tab-p1">英国商标注册</p>
              <p class="know-tab-p2">注册时间：4-6个月</p>
              <p class="know-tab-p3">有效期：10年</p>
            </div>
            <div class="know-tab-inner">
              <img class="know-tab-bg" src="../../assets/img/finance/knowledge/pic_1_6.jpg" alt="">
              <p class="know-tab-p1">德国商标注册</p>
              <p class="know-tab-p2">注册时间：12-18个月</p>
              <p class="know-tab-p3">有效期：10年</p>
            </div>
            <div class="know-tab-inner">
              <img class="know-tab-bg" src="../../assets/img/finance/knowledge/pic_1_7.jpg" alt="">
              <p class="know-tab-p1">日本商标注册</p>
              <p class="know-tab-p2">注册时间：9-11个月</p>
              <p class="know-tab-p3">有效期：10年</p>
            </div>
            <div class="know-tab-inner">
              <img class="know-tab-bg" src="../../assets/img/finance/knowledge/pic_1_8.jpg" alt="">
              <p class="know-tab-p1">更多国际商标</p>
              <p class="know-tab-p2">人工客服在线</p>
            </div>
          </div>
          <div class="know-tab-content" v-if="tab == 2">
            <div class="know-tab-inner">
              <img class="know-tab-bg" src="../../assets/img/finance/knowledge/pic_2_1.jpg" alt="">
              <p class="know-tab-p1">普通商标注册</p>
              <p class="know-tab-p2">价格实惠，快企务爆款</p>
            </div>
            <div class="know-tab-inner">
              <img class="know-tab-bg" src="../../assets/img/finance/knowledge/pic_2_2.jpg" alt="">
              <p class="know-tab-p1">商标极速注册</p>
              <p class="know-tab-p2">59分钟递送商标局</p>
            </div>
            <div class="know-tab-inner">
              <img class="know-tab-bg" src="../../assets/img/finance/knowledge/pic_2_3.jpg" alt="">
              <p class="know-tab-p1">极速双保注册</p>
              <p class="know-tab-p2">59分钟报件，如驳回免费再注册一个</p>
            </div>
          </div>
          <div class="know-tab-content" v-if="tab == 3">
            <div class="know-tab-inner">
              <img class="know-tab-bg" src="../../assets/img/finance/knowledge/pic_3_1.jpg" alt="">
              <p class="know-tab-p1">商标驳回复审</p>
              <p class="know-tab-p2">商标申请被驳回，申请复审力挽狂澜</p>
            </div>
            <div class="know-tab-inner">
              <img class="know-tab-bg" src="../../assets/img/finance/knowledge/pic_3_2.jpg" alt="">
              <p class="know-tab-p1">商标续展</p>
              <p class="know-tab-p2">面临被注销风险，及时续展保护权利</p>
            </div>
            <div class="know-tab-inner">
              <img class="know-tab-bg" src="../../assets/img/finance/knowledge/pic_3_3.jpg" alt="">
              <p class="know-tab-p1">商标异议答辩</p>
              <p class="know-tab-p2">商标被他人异议，答辩应助成功</p>
            </div>
          </div>
        </div>
    </div>
    <div class="box" style="height: 588px;background: rgba(242, 245, 250, 1);">
      <p class="box-p1">快企务助您职业路上后顾无忧！</p>
      <p class="box-p2">一站式知识产权整合服务</p>
      <div class="bank-info">
        <p class="bank-info-p1">免费商标寄售</p>
        <p class="bank-info-p2">免费上传通道，支持批量导入，极速审核</p>
        <p class="bank-info-p1">商标价值评估</p>
        <p class="bank-info-p2">大数据测算，保障评估更有意义</p>
        <p class="bank-info-p1">商标续展</p>
        <p class="bank-info-p2">商标有效期到期前一年，可对商标申请续展</p>
      </div>
      <img class="bank-img1" style="width: 308px;height: 260px;" src="../../assets/img/finance/knowledge/pic_4.jpg" alt="">
    </div>
    <div class="box" style="height: 348px;">
      <p class="box-p1">一站式企业服务</p>
      <p class="box-p2">十余年专业涉内外企业服务综合平台</p>
      <div class="bank-serve">
        <div class="bank-serve-inner">
          <img class="bank-serve-bg" src="../../assets/img/finance/knowledge/pic_4_1.jpg" alt="">
          <p class="bank-serve-p">全球公司注册</p>
        </div>
        <div class="bank-serve-inner">
          <img class="bank-serve-bg" src="../../assets/img/finance/knowledge/pic_4_2.jpg" alt="">
          <p class="bank-serve-p">国际公证认证</p>
        </div>
        <div class="bank-serve-inner">
          <img class="bank-serve-bg" src="../../assets/img/finance/knowledge/pic_4_3.jpg" alt="">
          <p class="bank-serve-p">全球银行开户</p>
        </div>
        <div class="bank-serve-inner">
          <img class="bank-serve-bg" src="../../assets/img/finance/knowledge/pic_4_4.jpg" alt="">
          <p class="bank-serve-p">秘书服务</p>
        </div>
      </div>
    </div>
    <div class="box" style="height: 159px;background: #fff;">
      <p class="box-p1">他们都选择快企务</p>
      <img class="bank-img1" style="width: 94%;height: 60px;" src="../../assets/img/finance/knowledge/pic_5.jpg" alt="">
    </div>
  </div>
</template>
<script>
export default {
  name: 'bvi-page',
  props: {
    msg: String
  },
  data(){
    return{
      form: {},
      tab: 1,
      activeName: '1'
    }
  },
  mounted(){
    
  }
}
</script>

<style scoped lang="scss">
  .m-bvi-banner{
    width: 100%;
    height: 460px;
    background: url('../../assets/img/finance/knowledge/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
  
</style>
