<template>
  <div class="home" style="background: rgba(242, 245, 250, 1);">
    <div class="join-banner">
      <p class="join-p1">欢迎加入我们，成就自我，<br />助力千万企业乘风出海，快企无忧！</p>
      <p class="banner-btn banner-btn1" style="margin-top: 20px;" @click="toAsk">立即办理</p>
    </div>
    <div class="box about-team">
      <p class="box-p1">快乐实干的团队</p>
      <div class="lunbo-box" style="height: 172px;margin-top: 20px;">
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
          <van-swipe-item>
            <img class="swiper-img" style="height: 172px;" src="../assets/img/join/pic_1_1.jpg" alt="">
          </van-swipe-item>
          <van-swipe-item>
            <img class="swiper-img" style="height: 172px;" src="../assets/img/join/pic_1_2.jpg" alt="">
          </van-swipe-item>
          <van-swipe-item>
            <img class="swiper-img" style="height: 172px;" src="../assets/img/join/pic_1_3.jpg" alt="">
          </van-swipe-item>
          <van-swipe-item>
            <img class="swiper-img" style="height: 172px;" src="../assets/img/join/pic_1_4.jpg" alt="">
          </van-swipe-item>
          <van-swipe-item>
            <img class="swiper-img" style="height: 172px;" src="../assets/img/join/pic_1_5.jpg" alt="">
          </van-swipe-item>
          <van-swipe-item>
            <img class="swiper-img" style="height: 172px;" src="../assets/img/join/pic_1_6.jpg" alt="">
          </van-swipe-item>
        </van-swipe>
      </div>
    </div>
    <div class="box about-link">
      <p class="box-p1">请选择职位</p>
      <div class="tab-box" style="width: 100%;margin-top: 20px;justify-content: center;">
        <div class="tab-inner" style="margin-right: 40px;" :class="{'active': tab == 1}" @click="tab = 1">销售顾问</div>
        <div class="tab-inner" :class="{'active': tab == 2}" @click="tab = 2">签证专员</div>
      </div>
      <div class="position-box">
        <div class="position-inner" v-if="tab == 1">
          <p class="position-p1">销售顾问</p>
          <p class="position-p2">公司产品：涉外企业注册（含后续开户年审报税）、国际公证认证、知识产权等问</p>
          <p class="position-p1">岗位职责：</p>
          <p class="position-p2">1. 负责客户来电、来访、网络和邮件的商务联系与咨询；<br />
2. 负责老客户的维护及新业务的及时跟进；<br />
3. 负责做好电话记录，并积极主动回访，随时了解客户需求；<br />
4. 负责收集信息来源及客户所属行业等有效的客户资料；<br />
5. 负责做好客户的接待工作，出色完成商务谈判与签约工作；<br />
6. 负责做好签约客户记录，详细记载办理进程以及重点事项，完善后期维护。</p>
          <p class="position-p1">任职要求：</p>
          <p class="position-p2">1. 市场营销、国际贸易、电子商务、财会类相关专业，大专以上学历；<br />
2. 具有较好的谈判技巧，熟悉商务流程，能够独立接待客户。<br />
3. 能自觉主动的工作、学习；能熟练操作相关现代化办公设备。<br />
4. 良好的团队精神、职业道德修养和职业形象。<br />
5. 原则性、自律性、责任心强、有一定的抗压能力；诚实守信，做事稳重，敬业爱岗，具备良好的保密意识。</p>
          <p class="position-p2">招聘地区：北京、上海、广州、深圳，期待您的加入！</p>
          <p class="position-p3">简历请投递至： hr@kuaiqiwu.com</p>
        </div>
      </div>
      <div class="position-inner" v-if="tab == 2">
          <p class="position-p1">签证专员</p>
          <p class="position-p2">公司产品：涉外企业注册（含后续开户年审报税）、国际公证认证、知识产权等问</p>
          <p class="position-p1">岗位职责：</p>
          <p class="position-p2">1、英语四级4级以上，英语可作为阅读语言；<br />

2、对热门国家的签证流程和规则非常熟悉；<br />

3、负责签证资料的整理、翻译、审查、填写、校对及送签的准备工作；<br />

4、及时了解各使馆签证动态，获取签证最新的准确信息；<br />

5、可外勤前往领馆送取签证。</p>
          <p class="position-p1">任职要求：</p>
          <p class="position-p2">1、大专及以上学历，英语4级以上；<br />

2、有1年以上各国送签经验及相关旅行社工作经验者，熟悉签证工作流程；<br />

3、有良好的英文读写能力，具备可独立完成各种签证资料和信函的中英文翻译工作；<br />

4、对签证知识有足够的了解和对签证有专业的独立操作的能力；<br />

5、为人正直诚信，责任心强，对工作有高度的责任心，具备良好的团队精神。</p>
          <p class="position-p2">招聘地区：深圳</p>
          <p class="position-p3">简历请投递至： hr@kuaiqiwu.com</p>
        </div>
    </div>
    <div class="join-coo">
      <p class="join-coo-p1">商务合作</p>
      <p class="join-coo-p2">公司旗下拥有“离岸岛”、“快企务”品牌，离岸岛品牌创建于2014年8月，定位为全球公司设立服务商。快企务品牌创建于2018年10月，定位为涉内外企业服务综合平台。十余年来，金百利一直致力于为客户提供一站式的涉内外企业咨询服务，拥有一支超100人的离岸、会计、税务、金融、法律、知产等高级顾问团队；欢迎更多合作伙伴加入我们！</p>
      <p class="join-coo-p3">咨询电话：400 860 5600</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  data(){
    return{
      activeName: '1',
      Dialog: false,
      tab: 1
    }
  },
 
  mounted(){
    window.scrollTo(0,0);
  },
  methods:{
    toAsk(){
      window.open('https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn','_blank')
    },
    toUrl(url){
      this.$router.push({name: url})
    }
  }
}
</script>
<style scoped>
  .join-banner{
    background: url('../assets/img/join/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
  .join-coo{
    background: url('../assets/img/join/pic_2.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
</style>
