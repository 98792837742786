<template>
  <div class="header">
    <div class="header-inner">
      <img class="home-logo" @click="toUrl('home')" src="../assets/img/home/logo.png" alt="">
      <div class="menu-right">
        <p class="contact" @click="toUrl('contact')">在线留言</p>
        <img class="home-menu" v-if="!isShow" @click="isShow = true" src="../assets/img/home/icon_1_open.png" alt="">
        <img class="home-menu" v-if="isShow" @click="isShow = false" src="../assets/img/home/icon_close.png" alt="">
      </div>
    </div>
    <div class="head-menu" v-if="isShow">
      <div class="menu-left">
        <p class="menu-title" :class="{'menuActive': tab == 1}" @click="tab = 1">全球公司注册</p>
        <p class="menu-title" :class="{'menuActive': tab == 2}" @click="tab = 2">国际公证认证</p>
        <p class="menu-title" :class="{'menuActive': tab == 3}" @click="tab = 3">秘书服务</p>
        <p class="menu-title" :class="{'menuActive': tab == 4}" @click="tab = 4">财税筹划</p>
        <p class="menu-title" :class="{'menuActive': tab == 5}" @click="tab = 5">境外投资备案</p>
        <p class="menu-title" :class="{'menuActive': tab == 6}" @click="tab = 6">无犯罪记录</p>
        <p class="menu-title" :class="{'menuActive': tab == 7}" @click="tab = 7;toUrl('financeBank')">海外银行开户</p>
        <p class="menu-title" :class="{'menuActive': tab == 8}" @click="tab = 8">知识产权</p>
        <p class="menu-title" :class="{'menuActive': tab == 9}" @click="tab = 9">关于</p>
      </div>
      <div class="menu-right">
        <div class="menu-right-inner">
          <div v-if="tab == 1">
            <div class="menu-info">
              <p class="info-title" @click="toUrl('registerglobal')">全球公司注册 > </p>
              <p class="info-page" @click="toUrl('registerBvi')">BVI公司注册 <span class="hot-icon">HOT</span></p>
              <p class="info-page" @click="toUrl('registerKaiman')">开曼公司注册 <span class="hot-icon">HOT</span></p>
              <p class="info-page" @click="toUrl('registerSingapore')">新加坡公司注册 <span class="hot-icon">HOT</span></p>
              <p class="info-page" @click="toUrl('registerHongKong')">中国香港公司注册 <span class="hot-icon">HOT</span></p>
              <p class="info-page" @click="toUrl('registerAmerica')">美国公司注册 <span class="hot-icon">HOT</span></p>
              <p class="info-page" @click="toUrl('registerBritain')">英国公司注册 <span class="hot-icon">HOT</span></p>
              <p class="info-page" @click="toUrl('registerGermany')">德国公司注册 <span class="hot-icon">HOT</span></p>
              <p class="info-page" @click="toUrl('registerFrench')">法国公司注册 <span class="hot-icon">HOT</span></p>
              <p class="info-page" @click="toUrl('registerJapan')">日本公司注册 <span class="hot-icon">HOT</span></p>
              <p class="info-page" @click="toUrl('registerKorea')">韩国公司注册 <span class="hot-icon">HOT</span></p>
              <p class="info-page" @click="toUrl('registerTaiwan')">中国台湾公司注册</p>
              <p class="info-page" @click="toUrl('registerMarshall')">马绍尔公司注册 </p>
              <p class="info-page" @click="toUrl('registerMacau')">中国澳门公司注册 </p>
              <p class="info-page" @click="toUrl('registerSwiss')">瑞士公司注册</p>
              <p class="info-page" @click="toUrl('registerCyprus')">塞浦路斯公司注册 </p>
              <p class="info-page" @click="toUrl('registerDubai')">迪拜公司注册 </p>
              <p class="info-page" @click="toUrl('registerAnquila')">安圭拉公司注册</p>
              <p class="info-page" @click="toUrl('registerMalta')">马耳他公司注册 </p>
              <p class="info-page" @click="toUrl('registerLuxembourg')">卢森堡公司注册 </p>
              <p class="info-page" @click="toUrl('registerVietnam')">越南公司注册</p>
              <p class="info-page" @click="toUrl('registerIndonesia')">印尼公司注册 </p>
              <p class="info-page" @click="toUrl('registerMauritius')">毛里求斯公司注册 </p>
              <p class="info-page" @click="toUrl('registerCanada')">加拿大公司注册</p>
              <p class="info-page" @click="toUrl('registerSamoa')">萨摩亚公司注册 </p>
              <p class="info-page" @click="toUrl('registerItaly')">意大利公司注册 </p>
              <p class="info-page" @click="toUrl('registerNetherlands')">荷兰公司注册</p>
            </div>
          </div>
          <div v-if="tab == 2">
            <div class="menu-info">
              <p class="info-title" @click="toUrl('notarization')">国际公证认证 > </p>
              <p class="info-page" @click="toUrl('notarizationHague')">海牙认证</p>
              <p class="info-page" @click="toUrl('notarizationEmbassy')">使馆认证</p>
              <p class="info-page" @click="toUrl('notarizationChina')">中国委托公证人认证</p>
              <p class="info-page" @click="toUrl('notarizationTrade')">贸促会认证</p>
              <p class="info-page" @click="toUrl('certificationSamoa')">中国驻萨摩亚使馆认证</p>
              <p class="info-page" @click="toUrl('certificationSingapore')">中国驻新加坡使馆认证</p>
              <p class="info-page" @click="toUrl('certificationCanada')">中国驻加拿大使馆认证</p>
              <p class="info-page" @click="toUrl('certificationBvi')">中国驻BVI使馆认证</p>
              <p class="info-page" @click="toUrl('certificationRussia')">中国驻俄罗斯使馆认证</p>
              <p class="info-page" @click="toUrl('certificationGermany')">中国驻德国使馆认证</p>
              <p class="info-page" @click="toUrl('certificationAmerica')">中国驻美国使馆认证</p>
              <p class="info-page" @click="toUrl('certificationAustralia')">中国驻澳大利亚使馆认证</p>
              <p class="info-page" @click="toUrl('certificationcayman')">中国驻开曼使馆认证</p>
              <p class="info-page" @click="toUrl('certificationBritain')">中国驻英国使馆认证</p>
              <p class="info-page" @click="toUrl('certificationSeychelles')">中国驻塞舌尔使馆认证</p>
              <p class="info-page" @click="toUrl('certificationFrench')">中国驻法国使馆认证</p>
              <p class="info-page" @click="toUrl('certificationMalaysia')">中国驻马来西亚使馆认证</p>
              <p class="info-page" @click="toUrl('certificationVietnam')">中国驻越南使馆认证</p>
              <p class="info-page" @click="toUrl('certificationKorea')">中国驻韩国使馆认证</p>
              <p class="info-page" @click="toUrl('certificationNetherlands')">中国驻荷兰使馆认证</p>
              <p class="info-page" @click="toUrl('certificationVideo')">视频委托公证认证</p>
              
            </div>
          </div>
          <div v-if="tab == 3">
            <div class="menu-info">
              <p class="info-title">秘书服务</p>
              <p class="info-page" @click="toUrl('examinationHongKong')">香港公司年审</p>
              <p class="info-page" @click="toUrl('examinationAmerica')">美国公司年审</p>
              <p class="info-page" @click="toUrl('examinationSingapore')">新加坡公司年审</p>
              <p class="info-page" @click="toUrl('examinationKaiman')">开曼公司年审</p>
              <p class="info-page" @click="toUrl('examinationBvi')">BVI公司年审</p>
            </div>
          </div>
          <div v-if="tab == 4">
            <div class="menu-info">
              <p class="info-title">财税筹划</p>
              <p class="info-page" @click="toUrl('financeHongKong')">香港公司财税筹划</p>
              <p class="info-page" @click="toUrl('financeAmerica')">美国公司财税筹划</p>
            </div>
          </div>
          <div v-if="tab == 5">
            <div class="menu-info">
              <p class="info-title">境外投资备案</p>
              <p class="info-page" @click="toUrl('ODI')">境外投资备案</p>
              <p class="info-page" @click="toUrl('VIE')">VIE架构</p>
              <p class="info-page" @click="toUrl('AmericaLaw')">美国法律意见书</p>
              <p class="info-page" @click="toUrl('HongKongLaw')">香港法律意见书</p>
              <p class="info-page" @click="toUrl('JapanVisa')">日本签证</p>
              <p class="info-page" @click="toUrl('plan')">香港优才计划</p>
            </div>
          </div>
          <div v-if="tab == 6">
            <div class="menu-info">
              <p class="info-title">无犯罪记录证明</p>
              <p class="info-page" @click="toUrl('noCriminalAmerica')">美国无犯罪记录证明</p>
              <p class="info-page" @click="toUrl('noCriminalSingapore')">新加坡无犯罪记录证明</p>
              <p class="info-page" @click="toUrl('noCriminalBritain')">英国无犯罪记录证明</p>
              <p class="info-page" @click="toUrl('noCriminalCanada')">加拿大无犯罪记录证明</p>
              <p class="info-page" @click="toUrl('noCriminalAustralia')">澳大利亚无犯罪记录证明</p>
            </div>
          </div>
          <!-- <div v-if="tab == 7">
            <div class="menu-info">
              
            </div>
          </div> -->
          <div v-if="tab == 8">
            <div class="menu-info">
              <p class="info-title" @click="toUrl('knowledge')">知识产权 ></p>
              <p class="info-page" @click="toUrl('innerTrademark')">国内商标注册</p>
              <p class="info-page" @click="toUrl('EUTrademark')">欧盟商标注册</p>
              <p class="info-page" @click="toUrl('AmericaTrademark')">美国商标注册</p>
              <p class="info-page" @click="toUrl('FranceTrademark')">法国商标注册</p>
              <p class="info-page" @click="toUrl('JapanTrademark')">日本商标注册</p>
            </div>
          </div>
          <div v-if="tab == 9">
            <div class="about-box">
              <p class="header-inner-title">关于</p>
              <p class="header-inner-p" @click="toUrl('about')">关于快企务</p>
              <p class="header-inner-p" @click="toUrl('join')">加入我们</p>
              <p class="header-inner-p" @click="toUrl('news')">快企务资讯</p>
            </div>
            <div class="about-box">
              <p class="header-inner-title">服务热线</p>
              <p class="header-inner-p1">400-860-5600</p>
            </div>
            <div class="about-box">
              <p class="header-inner-title">投诉电话</p>
              <p class="header-inner-p1">18033436502</p>
            </div>
            <div class="about-box" style="border-bottom: none;">
              <img class="wechat-img" src="../assets/img/home/pic_QR_code.jpg" alt="">
              <p class="wechat-kefu">官方客服微信</p>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home-page',
  props: {
    msg: String
  },
  data(){
    return{
      tab: 1,
      isShow: false
    }
  },
  methods: {
    toUrl(url){
      this.$router.push({name: url})
      this.isShow = false
    }
  }
}
</script>

