<template>
  <div class="home" style="background: rgba(242, 245, 250, 1);">
    <div class="join-banner">
      <p class="japan-visa-p1">香港优才计划</p>
      <p class="japan-visa-p2">拿香港身份，保留内地身份，免签全球163国</p>
      <p class="banner-btn banner-btn1" style="margin-top: 70px" @click="toAsk">立即办理</p>
    </div>

    <div class="box" style="background: #fff;">
      <p class="box-p1">香港优才计划</p>
      <img class="odi-img1" style="margin-top: 30px" src="../assets/img/plan/pic_1.jpg" alt="">
      <p class="odi-p1">香港优秀人才计划，简称“香港优才”，于2006年2月23日公布，于当年6月前开始实施。香港优才计划是香港政府推出的一项移民吸纳计划，旨在吸引高技术人才或优才来港定居，藉此提升香港的竞争力。所有申请人均必须首先符合基本资格的要求，才可根据计划所设两套计分制度的其中一套获取分数，提交申请资料。两套计分制度分别是「综合计分制」和「成就计分制」。</p>
      <div style="display: flex;align-items: center;">
        <p class="form-submit" style="width: 96px;margin-left: 5%;" @click="toAsk">立即咨询</p>
        <p class="form-submit" style="width: 96px;background: transparent;border: 1px solid rgba(47, 114, 235, 1);color: rgba(47, 114, 235, 1);margin-left: 0;" @click="toPoint">查看计分制</p>
      </div>
    </div>

    <div class="box">
      <p class="box-p1">香港优才计划适用人群</p>
      <div class="cer-lunbo-box" style="margin: 0 auto">
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white" :loop="true" >
          <van-swipe-item>
            <img class="cer-swiper-img" style="width: 345px;height: 204px;" src="../assets/img/plan/pic_2_1.jpg" alt="">
          </van-swipe-item>
          <van-swipe-item>
            <img class="cer-swiper-img" style="width: 345px;height: 204px;" src="../assets/img/plan/pic_2_2.jpg" alt="">
          </van-swipe-item>
          <van-swipe-item>
            <img class="cer-swiper-img" style="width: 345px;height: 204px;" src="../assets/img/plan/pic_2_3.jpg" alt="">
          </van-swipe-item>
        </van-swipe>
      </div>
    </div>

    <div class="box" style="background: #fff;">
      <p class="box-p1">香港优才计划申请条件</p>
      <p class="box-p2">香港优秀人才入境计划（简称“优才”）于2006年06月28日公布实施</p>
      <div class="plan-tiaojian">
        <img class="plan-t-icon" src="../assets/img/plan/pic_3_1.png" alt="">
        <div class="plan-t-right">
          <p class="plan-t-p1">年龄</p>
          <p class="plan-t-p2">申请人根据本计划提交申请时，年龄必须在18岁或以上</p>
        </div>
      </div>
      <div class="plan-tiaojian">
        <img class="plan-t-icon" src="../assets/img/plan/pic_3_2.png" alt="">
        <div class="plan-t-right">
          <p class="plan-t-p1">财政要求</p>
          <p class="plan-t-p2">申请人必须证明能独力负担其本人及受养人（如果有）居港期间的生活和住宿，不需依赖公共援助</p>
        </div>
      </div>
      <div class="plan-tiaojian">
        <img class="plan-t-icon" src="../assets/img/plan/pic_3_3.png" alt="">
        <div class="plan-t-right">
          <p class="plan-t-p1">良好品格</p>
          <p class="plan-t-p2">申请人不得有任何在香港或其他地方的刑事罪行记录或不良入境记录</p>
        </div>
      </div>
      <div class="plan-tiaojian">
        <img class="plan-t-icon" src="../assets/img/plan/pic_3_4.png" alt="">
        <div class="plan-t-right">
          <p class="plan-t-p1">语言能力</p>
          <p class="plan-t-p2">申请人须具备良好中文或英文的书写及口语能力（中文口语指普通话或粤语）</p>
        </div>
      </div>
      <div class="plan-tiaojian">
        <img class="plan-t-icon" src="../assets/img/plan/pic_3_5.png" alt="">
        <div class="plan-t-right">
          <p class="plan-t-p1">基本学历</p>
          <p class="plan-t-p2">申请人必须具备良好学历，一般要求为具备由认可大学或高等教育院校颁授的大学学位。在特殊情况下，能附以证明文件的良好技术资历、可证明的专业能力及／或经验及成就亦可获考虑</p>
        </div>
      </div>
      <div class="plan-tiaojian">
        <img class="plan-t-icon" src="../assets/img/plan/pic_3_6.png" alt="">
        <div class="plan-t-right">
          <p class="plan-t-p1">计分制</p>
          <p class="plan-t-p2">优才分为“综合计分制”或“成就计分制”两种申请方式</p>
        </div>
      </div>
    </div>

    <div class="box">
      <p class="box-p1">香港优才计划申请流程</p>
      <div class="cer-adv-box" style="margin-top: 30px">
        <div class="cer-adv-inner" style="border-radius: 4px;background: rgba(255, 255, 255, 1);">
          <img class="cer-adv-icon" src="../assets/img/plan/pic_4_1.png" alt="">
          <p class="cer-adv-p1">第一步</p>
          <p class="cer-adv-p2">咨询与评估工作（1周）</p>
        </div>
        <div class="cer-adv-inner" style="border-radius: 4px;background: rgba(255, 255, 255, 1);">
          <img class="cer-adv-icon" src="../assets/img/plan/pic_4_2.png" alt="">
          <p class="cer-adv-p1">第二步</p>
          <p class="cer-adv-p2">签署协议，准备材料（3-6周）</p>
        </div>
        <div class="cer-adv-inner" style="border-radius: 4px;background: rgba(255, 255, 255, 1);">
          <img class="cer-adv-icon" src="../assets/img/plan/pic_4_3.png" alt="">
          <p class="cer-adv-p1">第三步</p>
          <p class="cer-adv-p2">提交申请，领取档案编号（1-2周）</p>
        </div>
        <div class="cer-adv-inner" style="border-radius: 4px;background: rgba(255, 255, 255, 1);">
          <img class="cer-adv-icon" src="../assets/img/plan/pic_4_4.png" alt="">
          <p class="cer-adv-p1">第四步</p>
          <p class="cer-adv-p2">甄选程序（6-18个月）</p>
        </div>
        <div class="cer-adv-inner" style="border-radius: 4px;background: rgba(255, 255, 255, 1);">
          <img class="cer-adv-icon" src="../assets/img/plan/pic_4_5.png" alt="">
          <p class="cer-adv-p1">第五步</p>
          <p class="cer-adv-p2">获批通告（即时通知）</p>
        </div>
        <div class="cer-adv-inner" style="border-radius: 4px;background: rgba(255, 255, 255, 1);">
          <img class="cer-adv-icon" src="../assets/img/plan/pic_4_6.png" alt="">
          <p class="cer-adv-p1">第六步</p>
          <p class="cer-adv-p2">签发签证</p>
        </div>
        <div class="cer-adv-inner" style="border-radius: 4px;background: rgba(255, 255, 255, 1);">
          <img class="cer-adv-icon" src="../assets/img/plan/pic_4_7.png" alt="">
          <p class="cer-adv-p1">第七步</p>
          <p class="cer-adv-p2">向公安机关申请签发多次往返签注</p>
        </div>
        <div class="cer-adv-inner" style="border-radius: 4px;background: rgba(255, 255, 255, 1);">
          <img class="cer-adv-icon" src="../assets/img/plan/pic_4_8.png" alt="">
          <p class="cer-adv-p1">第八步</p>
          <p class="cer-adv-p2">预约及领取香港身份证</p>
        </div>
        <div class="cer-adv-inner" style="border-radius: 4px;background: rgba(255, 255, 255, 1);">
          <img class="cer-adv-icon" src="../assets/img/plan/pic_4_9.png" alt="">
          <p class="cer-adv-p1">第九步</p>
          <p class="cer-adv-p2">延长逗留签证</p>
        </div>
        <div class="cer-adv-inner" style="border-radius: 4px;background: rgba(255, 255, 255, 1);">
          <img class="cer-adv-icon" src="../assets/img/plan/pic_4_10.png" alt="">
          <p class="cer-adv-p1">第十步</p>
          <p class="cer-adv-p2">满七年申请永居身份</p>
        </div>
      </div>
    </div>

    <div class="box" style="background: #fff;">
      <p class="box-p1" style="margin-bottom: 30px;">香港身份的好处</p>
      <div class="plan-good">
        <p class="plan-good-p1">高等教育资源</p>
        <p class="plan-good-p2">在香港：香港身份的考生可以参加DSE考试（香港高考）升读香港/海外大学；或凭DSE成绩免试申请内地名牌大学。香港身份学生报考内地最多的十所院校有哪些？</p>
        <p class="plan-good-p2">在内地：凭借香港身份，参与国内联招考试（港澳台华侨生联考），400分以上就可上清北名校，内地320所985/211名校任意报考。家长必看！2023年港澳台华侨生联考可报考的内地320所大学名单！</p>
      </div>
      <div class="plan-good">
        <p class="plan-good-p1">保留两地身份</p>
        <p class="plan-good-p2">目前，通过香港“优秀人才计划”申请香港身份，无需注销内地户口。</p>
      </div>
      <div class="plan-good">
        <p class="plan-good-p1">国际教育资源</p>
        <p class="plan-good-p2">子女可获取的教育资源更加丰富，拥有香港身份的孩子除了可以在香港入读各类学校（含国际学校）也可以在内地入读国际学校。</p>
      </div>
      <div class="plan-good">
        <p class="plan-good-p1">社会保障体系</p>
        <p class="plan-good-p2">香港医疗保障体系让所有香港居民以低价享受高质量医疗服务，即使非永居，家人亦可享受香港优质的医疗资源，还可免费接种疫苗。</p>
      </div>
      <div class="plan-good">
        <p class="plan-good-p1">资产税务规划</p>
        <p class="plan-good-p2">拥有香港身份，可解决全球化资产架构搭建、税务筹划的问题。</p>
      </div>
    </div>

    <div class="box plan-cancel" style="height: 250px;">
      <p class="box-p1" style="margin-bottom: 20px;color: #fff;">取消配额限制</p>
      <p class="plan-cancel-p">香港特首李家超宣布的2023年关于优秀人才计划的新政：取消香港优才计划配额限制，同时为了提高优才审批效率新政还取消了“原则性审批环节”即审批通过后需要带原件到入境处进行会面的环节；增加了线上提交和补件的方式；采用即时获批即时通知的方式，取消了季度统一发文公布方式等。通过历史获批的数据可以看出资讯科技与电讯、金融与会计服务、商业与贸易、建筑工程及制造、艺术和文化都是热门行业。但同时优才项目还是一个综合筛选机制，只有提供合适的材料和恰当的描述，才能机会获得入境处的青睐。</p>
    </div>

    <div class="box" style="background: #fff;">
      <p class="box-p1">增值服务</p>
      <p class="box-p2">我们还为您提供</p>
      <div class="odi-add">
        <div class="odi-add-inner">
          <img class="odi-add-icon" src="../assets/img/finance/ODI/icon_3_1.png" alt="">
          <p class="odi-add-p">境外公司注册</p>
        </div>
        <div class="odi-add-inner">
          <img class="odi-add-icon" src="../assets/img/finance/ODI/icon_3_2.png" alt="">
          <p class="odi-add-p">境外银行开户</p>
        </div>
        <div class="odi-add-inner">
          <img class="odi-add-icon" src="../assets/img/finance/ODI/icon_3_3.png" alt="">
          <p class="odi-add-p">IPO业务咨询</p>
        </div>
        <div class="odi-add-inner">
          <img class="odi-add-icon" src="../assets/img/finance/ODI/icon_3_4.png" alt="">
          <p class="odi-add-p">税务筹划</p>
        </div>
        <div class="odi-add-inner">
          <img class="odi-add-icon" src="../assets/img/finance/ODI/icon_3_5.png" alt="">
          <p class="odi-add-p">VAT税号的申请和申报</p>
        </div>
        <div class="odi-add-inner">
          <img class="odi-add-icon" src="../assets/img/finance/ODI/icon_3_6.png" alt="">
          <p class="odi-add-p">全球商标申请</p>
        </div>
        <div class="odi-add-inner">
          <img class="odi-add-icon" src="../assets/img/finance/ODI/icon_3_7.png" alt="">
          <p class="odi-add-p">香港及境外公证</p>
        </div>
        <div class="odi-add-inner">
          <img class="odi-add-icon" src="../assets/img/finance/ODI/icon_3_8.png" alt="">
          <p class="odi-add-p">代持和法律意见书等服务</p>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'HomeView',
  data(){
    return{
      activeName: '1',
      Dialog: false,
      tab: 1
    }
  },
 
  mounted(){
    window.scrollTo(0,0);
  },
  methods:{
    toAsk(){
      window.open('https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn','_blank')
    },
    toUrl(url){
      this.$router.push({name: url})
    },
    toPoint(){
      this.$router.push({name: 'point'})
    }
  }
}
</script>
<style scoped>
  .join-banner{
    background: url('../assets/img/plan/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
  .plan-cancel{
    background: url('../assets/img/plan/pic_5.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
</style>
