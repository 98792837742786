<template>
  <div class="certification m-bvi">
    <div class="m-bvi-banner">
      <p class="f1-banner-p1">十余年专业财税筹划</p>
      <p class="f1-banner-p2">美国专业会计师团队<span style="color: rgba(47, 114, 235, 1)">1对1</span> 为您服务</p>
      <p class="f1-banner-p3">正规审计报告 解决税务难题</p>
      <p class="banner-btn banner-btn1" @click="toAsk">立即咨询</p>
    </div>
    <div class="box" style="height: 510px;">
      <p class="box-p1" style="width: 100vw;">在美国成立公司不做财税筹划的影响</p>
      <div class="a-f-box1">
        <div class="a-f-box1-inner">
          <img class="a-f-box1-icon" src="../../assets/img/finance/America/icon_1_1.png" alt="">
          <p class="a-f-box1-p">逾期产生罚款</p>
        </div>
        <div class="a-f-box1-inner">
          <img class="a-f-box1-icon" src="../../assets/img/finance/America/icon_1_2.png" alt="">
          <p class="a-f-box1-p">公司被强制注销</p>
        </div>
        <div class="a-f-box1-inner">
          <img class="a-f-box1-icon" src="../../assets/img/finance/America/icon_1_3.png" alt="">
          <p class="a-f-box1-p">银行账户被关闭</p>
        </div>
      </div>
    </div>
    <div class="box" style="height: 645px;background: rgba(242, 245, 250, 1);">
      <p class="box-p1">哪些美国公司必须做账审计</p>
      <img class="hk-img1" style="width: 270px;height: 270px;" src="../../assets/img/finance/America/pic_1.jpg" alt="">
      <p class="hk-p1">银行账户已经留下经营记录</p>
      <p class="hk-p1">政府海关、物流公司已经留下进出口记录</p>
      <p class="hk-p1">与美国客商发生购销头条</p>
      <p class="hk-p1">在美国已经聘请雇员</p>
      <p class="hk-p1">容许或授权在美国使用专利商标设计等资料</p>
      <p class="hk-p1">容许或授权在美国使用动产收取租金，租赁费等款项</p>
      <p class="hk-p1">委托在美国代销</p>
      <p class="hk-p1">其它得自或产生于美国的利润</p>
      <p class="form-submit" style="width: 96px;margin-top: 30px;margin-left: 5%;">立即咨询</p>
    </div>
    <div class="box hk-form-bg" style="height: 365px;">
      <p class="box-p1" style="color: rgba(255, 255, 255, 1)">美国公司报税费用</p>
      <p class="box-p2" style="color: rgba(214, 231, 255, 1);">请填写以下表单留下您的信息，我们将第一时间联系您，谢谢！</p>
      <div class="form-box">
        <div class="form-line" style="background: rgba(255, 255, 255, 0.1);border: none;">
          <p class="form-label" style="background: rgba(36, 94, 201, 1);">姓名</p>
          <input class="form-input" type="text" placeholder="请输入您的姓名" v-model="form.name">
        </div>
        <div class="form-line" style="background: rgba(255, 255, 255, 0.1);border: none;">
          <p class="form-label" style="background: rgba(36, 94, 201, 1);">电话</p>
          <input class="form-input" type="text" placeholder="请输入您的联系方式" v-model="form.mobile">
        </div>
        <div class="form-line" style="background: rgba(255, 255, 255, 0.1);border: none;">
          <p class="form-label" style="background: rgba(36, 94, 201, 1);">您的需求</p>
          <input class="form-input" type="text" placeholder="请输入您的需求" v-model="form.remark">
        </div>
      </div>
      <p class="form-submit" @click="toSubmit" style="background: rgba(255, 255, 255, 0.2);border: 1px solid rgba(255, 255, 255, 1);  ">提交</p>
    </div>
    <div class="box" style="height: 597px;">
      <p class="box-p1">如何规避美国银行调查和税务信息影响</p>
      <div class="hk-box2">
        <div class="hk-box2-inner hk-box2-inner-bg1">
          <p class="hk-box2-p">选择在美国进行税务申报的优势</p>
        </div>
        <div class="hk-box2-inner hk-box2-inner-bg2">
          <p class="hk-box2-p">选择正规的美国会计师行做账审计</p>
        </div>
        <div class="hk-box2-inner hk-box2-inner-bg3">
          <p class="hk-box2-p">出具正规的审计报告让税务局无处挑剔</p>
        </div>
      </div>
    </div>

    <div class="box" style="height: auto;padding-bottom: 30px;background: rgba(242, 245, 250, 1)">
      <p class="box-p1">财税筹划专业指导</p>
      <p class="box-p2">财税筹划中常见的专业问题</p>
      <div class="question">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item title="美国公司注册下来后要交税吗？" name="1">
            <p class="question-p">公司注册后是要报税的，分为零报税和交税
零申报：公司成立后没有业务销售，可以申请零申报
做账报税：公司有业务销售，提供单据给美国会计师做账报税</p>
          </el-collapse-item>
          <el-collapse-item title="美国公司交税以什么作为依据?" name="2">
            <p class="question-p"></p>
          </el-collapse-item>
          <el-collapse-item title="如何判断美国公司需要缴纳的税种?" name="3">
            <p class="question-p">每个州的基本情况不同，比如加州有800美金的基本州税，不管公司是否盈利都需缴纳。无基本州税的地区按
照公司营业利润申报州税及联邦税。</p>
          </el-collapse-item>
        </el-collapse>
      </div>
      <p class="to-ask" @click="toAsk"> 
        <img class="to-ask-icon" src="../../assets/img/register/bvi/icon_6_customer_service.png" alt=""> 
        更多问题？立即咨询人工客服</p>
    </div>
    <div class="box" style="height: 573px">
      <p class="box-p1">记帐/核数/报税流程</p>
      <p class="box-p2">全程跟进</p>
      <img class="hk-box5-img" src="../../assets/img/finance/America/pic_4.jpg" alt="">
      <div class="trademark-flow">
        <p class="trademark-flow-p1" style="margin-left: 0;margin-bottom: 10px;">
          <img class="trademark-flow-icon" src="../../assets/img/finance/innerTrademark/pic_2_1.png" alt="">
          <span class="trademark-flow-span1">第一步：</span>
          <span class="trademark-flow-span2">评估、报价、商洽后做报账协议</span> 
        </p>
        <p class="trademark-flow-p1" style="margin-left: 0;margin-bottom: 10px;">
          <img class="trademark-flow-icon" src="../../assets/img/finance/innerTrademark/pic_2_2.png" alt="">
          <span class="trademark-flow-span1">第二步：</span>
          <span class="trademark-flow-span2">预交款项</span> 
        </p>
        <p class="trademark-flow-p1" style="margin-left: 0;margin-bottom: 10px;">
          <img class="trademark-flow-icon" src="../../assets/img/finance/innerTrademark/pic_2_3.png" alt="">
          <span class="trademark-flow-span1">第三步：</span>
          <span class="trademark-flow-span2">整理单据税务处理</span> 
        </p>
        <p class="trademark-flow-p1" style="margin-left: 0;margin-bottom: 10px;">
          <img class="trademark-flow-icon" src="../../assets/img/finance/innerTrademark/pic_2_4.png" alt="">
          <span class="trademark-flow-span1">第四步：</span>
          <span class="trademark-flow-span2">做账完毕</span> 
        </p>
        <p class="trademark-flow-p1" style="margin-left: 0;margin-bottom: 10px;">
          <img class="trademark-flow-icon" src="../../assets/img/finance/innerTrademark/pic_2_5.png" alt="">
          <span class="trademark-flow-span1">第五步：</span>
          <span class="trademark-flow-span2">出具财务报表确认</span> 
        </p>
        <p class="trademark-flow-p1" style="margin-left: 0;margin-bottom: 10px;">
          <img class="trademark-flow-icon" src="../../assets/img/finance/innerTrademark/pic_2_6.png" alt="">
          <span class="trademark-flow-span1">第六步：</span>
          <span class="trademark-flow-span2">报税</span> 
        </p>
        <p class="trademark-flow-p1" style="margin-left: 0;margin-bottom: 10px;">
          <img class="trademark-flow-icon" src="../../assets/img/finance/innerTrademark/pic_2_7.png" alt="">
          <span class="trademark-flow-span1">第七步：</span>
          <span class="trademark-flow-span2">付尾款</span> 
        </p>
      </div>
    </div>

    <div class="box" style="height: 348px;background: rgba(242, 245, 250, 1);">
      <p class="box-p1">一站式专业审计服务</p>
      <p class="box-p2">我们为您提供</p>
      <div class="hk-box3">
        <div class="hk-box3-inner box3-bg1">
          <p class="hk-box3-inner-p">做账报税</p>
        </div>
        <div class="hk-box3-inner box3-bg2">
          <p class="hk-box3-inner-p">税务筹划</p>
        </div>
        <div class="hk-box3-inner box3-bg3">
          <p class="hk-box3-inner-p">审计报告</p>
        </div>
        <div class="hk-box3-inner box3-bg4">
          <p class="hk-box3-inner-p">税务指南</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'bvi-page',
  props: {
    msg: String
  },
  data(){
    return{
      form: {},
      activeName: '1'
    }
  },
  mounted(){
    window.scrollTo(0,0);
  },
  methods:{
    toAsk(){
      window.open('https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn','_blank')
    },
    toSubmit(){
      this.$message({
          message: '提交成功！',
          type: 'success',
          offset: 100
        });
    }
  }
}
</script>

<style scoped lang="scss">
  .m-bvi-banner{
    width: 100%;
    height: 460px;
    background: url('../../assets/img/finance/America/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
  .hk-form-bg{
    background: url('../../assets/img/finance/HongKong/pic_2.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
  .hk-box2-inner-bg1{
    background: url('../../assets/img/finance/HongKong/pic_3_1.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
  .hk-box2-inner-bg2{
    background: url('../../assets/img/finance/HongKong/pic_3_2.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
  .hk-box2-inner-bg3{
    background: url('../../assets/img/finance/HongKong/pic_3_3.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
  .box3-bg1{
    background: url('../../assets/img/finance/HongKong/pic_6_1.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
  .box3-bg2{
    background: url('../../assets/img/finance/HongKong/pic_6_2.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
  .box3-bg3{
    background: url('../../assets/img/finance/HongKong/pic_6_3.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
  .box3-bg4{
    background: url('../../assets/img/finance/HongKong/pic_6_4.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
</style>
