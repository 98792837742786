<template>
  <div class="certification m-bvi">
    <div class="m-bvi-banner">
      <p class="f1-banner-p1">美国公司年审</p>
      <p class="f1-banner-p2">一站式全程代办<br />最快两周完成</p>
      <p class="banner-btn banner-btn1" @click="toAsk">立即咨询</p>
    </div>

    <div class="box" style="height: auto;padding-bottom: 20px;">
      <p class="box-p1">为什么要按时年审</p>
      <p class="box-p2">逾期年审影响</p>
      <div class="why-exma1">
        <div class="why-exma1-inner">
          <img class="why-exma-inner-icon" src="../../assets/img/examination/America/icon_1_1.png" alt="">
          <div class="why-exma1-right">
            <p class="why-exma-inner-p">逾期越长，罚款越多</p>
            <p class="why-exma-inner-p1">各州政府会根据逾期的时长和注册地来决定罚款金额，年审逾期会产生罚款，时间越长罚款越多</p>
          </div> 
        </div>
        <div class="why-exma1-inner">
          <img class="why-exma-inner-icon" src="../../assets/img/examination/America/icon_1_2.png" alt="">
          <div class="why-exma1-right">
            <p class="why-exma-inner-p">逾期将被标记，影响正常经营</p>
            <p class="why-exma-inner-p1">美国公司逾期年审三个月会被标记为不正常经营，无法正常开展经营活动，难以维持客户关系 </p>
          </div> 
        </div>
        <div class="why-exma1-inner">
          <img class="why-exma-inner-icon" src="../../assets/img/examination/America/icon_1_3.png" alt="">
          <div class="why-exma1-right">
            <p class="why-exma-inner-p">被政府列入黑名单</p>
            <p class="why-exma-inner-p1">除此之外，该公司会被列入政府黑名单会有不良信誉记录的，除了美国公司不能正常运作外，该公司董事/股东后续想在美国注册公司就会有一定的阻碍</p>
          </div> 
        </div>
        <div class="why-exma1-inner">
          <img class="why-exma-inner-icon" src="../../assets/img/examination/America/icon_1_4.png" alt="">
          <div class="why-exma1-right">
            <p class="why-exma-inner-p">公司被强制除名</p>
            <p class="why-exma-inner-p1">美国公司如果长期不做年审，不仅仅会被罚款，公司也会被除名。一两年后被所在州政府自动注销除名的，这种情况是属于被政府强制除名的</p>
          </div> 
        </div>
      </div>
    </div>

    <div class="box" style="height: auto;padding-bottom: 30px;background: rgba(242, 245, 250, 1);">
      <p class="box-p1">美国公司年审办理时间与注意事项</p>
      <div class="amer-exma-tips">
        <div class="amer-exma-tips-inner">
          <img class="amer-exma-tips-icon" src="../../assets/img/examination/America/icon_2_1.png" alt="">
          <p class="amer-exma-tips-p">美国公司需要年审的最好提前一个月提前办理年审手续流程与要求，保障公司的正常经营</p>
        </div>
        <div class="amer-exma-tips-inner">
          <img class="amer-exma-tips-icon" src="../../assets/img/examination/America/icon_2_2.png" alt="">
          <p class="amer-exma-tips-p">美国政府对于没有在美国经营，没有和美国发生贸易往来的美国公司，可以不用做账和缴税（依然需要报税），即进行零申报。反之则需要缴纳相应税款</p>
        </div>
        <div class="amer-exma-tips-inner">
          <img class="amer-exma-tips-icon" src="../../assets/img/examination/America/icon_2_3.png" alt="">
          <p class="amer-exma-tips-p">美国公司如果不按时年审，州政府会根据逾期的时长和注册地来决定罚款金额</p>
        </div>
      </div>
    </div>
    

    <div class="box" style="height: auto;padding-bottom: 20px;">
      <p class="box-p1">美国公司年审事项</p>
      <div class="exma-things" style="padding-top: 20px;">
        <div class="exma-things-inner">
          <img class="exma-things-icon" src="../../assets/img/examination/America/icon_3_1.png" alt="">
          <p class="exma-things-p1">年审周期</p>
          <p class="exma-things-p2">特拉华州：每年3月1日前；加州、科罗拉多州、纽约州、内华达州、俄勒冈州：每年的注册月</p>
        </div>
        <div class="exma-things-inner">
          <img class="exma-things-icon" src="../../assets/img/examination/America/icon_3_2.png" alt="">
          <p class="exma-things-p1">所需资料</p>
          <p class="exma-things-p2">注册下来的主体文件</p>
        </div>
        <div class="exma-things-inner">
          <img class="exma-things-icon" src="../../assets/img/examination/America/icon_3_3.png" alt="">
          <p class="exma-things-p1">年审费用</p>
          <p class="exma-things-p2">特拉华州：2620；加州州税：1、只做年检，不办理州税 ¥1500；2、年检＋一年基本州税 ¥8800（如有罚款，需额外支付）科罗拉多州：990；内华达州：¥6,435；俄勒冈州：1,815;纽约州：¥2625</p>
        </div>
        <div class="exma-things-inner">
          <img class="exma-things-icon" src="../../assets/img/examination/America/icon_3_4.png" alt="">
          <p class="exma-things-p1">办理时间</p>
          <p class="exma-things-p2">不同州办理时间不一样</p>
        </div>
        <div class="exma-things-inner">
          <img class="exma-things-icon" src="../../assets/img/examination/America/icon_3_5.png" alt="">
          <p class="exma-things-p1">年审完成后所得数据或文件</p>
          <p class="exma-things-p2">部分州有年审回执</p>
        </div>
      </div>
    </div>


    <div class="box" style="height: auto;background: rgba(242, 245, 250, 1);">
      <p class="box-p1">快企务优质秘书服务</p>
      <p class="box-p2">快企务为您提供更多服务</p>
      <div class="exma-serve">
        <img class="exma-serve-img" src="../../assets/img/examination/HK/pic_3_1.jpg" alt="">
        <img class="exma-serve-img" src="../../assets/img/examination/HK/pic_3_2.jpg" alt="">
        <img class="exma-serve-img" src="../../assets/img/examination/HK/pic_3_3.jpg" alt="">
        <img class="exma-serve-img" src="../../assets/img/examination/HK/pic_3_4.jpg" alt="">
        <img class="exma-serve-img" src="../../assets/img/examination/HK/pic_3_5.jpg" alt="">
        <img class="exma-serve-img" src="../../assets/img/examination/HK/pic_3_6.jpg" alt="">

      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'bvi-page',
  props: {
    msg: String
  },
  data(){
    return{
      form: {},
      activeName: '1'
    }
  },
  mounted(){
    window.scrollTo(0,0);
  },
  methods:{
    toAsk(){
      window.open('https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn','_blank')
    },
  }
}
</script>

<style scoped lang="scss">
  .m-bvi-banner{
    width: 100%;
    height: 460px;
    background: url('../../assets/img/examination/America/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
</style>
