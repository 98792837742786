<template>
  <div class="certification m-bvi">
    <div class="m-bvi-banner">
      <div class="banner-tag">
        <p class="banner-tag-inner">无需回美国</p>
        <p class="banner-tag-inner">平台式申请</p>
        <p class="banner-tag-inner">合同保障</p>
      </div>
      <p class="banner-p1" style="padding-top: 20px">美国无犯罪记录申请</p>
      <p class="banner-p2">留学 | 绿卡 | 工作 | 签证 | 留学</p>
      <p class="banner-btn banner-btn1" @click="toAsk">立即咨询</p>
    </div>
    <div class="box" style="height: 373px;background: #fff;">
      <p class="box-p1">美国无犯罪记录办理</p>
      <p class="box-p2">请填写以下表单留下您的信息，我们将第一时间联系您，谢谢！</p>
      <div class="form-box">
        <div class="form-line">
          <p class="form-label">姓名</p>
          <input class="form-input" placeholder="请输入您的姓名" type="text" v-model="form.name">
        </div>
        <div class="form-line">
          <p class="form-label">电话</p>
          <input class="form-input" type="text" placeholder="请输入您的联系方式" v-model="form.mobile">
        </div>
        <div class="form-line">
          <p class="form-label">您的需求</p>
          <input class="form-input" type="text" placeholder="请输入您的需求" v-model="form.remark">
        </div>
      </div>
      <p class="form-submit" @click="toSubmit">提交</p>
    </div>
    <div class="box" style="background: rgba(242, 245, 250, 1);height: 440px;">
      <p class="box-p1" style="margin-bottom: 20px;">美国有犯罪办理类型</p>
      <div class="fingle-box">
        <p class="fingle-p1">有指纹无犯罪</p>
        <p class="fingle-p2">电子版可申请加急</p>
        <p class="fingle-p3">具体收费请咨询客户经理</p>
        <p class="fingle-p4">可办理移民、绿卡、工作、签证</p>
        <p class="fingle-p4">需2 ~ 3个工作日</p>
        <p class="fingle-p4">需2张指纹卡、FD-258原件、783表格，护照复印件</p>
      </div>
      <p class="fingle-tips">注：全国各城市公证处均可采集公证指纹</p>
    </div>
    
    <div class="box" style="height: 504px;background: #fff;">
      <p class="box-p1" style="width: 96%;">哪个部门可出具美国无犯罪记录证明</p>
      <!-- <p class="box-p2" style="margin-bottom: 10px">加拿大驻中国大使馆或领事馆并不能出具加拿大无犯罪记录证明，申请人需通过加拿大皇家警察和地方警察署来申请加拿大无犯罪记录证明</p> -->
      <div class="lunbo-box cer-lunbo-box" >
          <van-swipe class="my-swipe" :autoplay="3000" indicator-color="rgba(47, 114, 235, 1)" >
            <van-swipe-item>
              <div class="noc-swiper-box">
                <img class="noc-swiper-img" src="../../assets/img/noCriminal/America/pic_2_1.jpg" alt="">
                <div class="noc-swiper-bottom">
                  <p class="noc-swiper-p1">当地警察局</p>
                  <p class="noc-swiper-p2">Local police station</p>
                  <p class="noc-swiper-p3">名字（Name Based）检索的方式</p>
                  <p class="form-submit" style="width: 88px;margin: 20px 0 0 20px;">立即咨询</p>
                  <p class="noc-swiper-p5">无指纹无犯罪</p>
                </div>
              </div>
            </van-swipe-item>
            <van-swipe-item>
              <div class="noc-swiper-box">
                <img class="noc-swiper-img" src="../../assets/img/noCriminal/America/pic_2_2.jpg" alt="">
                <div class="noc-swiper-bottom">
                  <p class="noc-swiper-p1">加拿大皇家警察RCMP</p>
                  <p class="noc-swiper-p2">Royal Canadian Mounted Police</p>
                  <p class="noc-swiper-p3">指纹采集（Fingerprint acquisition）鉴定方式</p>
                  <p class="form-submit" style="width: 88px;margin: 20px 0 0 20px;">立即咨询</p>
                  <p class="noc-swiper-p5">有指纹无犯罪</p>
                </div>
              </div>
            </van-swipe-item>
          </van-swipe>
        </div>
    </div>

    <div class="box" style="background: rgba(242, 245, 250, 1);height: 516px;">
      <p class="box-p1">美国无犯罪记录证明有效期</p>
      <p class="box-p2" style="margin-bottom: 20px">一般为6个月内的无犯罪证明，超出需重新办理</p>
      <p class="box-p1" style="padding-top: 0;font-size: 14px;font-weight: bold;">6个月以内无犯罪证明</p>
      <p class="box-p2" style="margin-bottom: 20px">美国无犯罪记录证明报告上并不会体现该报告的有效期，一般来讲，用于在国内申请工作签证或永久居留权时，政府部门要求该报告为六个月以内的无犯罪记录证明，超出6个月则需要重新办理</p>
      <p class="form-submit" style="width: 88px;">立即咨询</p>
      <img class="box-img-center" src="../../assets/img/noCriminal/America/pic_3.png" alt="">
    </div>

    <div class="box" style="height: 489px;">
      <p class="box-p1">服务优势</p>
      <div class="noc-adv">
        <div class="noc-adv-inner">
          <img class="noc-adv-img" src="../../assets/img/noCriminal/Canada/icon_1_1.png" alt="">
          <p class="noc-adv-p">专业人员全程跟进</p>
        </div>
        <div class="noc-adv-inner">
          <img class="noc-adv-img" src="../../assets/img/noCriminal/Canada/icon_1_2.png" alt="">
          <p class="noc-adv-p">丰富经验积累</p>
        </div>
        <div class="noc-adv-inner">
          <img class="noc-adv-img" src="../../assets/img/noCriminal/Canada/icon_1_3.png" alt="">
          <p class="noc-adv-p">保护客户隐私</p>
        </div>
        <div class="noc-adv-inner">
          <img class="noc-adv-img" src="../../assets/img/noCriminal/Canada/icon_1_4.png" alt="">
          <p class="noc-adv-p">量身定制办理方案</p>
        </div>
        <div class="noc-adv-inner">
          <img class="noc-adv-img" src="../../assets/img/noCriminal/Canada/icon_1_5.png" alt="">
          <p class="noc-adv-p">合同保障客户权益</p>
        </div>
        <div class="noc-adv-inner">
          <img class="noc-adv-img" src="../../assets/img/noCriminal/Canada/icon_1_6.png" alt="">
          <p class="noc-adv-p">实时反馈办理进度</p>
        </div>
      </div>
    </div>

    <div class="box" style="height: auto;padding-bottom: 30px;background: rgba(242, 245, 250, 1)">
      <p class="box-p1">美国无犯罪热门问答</p>
      <div class="question">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item title="美国无犯罪证明怎么开？" name="1">
            <p class="question-p">美国FBI无犯罪证明申请资料：
1、申请美国无犯罪证明的申请表，即I-783无犯罪记录申请2、提供申请者的指纹卡，FD-258标准
3、申请人的有效护照扫描件/复印件
</p>
          </el-collapse-item>
          <el-collapse-item title="移民美国办理的无犯罪记录证明的有效期是多久?" name="2">
            <p class="question-p">美国无犯罪证明是没有有效期的，美国无犯罪证明认证文件有效期是6个月 美国无犯罪证明，也就是证明在美国
居住生活期间是合法的公民，该份证明是没有一直有效的</p>
          </el-collapse-item>
          <el-collapse-item title="美国的无犯罪记录证明该怎么做？" name="3">
            <p class="question-p">如果您曾经在美国居留超过6个月，申请西班牙学习签证时会被要求提供美国无犯罪记录证明。美国无犯罪有两
种形式，一种是FBI出具的无犯罪记录证明，范围覆盖美国全境；另一种是各州局出具的无犯罪记录证明</p>
          </el-collapse-item>
        </el-collapse>
      </div>
      <p class="to-ask" @click="toAsk"> 
        <img class="to-ask-icon" src="../../assets/img/register/bvi/icon_6_customer_service.png" alt=""> 
        更多问题？立即咨询人工客服</p>
    </div>

    <div class="box" style="height: 339px;">
      <p class="box-p1">美国无犯罪记录案例介绍</p>
      <div class="lunbo-box cer-lunbo-box" style="margin-top: 20px">
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="rgba(47, 114, 235, 1)" >
          <van-swipe-item>
            <div class="cer-case-box">
              <img class="cer-case-img" src="../../assets/img/noCriminal/America/pic_4_1.jpg" alt="">
              <img class="cer-case-img" src="../../assets/img/noCriminal/America/pic_4_2.jpg" alt="">
            </div>
          </van-swipe-item>
          <van-swipe-item>
            <div class="cer-case-box">
              <img class="cer-case-img" src="../../assets/img/noCriminal/America/pic_4_3.jpg" alt="">
            </div>
          </van-swipe-item>
        </van-swipe>
      </div>
    </div>
    <div class="box noc-bottom">
      <p class="box-p1" style="color: #fff;">办理中国驻美国大使馆领事认证</p>
      <p class="box-p2" style="width: 300px;color: rgba(214, 231, 255, 1);">美国无犯罪记录证明用于国内工作签证或永久居留权申请，需要办理中国驻美国大使馆的领事认证</p>
      <p class="noc-bottom-p1">将申请下来的美国无犯罪记录证明及当事人的护照扫描件提交至国际公证人处办理国际公证</p>
      <p class="noc-bottom-p1">提交至美国外交部或者省政府指定部门进行办理认证</p>
      <p class="noc-bottom-p1">交至中国驻美国大使馆办理认证</p>
      <p class="noc-bottom-p2">正常办理：15-18个工作日</p>
      <p class="noc-bottom-p2">加急服务：7-10个工作日（不包含国际邮寄时间）</p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'bvi-page',
  props: {
    msg: String
  },
  data(){
    return{
      form: {},
      activeName: '1'
    }
  },
  mounted(){
    window.scrollTo(0,0);
  },
  methods:{
    toAsk(){
      window.open('https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn','_blank')
    },
    toSubmit(){
      this.$message({
          message: '提交成功！',
          type: 'success',
          offset: 100
        });
    }
  }
}
</script>

<style scoped lang="scss">
  .m-bvi-banner{
    width: 100%;
    height: 460px;
    background: url('../../assets/img/noCriminal/America/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
  .fingle-box{
    background: url('../../assets/img/noCriminal/Canada/pic_1_2.png') no-repeat center center;
    background-size: 100% 100%;
  }
  .fingle-box1{
    background: url('../../assets/img/noCriminal/Canada/pic_1_1.png') no-repeat center center;
    background-size: 100% 100%;
  }
  .noc-bottom{
    background: url('../../assets/img/noCriminal/Canada/pic_5.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
</style>
