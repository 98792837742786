<template>
  <div class="certification m-bvi">
    <div class="m-bvi-banner">
      <div class="banner-tag">
        <p class="banner-tag-inner">无需回英国</p>
        <p class="banner-tag-inner">平台式申请</p>
        <p class="banner-tag-inner">合同保障</p>
      </div>
      <p class="banner-p1" style="padding-top: 20px">英国无犯罪记录申请</p>
      <p class="banner-p2">移民 | 绿卡 | 工作 | 签证 | 留学 | 优才</p>
      <p class="banner-btn banner-btn1" @click="toAsk">立即咨询</p>
    </div>
    <div class="box" style="height: 373px;background: #fff;">
      <p class="box-p1">英国无犯罪记录办理</p>
      <p class="box-p2">请填写以下表单留下您的信息，我们将第一时间联系您，谢谢！</p>
      <div class="form-box">
        <div class="form-line">
          <p class="form-label">姓名</p>
          <input class="form-input" placeholder="请输入您的姓名" type="text" v-model="form.name">
        </div>
        <div class="form-line">
          <p class="form-label">电话</p>
          <input class="form-input" type="text" placeholder="请输入您的联系方式" v-model="form.mobile">
        </div>
        <div class="form-line">
          <p class="form-label">您的需求</p>
          <input class="form-input" type="text" placeholder="请输入您的需求" v-model="form.remark">
        </div>
      </div>
      <p class="form-submit" @click="toSubmit">提交</p>
    </div>
    <div class="box" style="background: rgba(242, 245, 250, 1);height: 440px;">
      <p class="box-p1" style="margin-bottom: 20px;">英国无指纹无犯罪办理</p>
      <div class="fingle-box">
        <p class="fingle-p1">无指纹无犯罪</p>
        <p class="fingle-p2">电子版可申请加急</p>
        <p class="fingle-p3">具体收费请咨询客户经理</p>
        <p class="fingle-p4">可办理工作、入职、留学、续签证</p>
        <p class="fingle-p4">需15 ~ 20个工作日</p>
        <p class="fingle-p4">无需奔赴，就是这么任性</p>
        <p class="fingle-p4">需申请表、护照扫描件、住址证明、照片</p>
      </div>
      <p class="fingle-tips">注：全国各城市公证处均可采集公证指纹</p>
    </div>
    
    <div class="box" style="height: 504px;background: #fff;">
      <p class="box-p1" style="width: 96%;">哪个部门可出具英国无犯罪记录证明</p>
      <!-- <p class="box-p2" style="margin-bottom: 10px">加拿大驻中国大使馆或领事馆并不能出具加拿大无犯罪记录证明，申请人需通过加拿大皇家警察和地方警察署来申请加拿大无犯罪记录证明</p> -->
      <div class="lunbo-box cer-lunbo-box" >
          <van-swipe class="my-swipe" :autoplay="3000" indicator-color="rgba(47, 114, 235, 1)" >
            <van-swipe-item>
              <div class="noc-swiper-box">
                <img class="noc-swiper-img" src="../../assets/img/noCriminal/Britain/pic_2.jpg" alt="">
                <div class="noc-swiper-bottom">
                  <p class="noc-swiper-p1">英国警察协会刑事记录办公室</p>
                  <p class="noc-swiper-p2">Office of criminal records,British PoliceAssociation</p>
                  <p class="noc-swiper-p3">名字（Name Based）检索的方式</p>
                  <p class="form-submit" style="width: 88px;margin: 20px 0 0 20px;">立即咨询</p>
                  <p class="noc-swiper-p5">无指纹无犯罪</p>
                </div>
              </div>
            </van-swipe-item>
          </van-swipe>
        </div>
    </div>

    <div class="box" style="background: rgba(242, 245, 250, 1);height: 516px;">
      <p class="box-p1">英国无犯罪记录证明有效期</p>
      <p class="box-p2" style="margin-bottom: 20px">一般为6个月内的无犯罪证明，超出需重新办理</p>
      <p class="box-p1" style="padding-top: 0;font-size: 14px;font-weight: bold;">6个月以内无犯罪证明</p>
      <p class="box-p2" style="margin-bottom: 20px">英国无犯罪记录证明报告上并不会体现该报告的有效期，一般来讲，用于在国内申请工作签证或永久居留权时，政府部门要求该报告为六个月以内的无犯罪记录证明，超出6个月则需要重新办理</p>
      <p class="form-submit" style="width: 88px;">立即咨询</p>
      <img class="box-img-center" src="../../assets/img/noCriminal/Britain/pic_3.png" alt="">
    </div>

    <div class="box" style="height: 489px;">
      <p class="box-p1">服务优势</p>
      <div class="noc-adv">
        <div class="noc-adv-inner">
          <img class="noc-adv-img" src="../../assets/img/noCriminal/Canada/icon_1_1.png" alt="">
          <p class="noc-adv-p">专业人员全程跟进</p>
        </div>
        <div class="noc-adv-inner">
          <img class="noc-adv-img" src="../../assets/img/noCriminal/Canada/icon_1_2.png" alt="">
          <p class="noc-adv-p">丰富经验积累</p>
        </div>
        <div class="noc-adv-inner">
          <img class="noc-adv-img" src="../../assets/img/noCriminal/Canada/icon_1_3.png" alt="">
          <p class="noc-adv-p">保护客户隐私</p>
        </div>
        <div class="noc-adv-inner">
          <img class="noc-adv-img" src="../../assets/img/noCriminal/Canada/icon_1_4.png" alt="">
          <p class="noc-adv-p">量身定制办理方案</p>
        </div>
        <div class="noc-adv-inner">
          <img class="noc-adv-img" src="../../assets/img/noCriminal/Canada/icon_1_5.png" alt="">
          <p class="noc-adv-p">合同保障客户权益</p>
        </div>
        <div class="noc-adv-inner">
          <img class="noc-adv-img" src="../../assets/img/noCriminal/Canada/icon_1_6.png" alt="">
          <p class="noc-adv-p">实时反馈办理进度</p>
        </div>
      </div>
    </div>

    <div class="box" style="height: auto;padding-bottom: 30px;background: rgba(242, 245, 250, 1)">
      <p class="box-p1">英国无犯罪热门问答</p>
      <div class="question">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item title="在照片进行背书是什么意思?" name="1">
            <p class="question-p">背书是需要申请人找你认识两年以上的朋友，有正当职业，不得是你的配偶或家庭成员，并且年满18岁（任何
国籍)。需要在照片背后写上申请人的姓氏和出生日期，背书人自己的签名并注明日期。</p>
          </el-collapse-item>
          <el-collapse-item title="警局证明信的有效期有多久?" name="2">
            <p class="question-p">通常来说警局注册信没有一个明确的有效期，但是通常情况下如果申请人需要出具警局证明信来辅助签证申请，
则建议在警局注册信的出具日期在签证申请日期的6个月内出具。</p>
          </el-collapse-item>
          <el-collapse-item title="警局证明信将包含哪些信息?" name="3">
            <p class="question-p">警局证明信将会包含申请人在英国警局系统当中所记录的所有的犯罪记录，警告等不良记录。</p>
          </el-collapse-item>
          <el-collapse-item title="如果我现在居住在国外，但我之前在英国居住过，那我如何证明自己的英国地址呢?" name="3">
            <p class="question-p">如果申请人居住在国外，那么申请人不需要提供地址证明来证明其在英国的地址。申请人只需要提供地址证明来证明
现在的居住地址并告知警局办公室过去10年的英国地址。</p>
          </el-collapse-item>
          <el-collapse-item title="谁可以申请警局证明信?" name="3">
            <p class="question-p">任何在英国居住过的人都可以申请警局证明信。</p>
          </el-collapse-item>
        </el-collapse>
      </div>
      <p class="to-ask" @click="toAsk"> 
        <img class="to-ask-icon" src="../../assets/img/register/bvi/icon_6_customer_service.png" alt=""> 
        更多问题？立即咨询人工客服</p>
    </div>

    <div class="box" style="height: 339px;">
      <p class="box-p1">英国无犯罪记录证明案例</p>
      <div class="lunbo-box cer-lunbo-box" style="margin-top: 20px">
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="rgba(47, 114, 235, 1)" >
          <van-swipe-item>
            <div class="cer-case-box">
              <img class="cer-case-img" src="../../assets/img/noCriminal/Britain/pic_4_1.jpg" alt="">
              <img class="cer-case-img" src="../../assets/img/noCriminal/Britain/pic_4_2.jpg" alt="">
            </div>
          </van-swipe-item>
          <van-swipe-item>
            <div class="cer-case-box">
              <img class="cer-case-img" src="../../assets/img/noCriminal/Britain/pic_4_3.jpg" alt="">
            </div>
          </van-swipe-item>
        </van-swipe>
      </div>
    </div>
    <div class="box noc-bottom">
      <p class="box-p1" style="color: #fff;">办理中国驻英国大使馆领事认证</p>
      <p class="box-p2" style="width: 300px;color: rgba(214, 231, 255, 1);">英国无犯罪记录证明用于国内工作签证或永久居留权申请，需要办理中国驻英国大使馆的领事认证</p>
      <p class="noc-bottom-p1">将申请下来的英国无犯罪记录证明及当事人的护照扫描件提交至国际公证人处办理国际公证</p>
      <p class="noc-bottom-p1">提交至英国外交部或者省政府指定部门进行办理认证</p>
      <p class="noc-bottom-p1">交至中国驻英国大使馆办理认证</p>
      <p class="noc-bottom-p2">正常办理：15-18个工作日</p>
      <p class="noc-bottom-p2">加急服务：7-10个工作日（不包含国际邮寄时间）</p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'bvi-page',
  props: {
    msg: String
  },
  data(){
    return{
      form: {},
      activeName: '1'
    }
  },
  mounted(){
    window.scrollTo(0,0);
  },
  methods:{
    toAsk(){
      window.open('https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn','_blank')
    },
    toSubmit(){
      this.$message({
          message: '提交成功！',
          type: 'success',
          offset: 100
        });
    }
  }
}
</script>

<style scoped lang="scss">
  .m-bvi-banner{
    width: 100%;
    height: 460px;
    background: url('../../assets/img/noCriminal/Britain/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
  .fingle-box{
    background: url('../../assets/img/noCriminal/Canada/pic_1_2.png') no-repeat center center;
    background-size: 100% 100%;
  }
  .fingle-box1{
    background: url('../../assets/img/noCriminal/Canada/pic_1_1.png') no-repeat center center;
    background-size: 100% 100%;
  }
  .noc-bottom{
    background: url('../../assets/img/noCriminal/Canada/pic_5.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
</style>
