<template>
  <div class="home">
    <div class="m-bvi-banner">
      <p class="banner-p1">一站式开曼公司注册服务</p>
      <p class="banner-p2">快企务专注于涉内外企业服务十余年</p>
      <p class="banner-btn banner-btn1" @click="toAsk">立即咨询</p>
    </div>
    <div class="box" style="height: 473px;background: rgba(242, 245, 250, 1);">
      <p class="box-p1">注册开曼公司的优势</p>
      <div class="adv-content">
        <div class="adv-line">
          <img class="adv-icon" src="../../assets/img/register/kaiman/icon_1_1.png" alt="">
          <p class="adv-p">一位股东，一位董事，可同一人</p>
        </div>
        <div class="adv-line">
          <img class="adv-icon" src="../../assets/img/register/kaiman/icon_1_2.png" alt="">
          <p class="adv-p">注册资金＄50,000，且不需要验资</p>
        </div>
        <div class="adv-line">
          <img class="adv-icon" src="../../assets/img/register/kaiman/icon_1_3.png" alt="">
          <p class="adv-p">非当地运营可不交当地税，可投资国内</p>
        </div>
        <div class="adv-line">
          <img class="adv-icon" src="../../assets/img/register/kaiman/icon_1_4.png" alt="">
          <p class="adv-p">记名/不记名股票，可互换</p>
        </div>
        <div class="adv-line">
          <img class="adv-icon" src="../../assets/img/register/kaiman/icon_1_5.png" alt="">
          <p class="adv-p">资料保密，取名自由，架构搭建</p>
        </div>
      </div>
    </div>
    <div class="box" style="height: auto;padding-bottom: 30px;">
      <p class="box-p1">注册开曼公司的类型</p>
      <div class="type-content flex center-width">
        <img class="type-img" style="height: 110px;" src="../../assets/img/register/kaiman/pic_1_1.jpg" alt="">
        <img class="type-img" style="height: 110px;" src="../../assets/img/register/kaiman/pic_1_2.jpg" alt="">
        <img class="type-img" style="height: 110px;" src="../../assets/img/register/kaiman/pic_1_3.jpg" alt="">
        <img class="type-img" style="height: 110px;" src="../../assets/img/register/kaiman/pic_1_4.jpg" alt="">
        <img class="type-img" style="height: 110px;" src="../../assets/img/register/kaiman/pic_1_5.jpg" alt="">
        <img class="type-img" style="height: 110px;" @click="toAsk" src="../../assets/img/register/Britain/pic_2_4.jpg" alt="">
      </div>
    </div>
    <div class="box bg-box" style="height: auto;padding-bottom: 15px;">
      <p class="box-p1">注册开曼公司条件/流程/所得资料</p>
      <div class="tab-box">
        <div class="tab-inner" :class="{'active': tab == 1}" @click="tab = 1">注册条件</div>
        <div class="tab-inner" :class="{'active': tab == 2}" @click="tab = 2">注册流程</div>
        <div class="tab-inner" :class="{'active': tab == 3}" @click="tab = 3">所得资料</div>
      </div>
      <div class="file-content flex center-width">
        <div v-if="tab == 1">
          <div class="tab1-inner">
            <img class="tab1-icon" src="../../assets/img/register/kaiman/icon_2_1.png" alt="">
            <div class="tab1-right">
              <p class="tab1-p1">名称</p>
              <p class="tab1-p2">可中文、英文，一般以“有限公司”LIMITED结尾，如出现银行、信托、
保险、基金等字样需要政府特许
</p>
            </div>
          </div>
          <div class="tab1-inner">
            <img class="tab1-icon" src="../../assets/img/register/kaiman/icon_2_2.png" alt="">
            <div class="tab1-right">
              <p class="tab1-p1">股东</p>
              <p class="tab1-p2">至少一名，自然人或法人，国籍不限，提供身份证、护照等身份证明文件</p>
            </div>
          </div>
          <div class="tab1-inner">
            <img class="tab1-icon" src="../../assets/img/register/kaiman/icon_2_3.png" alt="">
            <div class="tab1-right">
              <p class="tab1-p1">董事</p>
              <p class="tab1-p2">至少一名，自然人或法人，国籍不限；提供身份证、护照等身份证明文件；
股东和董事可以为同一人，没有限制</p>
            </div>
          </div>
          <div class="tab1-inner">
            <img class="tab1-icon" src="../../assets/img/register/kaiman/icon_2_4.png" alt="">
            <div class="tab1-right">
              <p class="tab1-p1">秘书</p>
              <p class="tab1-p2">不需要，必须委任开曼公司或个人当注册代理人（我司提供）</p>
            </div>
          </div>
          <div class="tab1-inner">
            <img class="tab1-icon" src="../../assets/img/register/kaiman/icon_2_5.png" alt="">
            <div class="tab1-right">
              <p class="tab1-p1">资本</p>
              <p class="tab1-p2">50,000美金，无需验资，可划分为50,000股</p>
            </div>
          </div>
          <div class="tab1-inner">
            <img class="tab1-icon" src="../../assets/img/register/kaiman/icon_2_2.png" alt="">
            <div class="tab1-right">
              <p class="tab1-p1">地址</p>
              <p class="tab1-p2">必须是当地地址（我司可提供）</p>
            </div>
          </div>
        </div>
        <div v-if="tab == 2">
          <div class="kaiman-tab2">
            <div class="kaiman-tab2-inner">
              <img class="kaiman-tab2-icon" src="../../assets/img/register/kaiman/icon_3_1.png" alt="">
              <p class="kaiman-tab2-p"><span style="color: rgba(47, 114, 235, 1);font-weight: bold;">第一步：</span>提供三个公司名称给快企务进行查名</p>
            </div>
            <div class="kaiman-tab2-inner">
              <img class="kaiman-tab2-icon" src="../../assets/img/register/kaiman/icon_3_2.png" alt="">
              <p class="kaiman-tab2-p"><span style="color: rgba(47, 114, 235, 1);font-weight: bold;">第二步：</span>进行免费查名一般1~2工作日</p>
            </div>
            <div class="kaiman-tab2-inner">
              <img class="kaiman-tab2-icon" src="../../assets/img/register/kaiman/icon_3_3.png" alt="">
              <p class="kaiman-tab2-p"><span style="color: rgba(47, 114, 235, 1);font-weight: bold;">第三步：</span>客户签订合同</p>
            </div>
            <div class="kaiman-tab2-inner">
              <img class="kaiman-tab2-icon" src="../../assets/img/register/kaiman/icon_3_4.png" alt="">
              <p class="kaiman-tab2-p"><span style="color: rgba(47, 114, 235, 1);font-weight: bold;">第四步：</span>约8个工作日完成注册</p>
            </div>
            <div class="kaiman-tab2-inner">
              <img class="kaiman-tab2-icon" src="../../assets/img/register/kaiman/icon_3_5.png" alt="">
              <p class="kaiman-tab2-p"><span style="color: rgba(47, 114, 235, 1);font-weight: bold;">第五步：</span>客户领取注册文件的扫描件</p>
            </div>
            <div class="kaiman-tab2-inner">
              <img class="kaiman-tab2-icon" src="../../assets/img/register/kaiman/icon_3_6.png" alt="">
              <p class="kaiman-tab2-p"><span style="color: rgba(47, 114, 235, 1);font-weight: bold;">第六步：</span>文件快递给客户或客户自行提取</p>
            </div>
            
          </div>
        </div>
        <div v-if="tab ==3">
          <div class="kaiman-tab3">
            <div class="kaiman-tab3-inner">
              <img class="kaiman-tab3-icon" src="../../assets/img/register/kaiman/icon_4_1.png" alt="">
              <p class="kaiman-tab3-p">发起人决议书</p>
            </div>
            <div class="kaiman-tab3-inner">
              <img class="kaiman-tab3-icon" src="../../assets/img/register/kaiman/icon_4_2.png" alt="">
              <p class="kaiman-tab3-p">首任董事决议书</p>
            </div>
            <div class="kaiman-tab3-inner">
              <img class="kaiman-tab3-icon" src="../../assets/img/register/kaiman/icon_4_3.png" alt="">
              <p class="kaiman-tab3-p">公司唯一董事决议书</p>
            </div>
            <div class="kaiman-tab3-inner">
              <img class="kaiman-tab3-icon" src="../../assets/img/register/kaiman/icon_4_4.png" alt="">
              <p class="kaiman-tab3-p">企业注册登记证原件</p>
            </div>
            <div class="kaiman-tab3-inner">
              <img class="kaiman-tab3-icon" src="../../assets/img/register/kaiman/icon_4_5.png" alt="">
              <p class="kaiman-tab3-p">公司章程复印件</p>
            </div>
            <div class="kaiman-tab3-inner">
              <img class="kaiman-tab3-icon" src="../../assets/img/register/kaiman/icon_4_6.png" alt="">
              <p class="kaiman-tab3-p">股权证明</p>
            </div>
            <div class="kaiman-tab3-inner">
              <img class="kaiman-tab3-icon" src="../../assets/img/register/kaiman/icon_4_7.png" alt="">
              <p class="kaiman-tab3-p">股权转让书</p>
            </div>
            <div class="kaiman-tab3-inner">
              <img class="kaiman-tab3-icon" src="../../assets/img/register/kaiman/icon_4_8.png" alt="">
              <p class="kaiman-tab3-p">公司钢印和图章</p>
            </div>
            <div class="kaiman-tab3-inner">
              <img class="kaiman-tab3-icon" src="../../assets/img/register/kaiman/icon_4_9.png" alt="">
              <p class="kaiman-tab3-p">开曼公司文件登记时间表</p>
            </div>
            <div class="kaiman-tab3-inner">
              <img class="kaiman-tab3-icon" src="../../assets/img/register/kaiman/icon_4_10.png" alt="">
              <p class="kaiman-tab3-p">公司章程原件</p>
            </div>
            <div class="kaiman-tab3-inner">
              <img class="kaiman-tab3-icon" src="../../assets/img/register/kaiman/icon_4_11.png" alt="">
              <p class="kaiman-tab3-p">公司成员/董事/职员登记册和股权转让</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="box" style="height: 797px;">
      <p class="box-p1">开曼公司注册后续服务</p>
      <div class="re-serve">
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/kaiman/icon_5_1.png" alt="">
          <p class="re-serve-p1">年审/续牌</p>
          <p class="re-serve-p2">年审或续牌根据国家地区的要求，如期进行合规申报，否则产生罚款</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/kaiman/icon_5_2.png" alt="">
          <p class="re-serve-p1">股东董事变更</p>
          <p class="re-serve-p2">公司经营过程中的股东董事变更</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/kaiman/icon_5_3.png" alt="">
          <p class="re-serve-p1">公司名称变更</p>
          <p class="re-serve-p2">公司经营过程中的公司名称变更</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/kaiman/icon_5_4.png" alt="">
          <p class="re-serve-p1">章程修订</p>
          <p class="re-serve-p2">根据公司经营实际情况和相关法律法规、监管部门的要求，协助公司董事会拟对公司章程进行修订</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/kaiman/icon_5_5.png" alt="">
          <p class="re-serve-p1">良好证明</p>
          <p class="re-serve-p2">一般应银行需求，协助提供相关证明</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/kaiman/icon_5_6.png" alt="">
          <p class="re-serve-p1">代理变更</p>
          <p class="re-serve-p2">公司经营过程中的代理变更</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/kaiman/icon_5_7.png" alt="">
          <p class="re-serve-p1">董事在职证明申请</p>
          <p class="re-serve-p2">一般应银行需求，协助提供相关证明</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/kaiman/icon_5_8.png" alt="">
          <p class="re-serve-p1">公司注销</p>
          <p class="re-serve-p2">公司确认不继续经营之后，需及时提交相关资料进行注销，避免产生后续罚款</p>
        </div>
      </div>
    </div>

    <div class="box" style="height: 812px;">
      <p class="box-p1">注册开曼公司的利弊</p>
      <div class="libi">
        <div class="li-box" style="padding-bottom: 20px;height: auto;">
          <p class="li-p1">注册开曼公司的利</p>
          <p class="li-p2">开曼政治、经济和贸易环境稳定；</p>
          <p class="li-p2">拥有良好的金融法律设施；</p>
          <p class="li-p2">开曼政府保护股东利益，不公布受益人身份；</p>
          <p class="li-p2">政府鼓励经济发展、吸引外资，一个人即可注册一家有限公司；</p>
          <p class="li-p2">政府为企业提供隐私保护，董事、股东资料享有高度的隐秘性，绝对保密，完全不对外公开；</p>
          <p class="li-p2">取名自由，允许使用多个国家公司结尾形式；</p>
          <p class="li-p2">注册资金无上限，无需交纳厘印税；</p>
          <p class="li-p2">经营范围没有限制，无外汇管制；</p>
          <p class="li-p2">不需出具核数报告，只需保留资料反映经济状况即可；</p>
          <p class="li-p2">离岸经营所得利润无须交利得税。</p>
        </div>
        <div class="bi-box" style="height: 314px;">
          <p class="bi-p1">注册开曼公司的弊</p>
          <p class="bi-p2">首先，中小型企业并无必要大费周章去开曼群岛注册。大型企业通过开曼注册的部分原因是已经拥有足够大的国内市场，准备进入纳斯达克，以此实现曲线海外上市。但是，中小型企业并无该必要。</p>
          <p class="bi-p2">再次，在外注册公司可能不能享受相应的政府补贴。例如现代汽车，刚开始国内发售，政府补贴非常大；而如果是国外注册的，就不会有这样的补贴，国为税收低。</p>
          <p class="bi-p2">最后，削弱民族品牌意识。例如韩国三星曾经准备纳斯达克上市的时候在开曼注册，但是国内反响很强烈，于是又把公司迁回国。可见，韩国民族品牌意识非常强。那么，在国内注册也可以在一定程度上加强民族意识，增强品牌认同。</p>
        </div>
      </div>
    </div>

    <div class="box" style="height: auto;padding-bottom: 30px;background: rgba(242, 245, 250, 1)">
      <p class="box-p1">开曼公司注册常见问题</p>
      <div class="question" style="margin-top: 20px">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item title="开曼群岛介绍" name="1">
            <p class="question-p">开曼群岛由加勒比海中三个岛屿组成，主岛为大开曼岛，经济发达，达到全民就业，政府收入大部份来自金融服务业
及旅游业，官方语言以英语为主，通用货币是美元。政府对于持续把开曼群岛发展成为一个离岸金融中心给予很大支
持。开曼群岛的名声和受欢迎程度日趋增强，特别在亚洲地区，因其是香港证券交易所仅有的两个获准上市的离岸法
区之一。在1997年，开曼群岛建立自己的证券交易所,开曼的法律体系是参照英国普通法体系设立的。</p>
          </el-collapse-item>
          <el-collapse-item title="投资区块链为什么选择开曼？" name="2">
            <p class="question-p">1、没有附加条例限制贸易发展；
2、政治稳定良好，没有对国外利润征税；
3、没有外汇管制，资金可灵活调动；4、公司类型为免除公司的名称无须用"LIMITED"（即"有限"）字样结尾；
5、保密性良好。开曼的税务免除公司与一个自然人享有完全相同的权利；
6、开曼群岛完全没有税收，无论是对个人、公司还是信托行业都不征任何税；</p>
          </el-collapse-item>
          <el-collapse-item title="注册开曼公司有什么固定要求？" name="3">
            <p class="question-p">1、资本要求：开曼群岛政府对其离岸公司的注册资本并没有限制，但一般采用50,000美元作为最少注册资本，可划分
为50,000股，每股1美元。
2、股东要求：注册开曼公司至少一名股东，可以是自然人(年满18周岁)或法人，无国籍限制;董事资料需向公司注册处
披露，但这些资料不会被公开作公众查册。
3、董事要求：注册开曼公司至少一名董事，可以是自然人(年满18周岁)或法人，无国籍限制;
4、名称要求：注册开曼公司名称没有特殊要求，一般以“LIMITED”结尾，如出现“银行”、“信托”、“保险”、
“基金”等字样需要经过相关政府部门特许才行。
5、税务要求：无需申报或缴纳任何税项，除了年度牌照费。</p>
          </el-collapse-item>
          <el-collapse-item title="注册开曼群岛公司，为什么能减少缴税?" name="4">
            <p class="question-p">开曼群岛完全没有税收，无论是对个人、公司还是信托行业都不征任何税。所以开曼群岛被称为一个真正的税务避风
港。开曼群岛在1978年获得一个皇家法令，法律规定永远豁免开曼群岛的缴税义务，目前这个法令持续有效。还可申
请一个20年有效的证书更进一步保证其无须缴税的地位。没有利润税、财产税，唯一之税项为印花税，任何转让或按
揭房地产均需缴付印花税。</p>
          </el-collapse-item>
          <el-collapse-item title="开曼公司名称可以随意取吗？" name="5">
            <p class="question-p">对公司名称没有特殊要求，除非经过特许，否则公司名称不能出现BANK(银行) 、TRUST(信托)、MUTUAL FUND(基
金)、INSURANCE(保险)，或是REINSURANCE(再保险)等字眼。</p>
          </el-collapse-item>
          <el-collapse-item title="开曼公司的信息保密性高吗？" name="6">
            <p class="question-p">开曼公司的保密性良好。开曼的税务免除公司与一个自然人享有完全相同的权利。免除公司必须每年提交年检报告，但
是年检报告中无须被露公司董事和成员信息。2001年4月，开曼政府颁布了新规定要求所有公司向注册代理技露公司主
要负责人、成员、受益人和授权人的信息。这个规定适用所有新公司，已注册的公司必须在一定期限内提供有关信息。
有关信息被视为商业机密，受《保密关系维护法》管辖。因此任何人如果披露该信息或试图获取该信息或通过非法途径
获取了该信息，将融犯刑律。</p>
          </el-collapse-item>
        </el-collapse>
      </div>
      <p class="to-ask" @click="toAsk"> 
        <img class="to-ask-icon" src="../../assets/img/register/bvi/icon_6_customer_service.png" alt=""> 
        更多问题？立即咨询人工客服</p>
    </div>
    <div class="box" style="height: 480px;">
      <p class="box-p1">快企务的优势</p>
      <p class="box-p2">十余年注册开曼公司经验</p>
      <div class="re-adv">
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_1.png" alt="">
          <p class="re-adv-p1">保密完善</p>
          <p class="re-adv-p2">保密系统完善，客户隐私不外泄</p>
        </div>
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_2.png" alt="">
          <p class="re-adv-p1">全程服务</p>
          <p class="re-adv-p2">一站式专业服务从注册公司到年审、开户，审计报税等</p>
        </div>
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_3.png" alt="">
          <p class="re-adv-p1">专业高效</p>
          <p class="re-adv-p2">根据需求，为客户制定专业快捷的解决方案</p>
        </div>
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_4.png" alt="">
          <p class="re-adv-p1">合同保障</p>
          <p class="re-adv-p2">正规机构，合同保障，费用透明，无隐形消费</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  data(){
    return{
      tab: 1,
      activeName: '1'
    }
  },
  mounted(){
    window.scrollTo(0,0);
  },
  methods:{
    toAsk(){
      window.open('https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn','_blank')
    },
  }
}
</script>
<style scoped>
  .m-bvi-banner{
    width: 100%;
    height: 460px;
    background: url('../../assets/img/register/kaiman/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
</style>
