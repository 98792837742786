<template>
  <div class="home">
    <div class="m-bvi-banner">
      <p class="banner-p1">一站式卢森堡公司注册服务</p>
      <p class="banner-p2">【快企务专注于涉内外企业服务十余年】</p>
      <p class="banner-btn banner-btn1" @click="toAsk">立即咨询</p>
    </div>

    <div class="box" style="height: 313px;">
      <p class="box-p1">注册卢森堡公司优势</p>
      <div class="cy-adv">
        <div class="cy-adv-inner">
          <img class="cy-adv-icon" src="../../assets/img/register/Luxembourg/pic_1_1.png" alt="">
          <p class="cy-adv-p">近50个国家签订双重税收优惠</p>
        </div>
        <div class="cy-adv-inner">
          <img class="cy-adv-icon" src="../../assets/img/register/Luxembourg/pic_1_2.png" alt="">
          <p class="cy-adv-p">经济政治环境安全稳定</p>
        </div>
        <div class="cy-adv-inner">
          <img class="cy-adv-icon" src="../../assets/img/register/Luxembourg/pic_1_3.png" alt="">
          <p class="cy-adv-p">国际金融中心商业中心</p>
        </div>
        <div class="cy-adv-inner">
          <img class="cy-adv-icon" src="../../assets/img/register/Luxembourg/pic_1_4.png" alt="">
          <p class="cy-adv-p">创新金融产品高效专业</p>
        </div>
        <div class="cy-adv-inner">
          <img class="cy-adv-icon" src="../../assets/img/register/Luxembourg/pic_1_5.png" alt="">
          <p class="cy-adv-p">没被任何国家列入黑名单</p>
        </div>
        <div class="cy-adv-inner">
          <p class="cy-adv-p1" @click="toAsk">立即咨询 ></p>
        </div>
      </div>
    </div>

    <div class="box" style="height: auto;padding-bottom: 30px;">
      <p class="box-p1">注册卢森堡公司类型</p>
      <div class="type-content flex center-width">
        <img class="type-img" style="height: 110px;" src="../../assets/img/register/Luxembourg/pic_2_1.jpg" alt="">
        <img class="type-img" style="height: 110px;" src="../../assets/img/register/Luxembourg/pic_2_2.jpg" alt="">
        </div>
    </div>
    <div class="box bg-box" style="height: auto;padding-bottom: 15px;">
      <p class="box-p1">注册卢森堡公司条件/流程/资料</p>
      <div class="tab-box">
        <div class="tab-inner" :class="{'active': tab == 1}" @click="tab = 1">注册条件</div>
        <div class="tab-inner" :class="{'active': tab == 2}" @click="tab = 2">注册流程</div>
        <div class="tab-inner" :class="{'active': tab == 3}" @click="tab = 3">所得资料</div>
      </div>
      <div class="file-content">
        <div v-if="tab == 1">
          <div class="french-flow">
            <div class="french-flow-inner">
              <img class="french-flow-icon" src="../../assets/img/register/Luxembourg/pic_3_1.png" alt="">
              <p class="french-flow-p1">公司名称</p>
              <p class="french-flow-p2">3-4个使用名称</p>
            </div>
            <div class="french-flow-inner">
              <img class="french-flow-icon" src="../../assets/img/register/Luxembourg/pic_3_2.png" alt="">
              <p class="french-flow-p1">基本资料</p>
              <p class="french-flow-p2">护照复印件、联系地址、签名样本</p>
            </div>
            <div class="french-flow-inner">
              <img class="french-flow-icon" src="../../assets/img/register/Luxembourg/pic_3_3.png" alt="">
              <p class="french-flow-p1">持股百分比</p>
              <p class="french-flow-p2">各个股东的持股百分比</p>
            </div>
            <div class="french-flow-inner">
              <img class="french-flow-icon" src="../../assets/img/register/Luxembourg/pic_3_4.png" alt="">
              <p class="french-flow-p1">计划书</p>
              <p class="french-flow-p2">有关从事行业的计划书</p>
            </div>
            <div class="french-flow-inner">
              <img class="french-flow-icon" src="../../assets/img/register/Luxembourg/pic_3_5.png" alt="">
              <p class="french-flow-p1">授权书</p>
              <p class="french-flow-p2">当客户无法亲自到场时，公司所有者有关新建公司事宜的授权</p>
            </div>
            <div class="french-flow-inner">
              <img class="french-flow-icon" src="../../assets/img/register/Luxembourg/pic_3_6.png" alt="">
              <p class="french-flow-p1">启动资金</p>
              <p class="french-flow-p2">公司启动资金总数</p>
            </div>
          </div>
        </div>
        <div v-if="tab == 2">
          <img class="tab2-img" src="../../assets/img/register/Luxembourg/pic_4.jpg" alt="">
        </div>
        <div v-if="tab == 3">
          <div class="ger-tab3">
            <div class="ger-tab3-inner">
              <img class="ger-tab3-icon" src="../../assets/img/register/Luxembourg/pic_5_1.png" alt="">
              <p class="ger-tab3-p">公司成立证书（A、C）</p>
            </div>
            <div class="ger-tab3-inner">
              <img class="ger-tab3-icon" src="../../assets/img/register/Luxembourg/pic_5_2.png" alt="">
              <p class="ger-tab3-p">公司注册摘录(A、C）</p>
            </div>
            <div class="ger-tab3-inner">
              <img class="ger-tab3-icon" src="../../assets/img/register/Luxembourg/pic_5_3.png" alt="">
              <p class="ger-tab3-p">股权证书</p>
            </div>
            <div class="ger-tab3-inner">
              <img class="ger-tab3-icon" src="../../assets/img/register/Luxembourg/pic_5_4.png" alt="">
              <p class="ger-tab3-p"> 如果客户直接持股</p>
            </div>
            <div class="ger-tab3-inner">
              <img class="ger-tab3-icon" src="../../assets/img/register/Luxembourg/pic_5_5.png" alt="">
              <p class="ger-tab3-p">如果我方提供匿名股东</p>
            </div>
            <div class="ger-tab3-inner">
              <img class="ger-tab3-icon" src="../../assets/img/register/Luxembourg/pic_5_6.png" alt="">
              <p class="ger-tab3-p">股东名册（A）</p>
            </div>
            <div class="ger-tab3-inner">
              <img class="ger-tab3-icon" src="../../assets/img/register/Luxembourg/pic_5_7.png" alt="">
              <p class="ger-tab3-p">董事登记册</p>
            </div>
            <div class="ger-tab3-inner">
              <img class="ger-tab3-icon" src="../../assets/img/register/Luxembourg/pic_5_8.png" alt="">
              <p class="ger-tab3-p">股份登记册</p>
            </div>
            <div class="ger-tab3-inner">
              <img class="ger-tab3-icon" src="../../assets/img/register/Luxembourg/pic_5_9.png" alt="">
              <p class="ger-tab3-p"> 指定匿名股东或董事之协议（如有A和C）</p>
            </div>
            <div class="ger-tab3-inner">
              <img class="ger-tab3-icon" src="../../assets/img/register/Luxembourg/pic_5_10.png" alt="">
              <p class="ger-tab3-p">公司长形签名章（C）</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    
    <div class="box" style="height: 725px;background: rgba(242, 245, 250, 1);">
      <p class="box-p1">注册卢森堡公司后为您提供的后续服务</p>
      <div class="re-serve">
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Luxembourg/pic_6_1.png" alt="">
          <p class="re-serve-p1">年审/续牌</p>
          <p class="re-serve-p2">所有公司须按年度定时递交包含股东、董事、公司秘书和其他任何当年可能影响公司行政管理结构详情信息的公司年度报表</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Luxembourg/pic_6_2.png" alt="">
          <p class="re-serve-p1">秘书服务</p>
          <p class="re-serve-p2">办公地址，公司周年报表年检，公司董事股东成员名册更新，在职证明与良好证明，代缴政府规费，修改公司章程等</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Luxembourg/pic_6_3.png" alt="">
          <p class="re-serve-p1">做账报税</p>
          <p class="re-serve-p2">公司注册之后需按照当地政府单位要求进行做账报税</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Luxembourg/pic_6_4.png" alt="">
          <p class="re-serve-p1">银行开户</p>
          <p class="re-serve-p2">开设公司注册所在地的银行账户，有利于公司随时接受贸易汇款，享受当地政策或规避外汇风险</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Luxembourg/pic_6_5.png" alt="">
          <p class="re-serve-p1">税号申请</p>
          <p class="re-serve-p2">公司经营过程中的公司信息（如地址、股东、经营范围等）变更</p>
        </div>
        <div class="re-serve-inner">
          <img class="re-serve-icon" src="../../assets/img/register/Luxembourg/pic_6_6.png" alt="">
          <p class="re-serve-p1">公司注销</p>
          <p class="re-serve-p2">因破产、股东决议解散、存续期届满等原因需要注销的，应及时主动注销公司</p>
        </div>
      </div>
    </div>

    <div class="box" style="height: 480px;">
      <p class="box-p1">快企务的优势</p>
      <p class="box-p2">十余年注册卢森堡公司经验</p>
      <div class="re-adv">
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_1.png" alt="">
          <p class="re-adv-p1">保密完善</p>
          <p class="re-adv-p2">保密系统完善，客户隐私不外泄</p>
        </div>
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_2.png" alt="">
          <p class="re-adv-p1">全程服务</p>
          <p class="re-adv-p2">一站式专业服务从注册公司到年审、开户，审计报税等</p>
        </div>
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_3.png" alt="">
          <p class="re-adv-p1">专业高效</p>
          <p class="re-adv-p2">根据需求，为客户制定专业快捷的解决方案</p>
        </div>
        <div class="re-adv-inner">
          <img class="re-adv-icon" src="../../assets/img/register/bvi/icon_5_4.png" alt="">
          <p class="re-adv-p1">合同保障</p>
          <p class="re-adv-p2">正规机构，合同保障，费用透明，无隐形消费</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  data(){
    return{
      tab: 1,
      activeName: '1'
    }
  },
 
  mounted(){
    window.scrollTo(0,0);
  },
  methods:{
    toAsk(){
      window.open('https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn','_blank')
    },
  }
}
</script>
<style scoped>
  .m-bvi-banner{
    width: 100%;
    height: 460px;
    background: url('../../assets/img/register/Luxembourg/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
</style>
